import { DatePicker, Form, Input, InputNumber, Select, TableProps, Tag } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { CustomDatePresets } from '../utils/datePresets';
import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { find_date_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import { RouteSearch } from '@/components/Common/RouteSearch/RouteSearch';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import { DEFAULT_DATE_FORMAT } from '@/constants';
const { RangePicker } = DatePicker;

const PendingPaymentSellReport = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;
  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      routeId: '',
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Payment Pending Sell',
      link: '/reports/pending-payment-sell'
    }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };
  const columns: ColumnsType<any> = [
    // Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 35,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Date',
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{moment(record.date).local().format(DEFAULT_DATE_FORMAT)}</div>;
      },
      width: 120
    },
    {
      title: 'Invoice No.',
      key: 'reference_number',
      className: 'invoice',
      sorter: (a, b) => a.reference_number.localeCompare(b.reference_number),
      sortOrder: sortedInfo.columnKey === 'reference_number' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setId(record.id);
            }}>
            {record.reference_number}
          </a>
        );
      },
      width: 120
    },

    {
      title: 'Customer Name',
      key: 'customer_name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      sortOrder: sortedInfo.columnKey === 'customer_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.customer_name}</div>;
      },
      width: 120
    },
    // {
    //   title: 'Created By',
    //   key: 'createdBy',
    //   render: (record) => {
    //     return (
    //       // <Link to={`/users/${record.createdBy}`} color="black">
    //       <div>{record.createdBy}</div>
    //       // </Link>
    //     );
    //   }
    // },
    {
      title: 'Location',
      key: 'location_name',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div color="black">{record.location_name}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Route',
      key: 'route_name',
      sorter: (a, b) => a.route_name.localeCompare(b.route_name),
      sortOrder: sortedInfo.columnKey === 'route_name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div>{record.route_name}</div>
          // </Link>
        );
      },
      width: 120
    },
    {
      title: 'Payment Status',
      key: 'payment_status',
      sorter: (a, b) => a.payment_status.localeCompare(b.payment_status),
      sortOrder: sortedInfo.columnKey === 'payment_status' ? sortedInfo.order : null,
      render: (record) => {
        const colorCode: any = { full: 'green', pending: 'red', partial: 'purple' };
        return <Tag color={colorCode[record.payment_status]}>{record.payment_status}</Tag>;
      },
      width: 50
    },
    {
      title: 'Total amount',
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>
          // </Link>
        );
      },
      width: 70
    },
    {
      title: 'Total paid',
      key: 'amount_paid',
      sorter: (a, b) => a.amount_paid - b.amount_paid,
      sortOrder: sortedInfo.columnKey === 'amount_paid' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_paid)}</div>
          // </Link>
        );
      },
      width: 70
    },
    {
      title: 'Total Returned',
      key: 'amount_returned',
      sorter: (a, b) => a.amount_returned - b.amount_returned,
      sortOrder: sortedInfo.columnKey === 'amount_returned' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_returned)}</div>
          // </Link>
        );
      },
      width: 70
    },
    {
      title: 'Total remaining',
      key: 'totalRemaining',
      sorter: (a, b) => {
        const atotalRem = a.total_amount - a.amount_paid;
        const btotalRem = b.total_amount - b.amount_paid;
        return atotalRem - btotalRem;
      },
      sortOrder: sortedInfo.columnKey === 'totalRemaining' ? sortedInfo.order : null,

      render: (record) => {
        return (
          <div color="black" className="classfornormaltext capitalize text-right mr-2">
            {nepaliNumberFormatter(record.total_amount - record.amount_paid)}
          </div>
        );
      },
      width: 70
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      toSort={handleChange}
      // locationList={locationsList?.data.results}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 34, name: 'Pending Payment Sell Report.' }}
      columnsData={columns}
      form={form}
      toDownload={true}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>

      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      <RouteSearchV2 showAll hasParentFormItem={false} name={['constraints', 'routeId']} />
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>

      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default PendingPaymentSellReport;
