import AppContent from '@/components/Common/Content/Content';
import { Alert, Collapse, PageHeader, Spin, message } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useState } from 'react';
import FilterForm from './Form';

import TableData from './Table';
import { check_date_cap, check_export_report_date_cap } from '@/utils/common';
import { download_report, get_report } from '@/services/report/queries';
import { IProductDailyIdentifierWiseTotalReport } from '@/services/report/types';

import withReportPagination, {
  PaginatedReportValues,
  WithReportPaginationProps
} from '@/components/Common/Report/withReportPagination';

import { showReportConditionalDownloadMessage } from '@/utils/reportConditionalDownloadMessage';
import { getUpdatedData } from './columns.export';

export type ExtendedInitialValues = PaginatedReportValues & {
  constraints: {
    identifier: string;
    unitId: string | number;
    productId: string | number;
    locationId: string | number;
  };
};

function ProductTotalByIdentifierReportBase({
  form,
  initialValues,
  pagination,
  ...handlers
}: WithReportPaginationProps) {
  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    {
      label: 'Product Total Qty and Amount by Identifier',
      link: '/reports/products-daily-identifier-wise-total'
    }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [fullExportURL, setFullExportURL] = useState<any>(null);

  const [data, setData] = useState({
    total: 0,
    data: [] as IProductDailyIdentifierWiseTotalReport[]
  });

  const state = { id: 67, name: 'Product Total Qty and Amount by Identifier' };
  const defaultUnitState = { id: 71, name: 'Product Total Qty and Amount by Identifier' };

  const extendedInitialValues: ExtendedInitialValues = {
    ...initialValues,
    constraints: {
      ...initialValues.constraints,
      unitId: 'default',
      productId: '',
      locationId: '',
      identifier: ''
    }
  };

  async function onSearchAll() {
    try {
      setIsLoading(true);
      if (!fullExportURL) {
        message.error('No URL specified. Please search first.');
        return;
      }
      const isDefaultUnitId = fullExportURL?.unitId === 'default';
      const reportId = isDefaultUnitId ? defaultUnitState.id : state.id;
      const { data } = await get_report(fullExportURL, reportId);

      return getUpdatedData(data.data) as any;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSearch(values: ExtendedInitialValues) {
    setIsLoading(true);
    const { constraints } = values;

    try {
      const hasDateCap = check_date_cap(constraints.startDate, constraints.endDate);
      if (hasDateCap) {
        await onDownload();
        return;
      }

      const updatedConstraints = await handlers.getConstraint();
      const isDefaultUnitId = constraints.unitId === 'default';
      const reportId = isDefaultUnitId ? defaultUnitState.id : state.id;

      const newPayload = updatedConstraints as any;
      if (isDefaultUnitId) newPayload.unitId = '';

      const { data } = await get_report(newPayload, reportId);
      if (data.data.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }

      // Get all constraints excluding page, size
      const payload = updatedConstraints as any;
      payload.size = data.count;

      setFullExportURL(payload);
      setData({ total: data.count, data: data.data });
      return data.data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onDownload() {
    if (isEmpty) {
      showReportConditionalDownloadMessage();
      return;
    }
    setIsLoading(true);
    try {
      await form.validateFields();

      const constraints =
        (await handlers.getConstraint()) as unknown as ExtendedInitialValues['constraints'];
      if (!constraints) return;

      const hasDateCap = check_export_report_date_cap(constraints.startDate, constraints.endDate);

      if (!hasDateCap) return;
      const customReportQueueName = state.name;
      const isDefaultUnitId = constraints.unitId === 'default';
      const reportId = isDefaultUnitId ? defaultUnitState.id : state.id;

      if (isDefaultUnitId) constraints.unitId = '';

      message.loading({
        key: 'downloading',
        content: `${customReportQueueName} Downloading`,
        duration: 0
      });
      await download_report({ ...constraints, customReportQueueName }, reportId);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Collapse>
          <CollapsePanel header="Filters" key="1">
            <FilterForm
              form={form}
              isLoading={isLoading}
              initialValues={extendedInitialValues}
              onSearch={onSearch}
              onDownload={onDownload}
              onChange={handlers.onChange}
              onDisabledDate={handlers.onDisabledDate}
              onChangeNepali={handlers.onChangeNepali}
            />
          </CollapsePanel>
        </Collapse>

        <Alert
          style={{ margin: '8px 0px' }}
          type="info"
          showIcon
          message='When the "All with Default Unit" option is selected, all product prices are based on the default unit of the selected products.'
        />

        <PageHeader subTitle="Table" style={{ padding: '8px 0px' }} />
        <TableData
          data={data}
          pagination={pagination}
          onSearch={onSearch}
          onSearchAll={onSearchAll}
          onChange={handlers.onChange}
          onPagination={handlers.onPagination}
        />
      </AppContent>
    </Spin>
  );
}

const ProductTotalByIdentifierReport = withReportPagination(ProductTotalByIdentifierReportBase);
ProductTotalByIdentifierReport.displayName = 'ProductTotalByIdentifierReport';

export default ProductTotalByIdentifierReport;
