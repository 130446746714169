import AppContent from '@/components/Common/Content/Content';
import AgentSearchV2 from '@/components/Common/CustomSearch/Agents';
import { create_purchase_agent_bill_range } from '@/services/purchases/mutations';
import { IPurchaseAgentBillRange } from '@/services/purchases/types';
import getError from '@/utils/getError';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, InputNumber, message, Modal, PageHeader, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  isParentLoading?: boolean;
  data?: IPurchaseAgentBillRange;
  type: 'create';
  breadcrumbItems: { label: string; link: string }[];
}

interface FormValues {
  invoiceNumber: string;
  min: number;
  max: number;
  agentId: number;
}

function AgentBillMutationUI({ breadcrumbItems, isParentLoading = false, type, data }: Props) {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm<FormValues>();

  // useEffect(() => {
  //   if (type === 'update' && data) {
  //     form.setFieldsValue({
  //       invoiceNumber: data.invoiceNumber,
  //       min: data.min,
  //       max: data.max,
  //       agentId: data.agentId
  //     });
  //   }
  // }, [data]);

  const createMutation = useMutation(create_purchase_agent_bill_range, {
    onSuccess: async () => {
      message.success('Purchase Agent Bill Created Successfully.');
      navigate('/purchases/agents-bill-range');
    },
    onError: (error) => {
      message.error(getError(error) || 'Failed to create purchase agent bill');
    }
  });

  // const updateMutation = useMutation(update_purchase_agent_bill_range, {
  //   onSuccess: async () => {
  //     message.success('Purchase Agent Bill Updated Successfully.');
  //     navigate('/purchases/agents-bill-range');
  //   },
  //   onError: (error) => {
  //     message.error(getError(error) || 'Failed to update purchase agent bill');
  //   }
  // });

  const onSubmit = async () => {
    try {
      setShowModal(false);
      setIsloading(true);
      const payload = form.getFieldsValue();

      if (type === 'create') {
        await createMutation.mutateAsync(payload);
      }
    } finally {
      setIsloading(false);
    }
  };

  const onFinalizeClick = async () => {
    await form.validateFields();
    setShowModal(true);
  };

  return (
    <Spin spinning={isLoading || isParentLoading}>
      <Modal
        title="Confirmation"
        visible={showModal}
        onOk={onSubmit}
        onCancel={() => setShowModal(false)}>
        <div>Are you sure you want to {type} this agent bill range?</div>
      </Modal>

      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader subTitle="Agents Range" style={{ padding: '8px 0px' }} />
          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <AgentSearchV2
              hasParentFormItem={false}
              name={'agentId'}
              required
              defaultValue={data?.agentId}
            />

            <Form.Item
              label="Start"
              name="min"
              rules={[{ required: true, message: 'Please provide start value' }]}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>

            <Form.Item
              label="End"
              name="max"
              rules={[{ required: true, message: 'Please provide end value' }]}>
              <InputNumber className="w-full" min={0} />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-5 gap-4 flex-wrap">
            <Button type="primary" loading={isLoading} onClick={onFinalizeClick}>
              Finalize
            </Button>

            <Button
              type="default"
              htmlType="button"
              onClick={() => navigate('/purchases/agents-bill-range')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default AgentBillMutationUI;
