import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  PageHeader,
  Select
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { UsersSearch } from '../../../components/Common/UsersSearch';
import { AuthContext } from '../../../contexts/auth.context';
import {
  get_purchase_list,
  get_purchase_order_list,
  get_purchase_return_list
} from '../../../services/purchases/queries';
import { get_sell_list, get_sell_order_list } from '../../../services/sell/queries';

import { update_tasks_log, update_tasks_mutation } from '../../../services/tasks/mutations';
import { get_tasks_details, get_tasks_logs } from '../../../services/tasks/queries';
import { ITasks, ITasksCreateRequest } from '../../../services/tasks/types';
import { get_user_list } from '../../../services/users/queries';
import { User } from '../../../services/users/types';
import { get_vehicles_list } from '../../../services/vehicle/queries';
import { IVehicleData, IVehicleLiveData } from '../../../services/vehicle/types';
import RichTextEditor from '../../../components/RichTextEditor';
import TextArea from 'antd/lib/input/TextArea';

interface ITaskDetailsModal {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  taskDetails: any;
  handleResetTaskModal: () => void;
  refetch: () => void;
}

const DetailsModal = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const referenceType = Form.useWatch('reference', form);
  const [taskDetails, setTaskDetails] = useState<ITasks>();
  const { profileData } = useContext(AuthContext);
  const updateTaskLog = useMutation(update_tasks_log);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [activeReferenceOptions, setActiveReferenceOptions] = useState<any[]>([]);
  const [vehicleList, setVehicleList] = useState<IVehicleData[]>([]);
  const [userList, setUserList] = useState<User[]>([]);

  const handleRichTextEditorChange = (field: string, value: string) => {
    form.setFieldValue(field, value);
  };

  useQuery([`taskdetails${id}`], async () => {
    const response = await get_tasks_details(parseInt(id as string));
    // console.log('Task details Response-->', response);
    setTaskDetails(response);
  });

  useQuery(['vehicleList'], async () => {
    // get_vehicles_list;
    const response = await get_vehicles_list();
    // console.log('Response-->', response.data);
    setVehicleList(response.data.vehicles);
    return response;
  });

  useQuery(['users'], async () => {
    const response = await get_user_list(0, 999, '');
    setUserList(response.data.results);
    setLoading(false);
    return response;
  });

  const [taskLogs, setTaskLogs] = useState<any[]>([]);
  useQuery(
    ['task-logs', id],
    async () => {
      const response = await get_tasks_logs(parseInt(id as string));
      // sort decending order based on id
      response.sort((a: any, b: any) => b.id - a.id);
      setTaskLogs(response);
      return response;
    },
    {
      enabled: !!taskDetails?.id
    }
  );

  // useQuery(
  //   ['purchaseList'],
  //   async () => {
  //     const response = await get_purchase_list(0, 999, '');
  //     setActiveReferenceOptions(response.data.results);

  //     return response;
  //   },
  //   {
  //     enabled: referenceType === 'purchase'
  //   }
  // );

  // useQuery(
  //   ['purchaseOrderList'],
  //   async () => {
  //     const response = await get_purchase_order_list(0, 999, '');
  //     setActiveReferenceOptions(response.data.results);

  //     return response;
  //   },
  //   {
  //     enabled: referenceType === 'purchase_order'
  //   }
  // );

  // useQuery(
  //   ['purchaseReturnList'],
  //   async () => {
  //     const response = await get_purchase_return_list(0, 999, '');
  //     setActiveReferenceOptions(response.data.results);

  //     return response;
  //   },
  //   {
  //     enabled: referenceType === 'purchase_return'
  //   }
  // );

  // useQuery(
  //   ['sell'],
  //   async () => {
  //     setLoading(true);
  //     const response = await get_sell_list();
  //     setActiveReferenceOptions(response.data.results);
  //     setLoading(false);

  //     return response;
  //   },
  //   {
  //     enabled: referenceType === 'sell'
  //   }
  // );

  // useQuery(
  //   ['sellOrder'],
  //   async () => {
  //     const response = await get_sell_order_list();
  //     setActiveReferenceOptions(response.data.results);
  //     return response;
  //   },
  //   {
  //     enabled: referenceType === 'sell_order'
  //   }
  // );

  const updateTasksMutation = useMutation(update_tasks_mutation, {
    onSuccess: () => {
      message.success('Task updated successfully');
      if (profileData) {
        updateTaskLog.mutate({
          taskId: parseInt(id as string),
          userId: profileData.id,
          operation: 'Task Details updated'
        });
      }
      setLoading(false);
      navigate('/tasks');
    }
  });
  const onFinish = (values: ITasks) => {
    // console.log('Values-->', values);
    setLoading(true);
    values.id = parseInt(id as string);
    updateTasksMutation.mutate(values);
  };

  useEffect(() => {
    console.log(taskDetails, profileData);
    if (taskDetails && profileData) {
      console.log('this called');
      updateTaskLog.mutate({
        taskId: +taskDetails.id,
        userId: profileData.id,
        operation: 'Task Details Viewed'
      });
      // console.log('form set field values', taskDetails);
      form.setFieldsValue({
        dueDate: dayjs(taskDetails?.dueDate),
        name: taskDetails?.name,
        description: taskDetails.description,
        status: taskDetails.status,
        assignee: taskDetails.assignee,
        reference: taskDetails.reference,
        referenceId: taskDetails.referenceId,
        vehicleId: taskDetails.vehicleId,
        isArchived: true
      });
      setLoading(false);
    }
  }, [taskDetails]);

  useEffect(() => {
    return () => {
      // handleResetTaskModal();
      setTaskLogs([]);
      form.resetFields();
    };
  }, []);

  const getUserFromId = (id: number) => {
    //@ts-ignore
    return userList.find((user) => user?.id == id)?.name;
  };
  // console.log(taskDetails);
  const breadcrumbItems = [
    {
      label: 'Tasks',
      link: '/tasks'
    },
    {
      label: 'Details'
    }
  ];
  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Task Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          initialValues={{
            title: undefined,
            description: undefined,
            status: undefined,
            dueDate: '',
            assignee: undefined,
            reference: undefined,
            referenceId: undefined,
            isArchived: false
          }}
          disabled={loading}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>

          <Form.Item label="ID" name="id" hidden>
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please add description!' }]}>
            {/* <RichTextEditor
              value={form.getFieldValue('description')}
              fieldName="description"
              fn={handleRichTextEditorChange}
            /> */}
            <TextArea
              value={form.getFieldValue('description')}
              rows={5}
              placeholder="Description..."
            />
          </Form.Item>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-5">
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Please add status!' }]}>
              <Select allowClear>
                <Select.Option value={'Packed'}>Packed</Select.Option>
                <Select.Option value={'Dispatched'}>Dispatched</Select.Option>
                <Select.Option value={'Delivered'}>Delivered</Select.Option>
                <Select.Option value={'Assigned'}>Assigned</Select.Option>
              </Select>
            </Form.Item>

            {/* <Form.Item
              label="Assignee"
              name="assignee"
              rules={[{ required: true, message: 'Please add assignee!' }]}>
              <Select allowClear>
                {userList.map((user: any, index: any) => (
                  <Select.Option key={index} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <UsersSearch formData={{ formName: 'assignee' }} isAll={false} required={true} />

            <Form.Item
              label="Due date"
              name="dueDate"
              rules={[{ required: true, message: 'Please add due date!' }]}>
              <DatePicker className="w-full" format={'DD-MM-YYYY'} />
            </Form.Item>
            <Form.Item
              label="Vehicle"
              name="vehicleId"
              rules={[{ required: false, message: 'Please select vehicle' }]}>
              <Select allowClear>
                {vehicleList &&
                  vehicleList?.map((currvehicle: any, index: any) => (
                    <Select.Option key={index} value={currvehicle.id}>
                      {currvehicle.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>

          <PageHeader
            title="References"
            style={{
              padding: '8px 0px'
            }}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mb-5">
            <Form.Item
              label="Reference"
              name="reference"
              rules={[{ required: false, message: 'Please add reference!' }]}>
              <Select allowClear disabled>
                <Select.Option value={'purchase'}>Purchase</Select.Option>
                <Select.Option value={'purchase_order'}>Purchase Order</Select.Option>
                <Select.Option value={'purchase_return'}>Purchase return</Select.Option>
                <Select.Option value={'transfer'}>Transfer</Select.Option>
                <Select.Option value={'adjustment'}>Adjustment</Select.Option>
                <Select.Option value={'sell'}>Sell</Select.Option>
                <Select.Option value={'sell_order'}>Sell order</Select.Option>
                <Select.Option value={'sell_return'}>Sell return</Select.Option>
                <Select.Option value={'other'}>other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Reference ID"
              name="referenceId"
              rules={[{ required: false, message: 'Please add reference!' }]}>
              <Select allowClear loading={loading} disabled>
                {activeReferenceOptions?.map((purchase: any, index: any) => (
                  <Select.Option key={index} value={purchase.id}>
                    {purchase.id}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Is Archived?" name="isArchived">
              <Checkbox
                value={form.getFieldValue(['isArchived'])}
                onChange={(value) => form.setFieldValue(['isArchived'], value.target.checked)}
              />
            </Form.Item>
          </div>
          <Collapse>
            <CollapsePanel header="Tasks Log" key="1">
              {/* <PageHeader
                title="Tasks Log"
                style={{
                  padding: '8px 0px'
                }}
              /> */}

              <List
                itemLayout="horizontal"
                size="small"
                bordered
                dataSource={taskLogs}
                style={{
                  height: 300,
                  overflowY: 'scroll'
                }}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item?.operation}
                      description={
                        'On  ' +
                        dayjs(item?.createdAt).format('DD-MM-YYYY HH:mm') +
                        '  By  ' +
                        getUserFromId(item?.userId)
                      }
                    />
                  </List.Item>
                )}
              />
            </CollapsePanel>
          </Collapse>
          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton text={'Update'} />
            </Form.Item>
            <Button
              style={{ borderRadius: '9px' }}
              type="default"
              className="ml-5"
              htmlType="button"
              // onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
      {/* </Modal> */}
    </div>
  );
};

export default DetailsModal;
