import { useQuery } from '@tanstack/react-query';
import { Button, Form, InputNumber, PageHeader, Pagination, Select, Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import { get_location_list } from '../../../services/locations/queries';
import {
  get_lots_by_location,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { SorterResult } from 'antd/lib/table/interface';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';

interface FormData {
  locationId: number;
  yesterday: number;
  today: number;
}
export const SellEstimation = () => {
  const [isloading, setIsloading] = useState(false);
  const [form] = Form.useForm();
  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const { data: locationsList } = useQuery(['locations'], async () => {
    const response = await get_location_list();
    if (response?.data.results.length > 1) {
      const initialValues: FormData = {
        locationId: response.data.results[0].id,
        yesterday: 1,
        today: 1
      };
      setFormData(initialValues);
      form.setFieldsValue(initialValues);
      onEstimate(initialValues);
    }
    return response;
  });
  const [formData, setFormData] = useState<FormData>({
    locationId: 0,
    yesterday: 1,
    today: 1
  });
  const [data, setData] = useState<any[]>();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  // const columns: ColumnsType<any> = [
  //   {
  //     title: 'Product Name',
  //     key: 'productId',
  //     render: (record) => {
  //       return <div className="forless">{record.productId}</div>;
  //     }
  //   },
  //   {
  //     title: 'Stock Available',
  //     key: 'qtyAvailable',
  //     render: (record) => {
  //       return <div className="forless">{record.qtyAvailable}</div>;
  //     }
  //   },
  //   {
  //     title: 'Total Sold Yesterday',
  //     key: 'qtySold',
  //     render: (record) => {
  //       return <div className="forless">{record.qtySold}</div>;
  //       // return <div className="forless">{record.qtySold}</div>;
  //     }
  //   },
  //   {
  //     title: 'Stock Deficit',
  //     key: 'productId',
  //     render: (record) => {
  //       let total = record.qtySold * (formData.today / formData.yesterday) - record.qtyAvailable;
  //       if (total > 0) {
  //         total = 0;
  //       }
  //       return <div className="forless">{total}</div>;
  //     }
  //   },
  //   {
  //     title: 'Purchase Order',
  //     key: 'productId',
  //     render: (record) => {
  //       let total = record.qtySold * (formData.today / formData.yesterday) - record.qtyAvailable;
  //       if (total < 0) {
  //         total = 0;
  //       }
  //       return <div className="forless">{total}</div>;
  //     }
  //   }
  // ];
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'productId',
      sorter: (a, b) => a.productId - b.productId,
      sortOrder: sortedInfo.columnKey === 'productId' ? sortedInfo.order : null,
      width: 1,
      render: (record) => {
        return <div className="forless">{record.productId}</div>;
      }
    },
    {
      title: 'Product',
      key: 'productName',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      width: 7,
      render: (record) => {
        return <div className="forless">{record.productName}</div>;
      }
    },
    {
      title: 'Unit',
      key: 'unit',
      sorter: (a, b) => a.unit.localeCompare(b.unit),
      sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null,
      width: 3,
      render: (record) => {
        return <div className="forless">{record.unit ? record.unit : 'No Unit'}</div>;
      }
    },
    // {
    //   title: 'Lot',
    //   key: 'lotNumber',
    //   render: (record) => {
    //     return <div className="forless">{record.lotNumber}</div>;
    //   }
    // },
    {
      title: 'Stock Available',
      key: 'qtyAvailable',
      width: 3,
      sorter: (a, b) => a.qtyAvailable - b.qtyAvailable,
      sortOrder: sortedInfo.columnKey === 'qtyAvailable' ? sortedInfo.order : null,
      render: (record) => {
        const baseUnitMultiplier = record.baseUnitMultiplier ? record.baseUnitMultiplier : 1;
        return (
          <div className="forless">{`${record.qtyAvailable / baseUnitMultiplier} ${
            record.shortUnit ? record.shortUnit : ''
          }`}</div>
        );
      }
    },
    {
      title: 'Sold Yesterday',
      key: 'qtySold',
      width: 3,
      sorter: (a, b) => a.qtySold - b.qtySold,
      sortOrder: sortedInfo.columnKey === 'qtySold' ? sortedInfo.order : null,
      render: (record) => {
        const baseUnitMultiplier = record.baseUnitMultiplier ? record.baseUnitMultiplier : 1;
        return (
          <div className="forless">{`${record.qtySold / baseUnitMultiplier} ${
            record.shortUnit ? record.shortUnit : ''
          }`}</div>
        );
      }
    },
    {
      title: 'Stock Deficit',
      key: 'stockDeficit',
      width: 3,
      sorter: (a, b) => {
        const totala = a.qtySold * (formData.today / formData.yesterday) - a.qtyAvailable;
        const totalb = a.qtySold * (formData.today / formData.yesterday) - b.qtyAvailable;
        return totala - totalb;
      },
      sortOrder: sortedInfo.columnKey === 'stockDeficit' ? sortedInfo.order : null,
      render: (record) => {
        let total = record.qtySold * (formData.today / formData.yesterday) - record.qtyAvailable;
        // let total =
        //   record.qtyAvailable * (formData.today / formData.yesterday) - record.qtyAvailable;
        const baseUnitMultiplier = record.baseUnitMultiplier ? record.baseUnitMultiplier : 1;
        if (total > 0) {
          total = 0;
        }
        return (
          <div className="forless">{`${total / baseUnitMultiplier} ${
            record.shortUnit ? record.shortUnit : ''
          }`}</div>
        );
      }
    },
    {
      title: 'Purchase Order',
      key: 'purchaseOrder',
      width: 3,
      sorter: (a, b) => {
        const totala = a.qtySold * (formData.today / formData.yesterday) - a.qtyAvailable;
        const totalb = a.qtySold * (formData.today / formData.yesterday) - b.qtyAvailable;
        return totala - totalb;
      },
      sortOrder: sortedInfo.columnKey === 'purchaseOrder' ? sortedInfo.order : null,
      render: (record) => {
        let total = record.qtySold * (formData.today / formData.yesterday) - record.qtyAvailable;
        // let total =
        //   record.qtyAvailable * (formData.today / formData.yesterday) - record.qtyAvailable;

        if (total < 0) {
          total = 0;
        }
        const baseUnitMultiplier = record.baseUnitMultiplier ? record.baseUnitMultiplier : 1;
        return (
          <div className="forless">{`${total / baseUnitMultiplier} ${
            record.shortUnit ? record.shortUnit : ''
          }`}</div>
        );
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Estimate'
    },
    {
      label: 'Sell',
      link: '/estimate/sell'
    }
  ];

  const onEstimate = async (values: FormData) => {
    try {
      setIsloading(true);
      if (values.locationId) {
        const lotsData = await get_lots_by_location(values.locationId);
        if (lotsData?.data?.length > 0) await accumulateLots(lotsData.data);
        // setData(lotsData.data);
      }
      setFormData(values);
      setIsloading(false);
    } catch (e) {
      setIsloading(false);
      console.log(e);
    }
  };

  const accumulateLots = async (allLots: any[]) => {
    const products: any = {};
    for (let i = 0; i < allLots.length; i++) {
      if (`${allLots[i].productId}` in products) {
        products[`${allLots[i].productId}`].qtyAvailable += allLots[i].qtyAvailable;
        products[`${allLots[i].productId}`].qtySold += allLots[i].qtySold;
      } else {
        products[`${allLots[i].productId}`] = {
          qtyAvailable: allLots[i].qtyAvailable,
          qtySold: allLots[i].qtySold
        };
      }
    }
    // console.log('PRoducts', products);
    // console.log('Products-->', products);
    // console.log('Products length', Object.entries(products).length);
    const convertedData: any[] = [];
    const searchProducts: any[] = [];
    for (const key in products) {
      // console.log('this is running', key);
      const productName: any = await ProductsDB.getProduct(parseInt(key));
      // console.log('productName', productName);
      if (!productName) {
        // const response = await get_product_details(parseInt(key));
        // ProductsDB.addProducts([response]);
        // productName = response;
        searchProducts.push(key);
      } else {
        // console.log('this is running', key);
        const filteredUnit = productName.productUnits.find((value: any) => value.isDefault);
        if (filteredUnit) {
          let findUnit: any = await UnitsDB.getUnit(filteredUnit.unitId);
          if (!findUnit) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            findUnit = await UnitsDB.getUnit(filteredUnit.unitId);
          }
          convertedData.push({
            productId: key,
            productName: productName.name,
            unit: findUnit.name,
            shortUnit: findUnit.shortName,
            baseUnitMultiplier: findUnit.baseUnitMultiplier,
            ...products[key]
          });
        } else {
          convertedData.push({
            productId: key,
            productName: productName.name,
            // unit: findUnit.name,
            // shortUnit: findUnit.shortName,
            ...products[key]
          });
        }
      }
    }
    if (searchProducts.length > 0) {
      const productsResponse = await get_product_list_ids(searchProducts);
      for (let ind = 0; ind < productsResponse?.data?.results.length; ind++) {
        const productName = productsResponse.data.results[ind];
        const filteredUnit = productName.productUnits.find((value: any) => value.isDefault);
        if (filteredUnit) {
          let findUnit: any = await UnitsDB.getUnit(filteredUnit.unitId);
          if (!findUnit) {
            const allUnits = await get_units_list();
            await UnitsDB.addUnits(allUnits);
            findUnit = await UnitsDB.getUnit(filteredUnit.unitId);
          }
          convertedData.push({
            productId: productName.id,
            productName: productName.name,
            unit: findUnit.name,
            shortUnit: findUnit.shortName,
            baseUnitMultiplier: findUnit.baseUnitMultiplier,
            ...products[productName.id]
          });
        } else {
          convertedData.push({
            productId: productName.id,
            productName: productName.name,
            ...products[productName.id]
          });
        }
        ProductsDB.addProducts(productsResponse.data.results);
      }
    }
    // } else {
    //   convertedData.push({
    //     productId: key,
    //     productName: productName.name,
    //     ...products[key]
    //   });
    // }
    // console.log('converted Datas', convertedData);
    setData(convertedData);
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          onFinish={onEstimate}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off"
          disabled={isloading}>
          {/* <PageHeader
            title={state?.name}
            style={{
              padding: '8px 0px'
            }}
          /> */}
          {/* <PageHeader
            subTitle="Constraints"
            style={{
              padding: '8px 0px'
            }}
          /> */}
          <div className="grid grid-cols-3 gap-5 ">
            <Form.Item
              name={'locationId'}
              label="Location"
              rules={[
                {
                  required: true,
                  message: 'Please choose location!'
                }
              ]}>
              <Select placeholder="Select a location!" allowClear>
                {locationsList?.data.results.map((value: any) => {
                  return (
                    <Select.Option value={value.id} key={value.id}>
                      {value.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Customers Yesterday'}
              name={'yesterday'}
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid number!'
                }
              ]}>
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              label={'Customers Today'}
              name={'today'}
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid number!'
                }
              ]}>
              <InputNumber min={1} />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Estimate
              </Button>
            </Form.Item>
          </div>
        </Form>
        {/* <Table scroll={{ x: true }} loading={isloading} columns={columns} dataSource={data} /> */}
        <CustomizeTable
          columns={columns}
          data={data ? data : []}
          usersLoading={isloading}
          notshowPagination={true}
          customScroll={{ x: 1000, y: '75vh' }}
          toSort={handleChange}
          // toshowcustomPagintion={true}
        />
      </AppContent>
    </div>
  );
};
