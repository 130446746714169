import { Button, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { checkAccess } from '@/routes/acl';
import { IJournalLinesViewResponseTableData } from '@/services/accounts/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

import generateTableSummary from './generateTableSummary';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface Props {
  pagination: { page: number; size: number; total: number };
  data: IJournalLinesViewResponseTableData[];
  total: { credit: number; debit: number };
  currentClosingBalance: number;
  isRunningBalance: boolean;
  redirectTo: (journalId: number) => Promise<void>;
  onPagination: (page: number, size: number, isSize?: boolean) => void;
}

function DetailedLedgerTable({
  data,
  pagination,
  total,
  currentClosingBalance,
  isRunningBalance,
  redirectTo,
  onPagination
}: Props) {
  const columns: ColumnsType<IJournalLinesViewResponseTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (txt, record) => {
        return (
          <TableCell>
            {record.sn ? (pagination.page - 1) * pagination.size + record.sn : ''}
          </TableCell>
        );
      }
    },
    {
      title: 'Journal',
      key: 'journalName',
      width: 15,
      rowSpan: 1,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Link to={'/accounts/journal/view/' + record.journalId}>{record.journalName}</Link>
        </TableCell>
      )
    },
    {
      title: 'Account',
      key: 'accountName',
      width: 15,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>{record.accountName}</TableCell>
      )
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.debit !== undefined ? nepaliNumberFormatter(record.debit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.credit !== undefined ? nepaliNumberFormatter(record.credit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Running Balance',
      key: 'runningBalance',
      className: 'highlight',
      width: 15,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>
          {record.runningBalance
            ? nepaliNumberFormatter(record.runningBalance)
            : nepaliNumberFormatter(0)}
        </TableCell>
      )
    },
    {
      title: 'Reference',
      key: 'ledgerType',
      width: 20,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Button
            type="link"
            block
            onClick={async () => await redirectTo(record.journalId)}
            style={{
              padding: '0px',
              textAlign: 'left',
              wordBreak: 'break-all',
              whiteSpace: 'normal'
            }}>
            {record.ledgerType}
          </Button>
        </TableCell>
      )
    },
    {
      title: 'Reference Number',
      key: 'refNumber',
      className: 'invoice',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => <TableCell>{record.refNumber}</TableCell>
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell className={record.isBold ? 'font-bold' : ''}>
              {record.locationName}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Transaction Date',
      key: 'transactionDate',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.journalDate
                ? convertUTCStringtoLocalString(record.journalDate, DEFAULT_DATE_FORMAT)
                : null}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.createdAt
                ? convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)
                : ''}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('READ_ACCOUNT')) {
          menuItems.push({
            key: '1',
            label: <CustomViewIcon link={'/accounts/journal/view/' + record.journalId} />
          });
        }

        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <CustomizeTable
      columns={isRunningBalance ? columns : columns.filter((val) => val.key !== 'runningBalance')}
      data={data}
      notshowPagination={true}
      customScroll={{ x: 1000, y: '75vh' }}
      showPager={false}
      paginationDatas={{
        ...pagination,
        onPagination,
        scrollToTop: true
      }}
      // toSort={handleChange}
      tableName={'detailed-ledger-list'}
      tableSummary={generateTableSummary(
        isRunningBalance ? columns : columns.filter((val) => val.key !== 'runningBalance'),
        {
          credit: total.credit,
          debit: total.debit,
          currentRunningBalance: currentClosingBalance,
          data
        }
      )}
    />
  );
}

export default DetailedLedgerTable;
