import { UserTypeSearch } from '@/components/Common/UsersTypeSearch';
import { API_URL, JWT_TOKEN } from '@/constants/config';
import { onPreviewImage } from '@/services/upload/services';
import { generatePassword } from '@/utils/password.utils';
import { getLocalStorage } from '@/utils/storage.utils';
import { PlusOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Tooltip, Upload, UploadFile } from 'antd';
import { UploadProps } from 'antd/es/upload';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { useState } from 'react';

function UserCreateForm() {
  const form = useFormInstance();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('avatarId', updatedFileId);
    }
    setFileList(newFileList);
  };

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreviewImage,
    onChange: onChange,
    onRemove: () => {
      setFileList([]);
      form.setFieldValue('avatarId', undefined);
    }
  };

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <div className="flex-col mb-5 justify-center align-center left-div" style={{ flex: '1' }}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input name!' }]}>
          <Input style={{ paddingBlock: '4px', borderRadius: '4px' }} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'Please add phone!' }]}>
          <InputNumber style={{ borderRadius: '4px' }} />
        </Form.Item>
        <Form.Item label="Phone (Secondary)" name="secondaryPhone">
          <InputNumber style={{ borderRadius: '4px' }} />
        </Form.Item>
        <UserTypeSearch
          formData={{ formName: 'typeId', label: 'User Type' }}
          required={true}
          isAll={false}
          initialValue={{
            typeId: 1
          }}
        />
        <div className="flex flex-row justify-between items-center gap-1">
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please add password!' }]}>
            <Input.Password
              autoComplete="new-password"
              className="mb-5"
              style={{ paddingBlock: '4px', borderRadius: '4px' }}
            />
          </Form.Item>
          <Tooltip title="generate password">
            <Button
              style={{ borderRadius: '9px' }}
              icon={<RetweetOutlined />}
              onClick={() => form.setFieldValue('password', generatePassword(10))}
            />
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-col" style={{ flex: 1 }}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please provide correct email!', type: 'email' }]}>
          <Input style={{ paddingBlock: '4px', borderRadius: '4px' }} />
        </Form.Item>
        <div className="flex flex-row-reverse align-center">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Upload className="rounded-full" {...uploadProps}>
              {fileList.length >= 1 ? null : (
                <div className="rounded-full">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </div>
        </div>
        <Form.Item
          label="Image"
          name="avatarId"
          hidden
          rules={[{ required: false, message: 'Please upload image!' }]}>
          <Input />
        </Form.Item>
      </div>
    </div>
  );
}

export default UserCreateForm;
