import { useMutation } from '@tanstack/react-query';
import {
  Form,
  message,
  PageHeader,
  Input,
  Spin,
  Upload,
  DatePicker,
  Checkbox,
  Tooltip,
  InputNumber
} from 'antd';
// import { useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import AppContent from '@/components/Common/Content/Content';

import CustomSubmitButton from '@/components/Common/CustomButton/CustomSubmitButton';
import { create_vehicles_mutation } from '@/services/vehicle/mutations';
import { ICreateVehicle } from '@/services/vehicle/types';
import { API_URL, JWT_TOKEN } from '@/constants/config';
import { getLocalStorage } from '@/utils/storage.utils';
import getErrorMessage from '@/utils/getError';

const CreateVehicle = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [bluebookId, setBluebookId] = useState<UploadFile[]>([]);
  const [insuranceId, setInsuranceId] = useState<UploadFile[]>([]);
  const [pollutionId, setPollutionId] = useState<UploadFile[]>([]);

  const isReminderByDistance = Form.useWatch('isReminderByDistance', form);

  const breadcrumbItems = [
    { label: 'Vehicles', link: '/vehicle' },
    { label: 'Create', link: '/vehicle/new' }
  ];
  const createVehicleMutation = useMutation(create_vehicles_mutation, {
    onSuccess: () => {
      message.success('Vehicle added successfully');
      navigate('/vehicle');
    },

    onError: (error) => {
      message.error(getErrorMessage(error));
    }
  });
  const onFinish = async (values: ICreateVehicle) => {
    setisLoading(true);
    await createVehicleMutation.mutateAsync(values);
    setisLoading(false);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onBluebookIdChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('bluebookId', updatedFileId);
    }
    setBluebookId(newFileList);
  };

  const uploadBluebookProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: bluebookId,
    accept:
      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onPreview: onPreview,
    onChange: onBluebookIdChange
  };
  const onInsuranceIdChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('insuranceId', updatedFileId);
    }
    setInsuranceId(newFileList);
  };

  const uploadInsuranceIdProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: insuranceId,
    accept:
      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onPreview: onPreview,
    onChange: onInsuranceIdChange
  };
  const onPollutionIdChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('pollutionId', updatedFileId);
    }
    setPollutionId(newFileList);
  };

  const uploadPollutionIdProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: pollutionId,
    accept:
      '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*',
    onPreview: onPreview,
    onChange: onPollutionIdChange
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Upload</div>
    </div>
  );

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Vehicle Information"
          style={{ padding: '8px 0px' }}
          className="small-title"
        />
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <Form.Item
              label="Name"
              name={['name']}
              rules={[{ required: true, message: 'Please add Name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="IMEI"
              name={['imei']}
              rules={[{ required: true, message: 'Please add IMEI!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Vehicle Number"
              name={['number']}
              rules={[{ required: true, message: 'Please add vehicle number!' }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="flex flex-wrap sm:grid grid-cols-3 gap-2 mt-2">
            <Form.Item
              label="Bluebook "
              name={['bluebookId']}
              rules={[{ required: true, message: 'Please add Bluebook Id!' }]}>
              <Upload {...uploadBluebookProps}>
                {bluebookId.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item
              label="Insurance "
              name={['insuranceId']}
              rules={[{ required: true, message: 'Please add Insurance Id!' }]}>
              <Upload {...uploadInsuranceIdProps}>
                {insuranceId.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item
              label="Pollution"
              name={['pollutionId']}
              rules={[{ required: true, message: 'Please add Pollution Id!' }]}>
              <Upload {...uploadPollutionIdProps}>
                {pollutionId.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </div>

          <div className="flex sm:gap-4 flex-wrap justify-between">
            <PageHeader title="Servicing Reminder" style={{ padding: 0 }} className="small-title" />
            <div className="flex items-center">
              <Form.Item
                className="flex items-center space-x-2"
                name="isReminderByDistance"
                valuePropName="checked">
                <Checkbox
                  onChange={() => form.setFieldValue('servicingReminderDistance', undefined)}>
                  Set Reminder by Distance
                </Checkbox>
              </Form.Item>

              <Tooltip
                overlayStyle={{ maxWidth: 500 }}
                title="If you choose this option, you must specify the distance in km. The system will send a reminder when the distance is reached from the specified start date. If unchecked, the reminder will be based on the selected date.">
                <InfoCircleOutlined className="cursor-pointer -mt-2" />
              </Tooltip>
            </div>
          </div>

          <div className="mt-2">
            {isReminderByDistance ? (
              <div className="flex flex-col sm:flex-row gap-4 max-w-3xl">
                <div className="flex-1">
                  <Form.Item
                    label="Start Date"
                    name="servicingReminderDate"
                    rules={[
                      { required: isReminderByDistance, message: 'Please select a start date' }
                    ]}>
                    <DatePicker
                      className="w-full"
                      showTime
                      showNow={false}
                      format="YYYY-MM-DD hh:mm a"
                    />
                  </Form.Item>
                </div>

                <div className="flex-1">
                  <Form.Item
                    label="Distance (km)"
                    name="servicingReminderDistance"
                    rules={[
                      { required: isReminderByDistance, message: 'Please enter distance in km' }
                    ]}>
                    <InputNumber
                      placeholder="Enter distance in km"
                      addonAfter="km"
                      min={0}
                      className="w-full"
                    />
                  </Form.Item>
                </div>
              </div>
            ) : (
              <Form.Item label="Reminder Date" name="servicingReminderDate">
                <DatePicker
                  className="w-[300px]"
                  showTime
                  showNow={false}
                  format="YYYY-MM-DD hh:mm a"
                />
              </Form.Item>
            )}
          </div>

          <PageHeader
            title="Other Reminders"
            style={{ padding: '8px 0px' }}
            className="small-title"
          />
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <Form.Item label="Bluebook Reminder" name="blueBookReminderDate">
              <DatePicker
                className="w-full"
                placeholder="Select bluebook reminder date"
                showTime
                showNow={false}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>

            <Form.Item label="Pollution Reminder" name="pollutionReminderDate">
              <DatePicker
                className="w-full"
                placeholder="Select pollution reminder date"
                showTime
                showNow={false}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>

            <Form.Item label="Insurance Reminder" name="insuranceReminderDate">
              <DatePicker
                className="w-full"
                placeholder="Select insurance reminder date"
                showTime
                showNow={false}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              <CustomSubmitButton text="Submit" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateVehicle;
