import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { Form, Input, InputNumber } from 'antd';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { useState } from 'react';

function CustomerAddressForm() {
  const form = useFormInstance();
  const center = { lat: 27.7172, lng: 85.324 };
  const [marker, setMarkerPosition] = useState(center);

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (e?.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      form.setFieldValue(['addresses', 'latitude'], lat);
      form.setFieldValue(['addresses', 'longitude'], lng);
      setMarkerPosition({ lat, lng });
    }
  };

  return (
    <div>
      <div className={'grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'}>
        <Form.Item
          name={['addresses', 'addressLine1']}
          label="Address 1"
          rules={[{ required: true, message: 'Please add address!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'addressLine2']}
          label="Address 2"
          rules={[{ required: true, message: 'Please add address!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'street']}
          label="Street"
          rules={[{ required: true, message: 'Please add street!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'city']}
          label="City"
          rules={[{ required: true, message: 'Please add city!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'state']}
          label="State"
          rules={[{ required: true, message: 'Please add state!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'country']}
          label="Country"
          rules={[{ required: true, message: 'Please add country!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'postalCode']}
          label="Postal Code"
          rules={[{ required: true, message: 'Please add postal code!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'district']}
          label="District"
          rules={[{ required: true, message: 'Please add district!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'municipality']}
          label="Municipality"
          rules={[{ required: true, message: 'Please add municipality!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'ward']}
          label="Ward"
          rules={[{ required: true, message: 'Please add ward!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item
          name={['addresses', 'landmark']}
          label="Landmark"
          rules={[{ required: true, message: 'Please add landmark!' }]}>
          <Input style={{ paddingBlock: '4.5px', borderRadius: '4px' }} />
        </Form.Item>

        <Form.Item name={['addresses', 'latitude']} label="Latitude" initialValue={center.lat}>
          <InputNumber style={{ paddingBlock: '0.5px', borderRadius: '4px' }} disabled />
        </Form.Item>

        <Form.Item name={['addresses', 'longitude']} label="Longitude" initialValue={center.lng}>
          <InputNumber style={{ paddingBlock: '0.5px', borderRadius: '4px' }} disabled />
        </Form.Item>

        <RouteSearchV2 hasParentFormItem={false} name={['addresses', 'routeId']} required />
      </div>

      <div className="my-5">
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY || ''}>
          <GoogleMap
            center={marker}
            zoom={15}
            options={{ fullscreenControl: false, streetViewControl: false, mapTypeControl: false }}
            onClick={onClick}
            mapContainerStyle={{ width: '100%', height: '400px', marginBottom: '20px' }}>
            <MarkerF position={marker} />
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
}

export default CustomerAddressForm;
