import { Button, Form, Input, Menu, Spin, TableProps, Tooltip, message } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState, useRef } from 'react';
import { getUserData } from '@/utils/auth.utils';
import {
  IPurchaseReturnRegisterList,
  IPurchaseReturnRegisterListData,
  IPurchaseReturnRegisterListExport
} from '@/services/ird/purchase/types';
import { get_purchase_return_register_list } from '@/services/ird/purchase/queries';
import { ConvertObjectToURL } from '@/utils/converturl';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import TableFilter from '@/components/FliterTable';
import VendorsDB from '@/store/localstorage/VendorDB';
import { get_user_pan_ids, get_vendor_list_ids } from '@/services/users/queries';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import CopyButton from '@/components/Common/CopyButton';
import { PurchaseType } from '@/services/purchases/enums';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTableForIrd from '@/components/Common/CustomizeTable/CustomTableForIrd';
import { Excel } from 'antd-table-saveas-excel';
import { ExportColumnType } from '@/utils/exportExcel';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const PurchaseReturnRegisterList = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const [allPurchaseReturnList, setAllPurchaseReturnList] =
    useState<IPurchaseReturnRegisterListData>({
      total: 0,
      results: []
    });
  const [exportData, setExportData] = useState<{
    data: IPurchaseReturnRegisterListExport[];
    year: string;
    date: string;
  }>({ data: [], year: '', date: '' });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [
    {
      label: 'Purchase Return Register',
      link: '/ird/purchase-return-register'
    }
  ];

  const columns: ColumnsType<IPurchaseReturnRegisterList> = [
    {
      title: 'Tax Invoice',
      width: 100,
      children: [
        {
          title: 'Date',
          key: 'date',
          width: 20,
          sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
          render: (a, record) => {
            return (
              <TableCell>
                {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
              </TableCell>
            );
          }
        },
        {
          title: 'Invoice Number',
          key: 'financialReference',
          width: 30,
          sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
          sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
          render: (a, record) => {
            return (
              <TableCell className="flex flex-row justify-between items-center text-xs px-1">
                {record.financialReference}
                {record.financialReference && <CopyButton text={record.financialReference} />}
              </TableCell>
            );
          }
        },
        {
          title: 'Name of Supplier',
          key: 'supplierName',
          width: 20,
          sorter: (a, b) =>
            a.supplierName && b.supplierName ? a.supplierName.localeCompare(b.supplierName) : 0,
          sortOrder: sortedInfo.columnKey === 'supplierName' ? sortedInfo.order : null,
          render: (a, record) => {
            return <TableCell>{record.supplierName}</TableCell>;
          }
        },
        {
          title: `Supplier's PAN`,
          key: 'supplierPan',
          width: 25,
          render: (a, record) => {
            return <TableCell>{record.vendorPan}</TableCell>;
          }
        },
        {
          title: 'Purchase/ Import Details',
          key: 'purchaseImportGoodsDetails',
          width: 25,
          render: (a, record) => {
            return <TableCell>{'Goods for: ' + record.referenceNumber}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Amount',
      width: 20,
      key: 'totalPurchasePrice',
      dataIndex: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalPurchasePrice' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{nepaliNumberFormatter(record.totalAmount)}</TableCell>;
      }
    },
    {
      title: 'Non Taxable / Taxable Amount',
      width: 20,
      key: 'totalNonTaxable',
      dataIndex: 'totalNonTaxable',
      sorter: (a, b) => a.totalNonTaxable - b.totalNonTaxable,
      sortOrder: sortedInfo.columnKey === 'totalNonTaxable' ? sortedInfo.order : null,
      render: (a, record) => {
        return <TableCell>{nepaliNumberFormatter(record.totalNonTaxable)}</TableCell>;
      }
    },
    {
      title: 'Taxable Return',
      width: 20,
      children: [
        {
          title: 'Amount',
          key: 'taxablePurchaseAmount',
          dataIndex: 'localPurchase',
          width: 15,
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['LOCAL'] ? record.totalTaxable : 0
                )}
              </TableCell>
            );
          }
        },
        {
          title: 'Tax',
          key: 'taxablePurchaseTax',
          dataIndex: 'localPurchaseTax',
          width: 15,
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['LOCAL'] ? record.totalVat : 0
                )}
              </TableCell>
            );
          }
        }
      ]
    },
    {
      title: 'Taxable Import Return',
      width: 20,
      children: [
        {
          title: 'Amount',
          key: 'taxableImportAmount',
          dataIndex: 'importPurchase',
          width: 15,
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['IMPORT'] ? record.totalTaxable : 0
                )}
              </TableCell>
            );
          }
        },
        {
          title: 'Tax',
          key: 'taxableImportTax',
          dataIndex: 'importPurchaseTax',
          width: 15,
          render: (a, record) => {
            return (
              <TableCell>
                {nepaliNumberFormatter(
                  record.purchaseType === PurchaseType['IMPORT'] ? record.totalVat : 0
                )}
              </TableCell>
            );
          }
        }
      ]
    },
    {
      title: 'Capital Taxable',
      width: 20,
      children: [
        {
          title: 'Amount',
          dataIndex: 'capitalAmount',
          key: 'capitalAmount',
          width: 15,
          render: () => {
            return <TableCell>{nepaliNumberFormatter(0)}</TableCell>;
          }
        },
        {
          title: 'Tax',
          dataIndex: 'capitalTax',
          key: 'capitalTax',
          width: 15,
          render: () => {
            return <TableCell>{nepaliNumberFormatter(0)}</TableCell>;
          }
        }
      ]
    }
  ];

  const columsforPrint: any = [
    {
      title: 'Tax Invocie',
      width: 1180,
      children: [
        {
          title: 'Date',
          width: 150,
          dataIndex: 'date',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Invoice Number',
          width: 230,
          dataIndex: 'financialReference',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Name of Supplier',
          width: 125,
          dataIndex: 'supplierName',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: `Supplier's PAN`,
          width: 115,
          dataIndex: 'vendorPan',
          render: (text: number) => {
            return <div className="text-center">{text}</div>;
          }
        },
        {
          title: 'Purchase/ Import Details',
          width: 270,
          dataIndex: 'purchaseImportGoodsDetails',
          render: (text: string) => {
            return <div className="text-center">{text}</div>;
          }
        }
      ]
    },
    {
      title: 'Amount',
      width: 60,
      dataIndex: 'totalAmount',
      render: (text: number) => {
        return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
      }
    },
    {
      title: 'Non Taxable/ Taxable Amount',
      width: 80,
      dataIndex: 'totalNonTaxable',
      render: (text: number) => {
        return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
      }
    },
    {
      title: 'Taxable Return',
      width: 85,
      children: [
        {
          title: 'Amount',
          width: 55,
          dataIndex: 'taxablePurchaseAmount',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        },
        {
          title: 'Tax',
          width: 30,
          dataIndex: 'taxablePurchaseTax',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        }
      ]
    },
    {
      title: 'Taxable Import Return',
      width: 85,
      children: [
        {
          title: 'Amount',
          width: 55,
          dataIndex: 'taxableImportAmount',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        },
        {
          title: 'Tax',
          width: 30,
          dataIndex: 'taxableImportTax',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        }
      ]
    },
    {
      title: 'Capital Taxable',
      width: 85,
      children: [
        {
          title: 'Amount',
          width: 55,
          dataIndex: 'capitalAmount',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        },
        {
          title: 'Tax',
          width: 30,
          dataIndex: 'capitalTax',
          render: (text: number) => {
            return <div className="text-center">{nepaliNumberFormatter(text)}</div>;
          }
        }
      ]
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_purchase_return_register_list(filter);
    // console.log('response', response);
    if (response) {
      const vendorUserIds = new Set<number>();
      for (let index = 0; index < response.results.length; index++) {
        // set vendor name
        let vendor = await VendorsDB.getVendors(response.results[index].vendorId);
        if (!vendor) {
          const allVendors = await get_vendor_list_ids([
            ...new Set(
              response.results.map((value) => {
                return value.vendorId;
              })
            )
          ]);
          await VendorsDB.addVendors(allVendors.data.results);
          vendor = await VendorsDB.getVendors(response.results[index].vendorId);
        }

        if (typeof vendor === 'object') {
          vendorUserIds.add(vendor.userId);
          response.results[index].supplierName = vendor.user.name;
          response.results[index].vendorUserId = vendor.userId;
        }

        const currentData = response.results[index];
        currentData.localPurchase =
          currentData.purchaseType === PurchaseType['LOCAL'] ? currentData.totalTaxable : 0;

        currentData.localPurchaseTax =
          currentData.purchaseType === PurchaseType['LOCAL'] ? currentData.totalVat : 0;

        currentData.importPurchase =
          currentData.purchaseType === PurchaseType['IMPORT'] ? currentData.totalTaxable : 0;

        currentData.importPurchaseTax =
          currentData.purchaseType === PurchaseType['IMPORT'] ? currentData.totalVat : 0;
      }

      //set pan
      if (Array.from(vendorUserIds).length > 0) {
        const allPan = await get_user_pan_ids([...vendorUserIds]);
        //console.log('allPan', allPan);

        for (let ind = 0; ind < allPan.data.length; ind++) {
          response.results.forEach((el) =>
            allPan.data[ind].taxNumber !== null
              ? (el.vendorPan = allPan.data[ind].taxNumber)
              : 'N/A'
          );
        }
      }

      const dataUpdated = response.results.map((item) => {
        return {
          ...item,
          purchaseImportGoodsDetails: 'Goods for: ' + item.financialReference,
          taxablePurchaseAmount:
            item.purchaseType === PurchaseType['LOCAL'] ? item.totalTaxable : 0,
          taxablePurchaseTax: item.purchaseType === PurchaseType['LOCAL'] ? item.totalVat : 0,
          taxableImportAmount: item.purchaseType === PurchaseType['IMPORT'] ? item.totalTaxable : 0,
          taxableImportTax: item.purchaseType === PurchaseType['IMPORT'] ? item.totalVat : 0,
          capitalAmount: 0,
          capitalTax: 0,
          date: convertUTCStringtoLocalString(item.date, DEFAULT_DATE_FORMAT)
        };
      });

      const startYear = form.getFieldValue(['startDateNepali']).split('-')[0];
      const startDate = form.getFieldValue(['startDateNepali']);
      const endYear = form.getFieldValue(['endDateNepali']).split('-')[0];
      const endDate = form.getFieldValue(['endDateNepali']);
      const fullYear = startYear === endYear ? startYear : startYear + ' to ' + endYear;
      const fullDate = startYear === endYear ? startDate : startDate + ' to ' + endDate;

      setAllPurchaseReturnList(response);
      setExportData({ data: dataUpdated, year: fullYear, date: fullDate });
    }
    setIsloading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const handleExcelExport = () => {
    setIsloading(true);
    try {
      const excelColumns: ExportColumnType[] = [
        {
          title: 'Purchase Return Register',
          width: 1500,
          children: [
            {
              title: '(Related with Rule 23 SubRule(1) part( g) )',
              width: 1500,
              children: [
                {
                  title:
                    'PAN: 609891700' +
                    `     Tax Payer's name: Freshktm` +
                    '     Year: ' +
                    exportData.year +
                    '     Tax Period: ' +
                    exportData.date,
                  width: 1500,
                  children: [
                    {
                      title: 'Tax Invoice',
                      width: 1180,
                      children: [
                        {
                          title: 'Date',
                          width: 150,
                          dataIndex: 'date'
                        },
                        {
                          title: 'Invoice Number',
                          width: 230,
                          dataIndex: 'financialReference'
                        },
                        {
                          title: 'Name of Supplier',
                          width: 125,
                          dataIndex: 'supplierName'
                        },
                        {
                          title: `Supplier's PAN`,
                          width: 115,
                          dataIndex: 'vendorPan'
                        },
                        {
                          title: 'Purchase/ Import Details',
                          width: 270,
                          dataIndex: 'purchaseImportGoodsDetails'
                        }
                      ]
                    },
                    {
                      title: 'Amount',
                      width: 60,
                      dataIndex: 'totalAmount'
                    },
                    {
                      title: 'Non Taxable/ Taxable Amount',
                      width: 80,
                      dataIndex: 'totalNonTaxable'
                    },
                    {
                      title: 'Taxable Return',
                      width: 85,
                      children: [
                        {
                          title: 'Amount',
                          width: 55,
                          dataIndex: 'taxablePurchaseAmount'
                        },
                        {
                          title: 'Tax',
                          width: 30,
                          dataIndex: 'taxablePurchaseTax'
                        }
                      ]
                    },
                    {
                      title: 'Taxable Import Return',
                      width: 85,
                      children: [
                        {
                          title: 'Amount',
                          width: 55,
                          dataIndex: 'taxableImportAmount'
                        },
                        {
                          title: 'Tax',
                          width: 30,
                          dataIndex: 'taxableImportTax'
                        }
                      ]
                    },
                    {
                      title: 'Capital Taxable',
                      width: 85,
                      children: [
                        {
                          title: 'Amount',
                          width: 55,
                          dataIndex: 'capitalAmount'
                        },
                        {
                          title: 'Tax',
                          width: 30,
                          dataIndex: 'capitalTax'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];

      if (allPurchaseReturnList.results.length === 0) {
        message.error('No Data to Export');
        setIsloading(false);
        return;
      }

      // Export Excel
      const excel = new Excel();
      excel.setTHeadStyle({
        background: 'ffffffff'
      });
      excel
        .setTHeadStyle({
          fontSize: 10
        })
        .setTBodyStyle({
          fontSize: 10
        })
        .addSheet('Purchase Return Register')
        .addColumns(excelColumns as any[])
        .addDataSource(exportData.data, {
          str2Percent: true
        })
        .saveAs('Purchase Return Register.xlsx');
      setIsloading(false);
    } catch (err: any) {
      setIsloading(false);
      console.log(err);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableForIrd
            columns={columsforPrint}
            datas={exportData.data}
            reff={printPDFRef}
            title={'Purchase Return Register'}
            subTitle={'(Related with Rule 23 SubRule(1) part( g) )'}
            date={exportData.date}
            year={exportData.year}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    value: '',
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  form={form}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" />
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExcelExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (allPurchaseReturnList.results.length === 0) {
                            message.error('No Data to Export');
                            setIsloading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <GenericTable
          columns={columns}
          data={allPurchaseReturnList.results}
          hideDefaultPagination={true}
          scroll={{ x: 1750, y: '75vh' }}
          pagination={{
            page: page,
            total: allPurchaseReturnList.total,
            size: size,
            onPagination
          }}
          generateSummary
          summaryClassName="text-left"
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default PurchaseReturnRegisterList;
