import { AccountType } from '@/services/accounts/enums';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import {
  IAccountTypeResponseData,
  IJournalLinesListResponseData,
  IJournalLinesViewResponseTableData,
  IJournalListResponseData
} from '@/services/accounts/types';
import { createEmptyPromise } from './reference.service';

type DynamicProps =
  | { hasAccountId: true }
  | { hasAccountId: false; journalLines: IJournalLinesListResponseData[] };

type Props = DynamicProps & {
  accountDetails: IAccountTypeResponseData[];
  journalList: IJournalListResponseData[];
};

function isTransferAccount(account?: IAccountTypeResponseData) {
  return (
    account &&
    (account.type === AccountType.TRANSFER_INITIATE ||
      account.type === AccountType.TRANSFER_RECEIVE)
  );
}

function getFilteredAccount(accountId: number, accountDetails: IAccountTypeResponseData[]) {
  if (accountId !== 0) {
    return accountDetails.find((val) => val.id === accountId);
  }
}

async function getLocationDetails(locationId: number, lines: IJournalLinesListResponseData[]) {
  if (locationId !== null) {
    let locationDetails = await LocationsDB.getLocation(locationId);
    if (!locationDetails) {
      const locationIds = lines
        .filter((value) => value?.locationId)
        .map((value) => value.locationId);

      const allLocations = await get_location_list_for_ids([...new Set(locationIds)]);
      await LocationsDB.addLocations(allLocations);
      locationDetails = await LocationsDB.getLocation(locationId);
    }
    return locationDetails;
  }
  return null;
}

async function getReferenceLocation(
  referenceId: number,
  accountDetails: IAccountTypeResponseData[]
) {
  let refLocation = await LocationsDB.getLocation(referenceId);
  if (!refLocation) {
    const allLocations = await get_location_list_for_ids([
      ...new Set(
        accountDetails
          .filter(
            (value) =>
              value.type === AccountType.TRANSFER_INITIATE ||
              value.type === AccountType.TRANSFER_RECEIVE
          )
          .map((value) => value.referenceId)
      )
    ]);

    await LocationsDB.addLocations(allLocations);
    refLocation = await LocationsDB.getLocation(referenceId);
  }
  return refLocation;
}

export async function addLocationToJournal({ accountDetails, ...props }: Props) {
  const tableData: IJournalLinesViewResponseTableData[] = [];

  if (props.hasAccountId) {
    const journalLists = props.journalList;
    for (const journal of journalLists) {
      for (const line of journal.lines) {
        // Get filtered Account based on accountId
        const filteredAcc = getFilteredAccount(line.accountId, accountDetails);

        // Get location details based on locationId
        const locationPromise = getLocationDetails(line.locationId, journal.lines);
        const refLocationPromise =
          filteredAcc && isTransferAccount(filteredAcc)
            ? getReferenceLocation(filteredAcc.referenceId, accountDetails)
            : createEmptyPromise(null);

        const [locationDetails, refLocation] = await Promise.all([
          locationPromise,
          refLocationPromise
        ]);

        // Append location name to locationDetails if filteredAcc is transfer account
        if (refLocation && locationDetails) {
          locationDetails.name = `${locationDetails.name} >>> ${refLocation?.name}`;
        }

        tableData.push({
          ...line,
          accountName: filteredAcc?.name || '',
          accountBalance: filteredAcc?.balance || 0,
          runningBalance: 0,
          journalName: journal.description,
          journalReferenceId: journal.referenceId,
          refNumber: journal.refNumber,
          locationName: locationDetails?.name || '',
          ledgerType: journal?.type || ''
        });
      }
    }
    return tableData;
  }

  const journalLines = props.journalLines;
  for (const lines of journalLines) {
    const filteredAcc = getFilteredAccount(lines.accountId, accountDetails);
    const journal = props.journalList.find((j) => j.id === lines.journalId);

    // Get location details based on locationId
    const locationPromise = getLocationDetails(lines.locationId, journalLines);
    const refLocationPromise =
      filteredAcc && isTransferAccount(filteredAcc)
        ? getReferenceLocation(filteredAcc.referenceId, accountDetails)
        : createEmptyPromise(null);

    const [locationDetails, refLocation] = await Promise.all([locationPromise, refLocationPromise]);

    if (refLocation && locationDetails) {
      locationDetails.name = `${locationDetails.name} >>> ${refLocation?.name}`;
    }

    tableData.push({
      ...lines,
      accountName: filteredAcc?.name || '',
      accountBalance: filteredAcc?.balance || 0,
      runningBalance: 0,
      journalName: journal?.description,
      journalReferenceId: journal?.referenceId,
      refNumber: journal?.refNumber,
      locationName: locationDetails?.name || '',
      ledgerType: journal?.type
    });
  }

  return tableData;
}
