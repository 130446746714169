import { DatePicker, Form, Input, InputNumber, Select, TableProps, Tag } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useEffect, useState } from 'react';
import { CustomDatePresets } from '../utils/datePresets';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableSellView from '../../../sell/view/ReusableView';
import CustomReport from './report';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { find_date_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import { getUserData } from '@/utils/auth.utils';
import UserSearchV2 from '@/components/Common/CustomSearch/Users';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
const { RangePicker } = DatePicker;

const SellRepresentativeReport = () => {
  const [form] = Form.useForm();
  // const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      userId: '',
      by: '',
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Sales Representative Report',
      link: '/reports/sales-representative-report'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columnsSell: ColumnsType<any> = [
    // Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 35,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Date',
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{moment(record.date).local().format('YYYY-MM-DD')}</div>;
      },
      width: 100
    },
    {
      title: 'Invoice No.',
      key: 'reference_number',
      className: 'invoice',
      sorter: (a, b) => a.reference_number.localeCompare(b.reference_number),
      sortOrder: sortedInfo.columnKey === 'reference_number' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setId(record.id);
            }}>
            {record.reference_number}
          </a>
        );
      },
      width: 100
    },

    {
      title: 'Customer Name',
      key: 'userName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortOrder: sortedInfo.columnKey === 'userName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/users/${record.address.userId}`} color="black">
          <div>{record.userName}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Location',
      key: 'locationName',
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div color="black">{record.locationName}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Payment Status',
      key: 'payment_status',
      sorter: (a, b) => a.payment_status.localeCompare(b.payment_status),
      sortOrder: sortedInfo.columnKey === 'payment_status' ? sortedInfo.order : null,
      render: (record) => {
        const colorCode: any = { paid: 'green', pending: 'red', partial: 'purple' };
        return (
          <Tag className="uppercase" color={colorCode[record.payment_status]}>
            {record.payment_status}
          </Tag>
        );
      },
      width: 100
    },
    {
      title: 'Total amount',
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Total paid',
      key: 'amount_paid',
      sorter: (a, b) => a.amount_paid - b.amount_paid,
      sortOrder: sortedInfo.columnKey === 'amount_paid' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_paid)}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Total Returned',
      key: 'amount_returned',
      sorter: (a, b) => a.amount_returned - b.amount_returned,
      sortOrder: sortedInfo.columnKey === 'amount_returned' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record.amount_returned)}</div>
        );
      },
      width: 100
    },
    {
      title: 'Total remaining',
      key: 'totalRemaining',
      sorter: (a, b) => {
        const atotalRem = a.total_amount - a.amount_paid;
        const btotalRem = b.total_amount - b.amount_paid;
        return atotalRem - btotalRem;
      },
      sortOrder: sortedInfo.columnKey === 'totalRemaining' ? sortedInfo.order : null,

      render: (record) => {
        return (
          <div color="black" className="classfornormaltext capitalize text-right mr-2">
            {nepaliNumberFormatter(record.total_amount - record.amount_paid)}
          </div>
        );
      },
      width: 100
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ name: 'Sell Representative Report' }}
      form={form}
      sortHandle={handleChange}
      columnsData={[columnsSell]}>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'Sell View'}>
        <ReusableSellView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <UserSearchV2 showAll hasParentFormItem={false} name={['constraints', 'by']} />
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      {/* <Form.Item name={['constraints', 'locationId']} label="Location"> */}
      {/* <Select placeholder="Select a location!">
          <Option value="">All</Option>
          {locationsList?.data.results.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select> */}
      {/* <LocationSearch withoutForm={true} onSelect={(val) => onSelectt(val)} /> */}
      {/* </Form.Item> */}
      {/* <LocationSearch /> */}
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default SellRepresentativeReport;
