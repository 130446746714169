import { forwardRef, Ref } from 'react';

import { IExportProps, IJournalLinesViewResponseTableData } from '@/services/accounts/types';
import getGroupedData from '@/utils/getGroupedData';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

interface Props extends IExportProps<IJournalLinesViewResponseTableData> {
  selectedColumns: string[];
  title: string;
}

const tdthStyle = {
  border: '1px solid black',
  padding: '4px'
};

function TableExportComponent(
  { title, data, total, selectedColumns, ...props }: Props,
  ref: Ref<HTMLDivElement>
) {
  const columns = [
    { dataIndex: 'sn', label: 'SN', width: 55 },
    { dataIndex: 'journalName', label: 'Journal', width: 275 },
    { dataIndex: 'accountName', label: 'Account', width: 220 },
    { dataIndex: 'debit', label: 'Debit', width: 165 },
    { dataIndex: 'credit', label: 'Credit', width: 165 },
    { dataIndex: 'runningBalance', label: 'Running Balance', width: 165 },
    { dataIndex: 'ledgerType', label: 'Reference', width: 275 },
    { dataIndex: 'refNumber', label: 'Ref Number', width: 275 },
    { dataIndex: 'locationName', label: 'Location', width: 165 },
    { dataIndex: 'journalDate', label: 'Trn. Date', width: 190 },
    { dataIndex: 'createdAt', label: 'Created Date', width: 190 }
  ].filter((column) => selectedColumns.includes(column.label));

  const hasRunningBalance = columns.some((column) => column.dataIndex === 'runningBalance');
  const balanceKey = ['Credit', 'Debit', 'Running Balance'];
  const hasBalanceKey = balanceKey.some((key) => selectedColumns.includes(key));

  function getDynamicHeight(isAccountName: boolean, id: string) {
    const element = document.getElementById(id);
    return isAccountName ? {} : { height: element?.offsetHeight || 'auto' };
  }

  function RenderOpenCloseBalance({
    accountName,
    runningBalance
  }: {
    accountName?: string;
    runningBalance?: number;
  }) {
    return (
      <tr>
        {dynamicColumns.map((column) => {
          const isAccountName = column.dataIndex === 'accountName';
          const isRunningBalance = column.dataIndex === 'runningBalance';

          return (
            <td key={`column-${column.dataIndex}`} style={tdthStyle} className="text-right">
              {isAccountName ? accountName : isRunningBalance ? runningBalance?.toFixed(2) : ''}
            </td>
          );
        })}
      </tr>
    );
  }

  const isAccountSelected = props.account !== '--';
  const openingBalance = data.find((d) => d.accountName === 'Opening Balance');
  const closingBalance = data.find((d) => d.accountName === 'Closing Balance');
  const currentClosingBalance = data.find((d) => d.accountName === 'Current Closing Balance');

  // Exclude Opening And Closing Balance Information
  const dataWithoutBalance = data.filter(
    (d) =>
      !['Opening Balance', 'Closing Balance', 'Current Closing Balance'].includes(
        d.accountName ?? ''
      )
  );

  const dynamicColumns = columns.filter((column) => {
    if (isAccountSelected) return true;
    return column.dataIndex !== 'runningBalance';
  });

  const groupedData = getGroupedData<IJournalLinesViewResponseTableData>(
    dataWithoutBalance,
    'journalId'
  );

  const groupedRows = Object.values(groupedData);
  groupedRows.sort((a, b) => a[0].journalDate.localeCompare(b[0].journalDate));

  return (
    <div className="flex flex-col justify-center font-sans" ref={ref}>
      {<h3 className="text-center font-bold text-base">{title}</h3>}
      <div className="flex items-center font-bold bg-[#f2f2f2] px-2">
        <p className="export-paragraph">
          <span>Account:</span>
          <span>{props.account}</span>
        </p>

        <p className="export-paragraph text-center">
          <span>Address:</span>
          <span>Kathmandu, Nepal</span>
        </p>

        <p className="export-paragraph text-right">
          <span>Contact:</span>
          <span>9876543210</span>
        </p>
      </div>

      <div className="flex items-center px-2">
        <p className="export-paragraph">
          <span>From Date:</span>
          <span>{props.from}</span>
        </p>

        <p className="export-paragraph text-center">
          <span>To Date:</span>
          <span>{props.to}</span>
        </p>

        <p className="export-paragraph text-right">
          <span>Current Balance:</span>
          <span>{props.balance}</span>
        </p>
      </div>

      <table width={'99%'}>
        <thead>
          <tr>
            {dynamicColumns.map((currCol) => (
              <th key={currCol.label} style={{ ...tdthStyle, width: currCol.width }}>
                <div className="text-center">{currCol.label}</div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {/* // Add Opening Balance */}
          {openingBalance && hasRunningBalance && (
            <RenderOpenCloseBalance
              accountName={openingBalance?.accountName}
              runningBalance={openingBalance?.runningBalance || 0}
            />
          )}

          {/* // Add All Rows */}
          {groupedRows.map((rows, index) => {
            const [firstRow, ...remainingRow] = rows as any[];
            return (
              <tr key={`ledger-row-${index}`} className="even:bg-[#f2f2f2] ">
                {dynamicColumns.map((col, i) => {
                  const noMergeColumns = ['accountName', 'debit', 'credit', 'runningBalance'];

                  const columnDataIndex = col.dataIndex;
                  const firstRowValue = firstRow[columnDataIndex];

                  // Check if columns should be merge or not
                  if (!noMergeColumns.includes(columnDataIndex)) {
                    // return index for sn
                    if (columnDataIndex === 'sn') {
                      return (
                        <td
                          style={tdthStyle}
                          key={`column-${columnDataIndex}-${i}`}
                          className="px-2 text-center">
                          {index + 1}
                        </td>
                      );
                    }

                    const isDate = ['createdAt', 'journalDate'].includes(columnDataIndex);

                    return (
                      <td
                        key={`column-${columnDataIndex}-${i}`}
                        style={tdthStyle}
                        className={`${columnDataIndex === 'createdAt' ? 'pr-2' : ''} ${
                          columnDataIndex === 'journalName' ? '' : 'text-center'
                        }`}>
                        {isDate
                          ? convertUTCStringtoLocalString(firstRowValue, 'YYYY-MM-DD h:mm A')
                          : firstRowValue}
                      </td>
                    );
                  }

                  const isAccountName = columnDataIndex === 'accountName';
                  const isNumber = ['debit', 'credit', 'runningBalance'].includes(columnDataIndex);

                  const firstRowStyle = getDynamicHeight(
                    isAccountName,
                    `journal-${firstRow.journalId}-0`
                  );

                  return (
                    <td
                      key={`column-${columnDataIndex}-${i}`}
                      style={tdthStyle}
                      className={`${isAccountName ? '' : 'text-right'}`}>
                      <div>
                        {/* // Get height of account column and set it to the first row */}
                        <p
                          style={firstRowStyle || {}}
                          id={isAccountName ? `journal-${firstRow.journalId}-0` : ''}
                          className={'export-paragraph'}>
                          {isNumber ? firstRowValue?.toFixed(2) : firstRowValue}
                        </p>

                        {/* // Remaining Rows  */}
                        {remainingRow.map((row, id) => {
                          const value = row[columnDataIndex];

                          // Get height and set it to the remaining rows
                          const style = getDynamicHeight(
                            isAccountName,
                            `journal-${firstRow.journalId}-${id + 1}`
                          );

                          return (
                            <p
                              style={style || {}}
                              key={`secondary-row-${row.id}`}
                              id={isAccountName ? `journal-${firstRow.journalId}-${id + 1}` : ''}
                              className={'export-paragraph'}>
                              {isNumber ? value?.toFixed(2) : value}
                            </p>
                          );
                        })}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}

          {/* // Add Closing Balance */}
          {closingBalance && hasRunningBalance && (
            <RenderOpenCloseBalance
              accountName={closingBalance?.accountName}
              runningBalance={closingBalance?.runningBalance || 0}
            />
          )}

          {/* // Add Current Closing Balance */}
          {currentClosingBalance && (
            <RenderOpenCloseBalance
              accountName={currentClosingBalance?.accountName}
              runningBalance={currentClosingBalance?.runningBalance || 0}
            />
          )}

          {/* // Add Total */}
          {hasBalanceKey && (
            <tr>
              {dynamicColumns.map((column) => {
                const index = column.dataIndex;

                const isEmpty = !['accountName', 'debit', 'credit', 'runningBalance'].includes(
                  index
                );

                if (isEmpty) {
                  return <td key={index} style={tdthStyle} />;
                }

                if (index === 'accountName') {
                  return (
                    <td key={index} style={tdthStyle} className="text-right">
                      <div className="font-bold">Total</div>
                    </td>
                  );
                }

                return (
                  <td key={index} style={tdthStyle} className="text-right">
                    {index === 'debit' && (
                      <div className="font-bold">{nepaliNumberFormatter(total.debit)}</div>
                    )}

                    {index === 'credit' && (
                      <div className="font-bold">{nepaliNumberFormatter(total.credit)}</div>
                    )}

                    {index === 'runningBalance' && isAccountSelected && (
                      <div className="font-bold">
                        {closingBalance
                          ? nepaliNumberFormatter(closingBalance.runningBalance || 0)
                          : props.balance}
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

const TableExport = forwardRef(TableExportComponent);
TableExport.displayName = 'LedgerTableExport';

export default TableExport;
