import { IWidgetConfiguration } from '@/services/dashboard/v3/types';
import { LineType } from '@/services/report/enums';

export const DEFAULT_BORDER_RADIUS = 2;
export const DEFAULT_SHOW_FILTER = true;
export const DEFAULT_SHOW_ZOOM = true;
export const DEFAULT_SMOOTH = true;
export const DEFAULT_GRADIENT = false;
export const WIDGET_CUSTOMIZE_HEIGHT = 416;
export const DEFAULT_ENLARGE = true;

export const filterFields = {
  paymentType: {
    title: 'Payment Type',
    options: [
      { label: 'Sell', value: 'sell' },
      { label: 'Purchase', value: 'purchase' }
    ]
  },
  transactionType: {
    title: 'Transaction Type',
    options: Object.keys(LineType).map((key) => ({
      label: key,
      value: LineType[key as keyof typeof LineType]
    }))
  }
};

export const ReportRequiredDateFields = {
  13: { startDate: ['startDate', 'sellStartDate'], endDate: ['endDate', 'sellEndDate'] },
  12: { startDate: ['startDate'], endDate: ['endDate'] },
  49: { startDate: ['startDate', 'sellStartDate'], endDate: ['endDate', 'sellEndDate'] },
  50: { startDate: ['startDate', 'purchaseStartDate'], endDate: ['endDate', 'purchaseEndDate'] },
  38: { locationId: 'from', startDate: ['startDate'], endDate: ['endDate'] }
} as Record<
  number,
  { startDate: string[]; endDate: string[]; isOneDay?: boolean; locationId?: string }
>;

export const reportCompareField = {
  unit: { filter: 'unitId', value: 'Unit' },
  location: { filter: 'locationId', value: 'Location' },
  product: { filter: 'productId', value: 'Product' }
};

export const reportCompareRequiredFields = {
  66: [reportCompareField.location, reportCompareField.unit, reportCompareField.product],
  67: [reportCompareField.location, reportCompareField.unit, reportCompareField.product],
  70: [reportCompareField.location, reportCompareField.unit, reportCompareField.product],
  71: [reportCompareField.location, reportCompareField.unit, reportCompareField.product]
} as Record<number, { filter: string; value: string }[]>;

export const ReportRequiredFields = {
  8: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  59: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  64: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  65: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  66: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  67: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  68: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  69: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  70: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }],
  71: [{ field: 'identifier', formOption: filterFields.transactionType, required: true }]
} as Record<
  number,
  {
    field: string;
    required: boolean;
    formOption: { title: string; options?: { label: string; value: string }[] };
  }[]
>;

export const ReportCustomSearchFields = {
  38: [{ field: 'locationId', name: 'to', label: 'Location (To)', required: false }]
} as Record<number, { field: string; name: string; required: boolean; label: string }[]>;

export const defaultDashboardLayout = [
  {
    id: '23d99272-e748-42eb-9d7c-a0c0e1e82513',
    colSize: 6,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Sell Report',
    widgets: [
      { reportId: 12, name: 'Sell Report', field: ['total_amount'], plotAgainst: 'created_at' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  },
  {
    id: 'f41f50e0-710f-4e03-b69e-b800c3668223',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Adjustment Line Report',
    widgets: [
      { reportId: 30, name: 'Adjustment Line Report', field: ['total_amount'], plotAgainst: 'date' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'bar',
    type: 'report',
    isGradient: true,
    borderRadius: 2,
    enlargeChart: true
  },
  {
    id: '1336d22c-3a1a-4992-a040-1f1a32ea449a',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Purchase Report',
    widgets: [
      { reportId: 28, name: 'Purchase Report', field: ['total_amount'], plotAgainst: 'date' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  },
  {
    id: '2ceac301-ebfb-45ca-aad6-a61b5ff2c412',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: false,
    title: 'Most Sold Product',
    widgets: [
      {
        reportId: 39,
        name: 'Product History Range Report Summed',
        field: ['qty_sold'],
        plotAgainst: 'product_name'
      }
    ],
    showFilter: true,
    showZoom: false,
    chartType: 'pie',
    type: 'report',
    pieChartLimit: 5,
    enlargeChart: true
  },
  {
    id: 'a39752f7-0426-4a41-a290-4817d7a403cb',
    colSize: 3,
    isMultiple: false,
    isCustom: false,
    isPlotAgainstDate: false,
    title: 'Most Purchase Product',
    widgets: [
      {
        reportId: 39,
        name: 'Product History Range Report Summed',
        field: ['qty_purchased'],
        plotAgainst: 'product_name'
      }
    ],
    showFilter: true,
    showZoom: false,
    chartType: 'pie',
    type: 'report',
    pieChartLimit: 5,
    enlargeChart: true
  },
  {
    id: '109bce65-7587-4908-8786-a90fbdc0d113',
    colSize: 6,
    isMultiple: true,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Sell vs Purchase Payment',
    widgets: [
      { field: ['amount'], plotAgainst: 'paidOn', reportId: 13, name: 'Payment Sell Report' },
      { field: ['amount'], plotAgainst: 'paidOn', reportId: 14, name: 'Payment Purchase Report' }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  },
  {
    id: '88cc830e-71ce-4136-bd37-78a294e1a62f',
    colSize: 12,
    isMultiple: true,
    isCustom: false,
    isPlotAgainstDate: true,
    title: 'Sell, Purchase, Adjustment, Stock, Stock Transfer',
    widgets: [
      {
        field: ['total_amount'],
        plotAgainst: 'date',
        reportId: 12,
        name: 'Sell Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'date',
        reportId: 28,
        name: 'Purchase Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'date',
        reportId: 30,
        name: 'Adjustment Line Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'created_at',
        reportId: 57,
        name: 'Opening Stock Report'
      },
      {
        field: ['total_amount'],
        plotAgainst: 'created_at',
        reportId: 38,
        name: 'Stock Transfer Report'
      }
    ],
    showFilter: true,
    showZoom: true,
    chartType: 'line',
    type: 'report',
    isGradient: false,
    isSmooth: true,
    enlargeChart: true
  }
] as IWidgetConfiguration[];
