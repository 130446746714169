import { DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { RangePickerProps } from 'antd/lib/date-picker';
const { RangePicker } = DatePicker;
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { get_location_list } from '../../../../services/locations/queries';
import { useQuery } from '@tanstack/react-query';
import CustomReport from './report';
import { LocationSearch } from '../../../../components/Common/LocationSearch/LocationSearch';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { find_date_preference, find_to_use_NPR } from '../../../../store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '../../../../utils/nepaliDateConverter';
import { getUserData } from '../../../../utils/auth.utils';
import { ProfitCalculationDatePresets } from '../utils/profitCalculationDatePreset';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

interface DataType {
  amount_returned: number;
  total_amount: number;
  total_discount: number;
  total_tax: number;
  locationName: string;
}

const TotalPurchaseAndSaleReport = () => {
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [form] = Form.useForm();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType] = useState(find_date_preference());
  const [sortedInfotwo, setSortedInfotwo] = useState<SorterResult<any>>({});
  const handleChangetwo: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfotwo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      ProfitCalculationDatePresets.Today[0].format('YYYY-MM-DD HH:mm:ss')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      ProfitCalculationDatePresets.Today[0].format('YYYY-MM-DD HH:mm:ss')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : '',
      dateCustom: [...ProfitCalculationDatePresets.Today],
      startDate: ProfitCalculationDatePresets.Today[0].format('YYYY-MM-DD HH:mm:ss'),
      endDate: ProfitCalculationDatePresets.Today[1].format('YYYY-MM-DD HH:mm:ss'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Total Sale and Purchase Report',
      link: '/reports/total-sale-and-purchase'
    }
  ];
  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Location',
      // dataIndex: 'locationName',
      // render: (text: string) => <a>{text}</a>
      key: 'locationName',
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfo.columnKey === 'locationName' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div className="text-center">{record.locationName}</div>
          // </Link>
        );
      }
    },

    {
      title: 'Total Amount',
      // dataIndex: 'total_amount',
      // render: (text: string) => <a>{text}</a>
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Total Tax',
      // dataIndex: 'total_tax',
      // render: (text: string) => <a>{text}</a>
      key: 'total_tax',
      sorter: (a, b) => a.total_tax - b.total_tax,
      sortOrder: sortedInfo.columnKey === 'total_tax' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_tax)}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Total Discount',
      // dataIndex: 'total_discount',
      // render: (text: string) => <a>{text}</a>
      key: 'total_discount',
      sorter: (a, b) => a.total_discount - b.total_discount,
      sortOrder: sortedInfo.columnKey === 'total_discount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_discount)}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Total Returned',
      // dataIndex: 'sum(total_tax)',
      // render: (text: string) => <a>{text}</a>
      key: 'amount_returned',
      sorter: (a, b) => a['amount_returned'] - b['amount_returned'],
      sortOrder: sortedInfotwo.columnKey === 'amount_returned' ? sortedInfotwo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record['amount_returned'])}</div>
          // </Link>
        );
      }
    }
  ];
  const columns2: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Location',
      // dataIndex: 'locationName',
      // render: (text: string) => <a>{text}</a>
      key: 'locationName',
      sorter: (a, b) => a.locationName.localeCompare(b.locationName),
      sortOrder: sortedInfotwo.columnKey === 'locationName' ? sortedInfotwo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div className="text-center">{record.locationName}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Total Amount',
      className: 'highlight',
      // dataIndex: 'sum(total_amount)',
      // render: (text: string) => <a>{text}</a>
      key: 'total_amount',
      sorter: (a, b) => a['total_amount'] - b['total_amount'],
      sortOrder: sortedInfotwo.columnKey === 'total_amount' ? sortedInfotwo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record['total_amount'])}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Total Tax',
      // dataIndex: 'sum(total_tax)',
      // render: (text: string) => <a>{text}</a>
      key: 'total_tax',
      sorter: (a, b) => a['total_tax'] - b['total_tax'],
      sortOrder: sortedInfotwo.columnKey === 'total_tax' ? sortedInfotwo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record['total_tax'])}</div>
          // </Link>
        );
      }
    },
    {
      title: 'Total Returned',
      // dataIndex: 'sum(total_tax)',
      // render: (text: string) => <a>{text}</a>
      key: 'amount_returned',
      sorter: (a, b) => a['amount_returned'] - b['amount_returned'],
      sortOrder: sortedInfotwo.columnKey === 'amount_returned' ? sortedInfotwo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record['amount_returned'])}</div>
          // </Link>
        );
      }
    }
    // {
    //   title: 'Total Discount',
    //   dataIndex: 'total_discount',
    //   render: (text: string) => <a>{text}</a>
    // }
  ];

  const columns3: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Location',
      key: 'location_name',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfotwo.columnKey === 'location_name' ? sortedInfotwo.order : null,
      render: (record) => {
        return <div className="text-center">{record.location_name}</div>;
      }
    },
    {
      title: 'Total Amount',
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a['total_amount'] - b['total_amount'],
      sortOrder: sortedInfotwo.columnKey === 'total_amount' ? sortedInfotwo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">{nepaliNumberFormatter(record['total_amount'])}</div>
        );
      }
    }
  ];

  const onSelectt = (val: any) => {
    // console.log('this is from onSelect', val);
    form.setFieldValue(['constraints', 'locationId'], val);
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      toSort={handleChange}
      toSorttwo={handleChangetwo}
      breadcrumbItems={breadcrumbItems}
      state={{ id: [6, 7, 53], name: 'Total Purchase And Sale Report' }}
      locationList={locationsList?.data.results ? locationsList.data.results : []}
      form={form}
      columnsData={[columns, columns2, columns3]}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>

      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />

      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={ProfitCalculationDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm:ss'}
          allowClear={false}
        />
      </Form.Item>

      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default TotalPurchaseAndSaleReport;
