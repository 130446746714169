import { useMutation } from '@tanstack/react-query';
import { Button, Form, InputNumber, message } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import AppContent from '../../../components/Common/Content/Content';
import {
  create_sell_crate_receive_mutation,
  create_sell_crate_send_mutation
} from '../../../services/sell/mutations';

type Props = {
  amount: number;
  sellId: number;
  isReceive: boolean;
  refetchData: { refetch: (val?: string) => void; filter: string };
};

const EditSellCrates = (props: Props) => {
  const { amount, sellId, refetchData, isReceive } = props;
  const [form] = Form.useForm();

  const crateReceiveMutation = useMutation(create_sell_crate_receive_mutation);
  const crateSendMutation = useMutation(create_sell_crate_send_mutation);

  const onFinish = (values: { crates: number }) => {
    if (isReceive) {
      crateReceiveMutation.mutate(
        { sellId, crates: values.crates },
        {
          onSuccess: () => {
            message.success('success');
            refetchData.refetch(refetchData.filter);
          },
          onError: (err: any) => {
            message.error(`${err.response?.data?.message}`);
          }
        }
      );
    } else {
      crateSendMutation.mutate(
        { sellId, crates: values.crates },
        {
          onSuccess: () => {
            message.success('success');
            refetchData.refetch(refetchData.filter);
          },
          onError: () => {
            message.error('error');
          }
        }
      );
    }
  };

  const breadcrumbItems = [
    {
      label: isReceive ? `Sell (${sellId}) Receive Crates` : `Sell (${sellId}) Send Crates`
    }
  ];
  useEffect(() => {
    form.setFieldsValue({ crates: amount });
  }, [amount, sellId]);

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <Form form={form} initialValues={{ crates: 0 }} onFinish={onFinish}>
        <Form.Item
          label="Total Crates"
          name="crates"
          rules={[{ required: true, message: 'Please enter a quantity!' }]}>
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item className="flex flex-col justify-center items-end mt-10">
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </AppContent>
  );
};

export default EditSellCrates;
