import { useRef } from 'react';
import { IReturnInvoice } from '../../../../services/invoice/types';
import { useReactToPrint } from 'react-to-print';
import { useMutation } from '@tanstack/react-query';
import { update_sell_return_print_count_mutation } from '../../../../services/sell/mutations';
import { Button } from 'antd';
import { find_invoice_number } from '../../../../store/localstorage/preferences';
import { convert_string_to_nepali_date_string } from '../../../../utils/nepaliDateConverter';
import { convertUTCStringtoLocalString } from '../../../../utils/convertToUTC';
import { getUserData } from '../../../../utils/auth.utils';
import { nepaliNumberFormatter } from '../../../../utils/numberFormatter';
import { update_purchase_print_count_mutation } from '../../../../services/purchases/mutations';

const ReturnInvoice: React.FC<IReturnInvoice> = ({
  returnDetails,
  customerDetails,
  createdByDetails,
  firstCreatedByUserDetails,
  lines,
  handleModalClose
}) => {
  const printRef = useRef<any>();
  const loggedInUser = getUserData();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    async onAfterPrint() {
      if (returnDetails.fromPurchase) {
        if (returnDetails.printCount === 0)
          await updatePurchasePrintCountMutation.mutateAsync(returnDetails.id);
        await updatePurchasePrintCountMutation.mutateAsync(returnDetails.id);
      } else {
        if (returnDetails.printCount === 0)
          await updateSellPrintCountMutation.mutateAsync(returnDetails.id);
        await updateSellPrintCountMutation.mutateAsync(returnDetails.id);
      }
    }
  });

  const updateSellPrintCountMutation = useMutation(update_sell_return_print_count_mutation, {
    onSuccess: () => {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const updatePurchasePrintCountMutation = useMutation(update_purchase_print_count_mutation, {
    onSuccess: () => {
      if (handleModalClose) {
        handleModalClose();
      }
    }
  });

  const headers = [
    'S.N',
    'Description',
    'Quantity',
    'HS Code',
    'Unit Price(NPR)',
    'Discount(NPR)',
    'VAT(NPR)',
    'Amount(NPR)'
  ];

  return (
    <>
      <div className="flex justify-end mb-3">
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>
      <div ref={printRef}>
        <div style={{ color: 'black' }}>
          <div className="text-center mt-3">
            <div className="font-bold text-xl">{'FreshKtm Agro Pvt. Ltd.'}</div>
            <div>{returnDetails.printLocation?.name}</div>
            <div className="font-bold text-sm">
              {returnDetails.printCount === 0 && returnDetails?.fromPurchase
                ? 'Purchase Return Invoice'
                : returnDetails.printCount === 0
                ? 'Credit Note'
                : 'Copy of Original ' + returnDetails.printCount}
            </div>
            <div className="font-bold text-sm">VAT No.: {find_invoice_number()}</div>
          </div>
          <div className="flex justify-between items-center mt-3 px-8">
            <div>
              {returnDetails.financialReference ? (
                <>
                  <span>{'Financial Reference'}</span> :{' '}
                  <span className="italic font-bold">{returnDetails.financialReference} </span>
                </>
              ) : (
                <></>
              )}
              {returnDetails.debitReference && returnDetails.debitReference !== null ? (
                <div>
                  <span>{'Debit Reference'}</span> :{' '}
                  <span className="italic font-bold">{returnDetails.debitReference}</span>
                </div>
              ) : (
                ''
              )}
              <div>
                {returnDetails.fromPurchase ? (
                  <span>{'Supplier Name'}</span>
                ) : (
                  <span>{'Customer Name'}</span>
                )}
                : <span className="font-bold uppercase">{customerDetails.name}</span>
              </div>
              <div>
                {returnDetails?.fromPurchase ? (
                  <span>{'Supplier Address'}</span>
                ) : (
                  <span>{'Customer Address'}</span>
                )}
                :{' '}
                <span className="font-bold uppercase">
                  {customerDetails.address} {customerDetails.city},{customerDetails.country}
                </span>
              </div>
              <div>
                <span>{'Route'}</span>:{' '}
                <span className="font-bold uppercase">{customerDetails?.routeName}</span>
              </div>
              <div>
                <span>{returnDetails?.fromPurchase ? 'Supplier' : 'Customer'} Number</span>:{' '}
                <span className="font-bold uppercase">{customerDetails?.phone}</span>
              </div>
            </div>
            <div>
              <div>
                <span>{'Miti'}</span>:
                {convert_string_to_nepali_date_string(returnDetails.createdAt)}
              </div>
              <div>
                <span>{'Date'}</span>:
                {returnDetails?.createdAt
                  ? convertUTCStringtoLocalString(returnDetails.createdAt, 'YYYY-MM-DD')
                  : ''}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center px-8">
            {returnDetails?.fromPurchase ? (
              <div>
                <span>{'Supplier PAN'}</span>:{' '}
                <span className="font-bold uppercase">
                  {customerDetails?.panNumber ? customerDetails.panNumber : ''}
                </span>
              </div>
            ) : (
              <div>
                <span>{'Customer PAN'}</span>:{' '}
                <span className="font-bold uppercase">
                  {customerDetails?.panNumber ? customerDetails.panNumber : ''}
                </span>
              </div>
            )}
          </div>
          {/* Table Start */}
          <div className="flex justify-center items-center">
            <table width={'95%'}>
              {headers.map((curr: any, ind: any) => {
                return (
                  <th key={ind} style={thStyle}>
                    {curr}
                  </th>
                );
              })}
              {lines.map((currdata: any, ind: any) => {
                return (
                  <tr key={currdata}>
                    <td style={thStyle}>
                      <div className="text-center">{ind + 1}.</div>
                    </td>
                    <td style={thStyle}>
                      <div className="ml-1">{currdata.productName}</div>
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">{`${currdata.quantity} ${currdata.shortName}`}</div>
                    </td>
                    <td style={thStyle}>
                      <div className="ml-1">{currdata?.hsCode || 'N/A'}</div>
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">{currdata.unitPrice.toFixed(2)}</div>{' '}
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">{currdata.discount.toFixed(2)}</div>{' '}
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">
                        {currdata.vat != undefined ? currdata.vat.toFixed(2) : ''}
                      </div>{' '}
                    </td>
                    <td style={thStyle}>
                      <div className="text-right mr-1">
                        {(currdata.totalAmount - currdata.vat).toFixed(2)}
                      </div>{' '}
                    </td>
                  </tr>
                );
              })}
              {lines.length < 16 &&
                [...Array(16 - lines.length)].map((curr: any) => {
                  return (
                    <tr key={curr} style={{ height: '1.5rem' }}>
                      <td style={tdStyle}>
                        <div className="text-center">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="ml-1">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                      <td style={tdStyle}>
                        <div className="text-right mr-1">{}</div>{' '}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td colSpan={2} style={thStyle}>
                  <span>Total Return Quantity</span>:
                </td>
                <td colSpan={1} style={thStyle}>
                  <div className="text-center">{returnDetails.totalQuantity}</div>
                </td>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'Total Return Amount'}</div>
                </td>
                <td colSpan={1} style={thStyle} className="text-right">
                  {nepaliNumberFormatter(
                    returnDetails.totalTaxable - returnDetails.totalNonTaxable
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={3} rowSpan={6} style={thStyle}>
                  <div className="text-start">{'In Words'}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">Taxable</div>
                </td>
                <td style={thStyle} className="text-right">
                  {nepaliNumberFormatter(returnDetails.totalTaxable)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">Non Taxable</div>
                </td>
                <td style={thStyle} className="text-right">
                  {nepaliNumberFormatter(returnDetails.totalNonTaxable)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'13% VAT'}</div>
                </td>
                <td style={thStyle} className="text-right">
                  {nepaliNumberFormatter(returnDetails.totalVat)}
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={thStyle}>
                  <div className="text-center">{'Net Return Amount'}</div>
                </td>
                <td style={thStyle} className="text-right">
                  {nepaliNumberFormatter(returnDetails.totalAmount)}
                </td>
              </tr>
              <tr>
                <td colSpan={8} style={thStyle}>
                  <div>Remarks:</div>
                </td>
              </tr>
            </table>
          </div>
          {/* Table End */}
          <div className="mt-12 flex justify-between  px-8">
            <div>
              <div>..........................................</div>
              <div>{'Received By'}</div>
            </div>
            <div className="font-bold">www.freshktm.com</div>
            <div>
              <div>........................................................................</div>
              <div>
                For:
                <span>{'FreshKtm Agro Pvt. Ltd.'}</span>
              </div>
              <div>
                Finalized By:
                <span className="capitalize">
                  {createdByDetails?.name ? createdByDetails.name : ''}
                </span>
              </div>
              {firstCreatedByUserDetails && (
                <div>
                  Created By:
                  <span className="capitalize">
                    {firstCreatedByUserDetails?.name ? firstCreatedByUserDetails.name : ''}
                  </span>
                </div>
              )}
              <div>
                Printed By:{' '}
                <span className="capitalize">{loggedInUser?.name ? loggedInUser.name : ''}</span>
              </div>
            </div>
          </div>
        </div>
        {/* For First Print */}
        {returnDetails.printCount === 0 && (
          <>
            <div className="pagebreak"></div>
            <div style={{ color: 'black' }}>
              <div className="text-center mt-3">
                <div className="font-bold text-xl">{'FreshKtm Agro Pvt. Ltd.'}</div>
                <div>{returnDetails.printLocation?.name}</div>
                <div className="font-bold text-sm">Copy of Original 1</div>
                <div className="font-bold text-sm">VAT No.: {find_invoice_number()}</div>
              </div>
              <div className="flex justify-between items-center mt-3 px-8">
                <div>
                  {returnDetails.financialReference ? (
                    <>
                      <span>{'Financial Reference'}</span> :{' '}
                      <span className="italic font-bold">{returnDetails.financialReference} </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {returnDetails.debitReference && returnDetails.debitReference !== null ? (
                    <div>
                      <span>{'Debit Reference'}</span> :{' '}
                      <span className="italic font-bold">{returnDetails.debitReference}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    {returnDetails.fromPurchase ? (
                      <span>{'Supplier Name'}</span>
                    ) : (
                      <span>{'Customer Name'}</span>
                    )}
                    : <span className="font-bold uppercase">{customerDetails.name}</span>
                  </div>
                  <div>
                    {returnDetails?.fromPurchase ? (
                      <span>{'Supplier Address'}</span>
                    ) : (
                      <span>{'Customer Address'}</span>
                    )}
                    :{' '}
                    <span className="font-bold uppercase">
                      {customerDetails.address} {customerDetails.city},{customerDetails.country}
                    </span>
                  </div>
                  <div>
                    <span>{'Route'}</span>:{' '}
                    <span className="font-bold uppercase">{customerDetails?.routeName}</span>
                  </div>
                  <div>
                    <span>{returnDetails?.fromPurchase ? 'Supplier' : 'Customer'} Number</span>:{' '}
                    <span className="font-bold uppercase">{customerDetails?.phone}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>{'Miti'}</span>:
                    {convert_string_to_nepali_date_string(returnDetails.createdAt)}
                  </div>
                  <div>
                    <span>{'Date'}</span>:
                    {returnDetails?.createdAt
                      ? convertUTCStringtoLocalString(returnDetails.createdAt, 'YYYY-MM-DD')
                      : ''}
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center px-8">
                {returnDetails?.fromPurchase ? (
                  <div>
                    <span>{'Supplier PAN'}</span>:{' '}
                    <span className="font-bold uppercase">
                      {customerDetails?.panNumber ? customerDetails.panNumber : ''}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span>{'Customer PAN'}</span>:{' '}
                    <span className="font-bold uppercase">
                      {customerDetails?.panNumber ? customerDetails.panNumber : ''}
                    </span>
                  </div>
                )}
              </div>
              {/* Table Start */}
              <div className="flex justify-center items-center">
                <table width={'95%'}>
                  {headers.map((curr: any, ind: any) => {
                    return (
                      <th key={ind} style={thStyle}>
                        {curr}
                      </th>
                    );
                  })}
                  {lines.map((currdata: any, ind: any) => {
                    return (
                      <tr key={currdata}>
                        <td style={thStyle}>
                          <div className="text-center">{ind + 1}.</div>
                        </td>
                        <td style={thStyle}>
                          <div className="ml-1">{currdata.productName}</div>
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">{`${currdata.quantity} ${currdata.shortName}`}</div>
                        </td>
                        <td style={thStyle}>
                          <div className="ml-1">{currdata?.hsCode || 'N/A'}</div>
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">{currdata.unitPrice.toFixed(2)}</div>{' '}
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">{currdata.discount.toFixed(2)}</div>{' '}
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">
                            {currdata.vat != undefined ? currdata.vat.toFixed(2) : ''}
                          </div>{' '}
                        </td>
                        <td style={thStyle}>
                          <div className="text-right mr-1">
                            {(currdata.totalAmount - currdata.vat).toFixed(2)}
                          </div>{' '}
                        </td>
                      </tr>
                    );
                  })}
                  {lines.length < 16 &&
                    [...Array(16 - lines.length)].map((curr: any) => {
                      return (
                        <tr key={curr} style={{ height: '1.5rem' }}>
                          <td style={tdStyle}>
                            <div className="text-center">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="ml-1">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                          <td style={tdStyle}>
                            <div className="text-right mr-1">{}</div>{' '}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan={2} style={thStyle}>
                      <span>Total Return Quantity</span>:
                    </td>
                    <td colSpan={1} style={thStyle}>
                      <div className="text-center">{returnDetails.totalQuantity}</div>
                    </td>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'Total Return Amount'}</div>
                    </td>
                    <td colSpan={1} style={thStyle} className="text-right">
                      {nepaliNumberFormatter(
                        returnDetails.totalTaxable - returnDetails.totalNonTaxable
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} rowSpan={6} style={thStyle}>
                      <div className="text-start">{'In Words'}</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">Taxable</div>
                    </td>
                    <td style={thStyle} className="text-right">
                      {nepaliNumberFormatter(returnDetails.totalTaxable)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">Non Taxable</div>
                    </td>
                    <td style={thStyle} className="text-right">
                      {nepaliNumberFormatter(returnDetails.totalNonTaxable)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'13% VAT'}</div>
                    </td>
                    <td style={thStyle} className="text-right">
                      {nepaliNumberFormatter(returnDetails.totalVat)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={thStyle}>
                      <div className="text-center">{'Net Return Amount'}</div>
                    </td>
                    <td style={thStyle} className="text-right">
                      {nepaliNumberFormatter(returnDetails.totalAmount)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={8} style={thStyle}>
                      <div>Remarks:</div>
                    </td>
                  </tr>
                </table>
              </div>
              {/* Table End */}
              <div className="mt-12 flex justify-between  px-8">
                <div>
                  <div>..........................................</div>
                  <div>{'Received By'}</div>
                </div>
                <div className="font-bold">www.freshktm.com</div>
                <div>
                  <div>
                    ........................................................................
                  </div>
                  <div>
                    For:
                    <span>{'FreshKtm Agro Pvt. Ltd.'}</span>
                  </div>
                  <div>
                    Finalized By:
                    <span className="capitalize">
                      {createdByDetails?.name ? createdByDetails.name : ''}
                    </span>
                  </div>
                  {firstCreatedByUserDetails && (
                    <div>
                      Created By:
                      <span className="capitalize">
                        {firstCreatedByUserDetails?.name ? firstCreatedByUserDetails.name : ''}
                      </span>
                    </div>
                  )}
                  <div>
                    Printed By:{' '}
                    <span className="capitalize">
                      {loggedInUser?.name ? loggedInUser.name : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* For First End */}
      </div>
    </>
  );
};

const thStyle = {
  border: '1px solid black'
};

const tdStyle = {
  borderLeft: '1px solid black',
  borderRight: '1px solid black'
};

export default ReturnInvoice;
