import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, InputNumber, message, Modal } from 'antd';
import { formatTimeStr } from 'antd/lib/statistic/utils';
import { useEffect } from 'react';
import { diverge_products_mutation } from '../../../../../services/products/mutations';
import getErrorMessage from '@/utils/getError';

export const DivergeLotsModal = ({
  open,
  onOk,
  onCancel,
  selectedLot,
  setSelectedLot,
  lotsArray,
  refetch
}: any) => {
  const [form] = Form.useForm();

  const divergeLotsMutation = useMutation(diverge_products_mutation, {
    onSuccess: (data) => {
      message.success('Lots diverged');
      refetch();
    },
    onError: (error) => {
      message.error(getErrorMessage(error));
    }
  });

  useEffect(() => {
    const selected = lotsArray.find((value: any) => value.lotId === selectedLot.lotId);
    console.log('selected', selected);
    setSelectedLot(selected);
  }, [lotsArray]);

  const handleSubmit = (values: {
    lotId: number;
    lots: {
      qty: number;
      grade: string;
    }[];
  }) => {
    let total = 0;
    for (let i = 0; i < values.lots.length; i++) {
      total += values.lots[i].qty;
      if (total > selectedLot.qtyAvailable) {
        message.error('Quantity total is more than available!');
        return;
      }
    }
    if (total < selectedLot.qtyAvailable) {
      message.error('Quantity total is less than available, please add all!');
      return;
    }

    const formData = {
      ...values,
      lotId: selectedLot.id
    };
    divergeLotsMutation.mutate(formData);

    onCancel();
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      lots: [
        {
          grade: 'A',
          qty: 0
        },
        {
          grade: 'B',
          qty: 0
        }
      ]
    });
  }, [open]);

  return (
    <Modal
      destroyOnClose={true}
      title={
        <div className="font-bold text-lg">{`Diverge Lot Id:${selectedLot.id}, Avail.: (${selectedLot.qtyAvailable})`}</div>
      }
      visible={open}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
      footer={null}>
      <Form form={form} onFinish={handleSubmit}>
        <Form.List name="lots">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  className="grid gap-2"
                  key={key}
                  style={{
                    gridTemplateColumns: '1fr 1fr 50px',
                    padding: '10px',
                    marginTop: '2px',
                    border: '1px solid gray',
                    borderRadius: '9px'
                  }}>
                  <Form.Item
                    {...restField}
                    name={[name, 'grade']}
                    label={<div className="font-bold text-base">Grade</div>}>
                    <Input style={{ borderRadius: '9px' }} maxLength={1} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'qty']}
                    label={<div className="font-bold text-base">Quantity</div>}>
                    <InputNumber min={0} defaultValue={0} style={{ borderRadius: '9px' }} />
                  </Form.Item>

                  <div className="flex justify-center items-center">
                    {fields.length > 2 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button flex justify-center items-center"
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </div>
                </div>
              ))}

              <Form.Item>
                <Button
                  type="ghost"
                  style={{ borderRadius: '9px', marginTop: '3px' }}
                  onClick={() =>
                    add({
                      grade: 'A',
                      qty: 0
                    })
                  }
                  icon={<PlusOutlined />}>
                  Add Lot
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* <Button className="mt-5" type="primary" htmlType="submit">
          Diverge
        </Button> */}
        <div className="flex items-center justify-end mt-3">
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: '#1890ff', color: 'white', borderRadius: '9px' }}>
            Diverge
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
