import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import {
  ICheckSellDuplicatePayload,
  IDuplicateSellCheckResponse,
  ILines,
  ISellDetailsResponseFromServer,
  ISellDiscount,
  ISellFromServer,
  ISellLinesResponseFromServer,
  ISellManualPriceResponse,
  ISellOrderDetails,
  ISellOrderProductDataByLocationId,
  ISellOrderResponseData,
  ISellResponseFromServer,
  ISellReturn,
  ISellReturnResponse,
  ISellRoutePrint,
  ISellRoutePrintForInvoice_v2
} from './types';
import { getDataForIds } from '../../utils/queries.utils';

export const get_sell_list = async (filter = '') => {
  const response: AxiosResponse<ISellResponseFromServer, any> = await http.get('sell/?' + filter, {
    count: 100
  });
  return response;
};
export const get_sell_list_v2 = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<ISellResponseFromServer, any> = await http.get('sell/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};

export const get_sell_details_ids = async (ids: number[]) => {
  return await getDataForIds<ISellFromServer>(ids, 'sell/?');
};

export const check_sell_duplicate = async (value: ICheckSellDuplicatePayload) => {
  const response: AxiosResponse<IDuplicateSellCheckResponse | false> = await http.store(
    'sell/check-duplicate',
    value
  );
  return response.data;
};

export const get_sell_lists_v2 = async (url: string) => {
  const response: AxiosResponse<ISellResponseFromServer, any> = await http.get('sell/?' + url);
  return response;
};

export const get_sell_details = async (id: number) => {
  const response: AxiosResponse<ISellDetailsResponseFromServer, any> = await http.get('sell/' + id);
  return response.data;
};

export const get_sell_lines_details = async (id: number) => {
  const response: AxiosResponse<ISellLinesResponseFromServer[], any> = await http.get(
    'products/lines/SALE/' + id
  );
  return response;
};

export const delete_sell = async (id: number) => {
  const response = await http.remove('sell/' + id);
  return response;
};
export const delete_sell_order = async (id: number) => {
  const response = await http.remove('sell-order/' + id);
  return response;
};
export const get_sell_order_list = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<ISellOrderResponseData, any> = await http.get(
    'sell-order/?' + filter,
    { skip, count, value }
  );
  return response;
};
export const get_sell_order_list_for_filter = async (filter = '') => {
  const response: AxiosResponse<ISellOrderResponseData, any> = await http.get(
    'sell-order/?' + filter
  );
  return response;
};
export const get_sell_order_list_v2 = async (url: string) => {
  const response: AxiosResponse<ISellOrderResponseData, any> = await http.get('sell-order/?' + url);
  return response;
};
export const get_sell_order_details = async (id: number) => {
  const response: AxiosResponse<ISellOrderDetails, any> = await http.get('sell-order/' + id);
  return response;
};
export const get_sell_order_line_details = async (id: number) => {
  const response: AxiosResponse<ILines[], any> = await http.get('products/lines/SALE_ORDER/' + id);
  return response;
};
// export const get_sell_return_list = async (skip = 0, count = 0, value = '') => {
//   const response: AxiosResponse<ISellReturnResponse, any> = await http.get('sell-return', {
//     skip,
//     count,
//     value
//   });
//   return response;
// };

export const get_sell_return_list_v2 = async (url: string) => {
  const response: AxiosResponse<ISellReturnResponse, any> = await http.get('sell-return/?' + url);
  return response;
};
export const get_sell_return_details = async (sellId: string) => {
  const response = await http.get('sell-return/' + sellId);
  return response;
};

export const get_sell_return_details_by_id = async (id: number) => {
  const response: AxiosResponse<ISellReturn, any> = await http.get('sell-return/return/' + id);
  return response.data;
};

export const get_sell_return_list = async (filter: string) => {
  const response: AxiosResponse<ISellReturnResponse, any> = await http.get(
    'sell-return/?' + filter
  );
  return response;
};

export const get_sell_return_details_ids = async (ids: number[]) => {
  return await getDataForIds<ISellReturn>(ids, 'sell-return/?');
};

export const get_sell_return_lines_details = async (id: number) => {
  const response: AxiosResponse<ILines[], any> = await http.get('products/lines/SALE_RETURN/' + id);
  return response;
};
export const get_sell_recent_list = async ({
  customerId,
  count = 5
}: {
  customerId: number;
  count?: number;
}) => {
  const response = await http.get(`sell/recents/${customerId}/${count}`);
  return response;
};

export const get_sell_list_to_print_byRouteId = async (
  locationId: number,
  routeId?: number,
  categoryId?: number,
  filter = ''
) => {
  if (routeId) {
    const response: AxiosResponse<ISellRoutePrintForInvoice_v2, any> = await http.get(
      `sell/route/print?${filter}`,
      { routeId, locationId, categoryId }
    );
    return response;
  } else {
    const response: AxiosResponse<ISellRoutePrintForInvoice_v2, any> = await http.get(
      `sell/route/print?${filter}`,
      { locationId, categoryId }
    );
    return response;
  }
};

export const get_sell_order_list_to_print_byRouteId = async (routeId: number) => {
  const response: AxiosResponse<ISellRoutePrint, any> = await http.get(
    `sell-order/route/print/${routeId}`
  );
  return response;
};

export const get_sell_order_route_print = async ({
  locationId = '',
  routeId = '',
  categoryId = '',
  startDate,
  endDate
}: {
  locationId?: number | string;
  routeId?: number | string;
  categoryId?: number | string;
  startDate: string;
  endDate: string;
}) => {
  const response: AxiosResponse<ISellRoutePrintForInvoice_v2, any> = await http.get(
    'sell-order/route/print?',
    {
      locationId,
      routeId,
      categoryId,
      startDate,
      endDate
    }
  );
  return response;
};

export const get_sell_order_product_print_byLocationId = async (filter: string) => {
  const response: AxiosResponse<ISellOrderProductDataByLocationId, any> = await http.get(
    '/sell-order/product-data?' + filter
  );
  return response;
};

export const get_total_customer_sell_crates = async (id: string | number) => {
  const response = await http.get('sell/crates/' + id);
  return response.data;
};

export const get_sell_discount = async (locationId: number, customerId: number, lines: any[]) => {
  const response = await http.store('sell/get-discount/', { locationId, customerId, lines });
  return response.data as ISellDiscount[];
};

export const get_sell_manual_price = async (filter = '') => {
  const response: AxiosResponse<ISellManualPriceResponse> = await http.get(
    'sell/manual-price/?' + filter
  );
  return response.data;
};
