import { useQuery } from '@tanstack/react-query';
import {
  Form,
  Select,
  PageHeader,
  Input,
  DatePicker,
  Collapse,
  Typography,
  Divider,
  Avatar,
  Image,
  Carousel,
  Card
} from 'antd';
import moment from 'moment';
// import { useWatch } from 'antd/lib/form/Form';
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { ProductsSearch } from '../../../components/Common/ProductsSearch';
import { VendorSearch } from '../../../components/Common/VendorSearch/VendorSearch';
import { get_vendor_products_id } from '../../../services/vendor-products/queries';
import {
  IVendorProductsCreate,
  IVendorProductsCustom
} from '../../../services/vendor-products/types';
import { PictureUpload } from '../pictures';
import { get_product_details } from '../../../services/products/queries';
import { get_vendor_details } from '../../../services/users/queries';
import { VendorProductViewTypes } from './types';
import { AntDesignOutlined } from '@ant-design/icons';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';

const { Option } = Select;
const { Title, Paragraph, Text, Link } = Typography;
const ViewVendorProducts = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // This is needed to get the product details update as its written
  const [allData, setAllData] = useState<VendorProductViewTypes>();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { id } = useParams();

  useQuery(['vendor-product-details'], async () => {
    const response = await get_vendor_products_id(id as string);
    const productResponse = await get_product_details(response.data.productId);
    const vendorResponse = await get_vendor_details(response.data.vendorId);
    // console.log(productResponse);

    // const productImage = await get_image(productResponse.imgId);
    // productResponse.imgSrc = productImage;
    // console.log(productImage);
    const values = {
      ...response.data,
      productData: productResponse,
      vendorData: vendorResponse,
      details: JSON.parse(response.data.details),
      visits: JSON.parse(response.data.visits)
    } as IVendorProductsCreate;

    values.visits = values.visits.map((val) => {
      return {
        ...val,
        date: moment(val.date),

        metadata: JSON.parse(val.metadata as string) as IVendorProductsCustom
      };
    });

    if (values.visits) {
      values.visits = values.visits.map((val) => {
        if (typeof val.metadata != 'string') {
          if (val.metadata?.custom) {
            val.metadata.custom = val.metadata.custom.map((customval) => {
              if (customval.date)
                return {
                  ...customval,
                  date: moment(customval.date)
                };
              return customval;
            });
          }
        }
        return {
          ...val
          // date: (val.date as moment.Moment).utc().format()
        };
      });
    } else {
      values.visits = [];
    }
    if (values?.details?.custom) {
      values.details.custom = values.details.custom.map((val) => {
        if (val.date)
          return {
            ...val,
            date: moment(val.date)
          };
        return val;
      });
    }
    console.log(values);
    setAllData(values as VendorProductViewTypes);
    return response;
  });
  const [responsize, setResponsize] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 750) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 750) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      {allData && (
        <div className={responsize ? 'flex flex-col' : 'flex flex-row flex-wrap'}>
          <div
            className={responsize ? 'w-100% bg-slate-200 gap-5' : 'w-1/3 p-5 bg-slate-200 gap-5'}>
            <PageHeader
              title="Product Information"
              style={{
                padding: '8px 0px'
              }}
            />
            <Typography className="pb-10 pt-10">
              <div className="flex flex-col justify-center items-center">
                <Image
                  width={200}
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                />
                <Text strong className="mt-2">
                  {allData.productData.name}
                </Text>
                <Paragraph>{allData.productData.description}</Paragraph>
                <Divider />
                <Text strong className="text-center text-base">
                  {' '}
                  काक्रोमा क्यालोरीको मात्रा कम हुन्छ तर पानी र घुलनशील फाइबर राम्रो मात्रामा
                  पाइन्छ, जसले गर्दा शरीरमा हाइड्रेशन बढाउन र तौल घटाउनमा सहायता गर्दछ । काक्रोको
                  दैनिक सेवनले कब्जियत रोक्न, नियमितता बढाउन, शरीरिक तौल घटाउन र रगतमा चिनीको मात्रा
                  घटाउन मद्दत गर्छ ।
                </Text>
                <Text strong className="font-[1000] mt-2">
                  SKU
                </Text>
                <Text strong className="mb-2">
                  {allData.productData.sku}
                </Text>
                <Divider style={{ marginTop: '0px', marginBottom: '0px' }}></Divider>
                <Text strong className="font-bold mt-2">
                  Total Sales
                </Text>
                <Text strong>1000</Text>
              </div>
            </Typography>
            <div style={{ height: 'auto', margin: '0 auto', maxWidth: 180, width: '100%' }}>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={`https://freshktm.github.io/ERP-UI/#/vendor-products/view/${id}`}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
          <div className={responsize ? 'w-100%' : 'w-2/3 p-5 bg-lime-200'}>
            <PageHeader
              title="Farmer and Farm Information"
              style={{
                padding: '8px 0px',
                fontWeight: 'bold'
              }}
            />
            <Card style={{ borderRadius: '8px', padding: responsize ? '0px' : '10px' }}>
              <div className={responsize ? 'flex flex-col' : 'flex flex-row'}>
                <div className={responsize ? 'w-100% bg-lime-200' : 'w-1/3'}>
                  <Typography className="pb-10 pt-10">
                    <div className="flex flex-col justify-center items-center">
                      <Image
                        width={200}
                        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                      />
                      <Text strong className="font-extrabold mt-2 text-base">
                        {allData.vendorData.user.user.name}
                      </Text>
                      <Text strong className="font-extrabold text-base text-emerald-600">
                        {allData.vendorData.user.user.phone}
                      </Text>
                      <Text strong>{allData.vendorData.user.user.email}</Text>
                      <Text strong className="text-center text-base mt-2">
                        म विगत ४ वर्ष देखि कृषि पेशा गर्दै आइराहेको छु । विगतमा मेरो उत्पादनको सही
                        मूल्य नपाएर निरास थिए । अब Freshktm संगको सहकार्यमा खान योग्य तरकारी
                        उपभोक्ता सम्म पुर्याउन पाउदा म अत्यन्तै खुसी छु
                      </Text>
                    </div>
                  </Typography>
                </div>
                <div className={responsize ? 'w-100% bg-lime-200' : 'w-2/3 '}>
                  <Typography className="pb-10 pt-10 ">
                    <div className="flex flex-col justify-center items-center">
                      <Carousel
                        dotPosition={'left'}
                        style={{
                          width: 200
                        }}
                        autoplay>
                        <div className="flex flex-col justify-center items-center bg-red-500">
                          <Image
                            preview={false}
                            width={200}
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                          />
                        </div>
                        <div>
                          <Image
                            preview={false}
                            width={200}
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                          />
                        </div>
                        <div>
                          <Image
                            preview={false}
                            width={200}
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                          />
                        </div>
                      </Carousel>
                      <Text strong>{allData.vendorData.user.user.name}</Text>
                      <Text strong>{allData.vendorData.user.user.phone}</Text>
                      <div className="px-8">
                        {allData.details.custom.map((val, index) => {
                          return (
                            <div key={index} className="flex flex-col">
                              <Text strong className="font-extrabold text-base">
                                {val.label}
                              </Text>
                              <ul style={{ listStyle: 'none' }}>
                                <li className="flex" style={{ marginLeft: '-4px' }}>
                                  <div
                                    style={{
                                      height: '8px',
                                      width: '8px',
                                      backgroundColor: 'black',
                                      marginTop: '5px',
                                      marginRight: '10px',
                                      borderRadius: '50%'
                                    }}></div>
                                  <Text className="text-sm font-[300]">{val.value}</Text>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Typography>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </AppContent>
  );
};

export default ViewVendorProducts;
const breadcrumbItems = [
  {
    label: 'Vendor Products',
    link: '/vendor-products'
  },
  {
    label: 'View'
  }
];
const contentStyle: React.CSSProperties = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79'
};
