import { data } from 'autoprefixer';
import React, { useEffect } from 'react';
import { createDocumentRegistry } from 'typescript';

export interface IColumnDataForCustomTable {
  dataIndex: string;
  title: string;
  render: (x: any) => any;
  width?: number;
  children?: IColumnDataForCustomTable[];
}

export interface IcustomTable {
  columns: IColumnDataForCustomTable[];
  datas: any[];
  reff?: any;
  onAuto?: () => void;
  title?: string;
  subTitle?: string;
  date: string;
  year: string;
}

const CustomTable: React.FC<IcustomTable> = ({
  columns,
  datas,
  reff,
  title,
  subTitle,
  date,
  year,
  onAuto
}) => {
  // console.log('this is from custom table', datas);
  // console.log('this is from columns', columns);
  const firstRow = columns.map((value) => {
    return {
      title: value.title,
      width: value.width,
      colspan: value.children?.length !== undefined ? value.children?.length : 0,
      rowspan: value.children?.length !== undefined && value.children?.length > 0 ? 1 : 2
    };
  });

  const secondRow: any[] = [];
  columns.forEach((value) => {
    if (value.children?.length !== undefined) {
      value.children.forEach((valueChild) => {
        secondRow.push({
          title: valueChild.title,
          width: valueChild.width,
          dataIndex: valueChild.dataIndex,
          colspan: 1,
          rowspan: 1
        });
      });
    } else {
      secondRow.push({
        title: value.title,
        width: value.width,
        dataIndex: value.dataIndex,
        colspan: 0,
        rowspan: 0
      });
    }
  });

  // console.log('first Row', firstRow);
  // console.log('second Row', secondRow);
  useEffect(() => {
    if (onAuto) {
      onAuto();
    }
  }, [datas]);

  return (
    <div className="flex flex-col justify-center items-center w-full" ref={reff}>
      <table width={'100%'}>
        <thead>
          {title && (
            <tr>
              <th style={tdthStyle} colSpan={secondRow.length}>
                {title}
              </th>
            </tr>
          )}
          {subTitle && (
            <tr>
              <th style={tdthStyle} colSpan={secondRow.length}>
                {subTitle}
              </th>
            </tr>
          )}
          <tr>
            <th style={tdthStyle} colSpan={secondRow.length}>
              PAN: 609891700 &emsp;&emsp; Tax Payer&apos;s name: Freshktm &emsp;&emsp; Year: {year}{' '}
              &emsp;&emsp; Tax Period: {date}
            </th>
          </tr>
          <tr>
            {firstRow.map((currCol) => (
              <th
                key={currCol.title}
                style={{ ...tdthStyle, maxWidth: currCol.title === 'URL' ? '180px' : 'auto' }}>
                <div className="text-center">{currCol.title}</div>
              </th>
            ))}
          </tr>
          <tr>
            {secondRow.map((currCol) => {
              if (currCol.colSpan !== 0 && currCol.rowspan !== 0) {
                return (
                  <th
                    key={currCol.dataIndex}
                    style={{ ...tdthStyle, maxWidth: currCol.title === 'URL' ? '180px' : 'auto' }}>
                    <div className="text-center">{currCol.title}</div>
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {datas.map((currData: any, indr: number) => {
            return (
              <tr key={`${currData}${indr}`}>
                {secondRow.map((currCol, indc: number) => {
                  return (
                    <td
                      key={indr + indc}
                      style={{
                        ...tdthStyle,
                        maxWidth: ['URL', 'Event'].includes(currCol.title) ? '180px' : 'auto'
                      }}
                      className="text-center">
                      {currData[currCol.dataIndex]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const tdthStyle = {
  border: '1px solid black',
  padding: '2px 0px'
};

export default CustomTable;
