import { Button, Card, Divider, Form, Input, InputNumber, PageHeader, Spin, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '../../../../components/Common/Content/Content';
import { ICreateCustomerGroup } from '../../../../services/offfers/types';
import { CustomerSearch } from '../../../../components/Common/CustomerSearch/CustomerSearch';
import { useMutation } from '@tanstack/react-query';
import { create_customer_group_mutation } from '../../../../services/offfers/mutations';
import CustomErrorModal from '../../../../components/Common/CustomErrorModal';
import { get_customer_group_list_ids } from '../../../../services/offfers/queries';
import CustomersDB from '../../../../store/localstorage/CustomerDB';
import { get_customer_list_ids } from '../../../../services/users/queries';
import ProductsDB from '../../../../store/localstorage/ProductsDB';
import CustomButton from '../../../../components/Common/CustomButton/CustomButton';

const CreateCustomerGroup = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const breadcrumbItems = [
    {
      label: 'Customer Group',
      link: '/offer/customer-group'
    },
    {
      label: 'New',
      link: '/offer/customer-group/new'
    }
  ];

  const onFinish = async (values = form.getFieldsValue()) => {
    setIsLoading(true);
    // console.log('Values', values);
    try {
      const lines = form.getFieldValue(['lines']);
      if (lines) {
        if (lines.length > 0) {
          const mutateObj = {
            name: values.name,
            customerIds: [
              ...new Set<number>(
                lines.map((value: any) => {
                  return value.customerId;
                })
              )
            ]
          };
          await createCustomerGroupMutation.mutateAsync(mutateObj);
        } else {
          setIsLoading(false);
          message.error('Atleast one customer must be selected!');
        }
      } else {
        setIsLoading(false);
        message.error('Atleast one customer must be selected!');
      }
    } catch (err: any) {
      setIsLoading(false);
      console.error(err.message);
    }
  };

  const createCustomerGroupMutation = useMutation(create_customer_group_mutation, {
    onSuccess: () => {
      setIsLoading(false);
      message.success('Customer Group added successfully');
      navigate('/offer/customer-group');
    },
    onError: async (e: any) => {
      setIsLoading(false);
      if (e.response.data.exception.response.data) {
        const data = [];
        const customerGroupList = await get_customer_group_list_ids([
          ...new Set<number>(
            e.response.data.exception.response.data.map((value: any) => {
              return value.customerGroupId;
            })
          )
        ]);

        for (let i = 0; i < e.response.data.exception.response.data.length; i++) {
          let customerDetails = await CustomersDB.getCustomer(
            e.response.data.exception.response.data[i].customerId
          );
          if (!customerDetails) {
            const allCustomers = await get_customer_list_ids([
              ...new Set<number>(
                e.response.data.exception.response.data.map((value: any) => {
                  return value.customerId;
                })
              )
            ]);
            await CustomersDB.addCustomers(allCustomers.data.results);
            customerDetails = await CustomersDB.getCustomer(
              e.response.data.exception.response.data[i].customerId
            );
          }
          const currCustomerGroup = customerGroupList.results.find(
            (val) => val.id === e.response.data.exception.response.data[i].customerGroupId
          );
          data.push({
            id: e.response.data.exception.response.data[i].id,
            groupName: currCustomerGroup?.name,
            customerName: customerDetails.user.name
          });
        }

        CustomErrorModal({
          message: (
            <div>
              {e.response.data.message}
              <br></br>
              {data.map((value: any) => (
                <>
                  <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />
                  <div className="grid grid-cols-1" key={value.id}>
                    <span>
                      Name : <b>{value.groupName}</b>
                    </span>
                    <span>
                      Customer : <b>{value.customerName}</b>
                    </span>
                  </div>
                </>
              ))}
            </div>
          )
        });
      } else {
        CustomErrorModal({ message: e.response.data.message });
      }
    }
  });

  const onCustomerChange = async (
    customerId: number,
    add: (defaultValue?: any, insertIndex?: number | undefined) => void
  ) => {
    const lines = form.getFieldValue(['lines']);
    if (lines) {
      if (lines.length > 0) {
        if (lines.find((value: any) => value.customerId === customerId)) {
          message.error('Customer has been added already!');
          return;
        }
      }
    }
    let customerDetails = await CustomersDB.getCustomer(customerId);
    if (!customerDetails) {
      const allCustomers = await get_customer_list_ids([customerId]);
      await ProductsDB.addProducts(allCustomers.data.results);
      customerDetails = await CustomersDB.getCustomer(customerId);
    }
    add({
      customerId: customerDetails.id,
      customerName: customerDetails.user.name,
      phoneNo: customerDetails.user.phone
    });
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Customer Group Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 sm:grid-cols-4 gap-5 mb-5'}>
            <Form.Item
              label="Customer Group Name"
              name="name"
              rules={[{ required: true, message: 'Please add Customer Group Name!' }]}>
              <Input />
            </Form.Item>
          </div>
          <Card
            style={{
              maxHeight: '50vh',
              overflowY: 'scroll',
              borderRadius: '9px'
            }}>
            <Form.List name={['lines']}>
              {(fields, { add, remove }) => (
                <>
                  <div className="grid grid-cols-2 mb-3">
                    <CustomerSearch
                      isForm={false}
                      onSelect={(value) => onCustomerChange(value, add)}
                    />
                  </div>
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.key}>
                      <span className="font-bold text-sm mb-5">{index + 1}.</span>
                      <div
                        className="card"
                        style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                        <div className={'grid grid-cols-3 gap-3'}>
                          <Form.Item name={[field.name, 'customerId']} hidden>
                            <InputNumber disabled />
                          </Form.Item>
                          <Form.Item name={[field.name, 'customerName']} label={'Customer'}>
                            <Input style={{ color: 'black' }} disabled />
                          </Form.Item>
                          <Form.Item name={[field.name, 'phoneNo']} label={'Phone No.'}>
                            <InputNumber style={{ color: 'black' }} disabled />
                          </Form.Item>
                          <div className="flex items-center justify-start mt-5">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="green"
                              onClick={async () => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </Card>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/offer/customer-group')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateCustomerGroup;
