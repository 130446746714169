import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  message,
  Upload,
  UploadFile
} from 'antd';

import { ExpenseColumnDataType } from '@/services/expense/enum';
import { ExpenseCategoryColumnItem } from '@/services/expense/expense-category/types';
import { filterId } from '@/constants/filter.category';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import AgentsSearch from '@/components/Common/AgentsSearch';
import { UsersSearch } from '@/components/Common/UsersSearch';
import VehiclesSearch from '@/components/Common/VehiclesSearch/v2';
import { CustomerSearch } from '@/components/Common/CustomerSearch/CustomerSearch';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import { UploadOutlined } from '@ant-design/icons';
import { onPreviewImage } from '@/services/upload/services';
import { useState } from 'react';
import { RcFile } from 'antd/lib/upload';

interface Props {
  column: ExpenseCategoryColumnItem;
  onSelect: (value: any) => void;
}
type FilterIdKey = typeof filterId[number]['key'];

function RenderInputField({ column, onSelect }: Props) {
  const columnName = column.name as FilterIdKey;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const isReferencial = filterId.find((id) => id.key === columnName);

  function renameFiles(files: UploadFile[]) {
    return files.map((file, index) => {
      if (file.originFileObj) {
        const extension = file.originFileObj.name.split('.').pop();

        return {
          ...file,
          name: `image-${index + 1}.${extension}`
        };
      } else {
        return file;
      }
    });
  }

  const beforeUpload = (file: RcFile) => {
    const isImage = file.type.startsWith('image/');
    const isSizeValid = file.size / 1024 / 1024 < 2; // Check if file size is less than 2 MB

    if (!isImage) {
      message.error('You can only upload image files!');
    } else if (!isSizeValid) {
      message.error('Image must be smaller than 2MB!');
    }

    return isImage && isSizeValid;
  };

  switch (column.dataType) {
    case ExpenseColumnDataType.STRING:
      if (isReferencial) {
        if (columnName === 'mediaIds') {
          return (
            <Upload
              accept="image/*"
              multiple
              className="[&>div.ant-upload]:w-full"
              fileList={fileList}
              beforeUpload={() => false}
              onChange={({ fileList: newFileList, file: newFile }) => {
                const updatedFiles = renameFiles(newFileList);
                const isWithinLimit = beforeUpload(newFile as RcFile);

                // Remove file if it is not within limit
                if (!isWithinLimit) {
                  updatedFiles.pop();
                }

                setFileList(updatedFiles);
                onSelect(updatedFiles);
              }}
              onPreview={onPreviewImage}>
              <Button className="w-full" icon={<UploadOutlined />}>
                Upload Media
              </Button>
            </Upload>
          );
        }
      }

      return <Input onChange={(e) => onSelect(e.target.value)} />;

    case ExpenseColumnDataType.DECIMAL:
      return <InputNumber step="0.01" onChange={onSelect} />;

    case ExpenseColumnDataType.BOOLEAN:
      return <Checkbox onChange={onSelect} />;

    case ExpenseColumnDataType.DATE:
      return (
        <DatePicker
          showTime
          format={'YYYY-MM-DD [at] hh:mm a'}
          allowClear={false}
          className="w-full"
          onChange={(date) => onSelect(date)}
        />
      );

    case ExpenseColumnDataType.INTEGER:
      if (!isReferencial) {
        return <InputNumber step="1" onChange={onSelect} />;
      }

      if (columnName === 'locationId') {
        return <LocationSearch notAll withoutForm onSelect={onSelect} />;
      }

      if (columnName === 'agentId') {
        return <AgentsSearch hasParentFormItem onSelect={onSelect} returnValue="agentId" />;
      }

      if (columnName === 'userId') {
        return <UsersSearch onSelect={onSelect} isAll={false} />;
      }

      if (columnName === 'vehicleId') {
        return <VehiclesSearch onSelect={onSelect} isAll={false} />;
      }

      if (columnName === 'customerId') {
        return <CustomerSearch isForm={false} onSelect={onSelect} all={false} />;
      }

      if (columnName === 'vendorId') {
        return <VendorSearch withoutForm all={false} onSelect={onSelect} />;
      }

      return <InputNumber step="1" />;

    default:
      return null;
  }
}

export default RenderInputField;
