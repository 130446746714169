import { Button, Form, Input, Menu, Spin, TableProps, Tooltip, message } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState, useRef } from 'react';
import { getUserData } from '@/utils/auth.utils';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { ConvertObjectToURL } from '@/utils/converturl';
import moment from 'moment';
import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import TableFilter from '@/components/FliterTable';
import {
  ISalesRegisterList,
  ISalesRegisterListData,
  ISalesRegisterListExport
} from '@/services/ird/sales/types';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTableForIrd from '@/components/Common/CustomizeTable/CustomTableForIrd';
import { useReactToPrint } from 'react-to-print';
import { get_sales_register_list } from '@/services/ird/sales/queries';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import CopyButton from '@/components/Common/CopyButton';
import CustomersDB from '@/store/localstorage/CustomerDB';
import { get_customer_list_ids, get_user_pan_ids } from '@/services/users/queries';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { Excel } from 'antd-table-saveas-excel';
import { ExportColumnType } from '@/utils/exportExcel';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import GenericTable from '@/components/Common/CustomizeTable';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const SalesRegisterList = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const printPDFRef = useRef<any>();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const [allSalesList, setAllSalesList] = useState<ISalesRegisterListData>({
    total: 0,
    results: [],
    qtySold: 0
  });
  const [exportData, setExportData] = useState<{
    data: ISalesRegisterListExport[];
    year: string;
    date: string;
  }>({ data: [], year: '', date: '' });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const breadcrumbItems = [
    {
      label: 'Sales Register',
      link: '/ird/sales-register'
    }
  ];

  const columns: ColumnsType<ISalesRegisterList> = [
    {
      title: 'Tax Invoice',
      width: 100,
      children: [
        {
          title: 'Date',
          key: 'date',
          width: 20,
          sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
          sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
          render: (a, record) => {
            return (
              <TableCell>
                {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
              </TableCell>
            );
          }
        },
        {
          title: 'Bill Number',
          key: 'financialReference',
          className: 'invoice',
          width: 30,
          sorter: (a, b) => a.financialReference.localeCompare(b.financialReference),
          sortOrder: sortedInfo.columnKey === 'financialReference' ? sortedInfo.order : null,
          render: (a, record) => {
            return (
              <TableCell className="flex flex-row justify-between items-center text-xs px-1">
                {record.financialReference}
                {record.financialReference && <CopyButton text={record.financialReference} />}
              </TableCell>
            );
          }
        },
        {
          title: "Buyer's Name",
          key: 'buyersName',
          width: 20,
          sorter: (a, b) =>
            a.buyersName && b.buyersName ? a.buyersName.localeCompare(b.buyersName) : 0,
          sortOrder: sortedInfo.columnKey === 'buyersName' ? sortedInfo.order : null,
          render: (a, record) => {
            return <TableCell>{record.buyersName}</TableCell>;
          }
        },
        {
          title: `Buyer's PAN`,
          key: 'customerPan',
          width: 25,
          render: (a, record) => {
            return <TableCell>{record.customerPan}</TableCell>;
          }
        },
        {
          title: 'Goods/ Service Name',
          key: 'goodsServiceName',
          width: 25,
          render: (a, record) => {
            return <TableCell>{'Goods for: ' + record.financialReference}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Total Sales/ Export',
      width: 20,
      key: 'totalSalesExport',
      dataIndex: 'initialAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalSalesExport' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <TableCell>
            {nepaliNumberFormatter(record.totalNonTaxable + record.totalTaxable)}
          </TableCell>
        );
      }
    },
    {
      title: 'Local Tax Rebate',
      width: 20,
      key: 'localTaxRebate',
      dataIndex: 'totalNonTaxable',
      render: (a, record) => {
        return <TableCell>{nepaliNumberFormatter(record.totalNonTaxable)}</TableCell>;
      }
    },
    {
      title: 'Taxable Sales',
      width: 20,
      children: [
        {
          title: 'Amount',
          key: 'taxableSalesAmount',
          dataIndex: 'totalTaxable',
          width: 15,
          render: (a, record) => {
            return <TableCell>{nepaliNumberFormatter(record.totalTaxable)}</TableCell>;
          }
        },
        {
          title: 'Tax',
          key: 'taxablePurchaseTax',
          dataIndex: 'totalVat',
          width: 15,
          render: (a, record) => {
            return <TableCell>{nepaliNumberFormatter(record.totalVat)}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Export',
      width: 20,
      children: [
        {
          title: 'Goods/ Export Price',
          width: 20,
          key: 'goodsPurchasePrice',
          render: (a, record) => {
            return <TableCell>{nepaliNumberFormatter(0)}</TableCell>;
          }
        },
        {
          title: 'Exported Country',
          width: 20,
          key: 'exportedCountry',
          render: (a, record) => {
            return <TableCell>{''}</TableCell>;
          }
        },
        {
          title: 'LC Number',
          width: 20,
          key: 'lcNumber',
          render: (a, record) => {
            return <TableCell>{''}</TableCell>;
          }
        },
        {
          title: 'LC Date',
          width: 20,
          key: 'lcDateNumber',
          render: (a, record) => {
            return <TableCell>{''}</TableCell>;
          }
        }
      ]
    }
  ];

  const columsforPrint: any = [
    {
      title: 'Tax Invoice',
      width: 100,
      children: [
        {
          title: 'Date',
          dataIndex: 'date',
          width: 20,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Bill Number',
          dataIndex: 'financialReference',
          width: 30,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: `Buyer's Name`,
          dataIndex: 'buyersName',
          width: 20,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: `Buyer's PAN`,
          dataIndex: 'customerPan',
          width: 25,
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Goods/ Service Name',
          dataIndex: 'goodsServiceName',
          width: 25,
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Total Sales/ Export',
      width: 20,
      dataIndex: 'totalSalesExport',
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      title: 'Local Tax Rebate',
      width: 20,
      dataIndex: 'localTaxRebate',
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      title: 'Taxable Sales',
      width: 85,
      children: [
        {
          title: 'Amount',
          dataIndex: 'taxableSalesAmount',
          width: 55,
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Tax',
          dataIndex: 'taxableSalesTax',
          width: 30,
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        }
      ]
    },
    {
      title: 'Export',
      width: 20,
      children: [
        {
          title: 'Goods/ Export Price',
          width: 20,
          dataIndex: 'goodsPurchasePrice',
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'Exported Country',
          width: 20,
          dataIndex: 'exportedCountry',
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'LC Number',
          width: 20,
          dataIndex: 'lcNumber',
          render: (text: number) => {
            return <TableCell>{text}</TableCell>;
          }
        },
        {
          title: 'LC Date',
          width: 20,
          dataIndex: 'lcDate',
          render: (text: string) => {
            return <TableCell>{text}</TableCell>;
          }
        }
      ]
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_sales_register_list(filter);
    // console.log('response', response);
    if (response) {
      const customerUserIds = new Set<number>();
      for (let index = 0; index < response.results.length; index++) {
        // set customer name
        let customer = await CustomersDB.getCustomer(response.results[index].customerId);
        if (!customer) {
          const allCustomers = await get_customer_list_ids([
            ...new Set(
              response.results.map((value) => {
                return value.customerId;
              })
            )
          ]);
          await CustomersDB.addCustomers(allCustomers.data.results);
          customer = await CustomersDB.getCustomer(response.results[index].customerId);
        }

        if (typeof customer === 'object' && customer.userId) {
          customerUserIds.add(customer.userId);
          response.results[index].buyersName = customer.user.name;
          response.results[index].customerUserId = customer.userId;
        }

        const currentData = response.results[index];
        currentData.initialAmount = currentData.totalNonTaxable + currentData.totalTaxable;
      }

      // pan
      if (Array.from(customerUserIds).length > 0) {
        const allPan = await get_user_pan_ids([...customerUserIds]);
        //console.log('allPan', allPan);

        for (let ind = 0; ind < allPan.data.length; ind++) {
          response.results.forEach((el) =>
            allPan.data[ind].taxNumber !== null
              ? (el.customerPan = allPan.data[ind].taxNumber)
              : 'N/A'
          );
        }
      }

      const dataUpdated = response.results.map((item) => {
        return {
          ...item,
          date: convertUTCStringtoLocalString(item.date, DEFAULT_DATE_FORMAT),
          goodsServiceName: 'Goods for: ' + item.financialReference,
          totalSalesExport: item.totalNonTaxable + item.totalTaxable,
          localTaxRebate: item.totalNonTaxable,
          taxableSalesAmount: item.totalTaxable,
          taxableSalesTax: item.totalVat,
          goodsPurchasePrice: 0,
          exportedCountry: '',
          lcNumber: '',
          lcDate: ''
        };
      });

      const startYear = form.getFieldValue(['startDateNepali']).split('-')[0];
      const startDate = form.getFieldValue(['startDateNepali']);
      const endYear = form.getFieldValue(['endDateNepali']).split('-')[0];
      const endDate = form.getFieldValue(['endDateNepali']);
      const fullYear = startYear === endYear ? startYear : startYear + ' to ' + endYear;
      const fullDate = startYear === endYear ? startDate : startDate + ' to ' + endDate;

      setAllSalesList(response);
      setExportData({ data: dataUpdated, year: fullYear, date: fullDate });
    }
    setIsloading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const handleExcelExport = () => {
    setIsloading(true);
    try {
      const excelColumns: ExportColumnType[] = [
        {
          title: 'Sales Register',
          width: 1500,
          children: [
            {
              title: '(Related with Rule 23 SubRule(1) part( h) )',
              width: 1500,
              children: [
                {
                  title:
                    'PAN: 609891700' +
                    `     Tax Payer's name: Freshktm` +
                    '     Year: ' +
                    exportData.year +
                    '     Tax Period: ' +
                    exportData.date,
                  width: 1500,
                  children: [
                    {
                      title: 'Tax Invoice',
                      width: 1180,
                      children: [
                        {
                          title: 'Date',
                          dataIndex: 'date',
                          width: 150
                        },
                        {
                          title: 'Bill Number',
                          dataIndex: 'financialReference',
                          width: 230
                        },
                        {
                          title: `Buyer's Name`,
                          dataIndex: 'buyersName',
                          width: 145
                        },
                        {
                          title: `Buyer's PAN`,
                          dataIndex: 'customerPan',
                          width: 80
                        },
                        {
                          title: 'Goods/ Service Name',
                          dataIndex: 'goodsServiceName',
                          width: 280
                        }
                      ]
                    },
                    {
                      title: 'Total Sales/ Export',
                      width: 120,
                      dataIndex: 'totalSalesExport'
                    },
                    {
                      title: 'Local Tax Rebate',
                      width: 70,
                      dataIndex: 'localTaxRebate'
                    },
                    {
                      title: 'Taxable Sales',
                      width: 85,
                      children: [
                        {
                          title: 'Amount',
                          dataIndex: 'taxableSalesAmount',
                          width: 65
                        },
                        {
                          title: 'Tax',
                          dataIndex: 'taxableSalesTax',
                          width: 40
                        }
                      ]
                    },
                    {
                      title: 'Export',
                      width: 20,
                      children: [
                        {
                          title: 'Goods/ Export Price',
                          width: 110,
                          dataIndex: 'goodsPurchasePrice'
                        },
                        {
                          title: 'Exported Country',
                          width: 120,
                          dataIndex: 'exportedCountry'
                        },
                        {
                          title: 'LC Number',
                          width: 75,
                          dataIndex: 'lcNumber'
                        },
                        {
                          title: 'LC Date',
                          width: 75,
                          dataIndex: 'lcDate'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];

      if (allSalesList.results.length === 0) {
        message.error('No Data to Export');
        setIsloading(false);
        return;
      }

      // Export Excel
      const excel = new Excel();
      excel.setTHeadStyle({
        background: 'ffffffff'
      });
      excel
        .setTHeadStyle({
          fontSize: 10
        })
        .setTBodyStyle({
          fontSize: 10
        })
        .addSheet('Sales Register')
        .addColumns(excelColumns as any[])
        .addDataSource(exportData.data, {
          str2Percent: true
        })
        .saveAs('Sales Register.xlsx');
      setIsloading(false);
    } catch (err: any) {
      setIsloading(false);
      console.log(err);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableForIrd
            columns={columsforPrint}
            datas={exportData.data}
            reff={printPDFRef}
            title={'Sales Register'}
            subTitle={'(Related with Rule 23 SubRule(1) part( h) )'}
            date={exportData.date}
            year={exportData.year}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {
                <TableFilter
                  defaultValues={{
                    dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                    value: '',
                    locationId: preferenceLocationId ? preferenceLocationId : 1,
                    skip: 0,
                    count: 100
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  styleforbuttons={'flex justify-end items-center'}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  form={form}>
                  <LocationSearchV2 hasParentFormItem={false} name="locationId" />
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              }
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-end mb-2">
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExcelExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (allSalesList.results.length === 0) {
                            message.error('No Data to Export');
                            setIsloading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>

        <GenericTable
          columns={columns}
          data={allSalesList.results}
          hideDefaultPagination={true}
          scroll={{ x: 1750, y: '75vh' }}
          generateSummary
          summaryClassName="text-left"
          pagination={{
            page: page,
            total: allSalesList.total,
            size: size,
            onPagination
          }}
          toSort={handleChange}
        />
      </AppContent>
    </Spin>
  );
};

export default SalesRegisterList;
