import { useQuery, useMutation } from '@tanstack/react-query';
import { Form, Input, Select, InputNumber, message, PageHeader, Divider, Card } from 'antd';
// import { useWatch } from 'antd/lib/form/Form';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';

import {
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productId,
  get_price_groups_by_location,
  prices_by_groupId,
  get_product_details,
  get_product_list_ids,
  get_unexpired_lots_details_bylocationId_productIds
} from '../../../services/products/queries';
import { IUnits, IProductType, ILotDetails } from '../../../services/products/types';
import { ILines, IProductUnits, Line } from '../../../services/sell/types';
import { get_user_details, get_user_list } from '../../../services/users/queries';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import {
  create_transfer_in_mutation,
  create_transfer_mutation
} from '../../../services/transfer/mutations';
import useDebounce from '../../../hooks/useDebounce';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { ILocations } from '../../../services/locations/types';
import { get_location_details, get_location_list } from '../../../services/locations/queries';
import {
  get_transfer_lines_details,
  get_transfer_list,
  get_transfer_out_details,
  get_transfer_out_lines_details
} from '../../../services/transfer/queries';
import LocationsDB from '../../../store/localstorage/LocationsDB';
import { check } from 'prettier';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { curryGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { getUserData } from '../../../utils/auth.utils';
import { numberDecimalFormatter } from '../../../utils/numberFormatter';
import { ILot } from '../../../services/purchases/types';
import { WebSocketContext } from '../../../contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '../../../constants/websocketConfig';
import { calculateVat } from '../../../utils/vatCalculate.utils';
import { checkHasAccountRule } from '../../../services/accounts/services';
import CustomInfoModal from '../../../components/Common/CustomInfoModal';
import { AccountRulesEvent } from '../../../services/accounts/enums';
import useDebounceFunction from '@/hooks/useDebounceFunction';

interface ITaxtype {
  taxNumber: string;
  taxType: string;
  id?: string;
  userId?: number;
}

const { Option } = Select;

const EditTransfer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { socket } = useContext(WebSocketContext);
  const [isTask, setIsTask] = useState<boolean>(true);
  const [hasRule, setHasRule] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [totalLots, settotalLots] = useState<ILot[]>([]);
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());
  const [defaultSelect, setDefaultSelect] = useState<any>([]);
  const loggedInUser = getUserData();

  const [productList, setProductList] = useState<any[]>([]);

  useQuery(['units'], async () => {
    // const response = await get_product_list();
    // setProductList(response.data.results);
    let allUnits = await UnitsDB.getAllUnits();
    if (allLocalUnits.length == 0) {
      allUnits = await get_units_list();
      setAllLocalUnits(allUnits as IUnits[]);
    } else {
      setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
    }

    // await get_units_list();
    // return response;
  });
  const [isLoading, setIsloading] = useState<boolean>(false);
  //productSearch
  //product search
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [searchproductList, setsearchProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const [defaultPriceBylocationId, setDefaultPriceBylocationId] = useState<any>({});

  const breadcrumbItems = [
    {
      label: 'Transfers In',
      link: '/transfer-in'
    },
    {
      label: 'Confirm'
    }
  ];

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['to']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(
          lines.map((value: ILines) => {
            return value.productId;
          })
        );

        const locationId = form.getFieldValue(['to']);

        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }
        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const { data: transferList, refetch } = useQuery(['transfer', id], async () => {
    // const response = await get_transfer_list();
    const response = await get_transfer_out_details(parseInt(id as string));
    const productsIds = new Set<number>();
    if (response.lines.length > 0) {
      for (let i = 0; i < response.lines.length; i++) {
        productsIds.add(response.lines[i].productId);
      }
    }
    if (Array.from(productsIds).length > 0) {
      const productList = await get_product_list_ids(Array.from(productsIds));
      setProductList(productList?.data?.results);
      await ProductsDB.addProducts(productList.data.results);
    }
    // console.log('Response', response);
    // const linesResponse = await get_transfer_out_lines_details(parseInt(id as string));
    if (response) {
      //locaton name extract
      const checkListVals = JSON.parse(response.checklist);
      let netTotalAmount = 0;
      if (response.lines.length > 0) {
        const allProducts = [...productList];
        // console.log('allproducts', allProducts);
        const mySet = new Set<number>();
        setDefaultSelect(Array(response.lines.length).fill(false));
        for (let i = 0; i < response.lines.length; i++) {
          delete response.lines[i].lotId;
          mySet.add(response.lines[i].productId);
          // let findProduct = allProducts.find((value) => value.id == response.lines[i].productId);
          let findProduct: any = await ProductsDB.getProduct(response.lines[i].productId);
          if (!findProduct) {
            findProduct = await get_product_details(response.lines[i].productId);
            allProducts.push(findProduct);
          }
          response.lines[i].productName = findProduct.name;
          if (response.lines[i].vat) {
            response.lines[i].total = response.lines[i].totalAmount - response.lines[i].vat;
          } else {
            response.lines[i].total = response.lines[i].totalAmount;
          }
          netTotalAmount += response.lines[i].totalAmount;
        }
        await fetchLotsOnLocationandProductChange(Array.from(mySet), response.to, 'location');
      }
      // console.log('Lines Response data--> ', linesResponse);
      if (
        (response.from ? await checkAccountRule(response.from, 'from') : true) &&
        (response.to ? await checkAccountRule(response.to, 'to') : true)
      ) {
        setHasRule(true);
      } else {
        setHasRule(false);
        CustomInfoModal({
          title: 'Info',
          message: `"${AccountRulesEvent.TRANSFER_FROM}" or "${AccountRulesEvent.TRANSFER_TO}" rule has not been created!`
        });
      }
      form.setFieldsValue({
        ...response,
        ...checkListVals,
        grandtotal: netTotalAmount
        // lines: response.lines
      });
    }
    setIsloading(false);
    return response;
  });

  const checkAccountRule = async (locationId: number, location: string) => {
    if (
      await checkHasAccountRule(
        locationId,
        location === 'from' ? AccountRulesEvent.TRANSFER_FROM : AccountRulesEvent.TRANSFER_TO
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };

  const checkProductAndAdd = (products: any[]) => {
    if (searchproductList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setsearchProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setsearchProductList(products);
    }
  };
  useEffect(() => {
    form.setFieldsValue({ location: null, lines: [], reason: null });
    initializeUnits();
  }, []);
  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const createTransferMutation = useMutation(create_transfer_in_mutation);

  const onFinish = useDebounceFunction(async (values: any) => {
    try {
      setIsloading(true);
      values.checklist = JSON.stringify({
        bags: values.bags,
        crate: values.crate,
        sack: values.sack,
        others: values.others
      });
      delete values.bags;
      delete values.crate;
      delete values.sack;
      delete values.others;
      values.lines = values.lines.map((curr: any) => {
        return {
          productId: curr.productId,
          unitId: curr.unitId,
          quantity: curr.quantity,
          unitPrice: curr.unitPrice,
          discount: curr.discount,
          misc: curr.misc,
          lotId: curr.lotId,
          isTraceable: curr.isTraceable,
          vat: curr.vat
        };
      });
      values.date = JSON.stringify(new Date()).slice(1, 11);
      values.transferId = id;
      values.createdBy = loggedInUser.id;
      console.log(values);

      await createTransferMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          if (data) {
            message.success('Transferred successfully');
            setIsloading(false);
            navigate('/transfer-in');
          }
        },
        onError: (e: any) => {
          setIsloading(false);
          message.error(`${e.response.data.message}`, 5);
        }
      });
    } catch (error) {
      console.log(error);
    }
  });

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (productsIdArray.length == 0) return;
      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: ILot) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        currenttotalLots.push(...result);
        settotalLots([...currenttotalLots]);
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async (value: number) => {
    const from = form.getFieldValue(['from']);
    const to = form.getFieldValue(['to']);
    let lines = form.getFieldValue('lines');
    lines = lines.map((linesValue: any) => {
      return { ...linesValue, lotId: undefined };
    });
    form.setFieldValue('lines', lines);
    if (from === to) {
      message.warn('From and To cannot be same!');
      form.setFieldValue('to', undefined);
    }
    //to fetch new lots
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any) => {
        mySet.add(curr.productId);
      });
    }
    fetchLotsOnLocationandProductChange(Array.from(mySet), from, 'location');
  };
  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    // console.log('checkCurrentProduct', checkCurrentProduct);
    // console.log('productList', productList);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      // console.log('Product', selectedProduct);
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const FilterLot = (name: number) => {
    // console.log('This is called');
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const toLocation = form.getFieldValue(['to']);
    const tracheableval = form.getFieldValue(['lines', name, 'traceable']);
    // console.log('Traceable', tracheableval);
    if (checkCurrentProduct && toLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      filteredLots = filteredLots.sort((a: any, b: any) => b.qtyAvailable - a.qtyAvailable);
      // console.log('filterLots', filteredLots);
      // console.log('dd', defaultSelect);
      if (filteredLots.length > 0 && !defaultSelect[name]) {
        // console.log('this runs', filteredLots[0].id);
        form.setFieldValue(['lines', name, 'lotId'], filteredLots[0].id);
        setDefaultSelect((prev: any) =>
          prev.map((curr: any, ind: number) => {
            if (ind == name) {
              return !curr;
            } else {
              return curr;
            }
          })
        );
      }

      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${numberDecimalFormatter(
                value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
              )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };
  const checkLotQuantity = (index: number) => {
    const row = form.getFieldValue(['lines', index]);
    if (row.lotId) {
      const filteredLot: any = totalLots.find((value: any) => value.id == row.lotId);
      return filteredLot.qtyAvailable;
    }
    return null;
  };

  useEffect(() => {
    form.setFieldsValue({
      from: null,
      to: null,
      lines: []
    });
  }, []);

  const onUnitChange = (name: number) => {
    const data = form.getFieldValue(['lines']);
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const findOne: any = allLocalUnits.find((curr: any) => curr.id === selectedUnitId);
    const prevValue = form.getFieldValue(['lines', name, 'sellingPrice']);
    // form.setFieldValue(['lines', name, 'unitPrice'], prevValue * findOne.baseUnitMultiplier);
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['lines', name, 'total'], total);

    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    let productInfo = await ProductsDB.getProduct(selectedProductId);
    if (!productInfo) {
      const allProducts = await get_product_list_ids([selectedProductId]);
      await ProductsDB.addProducts(allProducts.data.results);
      productInfo = await ProductsDB.getProduct(selectedProductId);
    }
    let vat = 0;
    if (typeof productInfo === 'object') {
      if (productInfo.vat) {
        vat = calculateVat(total, productInfo.vat);
        form.setFieldValue(['lines', name, 'vat'], vat);
      } else {
        form.setFieldValue(['lines', name, 'vat'], 0);
      }
    }

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }
    // console.log('grandtotal', grandTotal);

    form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
    // const grandTotall = form.getFieldValue(['lines', 'grandtotal']);
    // console.log('grandTotall field value', grandTotall, form.getFieldsValue());
  };

  const onSelectt = (val: any) => {
    form.setFieldValue('from', val);
    onLocationChange(val);
  };

  const onSelect2 = (val: any) => {
    form.setFieldValue('to', val);
    onLocationChange(val);
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Transfer Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          disabled={isLoading}
          validateTrigger={'onChange'}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <PageHeader
            subTitle="Location"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 gap-5 mb-5">
            <Form.Item
              name="from"
              label="From"
              rules={[
                {
                  required: true,
                  message: 'Please choose location!'
                }
              ]}>
              <Select
                disabled
                placeholder="Select a location!"
                onChange={onLocationChange}
                allowClear>
                {locationsList?.data.results.map((value: ILocations) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {value.name}
                    </Option>
                  );
                })}
              </Select>
              {/* <LocationSearch withoutForm={true} onSelect={(val) => onSelectt(val)} notAll={true} /> */}
            </Form.Item>
            <Form.Item
              name="to"
              label="To"
              rules={[
                {
                  required: true,
                  message: 'Please choose location!'
                }
              ]}>
              <Select
                disabled
                placeholder="Select a location!"
                onChange={onLocationChange}
                allowClear>
                {locationsList?.data.results.map((value: ILocations) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {value.name}
                    </Option>
                  );
                })}
              </Select>
              {/* <LocationSearch withoutForm={true} onSelect={(val) => onSelect2(val)} notAll={true} /> */}
            </Form.Item>
          </div>

          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                {fields2.length > 0 && (
                  <PageHeader
                    title="All Products"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                )}
                <Card
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={key2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div className="card">
                        <PageHeader
                          subTitle="Products"
                          style={{
                            padding: '8px 0px 8px 10px'
                          }}
                        />
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-8'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'sellingPrice']}
                            hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                // border: '0px',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                disabled
                                onChange={() => {
                                  onUnitChange(name2), onUnitandQuantityChange(name2);
                                }}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: false,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                dropdownMatchSelectWidth={false}
                                // onChange={onLocationChange}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Quantity"
                            name={[name2, 'quantity']}
                            rules={[
                              { required: true, message: 'Please add a Valid Quantity!' },
                              () => ({
                                validator(_: any, value) {
                                  const quantity = checkLotQuantity(name2);
                                  console.log(quantity);
                                  if (!value) {
                                    return Promise.reject('Please select quantity!');
                                  }
                                  if (value < 0)
                                    return Promise.reject(`Please Input valid quantity!`);
                                  return Promise.resolve();
                                }
                              })
                            ]}>
                            <InputNumber
                              min={1}
                              onChange={() => onUnitandQuantityChange(name2)}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[{ required: true, message: 'Please add Rate!' }]}>
                            <InputNumber
                              disabled
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="VAT"
                            name={[name2, 'vat']}
                            rules={[{ required: true, message: 'Please add VAT!' }]}>
                            <InputNumber disabled={true} style={{ color: 'black' }} min={0} />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber
                              disabled
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber
                              disabled
                              style={{ color: 'black' }}
                              min={0}
                              onChange={() => onUnitandQuantityChange(name2)}
                            />
                          </Form.Item>

                          {/* <div className="flex justify-between gap-2"> */}
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber disabled min={0} style={{ color: 'black' }} />
                          </Form.Item>
                          {/* {fields2.length > 0 ? (
                        // <CloseCircleOutlined
                        //   onClick={() => remove2(name2)}
                        //   style={{
                        //     transform: 'scale(1.4)',
                        //     color: 'red',
                        //     marginTop: '40px'
                        //   }}
                        // />
                        <CustomButton
                          backgroundColor="white"
                          text="Remove"
                          textColor="red"
                          marginTop={responsize ? '10px' : '25px'}
                          onClick={() => remove2(name2)}
                        />
                      ) : null} */}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total">
                <InputNumber min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}

          <Divider />
          <PageHeader
            subTitle="Checklist"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-5">
            <Form.Item
              label={'Crate'}
              name={['crate']}
              rules={[{ required: true, message: "Can't be empty" }]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label={'Sack'}
              name={['sack']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label={'Bags'}
              name={['bags']}
              rules={[
                {
                  required: true,
                  message: `Can't be empty`
                }
              ]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label={'Others'} name={['others']}>
              <Input />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-5">
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Submit
              </Button> */}
              <CustomSubmitButton disabled={!hasRule || isLoading} text="Approve" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default EditTransfer;
