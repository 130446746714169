import moment from 'moment';
import { useEffect, useState } from 'react';

import { NepaliDatePicker } from 'nepali-datepicker-reactjs';

// Antd imports
import { Form, Input, InputNumber, TableProps } from 'antd';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';

// Custom imports
import CustomReport from './report';
import { CustomDatePresets } from '../utils/datePresets';
import { find_date_preference } from '@/store/localstorage/preferences';
import { RouteSearch } from '@/components/Common/RouteSearch/RouteSearch';

import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';

import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ICustomerHistoryDataType } from '@/services/report/types';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import RouteSearchV2 from '@/components/Common/CustomSearch/Routes';

function RouteHistory() {
  const { RangePicker } = DatePicker;

  const [form] = Form.useForm();
  const nepaliType = find_date_preference();

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  useEffect(() => {
    const startDateNepali = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );

    const endDateNepali = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );

    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      routeId: '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali,
      endDateNepali
    });
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Route History', link: '/reports/route-history' }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  const columns: ColumnsType<ICustomerHistoryDataType> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 25,
      render: (_, __, index) => <TableCell>{index + 1}</TableCell>
    },
    {
      title: 'Route',
      dataIndex: 'routeName',
      key: 'routeName',
      width: 100,
      sorter: (a, b) => a.routeName.localeCompare(b.routeName),
      sortOrder: sortedInfo.columnKey === 'routeName' ? sortedInfo.order : null,
      render: (_, record) => <TableCell>{record.routeName}</TableCell>
    },
    {
      title: 'Active Customers',
      dataIndex: 'totalCustomers',
      key: 'totalCustomers',
      sorter: (a, b) => a.totalCustomers - b.totalCustomers,
      sortOrder: sortedInfo.columnKey === 'totalCustomers' ? sortedInfo.order : null,
      render: (_, record) => <TableCell>{record.totalCustomers}</TableCell>,
      width: 50
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      className: 'highlight',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      sortOrder: sortedInfo.columnKey === 'totalAmount' ? sortedInfo.order : null,
      render: (_, record) => <TableCell>{nepaliNumberFormatter(record.totalAmount)}</TableCell>,
      width: 50
    },
    {
      title: 'Total Quantity',
      dataIndex: 'totalQuantity',
      key: 'totalQuantity',
      sorter: (a, b) => a.totalQuantity - b.totalQuantity,
      sortOrder: sortedInfo.columnKey === 'totalQuantity' ? sortedInfo.order : null,
      render: (_, record) => <TableCell>{nepaliNumberFormatter(record.totalQuantity)}</TableCell>,
      width: 50
    },
    {
      title: 'Quantity Avg',
      dataIndex: 'quantityAvg',
      key: 'quantityAvg',
      sorter: (a, b) => a.quantityAvg - b.quantityAvg,
      sortOrder: sortedInfo.columnKey === 'quantityAvg' ? sortedInfo.order : null,
      render: (_, record) => <TableCell>{nepaliNumberFormatter(record.quantityAvg)}</TableCell>,
      width: 50
    }
  ];

  return (
    <CustomReport
      form={form}
      columnsData={columns as any}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 51, name: 'Route History Report' }}
      toSort={handleChange}>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden>
        <InputNumber />
      </Form.Item>

      <RouteSearchV2 showAll hasParentFormItem={false} name={['constraints', 'routeId']} />

      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          disabledDate={disabledDate}
          ranges={CustomDatePresets}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>

      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>

      {/* // Nepali Date Picker  */}
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>

      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
}

export default RouteHistory;
