import { DEFAULT_DATE_FORMAT } from '@/constants';
import { IProductDailyIdentifierWiseTotalReport } from '@/services/report/types';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';

const fields = ['Unit', 'Product', 'Location', 'Total Qty', 'Total Amount', 'Date'];

export const tableExportColumns = fields.map((field) => ({
  label: field,
  dataIndex: field,
  render: (text: string) => {
    return <div className="text-center">{text}</div>;
  }
}));

export const excelExportColumns = fields.map((field) => ({
  title: field,
  dataIndex: field
}));

export function getUpdatedData(data: IProductDailyIdentifierWiseTotalReport[]) {
  return data.map((item) => {
    return {
      ...item,
      'Total Qty': nepaliNumberFormatter(parseFloat(item['Total Qty'])),
      'Total Amount': nepaliNumberFormatter(parseFloat(item['Total Amount'])),
      Date: convertUTCStringtoLocalString(item.Date, DEFAULT_DATE_FORMAT)
    };
  });
}
