import { PlusOutlined, RetweetOutlined } from '@ant-design/icons';
import { Form, Input, Button, InputNumber, PageHeader, Upload, Card, Tooltip } from 'antd';
// eslint-disable-next-line no-duplicate-imports
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import React, { useState } from 'react';
import AppContent from '../../../../components/Common/Content/Content';
import { breadcrumbItem } from '../types';
import { API_URL, JWT_TOKEN } from '../../../../constants/config';
import { getLocalStorage } from '../../../../utils/storage.utils';
import { useNavigate } from 'react-router-dom';
import './index.css';
import GoBackButton from '../../../../components/Common/GoBackButton/GoBackButton';
import { generatePassword } from '../../../../utils/password.utils';
import { VendorTypeSearch } from '../../../../components/Common/VendorTypeSearch';
import { CustomerGroupSearch } from '../../../../components/Common/CustomerGroupSearch';
import { ICustomerGroupListData } from '../../../../services/offfers/types';
import { UserTypeSearch } from '../../../../components/Common/UsersTypeSearch';
import { IUserCreateRequest } from '../../../../services/users/types';

interface CreateProps {
  breadcrumbItems: breadcrumbItem[];
  title: string;
  onFinish: (
    values: any,
    customerGroupList?: ICustomerGroupListData[],
    isAccount?: boolean
  ) => void;
  isloading: boolean;
}
const CreateUI: React.FC<CreateProps> = ({ isloading, breadcrumbItems, onFinish, title }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // This is needed to get the product details update as its written
  const [userDetails, setUserDetails] = useState<IUserCreateRequest>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const [customerGroupList, setCustomerGroupList] = useState<ICustomerGroupListData[]>([]);

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('avatarId', updatedFileId);
    }
    setFileList(newFileList);
  };

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange
  };

  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate(`${breadcrumbItems[0].link}`)} />}>
        <Card style={{ margin: '15px 0px', borderRadius: '9px' }}>
          <PageHeader
            title={title}
            style={{
              padding: '8px 0px'
            }}
          />
          <Form
            form={form}
            onFinish={(values) => onFinish(values, customerGroupList, activeKey.length > 0)}
            disabled={isloading}
            layout="vertical"
            className="main-div"
            validateTrigger={'onChange'}
            onValuesChange={(_, allFields) => {
              if (allFields.phone) {
                allFields.phone = allFields.phone.toString();
                setUserDetails({ ...userDetails, ...allFields, phone: allFields.phone.toString() });
              }
              if (allFields.secondaryPhone) {
                allFields.secondaryPhone = allFields.secondaryPhone.toString();
                setUserDetails({
                  ...userDetails,
                  ...allFields,
                  secondaryPhone: allFields.secondaryPhone.toString()
                });
              }
              if (!allFields.phone && allFields.secondaryPhone) {
                setUserDetails({ ...userDetails, ...allFields });
              }
            }}
            autoComplete="off">
            <div style={{ display: 'flex', gap: '10px' }}>
              <div
                className="flex-col mb-5 justify-center align-center left-div"
                style={{ flex: '1' }}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input name!' }]}>
                  <Input style={{ padding: '10px', borderRadius: '9px' }} />
                </Form.Item>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[{ required: true, message: 'Please add phone!' }]}>
                  <InputNumber style={{ padding: '6px', borderRadius: '9px' }} />
                </Form.Item>
                <Form.Item label="Phone (Secondary)" name="secondaryPhone">
                  <InputNumber style={{ padding: '6px', borderRadius: '9px' }} />
                </Form.Item>
                {breadcrumbItems[0].label == 'Vendors' && (
                  <VendorTypeSearch
                    formData={{ formName: 'vendorTypeId', label: 'Supplier Category' }}
                    required={true}
                    isAll={false}
                  />
                )}
                {breadcrumbItems[0].label == 'Customers' && (
                  <CustomerGroupSearch
                    formData={{ formName: 'customerGroupId', label: 'Customer Group' }}
                    customerGroupList={customerGroupList}
                    setCustomerGroupList={setCustomerGroupList}
                    required={false}
                    isAll={false}
                  />
                )}
                {breadcrumbItems[0].label == 'Users' && (
                  <UserTypeSearch
                    formData={{ formName: 'typeId', label: 'User Type' }}
                    required={true}
                    isAll={false}
                    initialValue={{
                      typeId: 1
                    }}
                  />
                )}
                <div className="flex flex-row justify-between items-center gap-1">
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please add password!' }]}>
                    <Input.Password
                      autoComplete="new-password"
                      className="mb-5"
                      style={{ padding: '10px', borderRadius: '9px' }}
                    />
                  </Form.Item>
                  <Tooltip title="generate password">
                    <Button
                      style={{ borderRadius: '9px' }}
                      icon={<RetweetOutlined />}
                      onClick={() => {
                        const password: any = generatePassword(10);
                        form.setFieldValue('password', password);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="flex flex-col" style={{ flex: 1 }}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please add email!' }]}>
                  <Input style={{ padding: '10px', borderRadius: '9px' }} />
                </Form.Item>
                <div className="flex flex-row-reverse align-center">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Upload className="rounded-full" {...uploadProps}>
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </div>
                </div>
                <Form.Item
                  label="Image"
                  name="avatarId"
                  hidden
                  rules={[{ required: false, message: 'Please upload image!' }]}>
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="flex flex-1 justify-end items-end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={isloading}
                  style={{ borderRadius: '5px', color: 'white', backgroundColor: '#1890ff' }}>
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </AppContent>
    </div>
  );
};

export default CreateUI;
