import LocalStore from '.';
import { IMenuList } from '../../services/settings/types';

export default class MenuDB extends LocalStore {
  static async searchMenuByName(name: string, limit = 10) {
    if (!MenuDB.db) await MenuDB.init();
    return new Promise((resolve: (value: IMenuList[]) => void, reject) => {
      const transaction = MenuDB.db.transaction('Menu', 'readonly');
      transaction.onerror = (event) => {
        reject((event.target as IDBTransaction).error);
      };
      const objectStore = transaction.objectStore('Menu');
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        const menuItems = (event.target as IDBRequest).result;
        const filteredMenu: IMenuList[] = [];
        for (let i = 0; i < menuItems.length; i++) {
          const menu = menuItems[i];
          if (menu.name?.toLowerCase().includes(name.toLowerCase())) {
            filteredMenu.push(menu);
          }
          if (filteredMenu.length == limit) break;
        }
        resolve(filteredMenu);
      };
      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  static async getMenuItem(id: number) {
    if (!MenuDB.db) await MenuDB.init();

    return new Promise<IMenuList[]>((resolve, reject) => {
      const transaction = MenuDB.db.transaction('Menu', 'readonly');
      transaction.onerror = (event) => {
        reject((event.target as IDBTransaction).error);
      };
      const objectStore = transaction.objectStore('Menu');
      const request = objectStore.get(id);
      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  static async getAllMenuItem() {
    if (!MenuDB.db) await MenuDB.init();

    return new Promise<IMenuList[]>((resolve, reject) => {
      const transaction = MenuDB.db.transaction('Menu', 'readonly');
      transaction.onerror = (event) => {
        reject((event.target as IDBTransaction).error);
      };
      const objectStore = transaction.objectStore('Menu');
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  static async addMenuItem(menuItemData: IMenuList[]) {
    if (!MenuDB.db) await MenuDB.init();

    return new Promise((resolve, reject) => {
      const transaction = MenuDB.db.transaction('Menu', 'readwrite');

      transaction.onerror = () => {
        reject('failed to find menu table in LC');
      };
      const objectStore = transaction.objectStore('Menu');
      menuItemData.forEach(async (menuItem) => {
        const checkKey = objectStore.count(menuItem.id);
        checkKey.onsuccess = async () => {
          if (checkKey.result == 0) {
            const request = objectStore.add(menuItem);
            request.onerror = (event) => {
              reject((event.target as IDBRequest).error);
            };
          } else {
            await MenuDB.updateMenuItem(menuItem);
          }
        };
      });
    });
  }

  static async updateMenuItem(menuItem: IMenuList) {
    if (!MenuDB.db) await MenuDB.init();

    return new Promise((resolve, reject) => {
      const transaction = MenuDB.db.transaction('Menu', 'readwrite');
      transaction.onerror = (event) => {
        reject((event.target as IDBTransaction).error);
      };
      const objectStore = transaction.objectStore('Menu');
      const request = objectStore.get(menuItem.id);
      request.onsuccess = (event) => {
        if ((event.target as IDBRequest).result) {
          const requestUpdate = objectStore.put(menuItem);
          requestUpdate.onerror = (event) => {
            reject((event.target as IDBRequest).error);
          };
          requestUpdate.onsuccess = () => {
            resolve('updated data');
          };
        } else reject('Error, could not find id.');
      };
      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  static async removeAllMenuItems() {
    if (!MenuDB.db) await MenuDB.init();
    return new Promise((resolve, reject) => {
      const transaction = MenuDB.db.transaction(['Menu'], 'readwrite');
      transaction.onerror = (event) => {
        reject((event.target as IDBTransaction).error);
      };
      const objectStore = transaction.objectStore('Menu');
      const objectStoreRequest = objectStore.clear();
      objectStoreRequest.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      objectStoreRequest.onerror = (event) => {
        reject((event.target as IDBRequest).result);
      };
    });
  }

  static async deleteMenuItem(id: number) {
    if (!MenuDB.db) await MenuDB.init();

    if (await MenuDB.getMenuItem(id)) {
      return new Promise((resolve, reject) => {
        const transaction = MenuDB.db.transaction('Menu', 'readwrite');
        const objectStore = transaction.objectStore('Menu');
        const request = objectStore.delete(id);
        request.onsuccess = () => {
          resolve('deleted successfuly.');
        };
        request.onerror = (event) => {
          reject((event.target as IDBRequest).error);
        };
      });
    } else {
      return new Promise((resolve) => {
        resolve('id does not exist');
      });
    }
  }
}
