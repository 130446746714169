import {
  get_account_detail_by_ids,
  get_account_history_ids,
  get_journal_details_ids,
  get_journal_lines_list
} from '@/services/accounts/queries';
import { createEmptyPromise, getReferenceTypeDetails } from './services/reference.service';

import {
  IAccountHistoryResponse,
  IAccountTypeResponseData,
  IJournalLinesViewResponseTableData
} from '@/services/accounts/types';
import { getAccountDetails, getBalance } from '@/services/accounts/services';
import { addLocationToJournal } from './services/location.service';
import { getNextJournalAccount, getSortedTableData } from './services';
import { convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import { getOpeningBalancev2 } from './services/openingBalance.service';
import { DEFAULT_DATE_FORMAT } from '@/constants';

export function getParamsInfo(filter: string) {
  const params = new URLSearchParams(filter);
  const endDate = params.get('endDate') as string;
  const accountId = params.get('accountId') as string;
  const startDate = params.get('startDate') as string;

  return {
    params,
    accountId,
    startDate,
    endDate
  };
}

interface Props {
  filter: string;
  pageNo: number;
  searchParams: URLSearchParams;
  isResetPageRunningBalance: boolean;
  closingBalance: { [key: number]: number };
  pageRunningBalance: { [key: number]: number };
}

export async function getLedgerInfo({
  filter,
  pageNo,
  isResetPageRunningBalance,
  closingBalance,
  pageRunningBalance,
  searchParams
}: Props) {
  const { startDate, endDate, accountId, params } = getParamsInfo(filter);
  params.append('isAsc', 'true');

  // Get accountIds from filter and split them, then join them with '&'
  const accountIds = accountId ? accountId.split(',').map(Number) : [];
  const accountIdFilter = accountIds.map((id) => `accountIds[]=${id}`).join('&');
  params.delete('accountId');

  // Add accountIds to filter if exists
  filter = `${params.toString()}${accountIds.length ? `&${accountIdFilter}` : ''}`;

  // Store total credit, debit, opening balance, and closing balance
  const mergeValue = new Set();
  const balance = { opening: 0, closing: 0 };

  const response = await get_journal_lines_list(filter);
  if (response.results.length === 0) return { total: 0, data: [] };

  // GET unique journalIds and store it
  const uniqueJournalIds = [...new Set(response.results.map((journal) => journal.journalId))];
  const journalLists =
    uniqueJournalIds.length > 0
      ? await get_journal_details_ids(uniqueJournalIds)
      : { total: 0, results: [] };

  // Get accountIds from journal details and journal list
  const accountIdsFromJournalsDetails = [
    ...new Set(
      journalLists.results.flatMap((journal) => journal.lines.map((line) => line.accountId))
    )
  ];

  const accountIdsFromJournalList = [
    ...new Set(response.results.map((journal) => journal.accountId))
  ];

  const accountIdFromServer = accountId ? accountIdsFromJournalsDetails : accountIdsFromJournalList;

  // Append reference number to refNumber property of journalLists if presents
  const journalWithReferencePromise = getReferenceTypeDetails(journalLists.results);
  const accountDetailsPromise = get_account_detail_by_ids(accountIdFromServer).then(
    (res) => res.results
  );

  const historyPromises =
    accountId && accountIds.length > 0
      ? get_account_history_ids(startDate, endDate, accountIds)
      : createEmptyPromise([]);

  const balancePromise = accountId
    ? getBalance(accountIds)
    : createEmptyPromise({ openingBalance: 0, closingBalance: 0 });

  const selectedAccountPromises = accountId
    ? getAccountDetails(accountIds[0])
    : createEmptyPromise(undefined);

  // const nextJournalAccountPromises = accountId
  //   ? getNextJournalAccount(filter)
  //   : createEmptyPromise([]);

  // removed api call for nextJournalAccountPromises
  const nextJournalAccountPromises = createEmptyPromise([]);

  const promisesList = [
    journalWithReferencePromise,
    accountDetailsPromise,
    historyPromises,
    balancePromise,
    selectedAccountPromises,
    nextJournalAccountPromises
  ];

  const [_, accountDetails, history, currentBalance, selectedAccount, nextJournalAccounts] =
    await Promise.all(promisesList);

  const journalTableData = await (accountId
    ? addLocationToJournal({
        accountDetails: accountDetails as IAccountTypeResponseData[],
        hasAccountId: true,
        journalList: journalLists.results
      })
    : addLocationToJournal({
        accountDetails: accountDetails as IAccountTypeResponseData[],
        hasAccountId: false,
        journalLines: response.results,
        journalList: journalLists.results
      }));

  balance.opening = await getOpeningBalancev2({
    data: journalTableData,
    accountId: parseInt(accountId),
    history: history as IAccountHistoryResponse[],
    pageNo,
    closingBalance,
    searchParams,
    startDate
  });

  const pageRunBal = isResetPageRunningBalance ? {} : pageRunningBalance;
  const { updatedGroups: tableData, runningBalanceCurrAcc } = await getSortedTableData({
    data: journalTableData,
    pageRunBal,
    pageNo,
    currentAccount: selectedAccount as IAccountTypeResponseData,
    openingBalance: balance.opening,
    history: history as IAccountHistoryResponse[],
    nextJournalLines: nextJournalAccounts as unknown as IJournalLinesViewResponseTableData[]
  });

  if (accountId) {
    balance.closing = runningBalanceCurrAcc;
  }

  // Calculate Running Balance
  const journalWithTransfer = tableData.map((value) => {
    if (value.locationName?.includes('>>>'))
      return { journalId: value.journalId, locationName: value.locationName };
  });

  // Update tableData with rows, sn and locationName
  let sn = 0;
  const updatedTableData = tableData.map((value) => {
    let updatedValue = { ...value };

    const hasJournalWithTransfer = journalWithTransfer.find(
      (val) => val?.journalId === value.journalId
    );

    if (hasJournalWithTransfer) {
      updatedValue = { ...value, locationName: hasJournalWithTransfer?.locationName };
    }

    if (mergeValue.has(value.journalId)) {
      updatedValue = { ...updatedValue, rows: 0, sn: 0 };
    } else {
      const rowCount = tableData.filter((data) => data.journalId === value.journalId).length;
      mergeValue.add(value.journalId);
      sn += 1;
      updatedValue = { ...updatedValue, rows: rowCount, sn };
    }

    return {
      ...updatedValue,
      createdAt: convertUTCStringtoLocalString(updatedValue.createdAt, DEFAULT_DATE_FORMAT),
      transactionDate: updatedValue.journalDate
        ? convertUTCStringtoLocalString(updatedValue.journalDate, DEFAULT_DATE_FORMAT)
        : ''
    };
  });

  // Return total for pagination
  return {
    total: response.total,
    balance,
    selectedAccount: selectedAccount as IAccountTypeResponseData | undefined,
    data: updatedTableData as IJournalLinesViewResponseTableData[],
    currentBalance: currentBalance as { openingBalance: number; closingBalance: number },
    runningBalanceCurrAcc
  };
}
