import { DatePicker, Form, InputNumber, TableProps } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CustomModal } from '@/components/Common/CustomModal';
import AdjustmentResuableView from '../../../adjustments/view';
import { CustomDatePresets } from '../utils/datePresets';
import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convert_nepali_to_english_date } from '@/utils/nepaliDateConverter';
import { find_date_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';

const StockValueReport = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType, setNepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : '',
      productId: '',
      endDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    });
  }, []);

  const breadcrumbItems = [
    {
      label: 'Reports',
      link: '/reports'
    },
    {
      label: 'Stock Value',
      link: '/reports/stock-value'
    }
  ];

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      form.setFieldValue(['constraints', 'endDate'], moment(dateString).format('YYYY-MM-DD HH:mm'));
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,

      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Location',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-left">{record.location_name}</div>;
      },
      width: 100
    },
    {
      title: 'Product Name',
      key: 'product_name',
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortOrder: sortedInfo.columnKey === 'product_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-left">{record.product_name}</div>;
      },
      width: 100
    },
    {
      title: 'Total Amount',
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>;
      },
      width: 60
    }
  ];

  const onChangeSingleNepali = (val: string) => {
    const englishDate = convert_nepali_to_english_date(val);
    form.setFieldValue(['constraints', 'endDate'], moment(englishDate).format('YYYY-MM-DD'));
  };
  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <CustomReport
      toSort={handleChange}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 54, name: 'Stock Value Report' }}
      columnsData={columns}
      form={form}
      toDownload={true}>
      <CustomModal
        isModalOpen={isModalOpen}
        footer={false}
        setIsModalOpen={setIsModalOpen}
        title={'Adjustment View'}>
        <AdjustmentResuableView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      <ProductSearchV2 showAll hasParentFormItem={false} name={['constraints', 'productId']} />

      <Form.Item
        label={'Date'}
        valuePropName="endDate"
        name={['constraints', 'endDate']}
        rules={[
          {
            required: true,
            message: 'Please select date!'
          }
        ]}>
        {nepaliType ? (
          <NepaliDatePicker
            onChange={onChangeSingleNepali}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        ) : (
          <DatePicker
            onChange={onChange}
            disabledDate={disabledDate}
            defaultValue={moment()}
            format={'YYYY-MM-DD'}
            className="w-full"
          />
        )}
      </Form.Item>
    </CustomReport>
  );
};

export default StockValueReport;
