import moment from 'moment';
import { useEffect, useState } from 'react';
import { RangePickerProps } from 'antd/lib/date-picker';
import { SorterResult } from 'antd/lib/table/interface';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { DatePicker, Form, Input, InputNumber, TableProps } from 'antd';

import type { ColumnsType } from 'antd/es/table';
import { CustomDatePresets } from '../utils/datePresets';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusableSellView from '../../../sell/view/ReusableView';
import { ProductsSearch } from '@/components/Common/ProductsSearch';
import CustomReport from './report';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';

const { RangePicker } = DatePicker;
import { find_date_preference } from '@/store/localstorage/preferences';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { getUserData } from '@/utils/auth.utils';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';

const AllProductQuantityReport = () => {
  const { preferences } = getUserData();
  const nepaliType = find_date_preference();

  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const [id] = useState(0);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleTableSortChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : undefined,
      productId: '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'All Product Quantity Report', link: '/reports/all-product-quantity' }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };

  function calculateUnitValue(record: any, field: string) {
    return parseFloat(record[field]);
  }

  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Product',
      key: 'product',
      sorter: (a, b) => a.product.localeCompare(b.product),
      sortOrder: sortedInfo.columnKey === 'product' ? sortedInfo.order : null,
      render: (record) => <div>{record.product}</div>,
      width: 100
    },
    {
      title: 'Location',
      key: 'location',
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortOrder: sortedInfo.columnKey === 'location' ? sortedInfo.order : null,
      render: (record) => <div>{record.location}</div>,
      width: 100
    },
    {
      title: 'Sold',
      key: 'sold',
      align: 'right',
      sorter: (a, b) => calculateUnitValue(a, 'sold') - calculateUnitValue(b, 'sold'),
      sortOrder: sortedInfo.columnKey === 'sold' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {calculateUnitValue(record, 'sold')} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Purchased',
      key: 'purchased',
      align: 'right',
      sorter: (a, b) => calculateUnitValue(a, 'purchased') - calculateUnitValue(b, 'purchased'),
      sortOrder: sortedInfo.columnKey === 'purchased' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {calculateUnitValue(record, 'purchased')} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Sell Return',
      key: 'sell_return',
      align: 'right',
      sorter: (a, b) => calculateUnitValue(a, 'sell_return') - calculateUnitValue(b, 'sell_return'),
      sortOrder: sortedInfo.columnKey === 'sell_return' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {calculateUnitValue(record, 'sell_return')} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Purchase Return',
      key: 'purchase_return',
      align: 'right',
      sorter: (a, b) =>
        calculateUnitValue(a, 'purchase_return') - calculateUnitValue(b, 'purchase_return'),
      sortOrder: sortedInfo.columnKey === 'purchase_return' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {calculateUnitValue(record, 'purchase_return')} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Wastage',
      key: 'wastage',
      align: 'right',
      sorter: (a, b) => calculateUnitValue(a, 'wastage') - calculateUnitValue(b, 'wastage'),
      sortOrder: sortedInfo.columnKey === 'wastage' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {calculateUnitValue(record, 'wastage')} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Transfer In',
      key: 'transfer_in',
      align: 'right',
      sorter: (a, b) => calculateUnitValue(a, 'transfer_in') - calculateUnitValue(b, 'transfer_in'),
      sortOrder: sortedInfo.columnKey === 'transfer_in' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {calculateUnitValue(record, 'transfer_in')} {record.unit}
          </div>
        );
      },
      width: 50
    },
    {
      title: 'Transfer Out',
      key: 'transfer_out',
      align: 'right',
      sorter: (a, b) =>
        calculateUnitValue(a, 'transfer_out') - calculateUnitValue(b, 'transfer_out'),
      sortOrder: sortedInfo.columnKey === 'transfer_out' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {calculateUnitValue(record, 'transfer_out')} {record.unit}
          </div>
        );
      },
      width: 50
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      breadcrumbItems={breadcrumbItems}
      state={{ id: [46], name: 'All Product Quantity Report' }}
      form={form}
      toSort={handleTableSortChange}
      footerData={{ sell: true, purchase: true }}
      columnsData={[columns]}>
      <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={'Sell View'}>
        <ReusableSellView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'identifier']} hidden={true}>
        <InputNumber />
      </Form.Item>

      <ProductSearchV2 showAll hasParentFormItem={false} name={['constraints', 'productId']} />
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default AllProductQuantityReport;
