import { useMutation } from '@tanstack/react-query';
import { Form, message, PageHeader, Spin, DatePicker, InputNumber } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '../../../../components/Common/Content/Content';

import CustomSubmitButton from '../../../../components/Common/CustomButton/CustomSubmitButton';
import { create_vehicles_fuel_mutation } from '../../../../services/vehicle/mutations';
import { IVehicleFuelCreate } from '../../../../services/vehicle/types';
import { UsersSearch } from '../../../../components/Common/UsersSearch';
import { VehiclesSearch } from '../../../../components/Common/VehiclesSearch';
import { AxiosError } from 'axios';
import { checkHasAccountRule } from '../../../../services/accounts/services';
import { AccountRulesEvent } from '../../../../services/accounts/enums';
import CustomInfoModal from '../../../../components/Common/CustomInfoModal';

const CreateVehicleFuel = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setisLoading] = useState<boolean>(false);

  const breadcrumbItems = [
    {
      label: 'Vehicle Fuel',
      link: '/vehicle-fuel'
    },
    {
      label: 'Create',
      link: '/vehicle-fuel/new'
    }
  ];

  const checkAccountRule = async () => {
    if (await checkHasAccountRule(null, AccountRulesEvent.VEHICLE_FUEL_CREATE)) {
      return true;
    } else {
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.VEHICLE_FUEL_CREATE}" rule has not been created!`
      });
      return false;
    }
  };

  const createVehicleFuelMutation = useMutation(create_vehicles_fuel_mutation, {
    onSuccess: () => {
      message.success('Vehicle Fuel successfully added!');
      navigate('/vehicle-fuel');
    },
    onError: (error: any) => {
      setisLoading(false);
      message.error(error.response?.data?.message);
    }
  });
  const onFinish = async (values: IVehicleFuelCreate) => {
    try {
      setisLoading(true);
      if (await checkAccountRule()) {
        await createVehicleFuelMutation.mutateAsync(values);
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Vehicle Fuel"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          initialValues={{ units: [], isTraceable: false }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2">
            <VehiclesSearch
              formData={{ formName: 'vehicleId', label: 'Vehicle' }}
              isAll={false}
              required={true}
            />
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: 'Please select date!'
                }
              ]}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount!'
                }
              ]}>
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Cost"
              name="cost"
              rules={[
                {
                  required: true,
                  message: 'Please input cost !'
                }
              ]}>
              <InputNumber />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <CustomSubmitButton text="Submit" />
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateVehicleFuel;
