import { useQuery, useMutation } from '@tanstack/react-query';
import {
  Form,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Input,
  Checkbox,
  Card,
  Spin,
  Upload,
  Button,
  UploadFile
} from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import CustomSubmitButton from '@/components/Common/CustomButton/CustomSubmitButton';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import { VendorSearch } from '@/components/Common/VendorSearch/VendorSearch';
import useDebounce from '@/hooks/useDebounce';
import { create_procurement_mutation } from '@/services/procurement/mutations';

import {
  get_product_details,
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productId,
  get_price_groups_by_location,
  prices_by_groupId,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import { IUnits } from '@/services/products/types';
import { edit_purchase_order_mutation } from '@/services/purchases/mutations';
import {
  get_purchase_agent_bill_cancel_list,
  get_purchase_agent_bill_list,
  get_purchase_order_details,
  get_purchase_order_lines_details
} from '@/services/purchases/queries';
import {
  ICreatePurchaseOrder,
  IPurchaseAgentBillRange,
  IUpdatePurchaseOrder
} from '@/services/purchases/types';

import { ILines, IProductUnits, Line } from '@/services/sell/types';
import { get_vendor_details } from '@/services/users/queries';

import UnitsDB from '@/store/localstorage/UnitsDB';
import { getUserData } from '@/utils/auth.utils';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import ReuseChannel from '../../../channel/Reuse';
import {
  find_default_product_category,
  find_financial_year,
  find_purchase_confirmation
} from '@/store/localstorage/preferences';
import { CustomModal } from '@/components/Common/CustomModal';
import ReusablePurchaseOrderConfirm from '@/components/Common/CustomModal/ReusablePurchaseOrderConfirm';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { PurchaseBuyerType, PurchaseType } from '@/services/purchases/enums';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent, AccountType } from '@/services/accounts/enums';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import AgentsSearch from '@/components/Common/AgentsSearch';
import useDebounceFunction from '@/hooks/useDebounceFunction';
import { AgentsDB } from '@/store/localstorage/AgentsDB';
import { onPreviewImage } from '@/services/upload/services';
import { UploadOutlined } from '@ant-design/icons';
import { getImagesFromServer, uploadImagesToServer } from '@/services/upload/queries';
import { getVendors } from '@/pages/expense/view/services.expense';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';
import DebouncedInputNumber from '@/components/Common/InputNumber';

const { Option } = Select;

const PurchaseOrderDetails = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { socket } = useContext(WebSocketContext);

  const [allLocalUnits, setAllLocalUnits] = useState<any>([]);
  const [isLoading, setIsloading] = useState(true);
  const [isFinalize, setIsFinalize] = useState<boolean>(false);
  const [hasRule, setHasRule] = useState<boolean>(true);

  const [totalLots, settotalLots] = useState<any[]>([]);
  const [userIdforchannel, setUserIdforchannel] = useState<any>(0);
  //product search
  const [searchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [initalVendor, setinitalVendor] = useState<any>();
  const [selectedAgentId, setinitialAgent] = useState<number>();
  const [createValues, setCreateValues] = useState<IUpdatePurchaseOrder>();
  const [confirmModalValues, setConfirmModalValues] = useState<ICreatePurchaseOrder>();
  const [modalOpenForConfirmation, setModalOpenForConfirmation] = useState<boolean>(false);
  const loggedInUser = getUserData();
  const [productCategory, setProductCategory] = useState<number | string | null | undefined>(null);

  const [baseInvoice, setBaseInvoice] = useState('');
  const [isSelfInvoice, setIsSelfInvoice] = useState(false);

  const [allPurchaseAgentBill, setAllPurchaseAgentBill] = useState<
    Record<number, IPurchaseAgentBillRange[]>
  >({});

  const [billDetails, setBillDetails] = useState<IPurchaseAgentBillRange>();
  const [disabledBillNumber, setDisabledBillNumber] = useState<number[]>([]);

  async function getAgentIdFromUserId(userId: number) {
    const agent = await AgentsDB.getAgentsByUserIds([userId]);
    return agent[0].id;
  }

  async function getAgentPurchaseBillRange(userId: number) {
    const agentId = await getAgentIdFromUserId(userId);

    if (allPurchaseAgentBill[userId]) {
      const current = allPurchaseAgentBill[userId];
      setAllPurchaseAgentBill((prev) => ({ ...prev, [userId]: current }));
      return current;
    }

    const params = new URLSearchParams();
    params.set('agentId', agentId.toString());
    params.set('archiveStatus', 'ACTIVE');
    params.set('page', '1');
    params.set('size', '1000');

    const filter = params.toString();

    const { results: agentBillList } = await get_purchase_agent_bill_list(filter);
    setAllPurchaseAgentBill((prev) => ({ ...prev, [userId]: agentBillList }));
    return agentBillList;
  }

  async function fetchCancelBill(agentId: number, billId: number) {
    setIsloading(true);
    const query = new URLSearchParams();
    query.set('agentId', agentId.toString());
    query.set('billRangeIds[]', billId.toString());
    query.set('skip', '0');
    query.set('count', '100');

    const { results } = await get_purchase_agent_bill_cancel_list(query.toString());
    const disabledBillNumber = results.map((val) => val.billNumber);
    setDisabledBillNumber(disabledBillNumber);
    setIsloading(false);
  }

  const onAgentInvoiceChange = async (billId: number) => {
    setIsloading(true);
    const agentBillInvoices = selectedAgentId ? allPurchaseAgentBill[selectedAgentId] : [];
    const value = agentBillInvoices.find((val) => val.id == billId);
    setBillDetails(value);

    if (!value) {
      setIsloading(false);
      console.error('Agent Invoice not found!');
      return;
    }

    await fetchCancelBill(value.agentId, billId);
    form.setFieldValue(['agentPurchase', 'billNo'], undefined);
  };

  const preferenceFinancialYear = find_financial_year();
  const [isAccountArchived, setIsAccountArchived] = useState(false);

  const onVendorChange = async (vendorId: number) => {
    const financialYear = preferenceFinancialYear;

    if (vendorId) {
      const userDetails = await get_vendor_details(vendorId);
      setUserIdforchannel(userDetails?.vendor?.userId);

      const vendorDetails = await getVendors(vendorId);
      if (typeof vendorDetails !== 'string') {
        const userId = vendorDetails.userId;
        const userAccount = await get_account_details_by_userid_type(userId, AccountType.USER);

        setIsAccountArchived(userAccount?.isArchived);
        if (userAccount?.isArchived) {
          message.error({
            content: (
              <span>
                Unable to update purchase order though this vendor. <strong>Reason:</strong>{' '}
                <span className="text-red-500">Account Archived</span>
              </span>
            ),
            duration: 5
          });

          form.setFieldValue(['vendorId'], null);
          return;
        }
      }
    }

    if (vendorId && financialYear) {
      const formattedInvoiceNumber = `${vendorId}-${financialYear}-`;
      setBaseInvoice(formattedInvoiceNumber);
    }
  };

  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const [defaultPriceBylocationId, setDefaultPriceBylocationId] = useState<any>({});
  const autofocusRef: React.Ref<any> = useRef(null);
  useQuery(['order-details'], async () => {
    const response = await get_purchase_order_details(parseInt(id as string));
    const { manualDiscount, vendorId, isSelfInvoice, agentId, billNo, billNoId, mediaId } =
      response.data;
    if (manualDiscount) {
      form.setFields([{ name: 'discount', value: manualDiscount }]);
    }

    setinitalVendor(vendorId);
    setIsSelfInvoice(isSelfInvoice);
    onVendorChange(vendorId);

    if (agentId) {
      const purchaseOptionss = isSelfInvoice ? await getAgentPurchaseBillRange(agentId) : [];

      if (mediaId) {
        const medias = await getImagesFromServer([mediaId]);
        const tempFileList = medias.map((media) => ({
          uid: `${media.id}`,
          name: 'image.png',
          status: 'done',
          url: media.url
        })) as UploadFile[];

        setFileList(tempFileList);
      }
      setinitialAgent(agentId);

      if (isSelfInvoice) {
        const billInvoice = purchaseOptionss?.find((val) => val.id == billNoId);
        if (billInvoice) {
          setBillDetails(billInvoice);
          await fetchCancelBill(billInvoice.agentId, billNoId);
          form.setFieldsValue({
            agentPurchase: { billNo, billNoId },
            invoiceNumber: `${billNo}`
          });
        }
      }
    }

    const linesResponse = await get_purchase_order_lines_details(response.data.id);
    const locationId = response.data.locationId;
    if (linesResponse.data.length > 0) {
      const allProducts = [...productList];
      const mySet = new Set<number>();
      for (let i = 0; i < linesResponse.data.length; i++) {
        mySet.add(linesResponse.data[i].productId);
        let findProduct = allProducts.find((value) => value.id == linesResponse.data[i].productId);
        if (!findProduct) {
          findProduct = await get_product_details(linesResponse.data[i].productId);
          allProducts.push(findProduct);
        }
        linesResponse.data[i].productName = findProduct.name;
        linesResponse.data[i].total =
          linesResponse.data[i].unitPrice * linesResponse.data[i].quantity +
          linesResponse.data[i].misc -
          linesResponse.data[i].discount;
        // await checkAndGetLot(linesResponse.data[i].productId);
      }
      setProductCategory(response.data.categoryId);
      let grandTotal = 0;
      for (let i = 0; i < linesResponse.data.length; i++) {
        grandTotal += linesResponse.data[i].total + linesResponse.data[i].vat;
      }

      form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
      await fetchLotsOnLocationandProductChange(Array.from(mySet), locationId, 'location');

      setProductList((prev) => [...prev, ...allProducts]);
    }

    setUserIdforchannel(response?.data?.vendor?.userId);
    const { invoiceNumber, ...remainingField } = response.data;
    form.setFieldsValue({ lines: linesResponse.data, ...remainingField });

    setIsloading(false);
    return response;
  });
  // This is needed to get the product details update as its written
  const createProcurementsMutation = useMutation(create_procurement_mutation);

  const breadcrumbItems = [
    { label: 'Purchases Order', link: '/purchase/order' },
    { label: 'Edit' }
  ];

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['locationId']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const locationId = form.getFieldValue(['locationId']);

        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const editPurchaseOrderMutation = useMutation(edit_purchase_order_mutation);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onFinish = useDebounceFunction(async (values: IUpdatePurchaseOrder) => {
    setIsloading(true);
    try {
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };

      // if (productCategory !== null) {
      if (productCategory) {
        if (typeof productCategory === 'number') {
          values.categoryId = productCategory;
        } else {
          const defaultCategory = find_default_product_category();
          if (defaultCategory === null) {
            throw {
              name: 'CategoryError',
              message: 'Please select a default Product Category from preferences.'
            };
          } else {
            values.categoryId = defaultCategory;
          }
        }
      }

      // Check Media
      const existingFileIds = fileList.filter((file) => file.url).map((file) => parseInt(file.uid));
      const newFiles = fileList.filter((file) => !file.url);

      if (newFiles.length !== 0) {
        message.loading({ key: 'uploadingImage', content: 'Uploading images...' });
        const responses = await uploadImagesToServer(newFiles);
        const media = responses[0];
        values.mediaId = media?.id;
        message.destroy('uploadingImage');
      } else {
        values.mediaId = existingFileIds[0] ? existingFileIds[0] : undefined;
      }

      values.date = JSON.stringify(new Date()).slice(1, -1);
      values.id = parseInt(id as string);

      let totalWithoutDiscount = 0;

      for (let i = 0; i < values.lines.length; i++) {
        totalWithoutDiscount += values.lines[i].total + values.lines[i].vat;
      }

      if (totalWithoutDiscount < values.discount) {
        form.setFields([
          { name: 'discount', errors: ['Discount cannot be greater than grand total'] }
        ]);
        setIsloading(false);
        return;
      }

      setCreateValues(values);
      if (find_purchase_confirmation()) {
        setIsloading(false);
        const modalValues = values;
        confirmationPopup(modalValues);
      } else {
        await editPurchaseOrderMutation.mutateAsync(values, {
          onSuccess: async ({ data }: { data: any }) => {
            const hasError = await finalizePurchase(values);
            if (!hasError) {
              message.success('Order edited successfully');
              navigate('/purchase/order');
            }
          },
          onError: (e: any) => {
            setIsloading(false);
            message.error(`${e.response.data.message}`, 5);
          }
        });
      }

      setIsloading(false);
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) message.error(errors.message);
      else message.error('Empty fields found!');
    }
  });

  const confirmationPopup = (values: ICreatePurchaseOrder) => {
    //console.log(values);
    setConfirmModalValues(values);
    setModalOpenForConfirmation(true);
  };

  const handleConfirmationModalSubmit = async () => {
    setIsloading(true);
    if (createValues) {
      await editPurchaseOrderMutation.mutateAsync(createValues, {
        onSuccess: async ({ data }: { data: any }) => {
          const hasError = await finalizePurchase(createValues);
          if (!hasError) {
            message.success('Order edited successfully');
            navigate('/purchase/order');
          }
        },
        onError: (e: any) => {
          setModalOpenForConfirmation(false);
          message.error(`${e.response.data.message}`, 5);
        },
        onSettled: () => {
          setIsloading(false);
        }
      });
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    if (!locationId) {
      throw {
        name: 'Location Error',
        message: 'Please select Location!'
      };
    }

    if (productsIdArray.length == 0) {
      throw {
        name: 'Product Error',
        message: 'Please select atleast one Product!'
      };
    }

    const currenttotalLots = [];
    if (from === 'productchange') {
      const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
      if (!filterLots) {
        const response = await get_unexpired_lots_details_bylocationId_productId(
          locationId,
          productsIdArray[0]
        );
        settotalLots([...totalLots, ...response]);
      }
    } else if (from === 'lotsupdate') {
      const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
        ...new Set(productsIdArray)
      ]);

      settotalLots((prev) => {
        const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
        return [...filterLots, ...result];
      });
    } else {
      const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
        ...new Set(productsIdArray)
      ]);
      currenttotalLots.push(...result);
      settotalLots([...currenttotalLots]);
    }
  };

  const onLocationChange = async (value: number) => {
    form.setFieldValue('locationId', value);
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], undefined);
      });
    }
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');

    let defaultPrice: any = [];
    if (defaultPriceBylocationId[value]) {
      defaultPrice = defaultPriceBylocationId[value];
    } else {
      const response = await get_price_groups_by_location(value);
      // console.log('resposne===>', response);
      if (response.data[0]?.id) {
        const response2 = await prices_by_groupId(response.data[0].id);
        // console.log('response 2-->', response2);
        defaultPrice = [...response2.data];
        setDefaultPriceBylocationId({ ...defaultPriceBylocationId, [value]: response2.data });
      } else {
        data.map((curr: any, ind: number) => {
          form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
          form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
        });
      }
    }
    // console.log('defaultPrice', defaultPrice);
    if (defaultPrice.length > 0) {
      data.map((curr: any, ind: number) => {
        const findone = defaultPrice.find((res2elem: any) => res2elem.productId === curr.productId);
        // console.log('findOne', findone);
        if (findone) {
          const alreadySelectedUnitId = form.getFieldValue(['lines', ind, 'unitId']);
          // console.log('alreadySelectedUnitId', alreadySelectedUnitId);
          if (alreadySelectedUnitId !== undefined && alreadySelectedUnitId !== null) {
            const findUnit = allLocalUnits.find((curr: any) => curr.id === alreadySelectedUnitId);
            // console.log('findUnit', findUnit);
            form.setFieldValue(
              ['lines', ind, 'unitPrice'],
              findUnit.baseUnitMultiplier * findone.sellingPrice
            );
          } else {
            form.setFieldValue(['lines', ind, 'unitPrice'], findone.sellingPrice);
          }
          form.setFieldValue(['lines', ind, 'sellingPrice'], findone.sellingPrice);
        } else {
          form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
          form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
        }
      });
    } else {
      data.map((curr: any, ind: number) => {
        form.setFieldValue(['lines', ind, 'unitPrice'], undefined);
        form.setFieldValue(['lines', ind, 'sellingPrice'], undefined);
      });
    }
  };

  const FilterUnits = (name: any) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList.length > 0) {
      const selectedProduct = productList.find((val: any) => val.id == checkCurrentProduct);
      if ('productUnits' in selectedProduct) {
        const filteredUnits = selectedProduct.productUnits.map((value: any) => {
          const data = allLocalUnits.find((val: any) => value.unitId == val.id);
          return data;
        });
        return (
          <>
            {filteredUnits.map((value: any) => {
              if (!value) return null;
              return (
                <Option value={value.id} key={value.id}>
                  {`${value.name}`}
                </Option>
              );
            })}
          </>
        );
      }
      return (
        <>
          {selectedProduct.units.map((value: any) => (
            <Option value={value.id} key={value.id}>
              {`${value.name}`}
            </Option>
          ))}
        </>
      );
    }
  };

  const checkAccountRule = async (locationId: number) => {
    if (
      (await checkHasAccountRule(locationId, AccountRulesEvent.SELL_CREATE)) &&
      (await checkHasAccountRule(locationId, AccountRulesEvent.VAT_CREATE))
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.SELL_CREATE}" or "${AccountRulesEvent.VAT_CREATE}" rule has not been created!`
      });
    }
  };

  function onLotChange(name: number, value: number) {
    const productId = form.getFieldValue(['lines', name, 'productId']);
    if (!productId) {
      return message.error('Failed to get product id');
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);
    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(['lines', name, 'hsCode'], currentSelectedLot?.hsCode);
  }

  const FilterLot = (name: number) => {
    // console.log('This is called..');
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    const currentLocation = form.getFieldValue(['locationId']);
    if (checkCurrentProduct && currentLocation) {
      let filteredLots: any = [];
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${numberDecimalFormatter(
                value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
              )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };
  const onProductChange = async (value: number, name?: number) => {
    // form.setFieldValue([name, 'productId'], value);
    // await checkAndGetLot(value);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['locationId']);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productList) {
      const selectedProduct = productSearch.find((val) => val.id == value);
      // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
      if (selectedProduct.purchasePrice) {
        form.setFieldValue(['lines', data.length - 1, 'unitPrice'], selectedProduct.purchasePrice);
      } else {
        let defaultPriceArray: any = [];
        if (defaultPriceBylocationId[currentLocation]) {
          defaultPriceArray = defaultPriceBylocationId[currentLocation];
        } else {
          const response = await get_price_groups_by_location(currentLocation);
          // console.log('resposne===>', response);
          if (response.data[0]?.id) {
            const response2 = await prices_by_groupId(response.data[0].id);
            defaultPriceArray = [...response2.data];
            setDefaultPriceBylocationId({
              ...defaultPriceBylocationId,
              currentLocation: response2.data
            });
            // console.log('response 2-->', response2);
          }
        }
        if (defaultPriceArray.length > 0) {
          const findone = defaultPriceArray.find((res2elem: any) => res2elem.productId === value);
          if (findone) {
            form.setFieldValue(['lines', data.length - 1, 'unitPrice'], findone.sellingPrice);
            form.setFieldValue(['lines', data.length - 1, 'sellingPrice'], findone.sellingPrice);
          }
        } else {
          form.setFieldValue(['lines', data.length - 1, 'unitPrice'], undefined);
        }
      }
    }
  };
  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };

  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      locationId: null,
      discount: 0,
      vendorId: null,
      shippingAmount: 0,
      labourCost: 0,
      purchaseBuyerType: PurchaseBuyerType.OTHER,
      // addressId: null,
      // taxId: null,
      lines: []
    });
    initializeUnits();
  }, []);

  useBeforeUnloadListener();

  const finalizePurchase = async (values: any) => {
    try {
      //validation
      if (!isFinalize) return false;
      await form.validateFields();
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };
      let categoryId;
      // if (productCategory !== null) {
      if (productCategory) {
        if (typeof productCategory === 'number') {
          categoryId = productCategory;
        } else {
          const defaultCategory = find_default_product_category();
          if (defaultCategory === null) {
            throw {
              name: 'CategoryError',
              message: 'Please select a default Product Category from preferences.'
            };
          } else {
            categoryId = defaultCategory;
          }
        }
      }
      // } else {
      //   throw {
      //     name: 'CategoryError',
      //     message: 'Please select a Product Category.'
      //   };
      // }
      //send purchase
      values = {
        locationId: values.locationId,
        purchases: [
          {
            agentId: values.agentId ? values.agentId : null,
            isSelfInvoice: values?.isSelfInvoice,
            mediaId: values?.mediaId ? values.mediaId : undefined,
            agentPurchase: values?.agentPurchase,
            invoiceNumber: values.invoiceNumber,
            purchaseType: values.purchaseType,
            purchaseBuyerType: values.purchaseBuyerType,
            locationLCCId: values.locationLCCId ? values.locationLCCId : null,
            vendorId: values.vendorId,
            lines: values.lines,
            discount: values.discount,
            shippingAmount: values.shippingAmount,
            labourCost: values.labourCost,
            purchaseOrderId: parseInt(id ? id : '0'),
            createdBy: loggedInUser.id,
            categoryId: categoryId
          }
        ]
      };
      values.date = JSON.stringify(new Date()).slice(1, -1);
      if (id) values.purchases.purchaseOrderId = parseInt(id);

      let checkErr = true;

      await createProcurementsMutation.mutateAsync(values, {
        onSuccess: async ({ data }: { data: any }) => {
          message.success('Purchase finalized successfully');
          checkErr = false;
        },
        onError: async (e: any) => {
          setModalOpenForConfirmation(false);
          setIsloading(false);
        }
      });

      return checkErr;
    } catch (errors: any) {
      if ('name' in errors) message.error(errors.response.data.message);
      else message.error('Empty fields found!');
      return true;
    }
  };
  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const onUnitChange = (name: number) => {
    const data = form.getFieldValue(['lines']);
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const findOne = allLocalUnits.find((curr: any) => curr.id === selectedUnitId);
    const prevValue = form.getFieldValue(['lines', name, 'sellingPrice']);
    // form.setFieldValue(['lines', name, 'unitPrice'], prevValue * findOne.baseUnitMultiplier);
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);
    const dis = form.getFieldValue(['lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['lines', name, 'total'], total);

    const productId = form.getFieldValue(['lines', name, 'productId']);
    let vat = 0;
    let productDetail = await ProductsDB.getProduct(productId);
    if (!productDetail) {
      const allProducts = await get_product_list();
      await ProductsDB.addProducts(allProducts.data.results);
      productDetail = await ProductsDB.getProduct(productId);
    }
    if (typeof productDetail === 'object' && productDetail.vat) {
      vat = (rate * qty - dis) * (productDetail.vat / 100);
    }
    form.setFieldValue(['lines', name, 'vat'], parseFloat(vat.toFixed(2)));

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    const discount = form.getFieldValue('discount');

    // console.log('lines', lines);
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    const totalWithDiscount = grandTotal - discount;

    form.setFieldValue('grandtotal', numberDecimalFormatter(totalWithDiscount));
    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  const isProductAlreadyAdded = (index: number, checkproductId: number) => {
    const lines = form.getFieldValue(['lines']);
    const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
    if (findOne) {
      return false;
    }
    return true;
  };

  const onDiscountChange = () => {
    // console.log('lines', lines);
    let grandTotal = 0;
    const discount = form.getFieldValue('discount');

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    const totalWithDiscount = grandTotal - discount;

    form.setFieldValue('grandtotal', totalWithDiscount);
  };

  const onVatChange = async () => {
    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    const discount = form.getFieldValue('discount');

    // console.log('lines', lines);
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
    }

    const totalWithDiscount = grandTotal - discount;

    form.setFieldValue('grandtotal', numberDecimalFormatter(totalWithDiscount));
  };

  const onAgentChange = async (agentId: number) => {
    if (!agentId) return;
    form.setFieldValue(['agentId'], agentId);
    if (isSelfInvoice) {
      form.setFieldValue('agentPurchase', undefined);
      form.setFieldValue('invoiceNumber', undefined);
      await getAgentPurchaseBillRange(Number(agentId));
    }

    setinitialAgent(Number(agentId));
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        isModalOpen={modalOpenForConfirmation}
        setIsModalOpen={setModalOpenForConfirmation}
        title={'Confirmation'}
        footer={false}>
        <ReusablePurchaseOrderConfirm
          disabled={isLoading}
          data={confirmModalValues}
          handleSubmitClick={handleConfirmationModalSubmit}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Order Information"
          style={{
            padding: '8px 0px'
          }}
        />
        <Form
          form={form}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <div className={'grid  cols-1  md:grid-cols-3 gap-5 mb-5'}>
            <LocationSearch notAll={true} onSelect={onLocationChange} />
            {initalVendor && (
              <VendorSearch
                all={false}
                onSelect={onVendorChange}
                initial={initalVendor}
                applyFilter={true}
              />
            )}
            <AgentsSearch
              defaultValue={selectedAgentId}
              required={isSelfInvoice}
              hasParentFormItem={false}
              onSelect={(id) => onAgentChange(Number(id))}
            />

            {/* </Form.Item> */}
            <Form.Item
              label="Discount"
              name={['discount']}
              rules={[{ required: true, message: 'Please add Discount!' }]}>
              <InputNumber
                min={0}
                onChange={() => {
                  onDiscountChange();
                }}
              />
            </Form.Item>
            <Form.Item
              label="Shipping"
              name={['shippingAmount']}
              rules={[{ required: true, message: 'Please add shipping amount!' }]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="Labour Cost"
              name={['labourCost']}
              rules={[{ required: true, message: 'Please add labour cost!' }]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="Invoice Number"
              name={['invoiceNumber']}
              rules={[{ required: isFinalize, message: 'Please add invoice no.!' }]}>
              <Input addonBefore={baseInvoice} disabled={isSelfInvoice} />
            </Form.Item>
            <Form.Item
              label="Purchase Type"
              name={['purchaseType']}
              rules={[{ required: true, message: 'Please add purchase type!' }]}>
              <Select placeholder="Select purchase type">
                {(Object.keys(PurchaseType) as Array<keyof typeof PurchaseType>).map((key) => (
                  <Select.Option key={key} value={key}>
                    {PurchaseType[key]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Buyer Type"
              initialValue={PurchaseBuyerType.OTHER}
              name={['purchaseBuyerType']}
              rules={[{ required: true, message: 'Please add purchase buyer type!' }]}>
              <Select placeholder="Select purchase buyer type">
                {(Object.keys(PurchaseBuyerType) as Array<keyof typeof PurchaseBuyerType>).map(
                  (key) => (
                    <Select.Option key={key} value={key}>
                      {PurchaseBuyerType[key]}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
            <LocationSearch
              formData={{ formLabel: 'LCC Location', formName: ['locationLCCId'] }}
              notAll={true}
              required={false}
            />

            <Form.Item name="isSelfInvoice" label="Invoice" valuePropName="checked">
              <Checkbox
                onChange={(value) => {
                  const isChecked = value.target.checked;
                  const selectedAgentId = form.getFieldValue('agentId');
                  if (selectedAgentId && isChecked) getAgentPurchaseBillRange(selectedAgentId);
                  if (!isChecked)
                    form.setFieldsValue({ agentPurchase: undefined, invoiceNumber: '' });
                  setIsSelfInvoice(isChecked);
                }}>
                Self Invoice
              </Checkbox>
            </Form.Item>

            {isSelfInvoice && (
              <>
                <Form.Item
                  label="Agent Invoice"
                  name={['agentPurchase', 'billNoId']}
                  rules={[{ required: isSelfInvoice, message: 'Please add Agent Invoice!' }]}>
                  <Select
                    key={selectedAgentId}
                    placeholder="Select invoice"
                    onChange={(id) => onAgentInvoiceChange(id)}
                    dropdownMatchSelectWidth={false}>
                    {selectedAgentId &&
                      allPurchaseAgentBill[selectedAgentId]?.map((val) => (
                        <Select.Option key={val.id} value={val.id}>
                          <span>
                            Current: {val.current} ({val.min} - {val.max})
                          </span>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Agent Bill No."
                  rules={[{ required: isSelfInvoice, message: 'Please add bill no.!' }]}
                  name={['agentPurchase', 'billNo']}>
                  <DebouncedInputNumber
                    restrictedNumbers={disabledBillNumber}
                    defaultValue={form.getFieldValue(['agentPurchase', 'billNo'])}
                    errorMessage="This bill number is cancelled! Please select another one."
                    debouncedOnChange={(value) => {
                      form.setFieldValue(['agentPurchase', 'billNo'], value);
                      form.setFieldValue('invoiceNumber', `${value || ''}`);
                    }}
                    min={billDetails?.min || 0}
                    max={billDetails?.max}
                  />
                </Form.Item>

                <Form.Item
                  key={selectedAgentId}
                  label="Media"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList}>
                  <Upload
                    defaultFileList={fileList}
                    accept="image/*"
                    multiple={false}
                    maxCount={1}
                    listType="text"
                    onPreview={onPreviewImage}
                    onChange={({ fileList }) => setFileList(fileList)}
                    beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </>
            )}
          </div>

          <div className="grid grid-cols-4 gap-5 mb-5"></div>
          <Divider />

          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div className={'grid mb-5'}>
                  <PageHeader
                    subTitle="Add Product"
                    style={{
                      padding: '8px 0px 8px 10px'
                    }}
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <ProductCategorySearch
                      setValue={productCategory}
                      onProductCategoryChange={(val) => {
                        setProductCategory(val);
                        if (val !== 'default') {
                          form.setFieldValue(['lines'], []);
                        }
                      }}
                      isAll={false}
                      onClearProduct={() => {
                        setProductCategory(undefined);
                      }}
                      allowClear={true}
                    />
                    <ProductSearchForLines
                      add2={add2}
                      onProductChange={onProductChange}
                      productList={productList}
                      setProductList={setProductList}
                      productSearch={productSearch}
                      setProductSearch={setProductSearch}
                      autofocusRef={autofocusRef}
                      isProductAlreadyAdded={isProductAlreadyAdded}
                      index={0}
                      locationId={form.getFieldValue(['locationId'])}
                      validation={productCategory !== null ? true : false}
                      productCategory={productCategory}
                    />
                  </div>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="All Products"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <Card
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                    <div className="flex gap-1 items-center" key={key2}>
                      <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                      <div className="card">
                        <div
                          className={
                            'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-9'
                          }
                          key={key2}>
                          <Form.Item {...restField2} name={[name2, 'productId']} hidden></Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'sellingPrice']}
                            hidden></Form.Item>
                          <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                            <Input
                              type={'text'}
                              disabled
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                                // border: '0px',
                                fontWeight: 'bold'
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'unitId']}
                            label="Unit"
                            rules={[
                              {
                                required: true,
                                message: 'Please choose a Unit!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Unit!"
                                onChange={() => onUnitChange(name2)}
                                dropdownMatchSelectWidth={false}
                                allowClear>
                                {FilterUnits(name2)}
                              </Select>
                            }
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            name={[name2, 'lotId']}
                            label="Lot"
                            rules={[
                              {
                                required: false,
                                message: 'Please choose a Lot!'
                              }
                            ]}>
                            {
                              <Select
                                placeholder="Select a Lot!"
                                dropdownMatchSelectWidth={false}
                                onChange={(value) => onLotChange(name2, value)}
                                allowClear>
                                {FilterLot(name2)}
                              </Select>
                            }
                          </Form.Item>
                          {/* <Form.Item
                          {...restField2}
                          label="Quantity"
                          name={[name2, 'quantity']}
                          rules={[{ required: true, message: 'Please add Quantity!' }]}>
                          <InputNumber
                            min={1}
                            onChange={() => onUnitandQuantityChange(name2)}
                            autoFocus
                          />
                        </Form.Item> */}
                          <ReusableQuantity
                            name={name2}
                            restField={restField2}
                            onChangeData={() => onUnitandQuantityChange(name2)}
                            onPressEnterData={(e) => {
                              if (autofocusRef.current) {
                                autofocusRef.current.focus();
                              }
                            }}
                            rules={[
                              { required: true, message: 'Please add Quantity!' },
                              () => ({
                                validator(_: any, value: any) {
                                  if (!value) {
                                    return Promise.reject('Please input valid Quantity!');
                                  }
                                  if (value == 0)
                                    return Promise.reject('Please Input valid Quantity!');
                                  return Promise.resolve();
                                }
                              })
                            ]}
                          />
                          <Form.Item
                            {...restField2}
                            label="Rate"
                            name={[name2, 'unitPrice']}
                            rules={[
                              { required: true, message: 'Please add Rate!' },
                              () => ({
                                validator(_: any, value: any) {
                                  if (
                                    value == 0 &&
                                    form.getFieldValue(['purchaseBuyerType']) ==
                                      PurchaseBuyerType.SELF
                                  )
                                    return Promise.resolve();

                                  if (!value) {
                                    return Promise.reject('Please input valid Rate!');
                                  }
                                  if (value == 0) return Promise.reject('Please Input valid Rate!');
                                  return Promise.resolve();
                                }
                              })
                            ]}>
                            <InputNumber min={0} onChange={() => onUnitandQuantityChange(name2)} />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Discount"
                            name={[name2, 'discount']}
                            rules={[{ required: true, message: 'Please add Discount!' }]}>
                            <InputNumber min={0} onChange={() => onUnitandQuantityChange(name2)} />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="Misc"
                            name={[name2, 'misc']}
                            rules={[{ required: true, message: 'Please add Misc!' }]}>
                            <InputNumber min={0} onChange={() => onUnitandQuantityChange(name2)} />
                          </Form.Item>
                          <Form.Item
                            {...restField2}
                            label="VAT"
                            name={[name2, 'vat']}
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (value !== null) {
                                    return Promise.resolve();
                                  } else {
                                    form.setFieldValue(['lines', name2, 'vat'], 0);
                                    return Promise.resolve();
                                  }
                                }
                              })
                            ]}>
                            <InputNumber
                              min={0}
                              style={{ color: 'black' }}
                              onChange={() => onVatChange()}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                            <InputNumber
                              min={0}
                              disabled
                              // className="font-bold"
                              style={{ color: 'black' }}
                            />
                          </Form.Item>
                          <Form.Item {...restField2} label="HS Code" name={[name2, 'hsCode']}>
                            <Input disabled />
                          </Form.Item>
                          {/* <div className="flex justify-center items-center"> */}
                          {fields2.length > 0 ? (
                            // <MinusCircleOutlined
                            //   className="dynamic-delete-button flex justify-center items-center"
                            //   onClick={() => remove2(name2)}
                            // />
                            <div className="flex items-center justify-start mt-5">
                              <CustomButton
                                backgroundColor="white"
                                text="Remove"
                                textColor="green"
                                onClick={() => remove2(name2)}
                              />
                            </div>
                          ) : null}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Card>
                {/* {fields2.length > 0 ? (
                  <Card
                    style={{ borderRadius: '10px', marginBottom: '10px' }}
                    className="grid grid-cols-2">
                    <Form.Item name={['grandtotal']} label="Grand Total">
                      <InputNumber min={0} disabled style={{ color: 'black' }} />
                    </Form.Item>
                  </Card>
                ) : null} */}
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total">
                <InputNumber min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}

          <Divider />
          <Checkbox
            onChange={async (value) => {
              setHasRule(true);
              if (value.target.checked) await checkAccountRule(form.getFieldValue(['locationId']));
              setIsFinalize(value.target.checked);
            }}>
            Finalize and Close?
          </Checkbox>
          <div className="flex justify-end mt-5 gap-5">
            <Form.Item>
              <CustomSubmitButton
                text="Submit"
                disabled={!hasRule || isLoading || isAccountArchived}
              />
            </Form.Item>
          </div>
        </Form>
        <Divider />
        <ReuseChannel
          slug={`purchase_order_${id}`}
          withReferenceandId={{ reference: 'purchase_order', referenceId: id || 0 }}
          fromSellorPurchaseId={userIdforchannel}
        />
      </AppContent>
    </Spin>
  );
};

export default PurchaseOrderDetails;
