import {
  Button,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
  message
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContent from '../../../components/Common/Content/Content';
import moment from 'moment';
import { API_URL, JWT_TOKEN } from '../../../constants/config';
import { getLocalStorage } from '../../../utils/storage.utils';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { ICreateDailyRate } from '../../../services/daily-rate/types';
import { create_daily_rate_mutation } from '../../../services/daily-rate/mutation';
import { useMutation } from '@tanstack/react-query';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';

const CreateDailyRate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const breadcrumbItems = [
    {
      label: 'Daily Rate',
      link: '/daily-rate'
    },
    {
      label: 'New',
      link: '/daily-rate/new'
    }
  ];

  const uploadButton = (
    <div className="rounded-full">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const updatedFileId = newFileList[0]?.response?.id;
    if (updatedFileId) {
      form.setFieldValue('mediaId', updatedFileId);
    }
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadProps: UploadProps = {
    name: 'media',
    multiple: false,
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: onPreview,
    onChange: onChange
  };

  const createDailyRateMutation = useMutation(create_daily_rate_mutation);

  const onFinish = async (values: ICreateDailyRate) => {
    setIsLoading(true);
    //console.log('Values', values);
    try {
      values.date = convertUTCStringtoLocalString(values.date, 'YYYY-MM-DD');
      await createDailyRateMutation.mutateAsync(values, {
        onSuccess: async ({ data }) => {
          if (data) {
            setIsLoading(false);
            message.success('Daily Rate added successfully');
            navigate('/daily-rate');
          }
        },
        onError: (e: any) => {
          setIsLoading(false);
          message.error(`${e.response.data.message}`, 5);
        }
      });
    } catch (err: any) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={false}>
        <Form
          form={form}
          initialValues={{
            date: moment()
          }}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Daily Rate Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-2 gap-5 mb-5'}>
            <div className="col-span-1">
              <Form.Item
                name={['date']}
                label="Date"
                rules={[
                  {
                    required: true,
                    message: 'Please choose date!'
                  }
                ]}>
                <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} allowClear={false} />
              </Form.Item>
            </div>
            <div className="col-span-2">
              <p>Upload an image</p>
              <Upload className="rounded-full" {...uploadProps}>
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
            </div>
            <Form.Item
              label="Image"
              name="mediaId"
              hidden
              rules={[{ required: false, message: 'Please upload image!' }]}>
              <Input />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
            <Button
              type="default"
              className="ml-5"
              htmlType="button"
              onClick={() => navigate('/daily-rate')}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default CreateDailyRate;
