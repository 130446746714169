import { find_date_preference } from '@/store/localstorage/preferences';
import { ExtendedInitialValues } from '.';
import { WithReportPaginationProps } from '@/components/Common/Report/withReportPagination';
import { Button, DatePicker, Form, Input, InputNumber, PageHeader } from 'antd';
import { CustomDatePresets } from '../utils/datePresets';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import VehiclesSearch from '@/components/Common/VehiclesSearch/v2';

interface Props
  extends Omit<WithReportPaginationProps, 'onPagination' | 'getConstraint' | 'pagination'> {
  isLoading: boolean;
  initialValues: ExtendedInitialValues;
  onSearch: (values: ExtendedInitialValues) => void;
  onDownload: () => void;
}

function FilterForm({ isLoading, form, initialValues, ...handler }: Props) {
  const nepaliType = find_date_preference();
  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      disabled={isLoading}
      validateTrigger="onChange"
      initialValues={initialValues}
      onFinish={handler.onSearch}>
      <PageHeader subTitle="Constraints" style={{ padding: '0px 0px' }} />

      <div className="grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-3">
        <Form.Item label={'page'} name={['constraints', 'page']} hidden>
          <InputNumber />
        </Form.Item>

        <Form.Item label={'size'} name={['constraints', 'size']} hidden>
          <InputNumber />
        </Form.Item>

        <VehiclesSearch
          formData={{ formName: ['constraints', 'vehicleId'], label: 'Vehicle' }}
          isAll
        />

        <Form.Item
          label={'Pick a date'}
          name={['constraints', 'dateCustom']}
          rules={[{ required: true, message: 'Please select date!' }]}
          hidden={nepaliType}>
          <DatePicker.RangePicker
            showTime
            ranges={CustomDatePresets}
            disabledDate={handler.onDisabledDate}
            onChange={handler.onChange}
            format={'YYYY-MM-DD HH:mm'}
            allowClear={false}
          />
        </Form.Item>

        <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            onChange={(val) => handler.onChangeNepali(val, true)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
          <NepaliDatePicker
            onChange={(val) => handler.onChangeNepali(val, false)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
          />
        </Form.Item>

        {/* // Buttons for submitting and downloading the form */}
        <div className="flex justify-start mt-7 gap-1">
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ borderRadius: '6px' }}>
              Search
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              className="secondary-button"
              style={{ borderRadius: '6px' }}
              onClick={handler.onDownload}>
              Download
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

export default FilterForm;
