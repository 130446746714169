import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, message, Modal, PageHeader, Spin, InputNumber } from 'antd';

import AppContent from '@/components/Common/Content/Content';
import { ICustomerDiscount, ICustomerDiscountFormData } from '@/services/offfers/types';
import {
  create_customer_discount_mutation,
  update_customer_discount_mutation
} from '@/services/offfers/mutations';

interface Props {
  isParentLoading?: boolean;
  data?: ICustomerDiscount;
  type: 'create' | 'update';
  breadcrumbItems: { label: string; link: string }[];
}

function CategoryDiscountUI({ breadcrumbItems, isParentLoading = false, type, data }: Props) {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm<ICustomerDiscountFormData>();

  useEffect(() => {
    if (type === 'update' && data) {
      form.setFieldsValue({
        name: data.name,
        min: data.min,
        max: data.max,
        discountPercent: data.discount
      });
    }
  }, [data]);

  const createMutation = useMutation(create_customer_discount_mutation, {
    onSuccess: async () => {
      message.success('Customer Range Discount Created Successfully.');
      navigate('/offer/customer-discount');
    },
    onError: () => {
      message.error('Failed to create customer range discount');
    }
  });

  const updateMutation = useMutation(update_customer_discount_mutation, {
    onSuccess: async () => {
      message.success('Customer Range Discount updated Successfully.');
      navigate('/offer/customer-discount');
    },
    onError: () => {
      message.error('Failed to update customer range discount');
    }
  });

  const onSubmit = async () => {
    try {
      setIsloading(true);
      const payload = form.getFieldsValue();

      if (type === 'create') {
        await createMutation.mutate(payload);
      }

      if (type === 'update' && data) {
        const payloadWithId = { ...payload, id: data.id };
        await updateMutation.mutate(payloadWithId);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
      setShowModal(false);
    }
  };

  const onFinalizeClick = async () => {
    await form.validateFields();
    setShowModal(true);
  };

  return (
    <Spin spinning={isLoading || isParentLoading}>
      <Modal
        title="Confirmation"
        visible={showModal}
        onOk={onSubmit}
        onCancel={() => setShowModal(false)}>
        <div>Are you sure you want to {type} this discount?</div>
      </Modal>

      <AppContent breadcrumbItems={breadcrumbItems}>
        <Form
          form={form}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader subTitle="Information" style={{ padding: '8px 0px' }} />

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please add name!' }]}>
              <Input />
            </Form.Item>
          </div>

          <PageHeader subTitle="Customer Range Discount" style={{ padding: '8px 0px' }} />
          <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-5'}>
            <Form.Item
              label="Discount"
              name="discountPercent"
              rules={[{ required: true, message: 'Please provide discount percentage' }]}>
              <InputNumber addonAfter="%" className="w-full" min={0} max={100} />
            </Form.Item>

            <Form.Item
              label="Minimum Amount"
              name="min"
              rules={[{ required: true, message: 'Please provide minimum amount' }]}>
              <InputNumber addonBefore="Rs" className="w-full" min={0} />
            </Form.Item>

            <Form.Item
              label="Maximum Amount"
              name="max"
              rules={[{ required: true, message: 'Please provide maximum amount' }]}>
              <InputNumber addonBefore="Rs" className="w-full" min={0} />
            </Form.Item>
          </div>

          <div className="flex justify-end mt-5 gap-4 flex-wrap">
            <Button type="primary" loading={isLoading} onClick={onFinalizeClick}>
              Finalize
            </Button>

            <Button
              type="default"
              htmlType="button"
              onClick={() => navigate(breadcrumbItems[0].link)}>
              Cancel
            </Button>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
}

export default CategoryDiscountUI;
