import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  SelectProps
} from 'antd';
import ChartFeaturesForm from '../ChartFeaturesForm';
import MiscellaneousFeaturesForm from '../MiscellaneousFeature';
import { useDashboardCustomize } from '@/contexts/dashboard-customize.context';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { IReportWithFields } from '@/services/report/types';
import { IChartType, IWidgetConfiguration } from '@/services/dashboard/v3/types';
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_ENLARGE,
  DEFAULT_GRADIENT,
  DEFAULT_SHOW_FILTER,
  DEFAULT_SHOW_ZOOM
} from '../../../constant.dashboard';

import { ChildComponentRef, FormValues } from './WidgetCustomizeModalv2';
import getPascalCase from '@/utils/getPascalCase';
import { WidgetType } from '@/services/dashboard/v3/enums';

interface Props {
  form: FormInstance;
  selectedWidget: { id: string; position: number; isCustom: boolean } | null;
}

function MultipleCustomizeUI({ form, selectedWidget }: Props, ref: React.Ref<ChildComponentRef>) {
  const { reportLists, editingWidgets } = useDashboardCustomize();
  const [allReportLists, setAllReportLists] = useState<IReportWithFields[]>([]);

  const [selectedReports, setSelectedReports] = useState<IReportWithFields[]>([]);
  const [selectedChartType, setSelectedChartType] = useState<IChartType | undefined>(undefined);

  const [possibleDatasets, setPossibleDatasets] = useState<string[][]>([]);
  const [possiblePlotAgainsts, setPossiblePlotAgainsts] = useState<string[][]>([]);

  useImperativeHandle(ref, () => ({ onSubmit }));

  useEffect(() => {
    // Filter reportLists which has no date fields
    const updatedLists = reportLists.filter((report) => {
      return Object.keys(report.fields).some((field) => report.fields[field] === 'date');
    });

    setAllReportLists(updatedLists);
  }, [reportLists]);

  useEffect(() => {
    if (!selectedWidget || selectedWidget.isCustom) {
      form.resetFields();
      form.setFieldsValue({
        selectedWidget: '',
        title: '',
        showFilter: DEFAULT_SHOW_FILTER,
        showZoom: DEFAULT_SHOW_ZOOM,
        enlargeChart: DEFAULT_ENLARGE,
        isMultiple: true
      });
      return;
    }

    const selectedWidgetConfig = editingWidgets[selectedWidget.position];
    const { widgets, ...rest } = selectedWidgetConfig;

    const reportIds = widgets.map((widget) => widget.reportId);
    setSelectedChartType(rest.chartType);
    onSelectWidget(reportIds, false);

    const configs = {
      ...rest,
      selectedWidgets: reportIds,
      widgetPlotConfig: widgets.map((w) => ({ ...w, field: w.field?.[0] }))
    };

    form.setFieldsValue(configs);
  }, [selectedWidget]);

  const handleChartChange: SelectProps['onSelect'] = (value: IChartType) => {
    setSelectedChartType(value);
    form.setFieldsValue({
      pieChartLimit: undefined,
      isGradient: DEFAULT_GRADIENT,
      showZoom: DEFAULT_SHOW_ZOOM,
      showFilter: DEFAULT_SHOW_FILTER,
      isSmooth: value === 'line' ? DEFAULT_SHOW_ZOOM : undefined,
      borderRadius: value === 'bar' ? DEFAULT_BORDER_RADIUS : undefined
    });
  };

  function onSelectWidget(value: number[], changeFormField?: boolean) {
    const selectedReports = reportLists.filter((report) => value.includes(report.id));

    // Sort selected reports based on selected widget
    selectedReports.sort((a, b) => {
      return value.indexOf(a.id) - value.indexOf(b.id);
    });

    setSelectedReports(selectedReports);

    if (value.length === 0) {
      setSelectedChartType(undefined);
      setPossibleDatasets([]);
      setPossiblePlotAgainsts([]);

      form.resetFields();
      form.setFieldValue('isMultiple', true);
      return;
    }

    const possibleDatasets = [] as string[][];
    const possiblePlotAgainsts = [] as string[][];

    message.destroy('chartErrorMessage');

    selectedReports.forEach((report) => {
      const allFields = report.fields;
      const datasets = Object.keys(allFields).filter((field) => allFields[field] === 'number');
      const plotAgaints = Object.keys(allFields).filter((field) => allFields[field] === 'date');

      const errorMessage =
        datasets.length === 0 && plotAgaints.length === 0
          ? 'dataset and plot Against'
          : datasets.length === 0
          ? 'dataset'
          : plotAgaints.length === 0
          ? 'plotAgainst'
          : '';

      if (errorMessage) {
        message.error({
          key: 'chartErrorMessage',
          content: `No ${errorMessage} found for ${report.name}`
        });
      }

      possibleDatasets.push(datasets);
      possiblePlotAgainsts.push(plotAgaints);
    });

    setPossibleDatasets(possibleDatasets);
    setPossiblePlotAgainsts(possiblePlotAgainsts);

    const widgetPlotConfig = (form.getFieldValue('widgetPlotConfig') ||
      []) as FormValues['widgetPlotConfig'];

    if (!changeFormField) return;
    form.setFieldsValue({
      selectedWidgets: value,
      enlargeChart: DEFAULT_ENLARGE,
      widgetPlotConfig: selectedReports.map((report) => {
        const config = widgetPlotConfig.find((config) => config.reportId === report.id);
        if (config) return config;
        return { field: '', plotAgainst: '', reportId: report.id, name: report.name };
      })
    });
  }

  async function onSubmit() {
    const values = (await form.validateFields()) as FormValues;
    if (!values.isMultiple) {
      message.error('Please consult the developer! Case: isMultiple = false');
      return;
    }

    // Check selected widget must be atleast 2
    if (values.selectedWidgets.length < 2) {
      message.error('Select atleast 2 widgets. For single widget, use "ADD WIDGET" button.');
      return;
    }

    if (!selectedWidget) {
      message.error('This should not happen!. Please consult the developer!');
      return;
    }

    const widgetConfig: Partial<IWidgetConfiguration> = {
      id: selectedWidget.id,
      chartType: values.chartType,
      title: values.title,
      showFilter: values.showFilter,
      showZoom: values.showZoom,
      type: WidgetType.REPORT,
      isGradient: values.isGradient,
      isSmooth: values.isSmooth,
      borderRadius: values.borderRadius,
      isPlotAgainstDate: true,
      isCustom: false,
      enlargeChart: values.enlargeChart,
      widgets: values.widgetPlotConfig.map((config) => ({
        ...config,
        field: [config.field]
      }))
    };

    return widgetConfig;
  }

  return (
    <div>
      <PageHeader subTitle="Select Multiple Widgets" style={{ padding: '8px 0' }} />
      <Form.Item
        label="Widget"
        name="selectedWidgets"
        rules={[{ required: true, message: 'Widget is required' }]}>
        <Select
          mode="multiple"
          className="w-full"
          placeholder="Select widgets"
          onChange={(value: number[]) => onSelectWidget(value, true)}
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) => {
            const widgetName = option?.label?.toLowerCase() ?? '';
            return widgetName.includes(input.toLowerCase());
          }}
          options={allReportLists.map((widget) => ({ label: widget.name, value: widget.id }))}
        />
      </Form.Item>

      {selectedReports.length > 0 && (
        <div className="mt-2">
          <PageHeader subTitle="Customize Widget" style={{ padding: '8px 0' }} />
          <div className="space-y-2">
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Title is required' }]}>
              <Input placeholder="Title" className="mt-1" />
            </Form.Item>

            <Form.Item
              name="chartType"
              label="Chart Type"
              rules={[{ required: true, message: 'Chart is required!' }]}>
              <Select
                className="w-full"
                placeholder="Select chart type"
                onSelect={handleChartChange}
                dropdownMatchSelectWidth={false}
                options={['line', 'bar'].map((chart) => ({
                  label: getPascalCase(chart),
                  value: chart
                }))}
              />
            </Form.Item>

            {selectedChartType && (
              <div>
                <PageHeader subTitle="Chart Features" style={{ padding: '4px 0' }} />
                <ChartFeaturesForm selectedChartType={selectedChartType} />
              </div>
            )}

            <MiscellaneousFeaturesForm selectedChartType={selectedChartType} />

            <PageHeader
              className="[&_span]:!text-base"
              title="Widget Plot Config"
              style={{ padding: '8px 0 0' }}
            />
            <Form.List name="widgetPlotConfig">
              {(fields) => (
                <>
                  {fields.map((field) => {
                    return (
                      <div key={field.key}>
                        <PageHeader
                          subTitle={selectedReports[field.key].name}
                          style={{ padding: '4px 0px' }}
                        />
                        <Form.Item name={[field.name, 'reportId']} label="Report" hidden>
                          <InputNumber className="w-full" value={selectedReports[field.key].id} />
                        </Form.Item>

                        <Form.Item name={[field.name, 'name']} label="Report" hidden>
                          <Input className="w-full" value={selectedReports[field.key].name} />
                        </Form.Item>

                        <div className="grid grid-cols-2 gap-4">
                          <Form.Item
                            name={[field.name, 'field']}
                            label="Dataset"
                            rules={[{ required: true, message: 'Select dataset to plot' }]}>
                            <Select
                              showSearch
                              className="w-full"
                              optionFilterProp="label"
                              placeholder="Select field"
                              dropdownMatchSelectWidth={false}
                              options={possibleDatasets[field.key].map((field) => ({
                                label: getPascalCase(field),
                                value: field
                              }))}
                            />
                          </Form.Item>

                          <Form.Item
                            name={[field.name, 'plotAgainst']}
                            label="Plot Against"
                            rules={[{ required: true, message: 'Select field to plot against' }]}>
                            <Select
                              showSearch
                              className="w-full"
                              optionFilterProp="label"
                              placeholder="Field to plot against"
                              dropdownMatchSelectWidth={false}
                              options={possiblePlotAgainsts[field.key].map((field) => ({
                                label: `${getPascalCase(field)} `,
                                value: field
                              }))}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>
        </div>
      )}
    </div>
  );
}

export default forwardRef(MultipleCustomizeUI);
