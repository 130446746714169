import { Button, Form, Input, Menu, Spin, TableProps, Tooltip, message } from 'antd';
import { useRef, useState } from 'react';
import AppContent from '@/components/Common/Content/Content';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { convertLocalToUTCString, convertUTCStringtoLocalString } from '@/utils/convertToUTC';
import AccountFilterTable from '@/components/AccountFilterTable';
import moment from 'moment';
import {
  get_account_detail_by_ids,
  get_account_history_ids,
  get_journal_details,
  get_journal_details_ids,
  get_journal_lines_list
} from '@/services/accounts/queries';
import {
  IAccountHistoryResponse,
  IAccountTypeResponseData,
  IJournalLinesListResponse,
  IJournalLinesViewResponseTableData,
  IJournalListResponse
} from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { ConvertObjectToURL } from '@/utils/converturl';
import { getUserData } from '@/utils/auth.utils';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import LocationsDB from '@/store/localstorage/LocationsDB';
import { get_location_list_for_ids } from '@/services/locations/queries';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import { checkAccess } from '@/routes/acl';
import JournalInvoice from '@/components/Common/CustomResuableInvoice/JournalInvoice';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { CustomModal } from '@/components/Common/CustomModal';
import { useReactToPrint } from 'react-to-print';
import { AccountRulesEvent, AccountType, redirectData } from '@/services/accounts/enums';
import { get_payment_details, get_payment_details_ids } from '@/services/payments/queries';
import { PaymentAgainst } from '@/services/payments/enums';
import {
  get_sell_details_ids,
  get_sell_return_details_by_id,
  get_sell_return_details_ids
} from '@/services/sell/queries';
import {
  get_purchase_details_ids,
  get_purchase_return_details_ids,
  get_purchase_reutrn_details_by_id
} from '@/services/purchases/queries';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import CustomTableRowSpan from '@/components/Common/CustomResuableInvoice/CustomTableRowSpan';
import { get_adjustments_details_ids } from '@/services/adjustments/queries';
import { get_expense_details_ids } from '@/services/expense/queries';
import { MultipleAccountsTypeSearch } from '@/components/Common/MultipleAccountsTypeSearch';
import { get_transfer_details_ids, get_transfer_in_details_ids } from '@/services/transfer/queries';
import { ISellFromServer, ISellReturn } from '@/services/sell/types';
import { IAdjustmentList } from '@/services/adjustments/types';
import { IExpenseList } from '@/services/expense/types';
import { IPurchaseList, IPurchaseReturn2 } from '@/services/purchases/types';
import { IPayment } from '@/services/payments/types';
import { ITransfer } from '@/services/transfer/types';
import { optionalDateSorter } from '@/utils/sorter.utils';
import AccountsDB from '@/store/localstorage/AccountsDB';
import getAccountInitialDate from '@/utils/getAccountInitialDate';
import { DEFAULT_DATE_FORMAT } from '@/constants';
import { getSingleHistory } from '@/services/accounts/services';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import AccountSearchV2 from '@/components/Common/CustomSearch/Accounts/AccountSearchV2';

const JournalLinesList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRunningBalance, setIsRunningBalance] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [pageRunningBalance, setPageRunningBalance] = useState<{
    [key: number]: { [key: number]: number };
  }>(
    {} as {
      [key: number]: { [key: number]: number };
    }
  );
  const printPDFRef = useRef<any>();
  const [journalLinesList, setJournalLinesList] = useState<IJournalLinesListResponse>(Object);
  const [lines, setLines] = useState<IJournalLinesViewResponseTableData[]>([]);
  const [exportData, setExportData] = useState<IJournalLinesViewResponseTableData[]>([]);

  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const [locationId, setLocationId] = useState<number>(preferenceLocationId);
  const [openingBalance, setOpeningBalance] = useState<string>('--');
  const [closingBalance, setClosingBalance] = useState<{ [key: number]: number }>(
    {} as { [key: number]: number }
  );
  const [selectedAccount, setSelectedAccount] = useState<string>('--');
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [openModalForPdfExport, setOpenModalForPdfExport] = useState<boolean>(false);
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [total, setTotal] = useState<any>({
    totalCredit: 0,
    totalDebit: 0
  });
  const [openModalForPrint, setOpenModalForPrint] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IJournalLinesViewResponseTableData[]>();

  const breadcrumbItems = [
    {
      label: 'General Ledger',
      link: '/accounts/journal-lines'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    //console.log('filterVal', val);
    setPageRunningBalance(() => {
      return {};
    });
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getTransactionIdForEndDay = (
    tableData: IJournalLinesViewResponseTableData[],
    transDate: string,
    accountId: number
  ) => {
    const targetDate = new Date(transDate);

    const validData = tableData.filter(
      (val) =>
        moment(val.journalDate).format('YYYY-MM-DD') === transDate && val.accountId === accountId
    );

    const maxDateTrans = validData.reduce((max, item) => {
      if (item.journalDate && max.journalDate) {
        const currentDate = new Date(item.journalDate);
        const maxDate = new Date(max.journalDate);

        if (currentDate >= targetDate && currentDate > maxDate) {
          return item;
        } else if (currentDate.getTime() === maxDate.getTime()) {
          const currentCreatedDate = new Date(item.createdAt);
          const maxCreatedDate = new Date(max.createdAt);
          return currentCreatedDate > maxCreatedDate ? item : max;
        } else {
          return max;
        }
      } else {
        return max;
      }
    }, validData[0]);

    return maxDateTrans.id;
  };

  const getInfo = async (filter = '', pageNo = 1, isResetPageRunningBalance = false) => {
    setIsLoading(true);
    setIsRunningBalance(false);
    setSelectedAccount('--');
    const urlParams = new URLSearchParams(filter);
    const params = Object.fromEntries(urlParams);
    const accountIds = urlParams.get('accountId');
    const startDate = urlParams.get('startDate');
    const endDate = urlParams.get('endDate');
    urlParams.append('isAsc', 'true');
    if (accountIds) {
      const accountIdList = accountIds.split(',');

      const updatedParams = accountIdList.map((id) => `accountIds[]=${id}`).join('&');

      urlParams.delete('accountId');
      const updatedURL = `${urlParams.toString()}&${updatedParams}`;
      filter = updatedURL;
    } else {
      urlParams.delete('accountId');
      const updatedURL = `${urlParams.toString()}`;
      filter = updatedURL;
    }
    const mergeValue = new Set();
    const response = await get_journal_lines_list(filter);

    let ctotalCredit = 0,
      ctotalDebit = 0;
    let opBalance = 0;
    if (response) {
      let allJournals: IJournalListResponse = { results: [], total: 0 };
      setJournalLinesList(response);

      if (response.results.length > 0) {
        const Ids = [
          ...new Set(
            response.results.map((value) => {
              return value.journalId;
            })
          )
        ];

        allJournals = await get_journal_details_ids(Ids);
      }

      const referenceByType = {
        sellReferenceIds: [] as number[],
        paymentReferenceIds: [] as number[],
        sellReturnReferenceIds: [] as number[],
        purchaseReferenceIds: [] as number[],
        purchaseReturnReferenceIds: [] as number[],
        expenseReferenceIds: [] as number[],
        adjustmentReferenceIds: [] as number[],
        vatReferenceIds: [] as number[],
        transferFromReferenceIds: [] as number[],
        transferToReferenceIds: [] as number[]
      };

      if (allJournals.results.length > 0) {
        for (let ind = 0; ind < allJournals.results.length; ind++) {
          switch (allJournals.results[ind].type) {
            case AccountRulesEvent.SELL_CREATE:
              {
                referenceByType.sellReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.SELL_PAYMENT_CASH:
            case AccountRulesEvent.SELL_PAYMENT_BANK:
            case AccountRulesEvent.SELL_PAYMENT_OTHER:
            case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
            case AccountRulesEvent.PURCHASE_PAYMENT_USER:
            case AccountRulesEvent.PURCHASE_PAYMENT_OTHER:
              {
                referenceByType.paymentReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.SELL_RETURN:
              {
                referenceByType.sellReturnReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.PURCHASE_CREATE:
              {
                referenceByType.purchaseReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.PURCHASE_RETURN:
              {
                referenceByType.purchaseReturnReferenceIds.push(
                  allJournals.results[ind].referenceId
                );
              }
              break;
            case AccountRulesEvent.EXPENSE_CREATE:
              {
                referenceByType.expenseReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.ADJUSTMENT_CREATE:
              {
                referenceByType.adjustmentReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.VAT_CREATE:
              {
                referenceByType.vatReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.TRANSFER_FROM:
              {
                referenceByType.transferFromReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            case AccountRulesEvent.TRANSFER_TO:
              {
                referenceByType.transferToReferenceIds.push(allJournals.results[ind].referenceId);
              }
              break;
            default:
              break;
          }
        }

        const apiPromises = {
          sell:
            referenceByType.sellReferenceIds.length > 0
              ? get_sell_details_ids(referenceByType.sellReferenceIds)
              : null,
          sellReturn:
            referenceByType.sellReturnReferenceIds.length > 0
              ? get_sell_return_details_ids(referenceByType.sellReturnReferenceIds)
              : null,
          adjustment:
            referenceByType.adjustmentReferenceIds.length > 0
              ? get_adjustments_details_ids(referenceByType.adjustmentReferenceIds)
              : null,
          expense:
            referenceByType.expenseReferenceIds.length > 0
              ? get_expense_details_ids(referenceByType.expenseReferenceIds)
              : null,
          purchase:
            referenceByType.purchaseReferenceIds.length > 0
              ? get_purchase_details_ids(referenceByType.purchaseReferenceIds)
              : null,
          purchaseReturn:
            referenceByType.purchaseReturnReferenceIds.length > 0
              ? get_purchase_return_details_ids(referenceByType.purchaseReturnReferenceIds)
              : null,
          payment:
            referenceByType.paymentReferenceIds.length > 0
              ? get_payment_details_ids(referenceByType.paymentReferenceIds)
              : null,
          vat:
            referenceByType.vatReferenceIds.length > 0
              ? get_sell_details_ids(referenceByType.vatReferenceIds)
              : null,
          transferFrom:
            referenceByType.transferFromReferenceIds.length > 0
              ? get_transfer_details_ids(referenceByType.transferFromReferenceIds)
              : null,
          transferTo:
            referenceByType.transferToReferenceIds.length > 0
              ? get_transfer_in_details_ids(referenceByType.transferToReferenceIds)
              : null
        };

        const filteredApiPromises = Object.fromEntries(
          Object.entries(apiPromises).filter(([key, value]) => value !== null)
        );

        const allApiResults = await Promise.all(Object.values(filteredApiPromises));

        Object.entries(filteredApiPromises).forEach(([key, promise], index) => {
          const result = allApiResults[index];

          if (result !== null) {
            switch (key) {
              case 'sell':
                if ('results' in result && result.results.length > 0) {
                  const sellResults = result.results as ISellFromServer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const sellResult = sellResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.SELL_CREATE
                    );

                    if (sellResult && 'financialReference' in sellResult) {
                      allJournals.results[ind].refNumber = sellResult.financialReference;
                    }
                  }
                }
                break;
              case 'sellReturn':
                if ('results' in result && result.results.length > 0) {
                  const sellReturnResults = result.results as ISellReturn[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const sellReturnResult = sellReturnResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.SELL_RETURN
                    );

                    if (sellReturnResult && 'financialReference' in sellReturnResult) {
                      allJournals.results[ind].refNumber = sellReturnResult.financialReference;
                    }
                  }
                }
                break;
              case 'adjustment':
                if ('results' in result && result.results.length > 0) {
                  const adjustmentResults = result.results as IAdjustmentList[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const adjustmentResult = adjustmentResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.ADJUSTMENT_CREATE
                    );

                    if (adjustmentResult && 'referenceNumber' in adjustmentResult) {
                      allJournals.results[ind].refNumber = adjustmentResult.referenceNumber;
                    }
                  }
                }
                break;
              case 'expense':
                if ('results' in result && result.results.length > 0) {
                  const expenseResults = result.results as IExpenseList[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const expenseResult = expenseResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.EXPENSE_CREATE
                    );

                    if (expenseResult && 'referenceNumber' in expenseResult) {
                      allJournals.results[ind].refNumber = expenseResult.referenceNumber;
                    }
                  }
                }
                break;
              case 'purchase':
                if ('results' in result && result.results.length > 0) {
                  const purchaseResults = result.results as IPurchaseList[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const purchaseResult = purchaseResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.PURCHASE_CREATE
                    );

                    if (purchaseResult && 'financialReference' in purchaseResult) {
                      allJournals.results[ind].refNumber = purchaseResult.financialReference;
                    }
                  }
                }
                break;
              case 'purchaseReturn':
                if ('results' in result && result.results.length > 0) {
                  const purchaseReturnResults = result.results as IPurchaseReturn2[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const purchaseReturnResult = purchaseReturnResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.PURCHASE_RETURN
                    );

                    if (purchaseReturnResult && 'financialReference' in purchaseReturnResult) {
                      allJournals.results[ind].refNumber = purchaseReturnResult.financialReference;
                    }
                  }
                }
                break;
              case 'payment':
                if ('results' in result && result.results.length > 0) {
                  const paymentResults = result.results as IPayment[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const paymentResult = paymentResults.find((res) => {
                      if (
                        res.id === allJournals.results[ind].referenceId &&
                        (allJournals.results[ind].type === AccountRulesEvent.SELL_PAYMENT_CASH ||
                          allJournals.results[ind].type === AccountRulesEvent.SELL_PAYMENT_BANK ||
                          allJournals.results[ind].type === AccountRulesEvent.SELL_PAYMENT_OTHER ||
                          allJournals.results[ind].type ===
                            AccountRulesEvent.PURCHASE_PAYMENT_CASH ||
                          allJournals.results[ind].type ===
                            AccountRulesEvent.PURCHASE_PAYMENT_USER ||
                          allJournals.results[ind].type ===
                            AccountRulesEvent.PURCHASE_PAYMENT_OTHER)
                      ) {
                        return true;
                      }
                      return false;
                    });

                    if (paymentResult && 'reference' in paymentResult) {
                      allJournals.results[ind].refNumber = paymentResult.reference;
                    }
                  }
                }
                break;
              case 'vat':
                if ('results' in result && result.results.length > 0) {
                  const vatResults = result.results as ISellFromServer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const vatResult = vatResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.VAT_CREATE
                    );

                    if (vatResult && 'financialReference' in vatResult) {
                      allJournals.results[ind].refNumber = vatResult.financialReference;
                    }
                  }
                }
                break;
              case 'transferFrom':
                if ('results' in result && result.results.length > 0) {
                  const transferFromResults = result.results as ITransfer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const transferFromResult = transferFromResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.TRANSFER_FROM
                    );

                    if (transferFromResult && 'referenceNo' in transferFromResult) {
                      allJournals.results[ind].refNumber = transferFromResult.referenceNo;
                    }
                  }
                }
                break;
              case 'transferTo':
                if ('results' in result && result.results.length > 0) {
                  const transferToResults = result.results as ITransfer[];
                  for (let ind = 0; ind < allJournals.results.length; ind++) {
                    const transferToResult = transferToResults.find(
                      (res) =>
                        res.id === allJournals.results[ind].referenceId &&
                        allJournals.results[ind].type === AccountRulesEvent.TRANSFER_TO
                    );

                    if (transferToResult && 'referenceNo' in transferToResult) {
                      allJournals.results[ind].refNumber = transferToResult.referenceNo;
                    }
                  }
                }
                break;
              default:
                break;
            }
          }
        });
      }

      let tableData: IJournalLinesViewResponseTableData[] = [];
      let allAccountsHistory: IAccountHistoryResponse[];
      const runningBalanceObj: { [key: number]: number } = {} as {
        [key: number]: number;
      };
      if ('accountId' in params) {
        // Fetch All Accounts For Balance
        const allAccountIds = [
          ...new Set(
            allJournals.results.flatMap((value) => {
              const accountIds = value.lines.map((line) => {
                return line.accountId;
              });

              return accountIds;
            })
          )
        ];

        const allAccounts = await get_account_detail_by_ids(allAccountIds);

        // Fetch All Accounts History
        if (allAccountIds.length > 0) {
          allAccountsHistory = await get_account_history_ids(startDate, endDate, allAccountIds);
        } else {
          allAccountsHistory = [];
        }

        for (let ind = 0; ind < allJournals.results.length; ind++) {
          for (let j = 0; j < allJournals.results[ind].lines.length; j++) {
            let filteredAcc;
            if (allJournals.results[ind].lines[j].accountId !== 0) {
              filteredAcc = allAccounts.results.find(
                (val) => val.id === allJournals.results[ind].lines[j].accountId
              );
            }
            let locationDetails;
            if (allJournals.results[ind].lines[j].locationId !== null) {
              locationDetails = await LocationsDB.getLocation(response.results[ind].locationId);
              if (!locationDetails) {
                const allLocations = await get_location_list_for_ids([
                  ...new Set(
                    allJournals.results[ind].lines
                      .filter((value) => value.locationId !== null)
                      .map((value) => {
                        return value.locationId;
                      })
                  )
                ]);
                await LocationsDB.addLocations(allLocations);
                locationDetails = await LocationsDB.getLocation(
                  allJournals.results[ind].lines[j].locationId
                );
              }
            }

            if (
              filteredAcc?.type === AccountType.TRANSFER_INITIATE ||
              filteredAcc?.type === AccountType.TRANSFER_RECEIVE
            ) {
              let refLocation = await LocationsDB.getLocation(filteredAcc.referenceId);
              if (!refLocation) {
                const allLocations = await get_location_list_for_ids([
                  ...new Set(
                    allAccounts.results
                      .filter(
                        (value) =>
                          value.type === AccountType.TRANSFER_INITIATE ||
                          value.type === AccountType.TRANSFER_RECEIVE
                      )
                      .map((value) => {
                        return value.referenceId;
                      })
                  )
                ]);
                await LocationsDB.addLocations(allLocations);
                refLocation = await LocationsDB.getLocation(filteredAcc.referenceId);
              }
              if (locationDetails) {
                locationDetails.name = `${locationDetails.name} >>> ${refLocation.name}`;
              }
            }

            tableData.push({
              ...allJournals.results[ind].lines[j],
              accountName: typeof filteredAcc === 'object' ? filteredAcc?.name : '',
              accountBalance: typeof filteredAcc === 'object' ? filteredAcc?.balance : 0,
              runningBalance: 0,
              journalName: allJournals.results[ind].description,
              journalReferenceId: allJournals.results[ind].referenceId,
              refNumber: allJournals.results[ind].refNumber,
              locationName: typeof locationDetails === 'object' ? locationDetails.name : '',
              ledgerType: allJournals.results[ind].type
            });

            ctotalCredit += allJournals.results[ind].lines[j].credit;
            ctotalDebit += allJournals.results[ind].lines[j].debit;
          }
        }
      } else {
        // Fetch All Accounts For Balance
        const allAccountIds = [
          ...new Set(
            response.results.map((value) => {
              return value.accountId;
            })
          )
        ];
        const allAccounts = await get_account_detail_by_ids(allAccountIds);
        // Fetch All Accounts History
        if (allAccountIds.length > 0) {
          allAccountsHistory = await get_account_history_ids(startDate, endDate, allAccountIds);
        } else {
          allAccountsHistory = [];
        }
        // console.log('allAccountsHistory', allAccountsHistory);

        for (let ind = 0; ind < response.results.length; ind++) {
          let filteredAcc: IAccountTypeResponseData | undefined;
          if (response.results[ind].accountId !== 0) {
            filteredAcc = allAccounts.results.find(
              (val) => val.id === response.results[ind].accountId
            );
          }

          let locationDetails;
          if (response.results[ind].locationId !== null) {
            locationDetails = await LocationsDB.getLocation(response.results[ind].locationId);
            if (!locationDetails) {
              const allLocations = await get_location_list_for_ids([
                ...new Set(
                  response.results
                    .filter((value) => value.locationId !== null)
                    .map((value) => {
                      return value.locationId;
                    })
                )
              ]);
              await LocationsDB.addLocations(allLocations);
              locationDetails = await LocationsDB.getLocation(response.results[ind].locationId);
            }
          }
          const journal = allJournals.results.find(
            (journal) => journal.id == response.results[ind].journalId
          );

          if (
            filteredAcc?.type === AccountType.TRANSFER_INITIATE ||
            filteredAcc?.type === AccountType.TRANSFER_RECEIVE
          ) {
            let refLocation = await LocationsDB.getLocation(filteredAcc.referenceId);
            if (!refLocation) {
              const allLocations = await get_location_list_for_ids([
                ...new Set(
                  allAccounts.results
                    .filter(
                      (value) =>
                        value.type === AccountType.TRANSFER_INITIATE ||
                        value.type === AccountType.TRANSFER_RECEIVE
                    )
                    .map((value) => {
                      return value.referenceId;
                    })
                )
              ]);
              await LocationsDB.addLocations(allLocations);
              refLocation = await LocationsDB.getLocation(filteredAcc.referenceId);
            }
            if (locationDetails) {
              locationDetails.name = `${locationDetails.name} >>> ${refLocation.name}`;
            }
          }

          tableData.push({
            ...response.results[ind],
            accountName: typeof filteredAcc === 'object' ? filteredAcc?.name : '',
            accountBalance: typeof filteredAcc === 'object' ? filteredAcc?.balance : 0,
            runningBalance: 0,
            journalName: journal?.description,
            journalReferenceId: journal?.referenceId,
            refNumber: journal?.refNumber,
            locationName: typeof locationDetails === 'object' ? locationDetails.name : '',
            ledgerType: journal?.type
          });

          ctotalCredit += response.results[ind].credit;
          ctotalDebit += response.results[ind].debit;
        }
      }

      if (accountIds && tableData.length > 0) {
        setIsRunningBalance(true);

        const currentFinancialStart = searchParams.get('fsd') as string;

        // Get 1 day before current financial start
        const currentFinancialHistoryDate = moment(currentFinancialStart)
          .subtract(1, 'days')
          .format('YYYY-MM-DD');

        let selectedAcc = await AccountsDB.getAccount(parseInt(accountIds));
        if (!selectedAcc) {
          const allAcc = await get_account_detail_by_ids([parseInt(accountIds)]);
          await AccountsDB.addAccounts(allAcc.results);
          selectedAcc = await AccountsDB.getAccount(parseInt(accountIds));
        }
        setSelectedAccount(typeof selectedAcc === 'object' ? selectedAcc.name : '--');

        // For Next
        let filterNext = '';
        const urlParamsNext = new URLSearchParams(filter);
        const accountIdsNext = urlParamsNext.get('accountId');
        const count = urlParamsNext.get('count');
        if (count) urlParamsNext.set('skip', count);
        urlParamsNext.set('count', '20');
        if (accountIdsNext) {
          const accountIdList = accountIdsNext.split(',');
          const updatedParams = accountIdList.map((id) => `accountIds[]=${id}`).join('&');
          urlParamsNext.delete('accountId');
          const updatedURL = `${urlParamsNext.toString()}&${updatedParams}`;
          filterNext = updatedURL;
        } else {
          urlParamsNext.delete('accountId');
          const updatedURL = `${urlParamsNext.toString()}`;
          filterNext = updatedURL;
        }
        const responseNext = await get_journal_lines_list(filterNext);
        tableData.sort((a, b) => optionalDateSorter(a.journalDate, b.journalDate));

        // Op Bal
        const opBalStart = tableData.find(
          (val) => val.accountId === parseInt(accountIds)
        )?.journalDate;

        let opBalDate = moment(opBalStart ? opBalStart : tableData[0].journalDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD');

        // use currentfinancial if opBalDate less than currentfinancial
        if (moment(opBalDate).isBefore(currentFinancialStart)) {
          opBalDate = currentFinancialHistoryDate;
        }

        if (pageNo === 1) {
          if (
            allAccountsHistory.find(
              (val) => val.date === opBalDate && val.accountId === parseInt(accountIds)
            )
          ) {
            const allHistory = allAccountsHistory.filter(
              (val) => val.date === opBalDate && val.accountId === parseInt(accountIds)
            );

            const selectedOpBal = getSingleHistory(allHistory, opBalDate);
            if (selectedOpBal) opBalance = selectedOpBal.balance;
          } else {
            const selectedOpBal = allAccountsHistory
              .filter((val) => val.accountId === parseInt(accountIds))
              .find((val) => val.date < opBalDate && val.date >= currentFinancialStart);
            if (selectedOpBal) opBalance = selectedOpBal.balance;
          }

          if (
            !allAccountsHistory.find(
              (val) => val.date < opBalDate && val.accountId === parseInt(accountIds)
            )
          ) {
            opBalance = 0;
          }
        } else {
          opBalance = closingBalance[pageNo - 1];
        }

        // Group and sort
        const groupedData = tableData.reduce<Record<number, IJournalLinesViewResponseTableData[]>>(
          (acc, item) => {
            const key = item.journalId;

            if (!acc[key]) {
              acc[key] = [];
            }

            acc[key].push(item);
            return acc;
          },
          {}
        );

        const sortedKeys = Object.keys(groupedData).sort((a, b) => {
          const journalDateA = groupedData[parseInt(a)][0].journalDate;
          const journalDateB = groupedData[parseInt(b)][0].journalDate;
          return optionalDateSorter(journalDateA, journalDateB);
        });

        const sortedGroupedData: [number, IJournalLinesViewResponseTableData[]][] = sortedKeys.map(
          (key) => [parseInt(key), groupedData[parseInt(key)]]
        );

        const pageRunBal = isResetPageRunningBalance ? {} : pageRunningBalance;

        tableData = sortedGroupedData.flatMap((sGroup, index) => {
          let group = sGroup[1];

          if (pageNo === 1 && index === 0) {
            group = group.map((g) => {
              let startTrans = 0;
              const startTransDate = moment(g.journalDate).subtract(1, 'days').format('YYYY-MM-DD');
              if (
                allAccountsHistory.find(
                  (val) => val.date === startTransDate && val.accountId === g.accountId
                )
              ) {
                const selectedTransBal = allAccountsHistory.find(
                  (val) => val.date === startTransDate && val.accountId === g.accountId
                );
                if (selectedTransBal) startTrans = selectedTransBal.balance;
              } else {
                const selectedTransBal = allAccountsHistory
                  .filter((val) => val.accountId === g.accountId)
                  .find((val) => val.date < opBalDate);
                if (selectedTransBal) startTrans = selectedTransBal.balance;
              }

              runningBalanceObj[g.accountId] = startTrans + g.debit - g.credit;
              return { ...g, runningBalance: runningBalanceObj[g.accountId] };
            });

            group = group.map((grp) => {
              const transDate = moment(grp.journalDate).format('YYYY-MM-DD');
              const selectedAccountsHistory = allAccountsHistory.find(
                (val) => val.accountId === grp.accountId && val.date === transDate
              );

              let hasNextSameDate = false;
              if (responseNext.results.length > 0) {
                if (
                  responseNext.results.find(
                    (val) =>
                      moment(val.journalDate).format('YYYY-MM-DD') === transDate &&
                      val.accountId === grp.accountId
                  )
                ) {
                  hasNextSameDate = true;
                }
              }

              if (selectedAccountsHistory && !hasNextSameDate) {
                const transactionId = getTransactionIdForEndDay(
                  tableData,
                  transDate,
                  grp.accountId
                );
                if (transactionId === grp.id) {
                  runningBalanceObj[grp.accountId] = selectedAccountsHistory.balance;
                }
              }
              return { ...grp, runningBalance: runningBalanceObj[grp.accountId] };
            });

            pageRunBal[pageNo] = runningBalanceObj;
          } else {
            if (pageRunBal[pageNo] !== undefined && pageRunBal[pageNo] !== null) {
              group = group.map((g) => {
                if (
                  pageRunBal[pageNo][g.accountId] !== undefined &&
                  pageRunBal[pageNo][g.accountId] !== null
                ) {
                  runningBalanceObj[g.accountId] =
                    pageRunBal[pageNo][g.accountId] + g.debit - g.credit;
                } else {
                  let found = false;
                  for (let ind = pageNo - 1; ind > 0; ind--) {
                    if (pageRunBal[ind][g.accountId]) {
                      runningBalanceObj[g.accountId] =
                        pageRunBal[ind][g.accountId] + g.debit - g.credit;
                      found = true;
                      break;
                    }
                  }
                  if (!found) {
                    const accBalance = allAccountsHistory.find(
                      (val) => val.accountId === g.accountId
                    )?.balance;
                    runningBalanceObj[g.accountId] = accBalance
                      ? accBalance + g.debit - g.credit
                      : 0;
                  }
                }
                return { ...g, runningBalance: runningBalanceObj[g.accountId] };
              });
            } else {
              group = group.map((g) => {
                let found = false;
                for (let ind = pageNo - 1; ind > 0; ind--) {
                  if (
                    pageRunBal[ind][g.accountId] !== undefined &&
                    pageRunBal[ind][g.accountId] !== null
                  ) {
                    runningBalanceObj[g.accountId] =
                      pageRunBal[ind][g.accountId] + g.debit - g.credit;
                    found = true;
                    break;
                  }
                }
                if (!found) {
                  const accBalance = allAccountsHistory.find(
                    (val) => val.accountId === g.accountId
                  )?.balance;
                  runningBalanceObj[g.accountId] = accBalance ? accBalance + g.debit - g.credit : 0;
                }
                pageRunBal[pageNo] = runningBalanceObj;
                return { ...g, runningBalance: runningBalanceObj[g.accountId] };
              });
            }

            if (index !== sortedGroupedData.length - 1) {
              group = group.map((grp) => {
                const transDate = moment(grp.journalDate).format('YYYY-MM-DD');
                const selectedAccountsHistory = allAccountsHistory.find(
                  (val) => val.accountId === grp.accountId && val.date === transDate
                );

                let hasNextSameDate = false;
                if (responseNext.results.length > 0) {
                  if (
                    responseNext.results.find(
                      (val) =>
                        moment(val.journalDate).format('YYYY-MM-DD') === transDate &&
                        val.accountId === grp.accountId
                    )
                  ) {
                    hasNextSameDate = true;
                  }
                }

                if (selectedAccountsHistory && !hasNextSameDate) {
                  const transactionId = getTransactionIdForEndDay(
                    tableData,
                    transDate,
                    grp.accountId
                  );
                  if (transactionId === grp.id) {
                    runningBalanceObj[grp.accountId] = selectedAccountsHistory.balance;
                  }
                }
                return { ...grp, runningBalance: runningBalanceObj[grp.accountId] };
              });
            }
            pageRunBal[pageNo] = runningBalanceObj;
          }

          return group;
        });
        setPageRunningBalance((prev) => {
          prev[pageNo] = runningBalanceObj;
          return { ...prev };
        });
      } else {
        // Group and sort
        const groupedData = tableData.reduce<Record<number, IJournalLinesViewResponseTableData[]>>(
          (acc, item) => {
            const key = item.journalId;

            if (!acc[key]) {
              acc[key] = [];
            }

            acc[key].push(item);

            return acc;
          },
          {}
        );

        const sortedKeys = Object.keys(groupedData).sort((a, b) => {
          const journalDateA = groupedData[parseInt(a)][0].journalDate;
          const journalDateB = groupedData[parseInt(b)][0].journalDate;
          return optionalDateSorter(journalDateA, journalDateB);
        });

        const sortedGroupedData: [number, IJournalLinesViewResponseTableData[]][] = sortedKeys.map(
          (key) => [parseInt(key), groupedData[parseInt(key)]]
        );

        tableData = sortedGroupedData.flatMap((group) => {
          return group[1];
        });
      }

      let sn = 0;
      tableData = tableData.map((value) => {
        if (mergeValue.has(`${value.journalId}`)) {
          return { ...value, rows: 0, sn: 0 };
        } else {
          const rowCount = tableData.filter((data) => data.journalId === value.journalId).length;
          mergeValue.add(`${value.journalId}`);
          sn += 1;
          return { ...value, rows: rowCount, sn: sn };
        }
      });

      setOpeningBalance(nepaliNumberFormatter(opBalance));
      setTotal({ totalCredit: ctotalCredit, totalDebit: ctotalDebit });

      const journalWithTransfer = tableData.map((value) => {
        if (value.locationName?.includes('>>>'))
          return { journalId: value.journalId, locationName: value.locationName };
      });

      tableData = tableData.map((value) => {
        if (journalWithTransfer.some((x) => x?.journalId === value.journalId))
          return {
            ...value,
            locationName: journalWithTransfer.find((val) => val?.journalId === value.journalId)
              ?.locationName
          };
        else return { ...value };
      });

      if (accountIds && tableData.length > 0) {
        const filteredByAcc = tableData.filter((val) => val.accountId === parseInt(accountIds));
        const clBalance = filteredByAcc[filteredByAcc.length - 1].runningBalance;
        const openingBalance = nepaliNumberFormatter(opBalance ? opBalance : 0);
        const closingBalance = nepaliNumberFormatter(clBalance ? clBalance : 0);
        setClosingBalance((prev) => {
          prev[pageNo] = clBalance ? clBalance : 0;
          return prev;
        });

        const openingBalanceRowData = {
          accountName: 'Opening Balance',
          runningBalance: parseFloat(openingBalance.replace(/,/g, '')) || '0.0',
          rows: 1,
          createdAt: '',
          isBold: true
        };

        const closingBalanceRowData = {
          accountName: 'Closing Balance',
          runningBalance: parseFloat(closingBalance.replace(/,/g, '')) || '0.0',
          rows: 1,
          createdAt: '',
          isBold: true
        };

        tableData = [
          openingBalanceRowData,
          ...tableData,
          closingBalanceRowData
        ] as IJournalLinesViewResponseTableData[];
      }

      setLines(tableData);
      const dataUpdated = tableData.map((item) => {
        return {
          ...item,
          createdAt: convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT),
          transactionDate: item.journalDate
            ? convertUTCStringtoLocalString(item.journalDate, DEFAULT_DATE_FORMAT)
            : ''
        };
      });
      setExportData(dataUpdated);
    }
    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    let resetPageRunBal = false;
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
      setPageRunningBalance(() => {
        return {};
      });
      resetPageRunBal = true;
      pageNo = 1;
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    await getInfo(url, pageNo, resetPageRunBal);
  };

  const fetchReferenceAndRedirect = async (journalId: number) => {
    setIsLoading(true);
    //redirect
    const response = await get_journal_details(journalId);
    const redirectObj = redirectData.find((val) => val.key === response.type);
    if (redirectObj && response) {
      // set referenceId
      switch (response.type) {
        case AccountRulesEvent.SELL_PAYMENT_CASH:
        case AccountRulesEvent.SELL_PAYMENT_BANK:
        case AccountRulesEvent.SELL_PAYMENT_OTHER: {
          const paymentDetails = await get_payment_details(response.referenceId);
          if (paymentDetails.against === PaymentAgainst.Sell)
            response.redirectId = paymentDetails.againstId;
          break;
        }
        case AccountRulesEvent.SELL_RETURN: {
          const returnDetails = await get_sell_return_details_by_id(response.referenceId);
          response.redirectId = returnDetails.sellId;
          break;
        }
        case AccountRulesEvent.PURCHASE_PAYMENT_CASH:
        case AccountRulesEvent.PURCHASE_PAYMENT_USER:
        case AccountRulesEvent.PURCHASE_PAYMENT_OTHER: {
          const paymentDetails = await get_payment_details(response.referenceId);
          if (paymentDetails.against === PaymentAgainst.Purchase)
            response.redirectId = paymentDetails.againstId;
          break;
        }
        case AccountRulesEvent.PURCHASE_RETURN: {
          const returnDetails = await get_purchase_reutrn_details_by_id(response.referenceId);
          response.redirectId = returnDetails.purchaseId;
          break;
        }
        default: {
          response.redirectId = response.referenceId;
        }
      }
      navigate(`${redirectObj.link}${response.redirectId}`);
    } else {
      setIsLoading(false);
      CustomErrorModal({ message: 'Cannot redirect! Page not found.' });
    }
  };

  const columns: ColumnsType<IJournalLinesViewResponseTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (txt, record) => {
        return <TableCell>{record.sn ? (page - 1) * size + record.sn : ''}</TableCell>;
      }
    },
    {
      title: 'Journal',
      key: 'journalName',
      width: 15,
      rowSpan: 1,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Link to={'/accounts/journal/view/' + record.journalId}>{record.journalName}</Link>
        </TableCell>
      )
    },
    {
      title: 'Account',
      key: 'accountName',
      width: 15,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>{record.accountName}</TableCell>
      )
    },
    {
      title: 'Debit',
      key: 'debit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.debit !== undefined ? nepaliNumberFormatter(record.debit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Credit',
      key: 'credit',
      width: 15,
      render: (a, record) => (
        <TableCell>
          {record.credit !== undefined ? nepaliNumberFormatter(record.credit) : ''}
        </TableCell>
      )
    },
    {
      title: 'Reference',
      key: 'ledgerType',
      width: 20,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => (
        <TableCell>
          <Button
            type="link"
            block
            style={{
              padding: '0px',
              textAlign: 'left',
              wordBreak: 'break-all',
              whiteSpace: 'normal'
            }}
            onClick={async () => await fetchReferenceAndRedirect(record.journalId)}>
            {record.ledgerType}
          </Button>
        </TableCell>
      )
    },
    {
      title: 'Reference Number',
      key: 'refNumber',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => <TableCell>{record.refNumber}</TableCell>
    },
    {
      title: 'Running Balance',
      key: 'runningBalance',
      width: 15,
      render: (a, record) => (
        <TableCell className={record.isBold ? 'font-bold' : ''}>
          {record.runningBalance
            ? nepaliNumberFormatter(record.runningBalance)
            : nepaliNumberFormatter(0)}
        </TableCell>
      )
    },
    {
      title: 'Location',
      key: 'locationName',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>{record.locationName}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Transaction Date',
      key: 'transactionDate',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.journalDate
                ? convertUTCStringtoLocalString(record.journalDate, DEFAULT_DATE_FORMAT)
                : null}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {record.createdAt
                ? convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)
                : ''}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      onCell: (record) => {
        return { rowSpan: record.rows };
      },
      render: (record) => {
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [];
        if (checkAccess('READ_ACCOUNT')) {
          menuItems.push({
            key: '1',
            label: <CustomViewIcon link={'/accounts/journal/view/' + record.journalId} />
          });
        }

        const menu = <Menu items={menuItems} />;

        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const columsforPrint = [
    {
      label: 'Journal',
      dataIndex: 'journalName',
      width: 100,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Account',
      dataIndex: 'accountName',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Debit',
      dataIndex: 'debit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Credit',
      dataIndex: 'credit',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Reference',
      dataIndex: 'ledgerType',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Reference Number',
      dataIndex: 'refNumber',
      width: 100,
      hasRowSpan: false,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Running Balance',
      dataIndex: 'runningBalance',
      width: 100,
      hasRowSpan: false,
      render: (text: number) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Location',
      dataIndex: 'locationName',
      width: 150,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{text}</TableCell>;
      }
    },
    {
      label: 'Transaction Date',
      dataIndex: 'transactionDate',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</TableCell>;
      }
    },
    {
      label: 'Created Date',
      dataIndex: 'createdAt',
      width: 130,
      hasRowSpan: true,
      render: (text: string) => {
        return <TableCell>{convertUTCStringtoLocalString(text, DEFAULT_DATE_FORMAT)}</TableCell>;
      }
    }
  ];

  const handleLocationChange = () => {
    // console.log('locationId', form.getFieldValue(['locationId']));
    form.resetFields(['accountId']);
    setLocationId(form.getFieldValue(['locationId']));
  };

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: `Opening Balance: ${openingBalance}`,
          width: 1500,
          children: [
            {
              title: 'S.N',
              dataIndex: 'sn',
              width: 50
            },
            {
              title: 'Journal',
              dataIndex: 'journalName',
              width: 100
            },
            {
              title: 'Account',
              dataIndex: 'accountName',
              width: 100
            },
            {
              title: 'Running Balance',
              dataIndex: 'runningBalance',
              width: 120
            },
            {
              title: 'Debit',
              dataIndex: 'debit',
              width: 100
            },
            {
              title: 'Credit',
              dataIndex: 'credit',
              width: 100
            },
            {
              title: 'Reference',
              dataIndex: 'ledgerType',
              width: 100
            },
            {
              title: 'Reference Number',
              dataIndex: 'refNumber',
              width: 100
            },
            {
              title: 'Location',
              dataIndex: 'locationName',
              width: 150
            },
            {
              title: 'Transaction Date',
              dataIndex: 'transactionDate',
              width: 100
            },
            {
              title: 'Created Date',
              dataIndex: 'createdAt',
              width: 100
            }
          ]
        }
      ];
      if (lines.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const dataUpdated = lines.map((item) => {
        return {
          ...item,
          sn: item.rows && item.rows > 0 ? item.sn : '',
          journalName: item.rows && item.rows > 0 ? item.journalName : '',
          locationName: item.rows && item.rows > 0 ? item.locationName : '',
          ledgerType: item.rows && item.rows > 0 ? item.ledgerType : '',
          refNumber: item.rows && item.rows > 0 ? item.refNumber : '',
          createdAt:
            item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.createdAt, DEFAULT_DATE_FORMAT)
              : '',
          transactionDate:
            item.journalDate && item.rows && item.rows > 0
              ? convertUTCStringtoLocalString(item.journalDate, DEFAULT_DATE_FORMAT)
              : ''
        };
      });
      exportExcel(columns, dataUpdated, 'Journal Lines');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  return (
    <Spin spinning={isLoading}>
      {modalData && (
        <JournalInvoice
          openModal={openModalForPrint}
          setOpenModal={setOpenModalForPrint}
          modalData={modalData}
        />
      )}
      <CustomModal
        footer={false}
        isModalOpen={openModalForPdfExport}
        setIsModalOpen={setOpenModalForPdfExport}
        title="PDF print">
        <div className="flex justify-end mb-3">
          <Button type="primary" onClick={handlePDFExport}>
            Print Pdf
          </Button>
        </div>
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTableRowSpan
            columns={columsforPrint}
            datas={exportData}
            reff={printPDFRef}
            title={'Accounts Journal'}
          />
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <AccountFilterTable
                defaultValues={{
                  dateCustom: getAccountInitialDate(location.search),
                  value: '',
                  skip: 0,
                  count: 100,
                  locationId: preferenceLocationId ? preferenceLocationId : 1,
                  accountId: accountId ? parseInt(accountId) : undefined
                }}
                initial={false}
                onSearch={onSubmitFilter}
                form={form}
                buttonParentStyle={'flex justify-end items-center'}>
                <LocationSearchV2
                  hasParentFormItem={false}
                  name={'locationId'}
                  showAll
                  onSelect={handleLocationChange}
                />
                <AccountSearchV2
                  name={'accountId'}
                  locationId={locationId}
                  hasParentFormItem={false}
                />
                {/* <MultipleAccountsTypeSearch
                  formData={{ formName: ['accountId'], label: 'Account' }}
                  isAll={false}
                  required={false}
                  locationId={locationId}
                  formMain={form}
                  mode={undefined}
                /> */}
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </AccountFilterTable>
            </div>
            {/* </div> */}
          </>
        }>
        <div className="flex justify-between items-center">
          <div>
            <div className="font-bold text-lg">
              {selectedAccount} : <span>{openingBalance}</span>
            </div>
          </div>
          <div>
            <ActionDropdown
              button={true}
              menu={
                <Menu
                  items={[
                    {
                      key: '1',
                      label: (
                        <Tooltip title="Export Excel" color="blue">
                          <div className="text-center">Excel</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        handleExport();
                      }
                    },
                    {
                      key: '2',
                      label: (
                        <Tooltip title="Export PDF" color="blue">
                          <div className="text-center">PDF</div>
                        </Tooltip>
                      ),
                      onClick: () => {
                        try {
                          if (lines.length === 0) {
                            message.error('No Data to Export');
                            setIsLoading(false);
                            return;
                          }
                          setOpenModalForPdfExport(true);
                        } catch (err: any) {
                          console.log(err);
                        }
                      }
                    }
                  ]}
                />
              }
            />
          </div>
        </div>
        <CustomizeTable
          columns={
            isRunningBalance ? columns : columns.filter((val) => val.key !== 'runningBalance')
          }
          data={lines}
          notshowPagination={true}
          customScroll={{ x: 1750, y: '75vh' }}
          showPager={false}
          paginationDatas={{
            page: page,
            total: journalLinesList.total,
            size: size,
            onPagination,
            scrollToTop: true
          }}
          toSort={handleChange}
          tableName={'journal-lines-list'}
          // tableSummaryForFilter={[
          //   { index: 2, render: <span className="flex mr-2 text-xs">Total</span> },
          //   {
          //     index: 3,
          //     render: (
          //       <span className="flex mr-2 text-xs">{nepaliNumberFormatter(total.totalDebit)}</span>
          //     )
          //   },
          //   { index: 4, render: nepaliNumberFormatter(total.totalCredit) }
          // ]}
          // tableSummary={
          //   <Table.Summary fixed={true}>
          //     <Table.Summary.Row>
          //       <Table.Summary.Cell index={0}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={1}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
          //       <Table.Summary.Cell index={3} className="text-right mr-2 text-xs">
          //         {nepaliNumberFormatter(total.totalDebit)}
          //       </Table.Summary.Cell>
          //       <Table.Summary.Cell index={4} className="text-right mr-2 text-xs">
          //         {nepaliNumberFormatter(total.totalCredit)}
          //       </Table.Summary.Cell>
          //       <Table.Summary.Cell index={5}></Table.Summary.Cell>
          //       <Table.Summary.Cell index={6}></Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   </Table.Summary>
          // }
        />
      </AppContent>
    </Spin>
  );
};

export default JournalLinesList;
