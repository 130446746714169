import { InfoCircleFilled, NotificationOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Badge, Dropdown, Menu, message } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { get_all_notification } from '@/services/notifications/queries';
import {
  IEventType,
  INotifications,
  IReportAddedEventType,
  IReportEventType,
  IReportFailedEventType,
  IVehicleReminderEvent
} from '@/services/notifications/types';
import { update_read_notification } from '@/services/notifications/mutation';
import { useNavigate } from 'react-router-dom';
import { getNotificationLinkAndMessage } from '@/services/notifications/constants';
import { API_URL } from '@/constants/config';
import { SocketEvents } from '@/constants/websocketConfig';
import { WebSocketContext } from '@/contexts/websocket.context';
import { ReportQueueEvents } from '@/services/report/enums';
import { CustomModal } from '@/components/Common/CustomModal';
import { DownloadReportModal } from '@/components/Common/CustomModal/DownloadReportModal';
import { get_image } from '@/components/Common/FetchImage/FetchImage';
import { NotificationEvents } from '@/services/notifications/enums';
import VehicleReminderModal from '@/components/Common/CustomModal/VehicleReminderModal';

const Notifications = () => {
  const [notifications, setNotifications] = useState<INotifications[]>([]);
  const navigate = useNavigate();
  const createReadMutation = useMutation(update_read_notification);
  const [downloadData, setDownloadData] = useState<{ event: IReportEventType; ids: number[] }>(
    {} as { event: IReportEventType; ids: number[] }
  );
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const { socket } = useContext(WebSocketContext);

  const [openVehicleModal, setOpenVehicleModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<INotifications>();

  const closeMessage = () => message.destroy('downloading');

  useEffect(() => {
    if (!socket?.connected) {
      socket?.on('reconnect', refetch);
    }

    socket?.on(SocketEvents.USER_NOTIFICATION, async (data: INotifications) => {
      try {
        if (data.title === ReportQueueEvents.REPORT_QUEUE_COMPLETED) {
          closeMessage();

          const parseData = JSON.parse(data.event as string) as IReportEventType;
          const isReportName = parseData.source.query.customReportQueueName ? true : false;

          message.loading({
            key: 'downloading',
            content: `${
              isReportName ? parseData.source.query.customReportQueueName : 'Report'
            } Downloading`
          });

          const downloadData = await get_image(`${API_URL}storage/media/${parseData.mediaId}`);
          window.open(downloadData, '_blank');
          closeMessage();
          message.success(
            isReportName
              ? `${parseData.source.query.customReportQueueName} Downloaded`
              : 'Report Downloaded Succesfully'
          );
        } else if (data.title === ReportQueueEvents.REPORT_QUEUE_FAILED) {
          throw Error('Failed to generate');
        }
      } catch (e) {
        closeMessage();
        message.error('Something Went Wrong, Please Try Again');
      }
    });

    return () => {
      socket?.off(SocketEvents.USER_NOTIFICATION);
    };
  }, [socket?.connected]);

  const { refetch } = useQuery(['notification'], async () => {
    try {
      const response = await get_all_notification();
      const data = response.data as INotifications[];

      const parseData = await getNotifData(data);
      setNotifications(parseData);
    } catch (e) {
      console.log(e);
      setNotifications([]);
    }
  });

  const getNotifData = async (data: INotifications[]) => {
    const parseData = await Promise.all(
      data.map(async (val) => {
        if (Object.values(ReportQueueEvents).find((value) => value === val.title)) {
          if (val.title === ReportQueueEvents.REPORT_QUEUE_COMPLETED) {
            const JsonEvent = JSON.parse(val.event as string) as IReportEventType;
            if (JsonEvent.source) {
              return {
                ...val,
                header: `${val.title} (${JsonEvent.source.query.customReportQueueName})`,
                event: JsonEvent
              };
            } else {
              return {
                ...val,
                event: JSON.parse(val.event as string) as IEventType
              };
            }
          } else if (val.title === ReportQueueEvents.REPORT_QUEUE_ADDED) {
            const JsonEvent = JSON.parse(val.event as string) as IReportAddedEventType;
            if (JsonEvent.data) {
              return {
                ...val,
                header: `${val.title} (${JsonEvent.data.query.customReportQueueName})`,
                event: JsonEvent
              };
            } else {
              return {
                ...val,
                event: JSON.parse(val.event as string) as IEventType
              };
            }
          } else if (val.title === ReportQueueEvents.REPORT_QUEUE_FAILED) {
            const JsonEvent = JSON.parse(val.event as string) as IReportFailedEventType;
            if (JsonEvent.query) {
              return {
                ...val,
                header: `${val.title} (${JsonEvent.query.customReportQueueName})`,
                event: JsonEvent
              };
            } else {
              return {
                ...val,
                event: JSON.parse(val.event as string) as IEventType
              };
            }
          } else {
            return {
              ...val,
              event: JSON.parse(val.event as string) as IEventType
            };
          }
        } else {
          return {
            ...val,
            event: JSON.parse(val.event as string) as IEventType
          };
        }
      })
    );

    return parseData;
  };

  const viewHandle = async (val: INotifications, info: any) => {
    setSelectedNotification(val);
    if (val.title === ReportQueueEvents.REPORT_QUEUE_COMPLETED) {
      if (typeof val.event === 'object' && 'mediaId' in val.event) {
        const queueId = val.event.source.queueId;
        const allIds = new Set<number>();
        notifications.forEach((value) => {
          if (typeof value.event === 'object' && 'data' in value.event) {
            if (value.event.data.queueId === queueId) allIds.add(value.id);
          }
        });
        setDownloadData({ event: val.event, ids: [val.id, ...allIds] });
      }

      setOpenDownloadModal(true);
    } else if (val.title === ReportQueueEvents.REPORT_QUEUE_FAILED) {
      if (typeof val.event === 'object' && 'queueId' in val.event) {
        const queueId = val.event.queueId;
        const allIds = new Set<number>();
        notifications.forEach((value) => {
          if (typeof value.event === 'object' && 'data' in value.event) {
            if (value.event.data.queueId === queueId) allIds.add(value.id);
          }
        });
        await createReadMutation.mutateAsync([...allIds], {
          onSuccess: async () => {
            setNotifications((prev) => prev.filter((value) => ![...allIds].includes(value.id)));
          }
        });
      }
    } else {
      if (val.title === NotificationEvents.VEHICLE_REMINDERS) {
        setOpenVehicleModal(true);
      }

      await createReadMutation.mutateAsync([val.id], {
        onSuccess: async () => {
          setNotifications((prev) => prev.filter((value) => value.id != val.id));

          if (typeof val.event === 'object') {
            if ('channelId' in val.event) {
              if (val.event?.type == 'MESSAGE_RECEIVED') {
                navigate(`${info.link}/?id=${val.event?.channelId}`);
              } else if (val.event.type == 'LOT_MERGED' || val.event.type == 'LOT_SPLIT') {
                navigate(`${info.link}`);
              } else {
                navigate(`${info.link}/${val.event?.id}`);
              }
            }
          }
        }
      });
    }
  };

  const handleDownloadReportModalClose = async (ids: number[]) => {
    await createReadMutation.mutateAsync([...ids], {
      onSuccess: async () => {
        setNotifications((prev) => prev.filter((value) => !ids.includes(value.id)));
      }
    });

    setOpenDownloadModal(false);
  };

  const menu = (
    <Menu
      items={notifications?.map((notific, index: number) => {
        let info = { link: '', message: '' };
        if (
          typeof notific.event === 'object' &&
          Object.values(ReportQueueEvents).find((value) => value === notific.title)
        ) {
          info.message = notific.header ? notific.header : notific.title;
        } else {
          if (typeof notific.event === 'object') {
            // VEHICLE_REMINDERS
            if (notific.event?.type === NotificationEvents.VEHICLE_REMINDERS) {
              const event = notific.event as IVehicleReminderEvent;
              const totalVehicles = event.notifications.length;
              info.message = `${totalVehicles} ${
                totalVehicles > 1 ? 'Vehicles' : 'Vehicle'
              } Reminder`;
            } else {
              info = getNotificationLinkAndMessage(notific.event.type);
            }
          }
        }

        return {
          key: index,
          // icon: <BellOutlined />,
          label: (
            <div className="flex flex-col py-2">
              <div className="flex gap-2 items-center justify-between px-3">
                {/* <span className="font-bold"> {notific.event.id} </span> */}
                <div>
                  <InfoCircleFilled style={{ color: '#1890ff', transform: 'scale(1.3)' }} />
                </div>
                <div>
                  <div className="font-bold col-span-2">{info?.message}</div>
                  <div className="text-sm text-slate-400 flex flex-row-reverse">
                    ({dayjs(notific.createdAt).format('hh:mm a MM/DD')})
                  </div>
                </div>
              </div>
              {/* <span>{notific.title} </span> */}
            </div>
          ),
          onClick: () => {
            viewHandle(notific, info);
          }
        };
      })}
    />
  );
  return (
    <>
      <CustomModal
        width={'50%'}
        footer={false}
        maskClosable={false}
        isModalOpen={openDownloadModal}
        setIsModalOpen={setOpenDownloadModal}
        isSticky={true}
        title="Download Report">
        <DownloadReportModal
          data={downloadData.event}
          ids={downloadData.ids}
          handleModalClose={handleDownloadReportModalClose}
        />
      </CustomModal>

      <VehicleReminderModal
        isOpen={openVehicleModal}
        setIsOpen={setOpenVehicleModal}
        notification={selectedNotification}
      />

      <Dropdown
        overlay={menu}
        trigger={['click']}
        overlayStyle={{ maxHeight: '500px', overflow: 'auto' }}>
        <Badge dot={notifications.length > 0 ? true : false}>
          <NotificationOutlined style={{ cursor: 'pointer' }} />
        </Badge>
      </Dropdown>
    </>
  );
};

export default Notifications;
