import { DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { CustomModal } from '@/components/Common/CustomModal';
import { CustomDatePresets } from '../utils/datePresets';
import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter, numberDecimalFormatter } from '@/utils/numberFormatter';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { find_date_preference } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import ReusableTransfersView from '../../../transfers/view/ReusableView';
import MultiSelectLocations from '@/components/Common/MultiSelectLocations';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import { DEFAULT_DATE_FORMAT } from '@/constants';
const { RangePicker } = DatePicker;

const TransferReport = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const [nepaliType] = useState(find_date_preference());

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );

    form.setFieldValue('constraints', {
      page: 1,
      size: -1,
      from: [],
      to: [],
      productId: '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0],
      endDate: CustomDatePresets.Today[1],
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Stock Transfer Report', link: '/reports/transfers' }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };
  const columns: ColumnsType<any> = [
    // Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 40,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Ref. No.',
      key: 'reference_no',
      className: 'invoice',
      sorter: (a, b) => a.reference_no.localeCompare(b.reference_no),
      sortOrder: sortedInfo.columnKey === 'reference_no' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <a
            color="black"
            onClick={() => {
              setIsModalOpen(true);
              setId(record.t_in_id);
            }}>
            {record.reference_no}
          </a>
        );
      },
      width: 120
    },
    {
      title: 'Location (From)',
      key: 'from_name',
      sorter: (a, b) => a.from_name.localeCompare(b.from_name),
      sortOrder: sortedInfo.columnKey === 'from_name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div color="black">{record.from_name}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Location (To)',
      key: 'to_name',
      sorter: (a, b) => a.to_name.localeCompare(b.to_name),
      sortOrder: sortedInfo.columnKey === 'to_name' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div color="black">{record.to_name}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Product',
      key: 'product_name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record) => <div>{record.product_name}</div>,
      width: 100
    },
    {
      title: 'Total amount',
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>
          // </Link>
        );
      },
      width: 80
    },
    {
      title: 'CreatedBy',
      key: 'created_by',
      sorter: (a, b) => a.created_by.localeCompare(b.created_by),
      sortOrder: sortedInfo.columnKey === 'created_by' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{record.created_by}</div>;
      },
      width: 80
    },
    {
      title: 'ApprovedBy',
      key: 'approved_by',
      sorter: (a, b) => a.approved_by.localeCompare(b.approved_by),
      sortOrder: sortedInfo.columnKey === 'approved_by' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{record.approved_by}</div>;
      },
      width: 80
    },
    {
      title: 'Quantity',
      key: 'quantity',
      sorter: (a, b) => a.total_quantity - b.total_quantity,
      sortOrder: sortedInfo.columnKey === 'quantity' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {numberDecimalFormatter(record.total_quantity)} {record.unit}
          </div>
        );
      },
      width: 80
    },
    {
      title: 'Base Unit Quantity',
      key: 'baseUnitQuantity',
      sorter: (a, b) => a.total_base_unit_quantity - b.total_base_unit_quantity,
      sortOrder: sortedInfo.columnKey === 'baseUnitQuantity' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div className="text-right mr-2">
            {numberDecimalFormatter(record.total_base_unit_quantity)}
          </div>
        );
      },
      width: 80
    },

    {
      title: 'Date',
      key: 'date',
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (record) => {
        return (
          <div color="black">{moment(record.created_at).local().format(DEFAULT_DATE_FORMAT)}</div>
        );
      },
      width: 100
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      toSort={handleChange}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 38, name: 'Stock Transfer Report.' }}
      columnsData={columns}
      form={form}
      toDownload={true}>
      <CustomModal
        isModalOpen={isModalOpen}
        footer={false}
        setIsModalOpen={setIsModalOpen}
        title={'Transfer View'}>
        <ReusableTransfersView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber />
      </Form.Item>

      <MultiSelectLocations />
      <ProductSearchV2 hasParentFormItem={false} name={['constraints', 'productId']} />
      {/* <LocationSearch
        required={false}
        formData={{ formName: ['constraints', 'from'], formLabel: 'Location(From)' }}
        notAll={false}
        onSelect={(val) => {
          if (val == form.getFieldValue(['constraints', 'to'])) {
            message.error("Locations can't be same!");
            form.setFieldValue(['constraints', 'from'], null);
          }
          //   console.log('valfrom', val);
        }}
      />
      <LocationSearch
        required={true}
        formData={{ formName: ['constraints', 'to'], formLabel: 'Location(To)' }}
        notAll={true}
        onSelect={(val) => {
          if (val == form.getFieldValue(['constraints', 'from'])) {
            message.error("Locations can't be same!");
            form.setFieldValue(['constraints', 'to'], null);
          }
          //   console.log('val', val);
        }}
      /> */}
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[{ required: true, message: 'Please select date!' }]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default TransferReport;
