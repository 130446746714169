import { DollarOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Spin
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';

import moment from 'moment';
import { useEffect, useState } from 'react';
import { create_purchase_payment } from '@/services/purchases/mutations';

import { ConvertObjectToURL } from '@/utils/converturl';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import { IAccountTypeResponseData } from '@/services/accounts/types';
import { IPurchasePaymentRequest } from '@/services/purchases/types';
import { duplicateCheck } from '@/utils/duplicateChecker.utils';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent } from '@/services/accounts/enums';
import CustomInfoModal from '../CustomInfoModal';
import AgentsSearch from '../AgentsSearch';
import AccountsDB from '@/store/localstorage/AccountsDB';
import { get_account_list_filter } from '@/services/accounts/queries';
import { getUserData } from '@/utils/auth.utils';
import DebounceButton from '../DebounceButton';
import useDebounce from '@/hooks/useDebounce';
import useDebounceFunction from '@/hooks/useDebounceFunction';

const PurchasePayment = ({
  visible,
  selectedSale,
  setShow,
  setSelectedSale,
  //   paymentType,
  fetchAgain,
  form2
}: {
  //   paymentType: 'sell' | 'purchase';
  visible: boolean;
  selectedSale: any;
  setShow: (value: boolean) => void;
  setSelectedSale: (value: any) => void;
  fetchAgain?: (filter?: string) => Promise<void>;
  form2?: FormInstance;
}) => {
  const user = getUserData();
  const isSuperAdmin = user.perms.includes('SUPER_ADMIN');

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hasRule, setHasRule] = useState(true);

  if (selectedSale?.isAccountArchived) {
    message.destroy('archived');
    message.error({
      content: (
        <span>
          Unable to create a payment from this supplier. <strong>Reason:</strong>{' '}
          <span className="text-red-500">Account Archived</span>
        </span>
      ),
      duration: 5,
      key: 'archived'
    });
  }

  //   console.log('selected sale', selectedSale);
  //   const createSellPayment = useMutation(create_sell_payment);
  const [formData, setFormData] = useState<IPurchasePaymentRequest>({} as IPurchasePaymentRequest);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(false);
  const [accountList, setAccountList] = useState<IAccountTypeResponseData[]>([]);
  const createPurchasePayment = useMutation(create_purchase_payment);
  //   const { refetch } = useQuery(['customerDetails', selectedSale?.customerId], async () => {
  //     const customerDetails = await get_customer_details(selectedSale.customerId);
  //     const { customer } = customerDetails;
  //     if (customer) {
  //       setSelectedSale((prev: any) => ({ ...prev, userData: customer }));
  //     }
  //     console.log('customerDetails', customerDetails);
  //   });

  useEffect(() => {
    if (selectedSale) {
      form.setFieldValue('reference', selectedSale.referenceNumber);
      form.resetFields(['paymentMethod']);
      form.setFieldValue('date', moment());
    }
  }, [selectedSale]);

  const checkAccountRule = async (locationId: number, val: string) => {
    if (
      await checkHasAccountRule(
        locationId,
        val === 'user'
          ? AccountRulesEvent.PURCHASE_PAYMENT_USER
          : val === 'cash'
          ? AccountRulesEvent.PURCHASE_PAYMENT_CASH
          : AccountRulesEvent.PURCHASE_PAYMENT_OTHER
      )
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${
          val === 'user'
            ? AccountRulesEvent.PURCHASE_PAYMENT_USER
            : val === 'cash'
            ? AccountRulesEvent.PURCHASE_PAYMENT_CASH
            : AccountRulesEvent.PURCHASE_PAYMENT_OTHER
        }" rule has not been created!`
      });
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    const values = await form.getFieldsValue();
    values.amount = +values.amount;
    values.id = selectedSale.id;
    values.date = convertLocalToUTCString(values.date, 'YYYY-MM-DD HH:mm:ss');
    values.walletPayment = 0;
    if (!values.adjustment) values.adjustment = false;
    values.madeBy = selectedSale.userData.userId;
    createPurchasePayment.mutate(values, {
      onSuccess: () => {
        setIsLoading(false);
        if (fetchAgain && form2) {
          const values = form2.getFieldsValue();
          values.endDate = convertLocalToUTCString(values.endDate);
          values.startDate = convertLocalToUTCString(values.startDate);
          // console.log('values', values);
          delete values.dateCustom;
          delete values.startDateNepali;
          delete values.endDateNepali;
          fetchAgain(ConvertObjectToURL(values));
          setSelectedSale(null);
          setIsUser(false);
          form.resetFields();
        }
      },
      onError: (e: any) => {
        setIsLoading(false);
        message.error(e.message);
      }
    });
    setIsLoading(false);
    setShow(false);
  };

  const handlePaymentAdd = async () => {
    await form.validateFields();

    const agentUserId = form.getFieldValue('agentId');
    if (isUser && agentUserId) {
      let account = await AccountsDB.getAccountByReferenceId(agentUserId);
      if (!account) {
        const query = `skip=0&count=1&referenceId=${agentUserId}&type=USER`;
        const { results } = await get_account_list_filter(query);
        if (results && results.length > 0) {
          const accountAPI = results[0];
          await AccountsDB.addAccounts([accountAPI]);
          account = await AccountsDB.getAccountByReferenceId(agentUserId);
        }
      }

      if (account) {
        form.setFieldsValue({ accountId: account.id });
      }
    }

    setConfirm(true);
    setIsLoading(true);
  };

  const debounceSubmitForm = useDebounceFunction(submitForm);

  return (
    <Modal
      title={<span className="font-semibold">Add payment</span>}
      visible={visible}
      // footer={selectedSale && selectedSale.totalAmount - selectedSale.amountPaid > 0}
      footer={null}
      onCancel={() => {
        setShow(false);
        setSelectedSale(null);
      }}>
      <Spin spinning={isLoading}>
        <Modal
          title={<span className="font-semibold">Confirmation</span>}
          visible={confirm}
          // footer={null}
          okText={'Accept'}
          cancelText={'Back'}
          onOk={async () => {
            setConfirm(false);
            await debounceSubmitForm();
          }}
          onCancel={() => {
            setConfirm(false);
          }}>
          <>
            <div> Supplier : {selectedSale && selectedSale.userName}</div>
            {/* <div> Invoice No. : {selectedSale && selectedSale.referenceNumber}</div> */}
            <div> Payment Amount : {form.getFieldValue('amount')}</div>
            {/* <div> Wallet Amount : {form.getFieldValue('walletPayment')}</div> */}
            <div> Date : {form.getFieldValue('date')?.format('YYYY-MM-DD')}</div>
            {/* <div className="flex justify-end">
            <Button
              className="mr-2"
              onClick={() => {
                setConfirm(false);
              }}>
              Back
            </Button>
            <Button
              type="primary"
              className="mr-2"
              onClick={() => {
                submitForm();
                setConfirm(false);
              }}>
              Pay
            </Button>
          </div> */}
          </>
        </Modal>
        {/* className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 gap-2" */}
        <div>
          <div className="bg-gray-600 text-white p-2" style={{ borderRadius: '9px' }}>
            <div>
              <span className="font-weight inline"> Supplier </span>:{' '}
              <span className="font-semibold">{selectedSale && selectedSale.userName}</span>
            </div>
            {/* <div>
            <span className="font-weight inline">Wallet balance</span>:{' '}
            <span className="font-semibold">
              {selectedSale && nepaliNumberFormatter(selectedSale?.userData?.balance || 0)}
            </span>
          </div> */}
            {/* <div className="bg-gray-600 text-white p-2"> */}
            <div>
              <span className="font-weight inline"> Invoice No. </span>:{' '}
              <span className="font-semibold">{selectedSale && selectedSale.referenceNumber}</span>
            </div>
            <div>
              <span className="font-weight inline"> Location </span>:{' '}
              <span className="font-semibold">{selectedSale && selectedSale.locationName}</span>
            </div>
            {/* </div> */}
            {/* <div className="bg-gray-600 text-white p-2"> */}
            <div>
              <span className="font-weight inline"> Total amount </span>:{' '}
              <span className="font-semibold">
                {selectedSale && nepaliNumberFormatter(selectedSale.totalAmount)}
              </span>
            </div>
            <div>
              <span className="font-weight inline"> Amount paid </span>:{' '}
              <span className="font-semibold">
                {selectedSale && nepaliNumberFormatter(selectedSale.amountPaid)}
              </span>
            </div>
            <div>
              <span className="font-weight inline"> Amount Returned </span>:{' '}
              <span className="font-semibold">
                {selectedSale && nepaliNumberFormatter(selectedSale.amountReturned)}
              </span>
            </div>

            <div>
              <span className="font-weight inline"> Remaining Amount to paid</span>:{' '}
              <span className="font-semibold">
                {selectedSale && (
                  <>
                    {selectedSale.amountPaid >=
                    selectedSale.totalAmount - selectedSale.amountReturned
                      ? 0
                      : nepaliNumberFormatter(
                          Math.floor(
                            selectedSale.totalAmount -
                              selectedSale.amountPaid -
                              selectedSale.amountReturned
                          )
                        )}
                  </>
                )}
              </span>
            </div>
            <div>
              <span className="font-weight inline"> Payment Note </span>:{' '}
              <span className="font-semibold">
                {selectedSale && selectedSale?.payments?.[0]?.note}
              </span>
            </div>
          </div>
          {/* <div className="bg-green-600 text-white p-2 mt-2" style={{ borderRadius: '9px' }}>
          <div>
            <span className="font-weight inline">Wallet balance</span>:{' '}
            <span className="font-semibold">
              {selectedSale && nepaliNumberFormatter(selectedSale?.userData?.balance || 0)}
            </span>
          </div>
        </div> */}
          {/* </div> */}
        </div>

        <hr />

        {/* <p>
        <span className="via-indigo-500 font-semibold"> Advance Balance:</span> Rs:{' '}
        {selectedSale?.totalAmount}{' '}
      </p> */}
        {selectedSale && selectedSale.adjustment > 0 ? (
          <>
            <div>There is adjustment in this purchase.Cannot Add Payment!</div>
          </>
        ) : (
          <>
            {selectedSale &&
              Math.floor(
                selectedSale.totalAmount - selectedSale.amountPaid - selectedSale.amountReturned
              ) > 0 && (
                <>
                  <Form
                    form={form}
                    onValuesChange={(_, allFields) => {
                      setFormData(allFields);
                    }}>
                    <div className="grid grid-cols-1">
                      <Form.Item
                        label="Payment Amount"
                        name="amount"
                        rules={[
                          {
                            required: true,
                            message: 'Please add amount!'
                          },
                          () => ({
                            validator(_: any, value: any) {
                              const maxValue = Math.floor(
                                selectedSale.totalAmount -
                                  selectedSale.amountPaid -
                                  selectedSale.amountReturned
                              );
                              if (value > maxValue) {
                                return Promise.reject('More amount added!');
                              }
                              return Promise.resolve();
                            }
                          })
                        ]}>
                        <InputNumber min={0} addonBefore={<DollarOutlined color="green" />} />
                      </Form.Item>
                    </div>
                    {/* <div className="grid grid-cols-1">
                    <Form.Item
                      label="Payment from Wallet"
                      name="walletPayment"
                      rules={[
                        {
                          required: true,
                          message: 'Please add amount!'
                        },
                        () => ({
                          validator(_: any, value: any) {
                            if (value == 0) return Promise.resolve();
                            const maxValue = Math.floor(
                              selectedSale.totalAmount -
                                selectedSale.amountPaid -
                                selectedSale.amountReturned
                            );
                            const otherPayment = form.getFieldValue(['amount']) | 0;
                            const remVal = maxValue - otherPayment;
                            // console.log('remVal ', remVal);
                            // console.log('Val ', value);
                            if (value > selectedSale.userData.balance) {
                              return Promise.reject(`You wallet balance is out of limit!`);
                            }
                            if (value > remVal) {
                              return Promise.reject(`You are adding amount more than to be paid!`);
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}>
                      <InputNumber min={0} addonBefore={<DollarOutlined color="green" />} />
                    </Form.Item>
                  </div> */}
                    <Form.Item hidden name="id">
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Payment Method"
                      name="paymentMethod"
                      rules={[
                        {
                          required: true,
                          message: 'Please select payment method!'
                        }
                      ]}>
                      <Select
                        onChange={async (val) => {
                          val === 'user' ? setIsUser(true) : setIsUser(false);
                          await checkAccountRule(selectedSale.locationId, val);
                        }}>
                        <Select.Option value="cash">Cash</Select.Option>
                        <Select.Option value="card">Card</Select.Option>
                        <Select.Option value="cheque">Cheque</Select.Option>
                        <Select.Option value="bank">Bank Tranfer</Select.Option>
                        <Select.Option value="user">Agent</Select.Option>
                        <Select.Option value="qr_offline">Offline QR</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                    </Form.Item>
                    {isUser && (
                      <>
                        <Form.Item hidden name="accountId">
                          <Input />
                        </Form.Item>
                        <AgentsSearch
                          hasParentFormItem={false}
                          formName="agentId"
                          label="Select Agent"
                          required
                          onSelect={(val) => form.setFieldValue('agentId', val)}
                        />
                      </>
                    )}
                    <Form.Item
                      label="Reference"
                      name="reference"
                      rules={[
                        {
                          required: true,
                          message: 'Please add reference!'
                        }
                      ]}>
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: 'Please select date!'
                        }
                      ]}>
                      <DatePicker showTime />
                    </Form.Item>

                    <Form.Item
                      label="Note"
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: 'Please add a note!'
                        }
                      ]}>
                      <TextArea />
                    </Form.Item>
                    <Form.Item
                      label="Finalize?"
                      name="adjustment"
                      valuePropName="checked"
                      hidden={!isSuperAdmin}>
                      <Checkbox />
                    </Form.Item>
                    <div className="flex justify-end gap-2">
                      <Button
                        color="white"
                        onClick={() => {
                          setShow(false);
                          setSelectedSale(null);
                        }}>
                        Back
                      </Button>
                      <DebounceButton
                        disabled={selectedSale?.isAccountArchived || !hasRule}
                        color="blue"
                        type="primary"
                        onClick={handlePaymentAdd}>
                        Add
                      </DebounceButton>
                    </div>
                  </Form>
                </>
              )}
            {selectedSale &&
              Math.floor(
                selectedSale.totalAmount - selectedSale.amountPaid - selectedSale.amountReturned
              ) <= 0 && (
                <>
                  <span className="font-semibold">Already full payment No payment to Add!</span>
                </>
              )}
          </>
        )}
      </Spin>
    </Modal>
  );
};

export default PurchasePayment;
