import { async } from '@firebase/util';
import moment from 'moment';
import http from '../../utils/http.utils';
import { AxiosResponse } from 'axios';
import {
  ICurrentLotsWithProductId,
  ILot,
  IPurchaseAgentBillCancel,
  IPurchaseAgentBillCancelResponse,
  IPurchaseAgentBillPurchaseResponse,
  IPurchaseAgentBillRange,
  IPurchaseAgentBillResponse,
  IPurchaseDetails,
  IPurchaseList,
  IPurchaseListData,
  IPurchaseOrder,
  IPurchaseOrderData,
  IPurchaseReturn2,
  IPurchaseReturnData,
  IPurchaseReturnInPurchaseDetails,
  ITypeForSellAndPurchaseDemand
} from './types';
import { ILines } from '../sell/types';
import { getDataForIds } from '../../utils/queries.utils';
export const get_purchase_list = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IPurchaseListData, any> = await http.get('purchase/?' + filter, {
    skip,
    count,
    value
  });
  return response;
};
// export const get_purchase_order_form_filter = async (filter: string) => {
//   const params_arr = filter.split('&');
//   let locationId = 0;
//   for (let i = 0; i < params_arr.length; i++) {
//     const pair = params_arr[i].split('=');
//     if (pair[0] == 'locationId') {
//       locationId = parseInt(pair[1]);
//       filter = filter.replace(`&${pair[0]}=${pair[1]}`, '');
//     }
//   }
//   const response = await http.get(`order/${locationId}/purchase/?` + filter);
//   return response;
// };
export const get_lot_by_location_product = async (filter: string, productId: string | number) => {
  const params_arr = filter.split('&');
  let locationId = 0;
  for (let i = 0; i < params_arr.length; i++) {
    const pair = params_arr[i].split('=');
    if (pair[0] == 'locationId') {
      locationId = parseInt(pair[1]);
      filter = filter.replace(`&${pair[0]}=${pair[1]}`, '');
    }
  }
  const response: AxiosResponse<ILot[], any> = await http.get(
    `products/locations/${locationId}/lots/` + productId
  );
  return response;
};

export const get_purchase_list_filter = async (filter = '') => {
  const response: AxiosResponse<IPurchaseListData, any> = await http.get('purchase/?' + filter);
  return response;
};

export const get_purchase_details_ids = async (ids: number[]) => {
  return await getDataForIds<IPurchaseList>(ids, 'purchase/?');
};

export const get_purchase_details = async (id: number) => {
  const response: AxiosResponse<IPurchaseDetails, any> = await http.get('purchase/' + id);
  return response.data;
};
export const get_purchase_order_list = async (skip = 0, count = 0, value = '', filter = '') => {
  const response: AxiosResponse<IPurchaseOrderData, any> = await http.get(
    'purchase-order/?' + filter,
    { skip, count, value }
  );
  return response;
};
export const get_purchase_order_list_for_filter = async (filter = '') => {
  const response: AxiosResponse<IPurchaseOrderData, any> = await http.get(
    'purchase-order/?' + filter
  );
  return response;
};
export const get_purchase_return_list = async (skip = 0, count = 0, value = '') => {
  const response: AxiosResponse<IPurchaseReturnData, any> = await http.get('purchase-return', {
    skip,
    count,
    value
  });
  return response;
};
export const get_purchase_return_list_filter = async (filter = '') => {
  const response: AxiosResponse<IPurchaseReturnData, any> = await http.get(
    'purchase-return/?' + filter
  );
  return response;
};

export const get_purchase_return_details_ids = async (ids: number[]) => {
  return await getDataForIds<IPurchaseReturn2>(ids, 'purchase-return/?');
};

export const get_purchase_reutrn_details_by_id = async (id: number) => {
  const response: AxiosResponse<IPurchaseReturnInPurchaseDetails, any> = await http.get(
    'purchase-return/' + id
  );
  return response.data;
};

export const get_purchase_order_details = async (id: number) => {
  const response: AxiosResponse<IPurchaseOrder, any> = await http.get('purchase-order/' + id);
  return response;
};
export const get_purchase_order_lines_details = async (id: number) => {
  const response: AxiosResponse<ILines[], any> = await http.get(
    'products/lines/PURCHASE_ORDER/' + id
  );
  return response;
};
export const get_purchase_lines_details = async (id: number) => {
  const response: AxiosResponse<ILines[], any> = await http.get('products/lines/PURCHASE/' + id);
  return response;
};
export const get_purchase_return_lines_details = async (id: number) => {
  const response: AxiosResponse<ILines[], any> = await http.get(
    'products/lines/PURCHASE_RETURN/' + id
  );
  return response;
};
// export const get_yesterday_purchase_by_location = async (locationId: number) => {
//   const response = await http.get(
//     `/data/9?page=1&size=1000&identifier=PURCHASE&identifierTable=purchases&date=${moment()
//       .subtract(1, 'days')
//       .format('YYYY-MM-DD')}&locationId=${locationId}`
//   );
//   return response;
// };

// export const get_sell_order_form_filter = async (filter: string) => {
//   const params_arr = filter.split('&');
//   let locationId = 0;
//   for (let i = 0; i < params_arr.length; i++) {
//     const pair = params_arr[i].split('=');
//     if (pair[0] == 'locationId') {
//       locationId = parseInt(pair[1]);
//       filter = filter.replace(`&${pair[0]}=${pair[1]}`, '');
//     }
//   }
//   // const response = await http.get(`order/${locationId}/PURCHASE?` + filter);
//   const response = await http.get(`order/${locationId}/sell/?` + filter);
//   return response;
// };

export const get_lots_by_group_of_product_id = async (
  ids: number[] | string[],
  locationId: number | string
) => {
  const response: AxiosResponse<ICurrentLotsWithProductId, any> = await http.get(
    'products/current-information',
    { productIds: ids, locationId }
  );
  return response;
};

export const get_latest_week_sales = async (ids: number[] | string[], date: string) => {
  const response: AxiosResponse<ILines[], any> = await http.get(
    'products/lines-latest/SALE?startDate=' +
      moment(date).subtract(6, 'days').utc().format() +
      '&endDate=' +
      moment(date).utc().format(),
    {
      ids
    }
  );
  return response;
};

export const get_latest_week_purchase = async (ids: number[] | string[], date: string) => {
  const response: AxiosResponse<ILines[], any> = await http.get(
    'products/lines-latest/PURCHASE_ORDER?startDate=' +
      moment(date).subtract(6, 'days').utc().format() +
      '&endDate=' +
      moment(date).utc().format(),
    {
      ids
    }
  );
  return response;
};

export const get_purchase_order_form_filter_v2 = async ({
  locationId,
  startDate,
  endDate
}: any) => {
  const response: AxiosResponse<ITypeForSellAndPurchaseDemand, any> = await http.get(
    'order/purchase/',
    { locationId, startDate, endDate }
  );
  return response;
};

export const get_sell_order_form_filter_v2 = async ({ locationId, startDate, endDate }: any) => {
  const response: AxiosResponse<ITypeForSellAndPurchaseDemand, any> = await http.get(
    `order/sell/`,
    { locationId, startDate, endDate }
  );
  return response;
};

export const get_purchase_agent_bill_list = async (filter = '') => {
  const response: AxiosResponse<IPurchaseAgentBillResponse> = await http.get(
    'purchase-agent-bill/?' + filter
  );
  return response.data;
};

export const get_purchase_agent_bill_list_v2 = async (
  skip = 0,
  count = 0,
  value?: number,
  filter = ''
) => {
  const config: any = { skip, count };
  if (value && value > 0) config.value = value;

  const response: AxiosResponse<IPurchaseAgentBillResponse> = await http.get(
    'purchase-agent-bill/?' + filter,
    config
  );
  return response.data;
};

export const get_purchase_agent_bill_details = async (id: number) => {
  const response: AxiosResponse<IPurchaseAgentBillRange> = await http.get(
    'purchase-agent-bill/' + id
  );
  return response.data;
};

export const get_purchase_agent_bill_cancel_list = async (filter = '') => {
  const response: AxiosResponse<IPurchaseAgentBillCancelResponse> = await http.get(
    'purchase-agent-bill/cancel/?' + filter
  );
  return response.data;
};

export const get_purchase_agent_bill_purchase_list = async (filter = '') => {
  const response: AxiosResponse<IPurchaseAgentBillPurchaseResponse> = await http.get(
    'purchase-agent-bill/purchase/?' + filter
  );
  return response.data;
};
