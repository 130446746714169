import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  get_adjustment_details,
  get_adjustment_line_details,
  get_reasons_list
} from '../../../services/adjustments/queries';
import { useContext, useEffect, useRef, useState } from 'react';
import { WebSocketContext } from '../../../contexts/websocket.context';
import useDebounce from '../../../hooks/useDebounce';
import { find_locationId_preference } from '../../../store/localstorage/preferences';
import { ILines, IProductUnits, Line } from '../../../services/sell/types';
import { SocketEvents, SystemNotificationType } from '../../../constants/websocketConfig';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Spin,
  Switch,
  message
} from 'antd';
import { approved_adjustments_mutation } from '../../../services/adjustments/mutations';
import {
  get_lots_details_bylocationId_productId,
  get_product_details,
  get_product_list,
  get_product_list_ids,
  get_units_list
} from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
import { ILotDetails, IProductType, IUnits } from '../../../services/products/types';
import UnitsDB from '../../../store/localstorage/UnitsDB';
import { numberDecimalFormatter } from '../../../utils/numberFormatter';
import ReusableQuantity from '../ReusableQuantity';
import ProductSearchForLines from '../ProductSearch2';
import { LocationSearch } from '../LocationSearch/LocationSearch';
import AppContent from '../Content/Content';
import { AdjustmentMultiple } from '../../../services/adjustments/types';
import CustomErrorModal from '../CustomErrorModal';

interface IMultipleAdjustmentApprove {
  data: DataType[];
  handleModalClose?: () => void;
}

interface DataType {
  id: number;
  name: string;
  age: number;
  address: string;
  description: string;
}

const { Option } = Select;

export const MultipleAdjustmentApprove: React.FC<IMultipleAdjustmentApprove> = ({
  data,
  handleModalClose
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allLocalUnits, setAllLocalUnits] = useState<any>([]);
  const [totalLots, settotalLots] = useState<any>([]);
  //product search
  const [searchValue, setSearchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const { data: reasonList } = useQuery(['reason'], async () => get_reasons_list());
  const autofocusRef: React.Ref<any> = useRef(null);
  const [fornewreason, setfornewreason] = useState<any>([]);

  const approvedAdjustmentMutation = useMutation(approved_adjustments_mutation);

  useEffect(() => {
    initializeUnits();
    getAllInfo();
  }, [data]);

  const getAllInfo = async () => {
    let allProducts: any[] = [];
    let allLots: ILotDetails[] = [];
    // const allData: Awaited<Promise<PromiseLike<ReturnType<typeof getData>>>>[] = [];
    const allData: AdjustmentMultiple[] = [];
    for (const single of data) {
      const singleData = await getData(single.id, allProducts, allLots);
      if (singleData?.allProductsList)
        allProducts = [...allProducts, ...singleData.allProductsList];
      if (singleData?.allLotsList) allLots = [...allLots, ...singleData.allLotsList];
      if (singleData?.formData) allData.push(singleData?.formData);
    }

    form.setFieldsValue({
      main: allData
    });

    setIsLoading(false);
  };

  const getData = async (id: number, allProducts: any[], allLots: ILotDetails[]) => {
    setIsLoading(true);
    let allProductsList: any[] = [];
    let allLotsList: ILotDetails[] | undefined;
    const adjustmentDetails = await get_adjustment_details(id);
    const adjustmentLines = await get_adjustment_line_details('PENDING', id);
    const productsIds = new Set<number>();
    if (adjustmentLines.data.length > 0) {
      for (let i = 0; i < adjustmentLines.data.length; i++) {
        if (!allProducts.find((value: any) => value.id === adjustmentLines.data[i].productId))
          productsIds.add(adjustmentLines.data[i].productId);
      }
    }
    if (Array.from(productsIds).length > 0) {
      const productList = await get_product_list_ids(Array.from(productsIds));
      setProductList((prev: any) => {
        allProductsList = [...prev, ...productList.data.results];
        return [...prev, ...productList.data.results];
      });
      await ProductsDB.addProducts(productList.data.results);
    }
    if (adjustmentDetails.data) {
      let netTotalAmount = 0;
      if (adjustmentLines.data.length > 0) {
        const allProducts = [...productList];
        const mySet = new Set<number>();

        for (let i = 0; i < adjustmentLines.data.length; i++) {
          delete adjustmentLines.data[i].lotId;
          mySet.add(adjustmentLines.data[i].productId);
          let findProduct: any = await ProductsDB.getProduct(adjustmentLines.data[i].productId);
          if (!findProduct) {
            findProduct = await get_product_details(adjustmentLines.data[i].productId);
            allProducts.push(findProduct);
          }
          adjustmentLines.data[i].productName = findProduct.name;
          adjustmentLines.data[i].reasonId = adjustmentDetails.data.reasonId;
          adjustmentLines.data[i].total = adjustmentLines.data[i].totalAmount;
          netTotalAmount += adjustmentLines.data[i].totalAmount;
        }
        allLotsList = await fetchLotsOnLocationandProductChange(
          Array.from(mySet),
          adjustmentDetails.data.locationId,
          'location',
          allLots
        );
      }

      return {
        formData: {
          ...adjustmentDetails.data,
          lines: adjustmentLines.data,
          grandtotal: netTotalAmount
        },
        allProductsList: allProductsList,
        allLotsList: allLotsList
      };
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    try {
      let approved = 0;
      for (let i = 0; i < values.main.length; i++) {
        await approvedAdjustmentMutation.mutateAsync(values.main[i], {
          onSuccess: ({ data }: { data: any }) => {
            if (data) {
              approved++;
            }
          },
          onError: async (e: any) => {
            setIsLoading(false);
            if (e.response.data.exception.name === 'ResourceStatusException') {
              await CustomErrorModal({
                message: (
                  <>
                    <div>
                      Adjustment Approved: <b>{approved}</b>
                    </div>
                    <div>{e.response.data.exception.message}</div>
                  </>
                )
              });
            } else {
              await CustomErrorModal({ message: e.response.data.message });
            }
            if (handleModalClose) handleModalClose();
          }
        });
      }
      setIsLoading(false);
      if (handleModalClose && approved > 0) handleModalClose();
    } catch (err: any) {
      setIsLoading(false);
      console.error(err.message);
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string,
    allLots?: ILotDetails[]
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }
      const currenttotalLots: ILotDetails[] = [];
      const totalAllLots: ILotDetails[] = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots((prev: any) => [...prev, ...response]);
        }
      } else if (from === 'lotsupdate') {
        for (let i = 0; i < productsIdArray.length; i++) {
          const result = await get_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[i]
          );
          settotalLots((prev: any) => {
            const filterLots = prev.filter((value: Line) => value.productId !== productsIdArray[i]);

            return [...filterLots, ...result];
          });
        }
      } else {
        for (let i = 0; i < productsIdArray.length; i++) {
          if (
            allLots &&
            allLots.filter(
              (value: any) =>
                value.productId === productsIdArray[i] && value.locationId === locationId
            ).length === 0
          ) {
            const result = await get_lots_details_bylocationId_productId(
              locationId,
              productsIdArray[i]
            );
            currenttotalLots.push(...result);
            settotalLots((prev: any) => {
              const filterLots = prev.filter(
                (value: Line) => value.productId !== productsIdArray[i]
              );
              return [...filterLots, ...currenttotalLots];
            });
            totalAllLots.push(
              ...totalLots.filter((value: Line) => value.productId !== productsIdArray[i]),
              ...currenttotalLots
            );
          }
        }
        return totalAllLots;
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const onLocationChange = async (value: number) => {
    form.setFieldValue('locationId', value);
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], null);
      });
    }
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');
  };

  const FilterUnits = (name: number, mainName: number) => {
    const checkCurrentProduct = form.getFieldValue(['main', mainName, 'lines', name, 'productId']);
    // console.log('checkCurrentProduct', checkCurrentProduct);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      // console.log('productList', productList);
      // console.log('selectedProduct', selectedProduct);
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      // console.log('filteredUnits', filteredUnits);
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const FilterLot = (name: number, mainName: number) => {
    const checkCurrentProduct = form.getFieldValue(['main', mainName, 'lines', name, 'productId']);
    const currentLocation = form.getFieldValue(['main', mainName, 'locationId']);

    if (checkCurrentProduct && currentLocation) {
      let filteredLots: any = [];
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
      }

      const unitId = form.getFieldValue(['main', mainName, 'lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => {
            const isExpired = value?.expirationDate
              ? moment().isAfter(value.expirationDate)
              : false;

            return (
              <Option
                value={value.id}
                key={value.id}
                style={{ color: value.qtyAvailable > 0 && !isExpired ? 'green' : 'red' }}>
                {`(${numberDecimalFormatter(
                  value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
                )} ${unitInfo?.shortName || ''}) ${value.lotNumber} Grade-${value.grade} Expiry-${
                  value?.expirationDate
                    ? new Date(value.expirationDate).toLocaleDateString()
                    : 'N/A'
                }`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const onProductChange = async (value: number, name?: number) => {
    setfornewreason([...fornewreason, false]);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['location']);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productList) {
      const selectedProduct = productList.find((val) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
      form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
    }
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };

  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };

  const initializeUnits = async () => {
    const allUnits = await get_units_list();
    await UnitsDB.addUnits(allUnits);
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const checkLotQuantity = (index: number, mainName: number) => {
    const row = form.getFieldValue(['main', mainName, 'lines', index]);
    const unitInfo: any = allLocalUnits.find((val: any) => row.unitId == val.id);
    if (row.lotId) {
      const filteredLot: any = totalLots.find((value: any) => value.id == row.lotId);
      return {
        quantity: filteredLot.qtyAvailable / unitInfo.baseUnitMultiplier,
        shortName: unitInfo.shortName
      };
    }
    return null;
  };

  const onUnitChange = async (name: number) => {
    const currentLocationId = form.getFieldValue(['location']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }
  };

  const onUnitandQuantityChange = (name: number, mainName: number) => {
    const rate = form.getFieldValue(['main', mainName, 'lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['main', mainName, 'lines', name, 'quantity']);
    const mis = form.getFieldValue(['main', mainName, 'lines', name, 'misc']);
    const dis = form.getFieldValue(['main', mainName, 'lines', name, 'discount']);

    const total = rate * qty - dis + mis;
    form.setFieldValue(['main', mainName, 'lines', name, 'total'], total);

    const lines = form.getFieldValue(['main', mainName, 'lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total;
    }

    form.setFieldValue(['main', mainName, 'grandtotal'], numberDecimalFormatter(grandTotal));
  };

  const onLotChange = (val: any, name: number, mainName: number) => {
    // console.log('Lot change', val);
    const unitId = form.getFieldValue(['main', mainName, 'lines', name, 'unitId']);
    const reqquantity = form.getFieldValue(['main', mainName, 'lines', name, 'quantity']);
    const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
    const lotInfo: any = totalLots.find((cval: any) => cval.id == val);
    if (lotInfo.qtyAvailable / unitInfo.baseUnitMultiplier >= reqquantity) {
      return;
    }
    message.error(
      `Selected Lot(${lotInfo.qtyAvailable / unitInfo.baseUnitMultiplier} ${
        unitInfo.shortName
      }) doesn't have required quantity ${reqquantity} ${unitInfo.shortName}`
    );
    form.setFieldValue(['main', mainName, 'lines', name, 'lotId'], null);
    return;
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <Form.List name="main">
            {(fields, { add }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <>
                      <div key={field.key}>
                        <PageHeader
                          title={`Adjustment: ${field.name + 1}`}
                          style={{
                            padding: '8px 0px 8px 10px'
                          }}
                        />
                        <div className={'grid grid-cols-1 mb-3 md:grid-cols-3 gap-5'}>
                          <LocationSearch
                            disabled={true}
                            notAll={true}
                            onSelect={onLocationChange}
                            formData={{
                              formName: [field.name, 'locationId'],
                              formLabel: 'Location'
                            }}
                          />
                        </div>
                        <Divider />
                        <Form.List name={[field.name, 'lines']}>
                          {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
                            <>
                              <div className={'grid mb-5'}>
                                <PageHeader
                                  subTitle="Add Product"
                                  style={{
                                    padding: '8px 0px 8px 10px'
                                  }}
                                />
                                <ProductSearchForLines
                                  add2={add2}
                                  onProductChange={onProductChange}
                                  productList={productList}
                                  setProductList={setProductList}
                                  productSearch={productSearch}
                                  setProductSearch={setProductSearch}
                                  autofocusRef={autofocusRef}
                                  disabled={true}
                                  locationId={form.getFieldValue([
                                    'main',
                                    field.name,
                                    'locationId'
                                  ])}
                                />
                                {fields2.length > 0 && (
                                  <>
                                    <PageHeader
                                      title="All Products"
                                      style={{
                                        padding: '8px 0px 8px 10px'
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <div
                                style={{
                                  maxHeight: '50vh',
                                  overflowY: 'scroll',
                                  // backgroundColor: 'gray',
                                  borderRadius: '9px'
                                }}>
                                {fields2.map(({ key: key2, name: name2, ...restField2 }) => (
                                  <div className="flex gap-1 items-center" key={key2}>
                                    <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                                    <div className="card">
                                      <div
                                        className={
                                          'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-9'
                                        }
                                        key={key2}>
                                        <Form.Item
                                          {...restField2}
                                          name={[name2, 'productId']}
                                          hidden></Form.Item>
                                        <Form.Item
                                          {...restField2}
                                          name={[name2, 'productName']}
                                          label="Name">
                                          <Input
                                            type={'text'}
                                            disabled
                                            style={{
                                              backgroundColor: 'white',
                                              color: 'black',
                                              // border: '0px',
                                              fontWeight: 'bold'
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField2}
                                          name={[name2, 'unitId']}
                                          label="Unit"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please choose a Unit!'
                                            }
                                          ]}>
                                          {
                                            <Select
                                              placeholder="Select a Unit!"
                                              onChange={() => {
                                                onUnitChange(name2),
                                                  onUnitandQuantityChange(name2, field.name);
                                              }}
                                              dropdownMatchSelectWidth={false}
                                              allowClear
                                              disabled>
                                              {FilterUnits(name2, field.name)}
                                            </Select>
                                          }
                                        </Form.Item>
                                        <Form.Item
                                          {...restField2}
                                          name={[name2, 'lotId']}
                                          label="Lot"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please choose a Lot!'
                                            }
                                          ]}>
                                          {
                                            <Select
                                              placeholder="Select a Lot!"
                                              onChange={(val) =>
                                                onLotChange(val, name2, field.name)
                                              }
                                              dropdownMatchSelectWidth={false}
                                              allowClear>
                                              {FilterLot(name2, field.name)}
                                            </Select>
                                          }
                                        </Form.Item>
                                        <ReusableQuantity
                                          disabled={true}
                                          name={name2}
                                          restField={restField2}
                                          onChangeData={() =>
                                            onUnitandQuantityChange(name2, field.name)
                                          }
                                          onPressEnterData={(e) => {
                                            if (autofocusRef.current) {
                                              autofocusRef.current.focus();
                                            }
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please add a Valid Quantity!'
                                            },
                                            () => ({
                                              validator(_: any, value: any) {
                                                const checked = checkLotQuantity(name2, field.name);
                                                if (!value) {
                                                  return Promise.reject();
                                                }
                                                if (value < 0)
                                                  return Promise.reject(
                                                    `Please Input valid quantity!`
                                                  );
                                                if (checked == null) {
                                                  return Promise.reject(`Please select Lot!`);
                                                }
                                                if (value > checked.quantity)
                                                  return Promise.reject(
                                                    `Please Input valid quantity! Currently in stock: ${checked.quantity} ${checked.shortName}`
                                                  );
                                                return Promise.resolve();
                                              }
                                            })
                                          ]}
                                        />
                                        <Form.Item
                                          {...restField2}
                                          label="Rate"
                                          name={[name2, 'unitPrice']}
                                          rules={[{ required: true, message: 'Please add Rate!' }]}>
                                          <InputNumber
                                            min={0}
                                            onChange={() =>
                                              onUnitandQuantityChange(name2, field.name)
                                            }
                                            disabled
                                            style={{ color: 'black' }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField2}
                                          label="Discount"
                                          name={[name2, 'discount']}
                                          rules={[
                                            { required: true, message: 'Please add Discount!' }
                                          ]}>
                                          <InputNumber
                                            min={0}
                                            onChange={() =>
                                              onUnitandQuantityChange(name2, field.name)
                                            }
                                            disabled
                                            style={{ color: 'black' }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField2}
                                          label="Misc"
                                          name={[name2, 'misc']}
                                          rules={[{ required: true, message: 'Please add Misc!' }]}>
                                          <InputNumber
                                            min={0}
                                            onChange={() =>
                                              onUnitandQuantityChange(name2, field.name)
                                            }
                                            disabled
                                            style={{ color: 'black' }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField2}
                                          name={[name2, 'total']}
                                          label="Total">
                                          <InputNumber
                                            min={0}
                                            disabled
                                            // className="font-bold"
                                            style={{ color: 'black' }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Reason"
                                          name={[name2, 'reasonId']}
                                          rules={[
                                            { required: true, message: 'Please select reason!' }
                                          ]}>
                                          <Select
                                            placeholder="Select a reason!"
                                            dropdownMatchSelectWidth={false}
                                            allowClear
                                            disabled>
                                            {reasonList?.data.map((value: any) => {
                                              return (
                                                <Option value={value.id} key={value.id}>
                                                  {value.name}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </Form.List>
                        <Card
                          style={{ borderRadius: '10px', marginBottom: '10px' }}
                          className="grid grid-cols-2">
                          <Form.Item name={[field.name, 'grandtotal']} label="Grand Total">
                            <InputNumber min={0} disabled style={{ color: 'black' }} />
                          </Form.Item>
                        </Card>
                        <Divider />
                      </div>
                    </>
                  ))}
                </div>
              );
            }}
          </Form.List>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    await form.validateFields();
                    await form.submit();
                  } catch (err: any) {
                    message.error(
                      `Empty Field on Adjustment Number: ${err.errorFields[0].name[1] + 1}`
                    );
                  }
                }}>
                Approve All
              </Button>
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};
