import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

// ANTD
import { Layout, Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { CloseOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import HeaderWrapper from './header.layout';
import freshktmlogo from './logo/freshktmlogo.png';
import freshktmfulllogo from './logo/freshktmfulllogo.png';

import './main.layout.css';
import MenuDB from '../store/localstorage/MenuDB';
import { get_menu_items } from '../services/settings/queries';
import { convertMenuItemsToItemType } from '../services/settings/services';
import MenuDrawer from './MenuDrawer';
import { useGlobalContext } from '@/contexts/global.context';
import InfoDrawer from './InfoDrawer';

export const fetchMenuItems = async (isFiltered = false) => {
  let allMenuItems = await MenuDB.getAllMenuItem();
  if (allMenuItems.length === 0) {
    const menuItems = await get_menu_items();
    await MenuDB.addMenuItem(menuItems.data.results);
    allMenuItems = await MenuDB.getAllMenuItem();
  }
  const topLevelMenuItems = allMenuItems
    .filter((val) => !val.parentId)
    .sort((a, b) => a.position - b.position);
  const convertedMenuItems = convertMenuItemsToItemType(allMenuItems, topLevelMenuItems, false);

  if (!isFiltered) return convertedMenuItems;
  return convertedMenuItems.map((menus) => {
    if (menus.key === 'accounts') {
      // remove all children of accounts
      menus.children = undefined;
      menus.label = <Link to="/accounts">Accounts</Link>;
    }

    return menus;
  });
};

const Main = ({ children }: { children: React.ReactNode }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [showCollapseBtn, setShowCollapseBtn] = useState(true);
  const [locationMenu] = useState('/');
  const location = useLocation();
  const [showHamburger, setshowHamburger] = useState(false);
  const [showDrawer, setshowDrawer] = useState(false);
  const [openKeys, setOpenKeys] = useState(['/']);
  const [infoContent, setInfoContent] = useState('');

  const { data: menuOrders, refetch } = useQuery(['filterSearchMenu'], () => fetchMenuItems(true));
  const { showInfo, setShowInfo } = useGlobalContext();

  function closeShowInfo() {
    setShowInfo(false);
  }

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (keys.filter((value) => menuOrders?.map((menu) => menu.key).includes(value)).length > 1) {
      keys = latestOpenKey ? [latestOpenKey] : [];
    }

    keys ? setOpenKeys(keys) : setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  useEffect(() => {
    const getMenuInfoContent = async () => {
      const response = await MenuDB.getAllMenuItem();
      const currentMenu = response.find((value) => value.key === location.pathname);

      const missingContentText = '<p>No information available</p>';
      setInfoContent(currentMenu?.content || missingContentText);
    };

    getMenuInfoContent();
  }, [location.pathname]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setCollapsed(true);
        setshowHamburger(true);
      } else {
        setshowHamburger(false);
        setshowDrawer(false);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <MenuDrawer
        showDrawer={showDrawer}
        locationMenu={locationMenu}
        menu={menuOrders}
        openKeys={openKeys}
        setShowDrawer={setshowDrawer}
        onOpenChange={onOpenChange}
      />
      <InfoDrawer showDrawer={showInfo} setShowDrawer={setShowInfo} content={infoContent} />

      <Layout>
        <Layout.Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="transition-class menuSider"
          width={220}
          style={{
            height: '100vh',
            position: 'fixed',
            left: showHamburger || showInfo ? '-100%' : 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 1
          }}
          onMouseOver={() => setShowCollapseBtn(false)}
          onMouseOut={() => setShowCollapseBtn(true)}>
          {/* // Top Logo  */}
          <div
            style={{ width: collapsed ? '75px' : '220px', zIndex: '10000000' }}
            className="header-logo bg-white flex items-center justify-center">
            {collapsed ? (
              <img src={freshktmlogo} style={{ width: '45px' }} />
            ) : (
              <img src={freshktmfulllogo} style={{ width: '130px' }} />
            )}
          </div>
          <div className="header-logo-div"></div>

          {/* // Menu  */}
          <Menu
            mode="vertical"
            selectedKeys={[locationMenu]}
            items={menuOrders as ItemType[]}
            onOpenChange={onOpenChange}
            openKeys={openKeys}
          />
        </Layout.Sider>

        <Layout className="site-layout">
          <Layout>
            <div
              className={`transition-class ${showInfo ? 'md:mr-[260px]' : 'mr-0'}`}
              style={{
                marginLeft: showHamburger || showInfo ? 0 : collapsed ? 81 : 220
              }}>
              <HeaderWrapper
                collapsed={collapsed}
                showHamburger={showHamburger}
                setshowDrawer={setshowDrawer}
                refetchMenu={refetch}
              />

              <div className={'site-content mt-16'}>{children}</div>
            </div>
          </Layout>

          <Layout.Sider
            width={260}
            trigger={null}
            theme="light"
            className="transition-class hidden md:block"
            style={{
              position: 'fixed',
              top: 64,
              right: showInfo ? 0 : -260,
              bottom: 0
            }}>
            <div
              style={{ height: 'calc(100% - 64px)' }}
              className="text-editor thin-scrollbar ql-editor">
              <div className="flex justify-end">
                <CloseOutlined className="close-icon" onClick={closeShowInfo} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: infoContent }} className="m-0" />
            </div>
          </Layout.Sider>

          {showHamburger ? (
            <></>
          ) : (
            <div
              className="collapseiconn"
              style={{
                left: collapsed ? 80 : 220,
                opacity: showCollapseBtn ? 1 : 0,
                visibility: showCollapseBtn ? 'visible' : 'hidden',
                transition: 'opacity 0.3s ease-in-out'
              }}
              onClick={() => {
                setCollapsed(!collapsed);
              }}>
              {collapsed ? (
                <DoubleRightOutlined style={{ fontSize: '16px', height: '25px', color: 'white' }} />
              ) : (
                <DoubleLeftOutlined style={{ fontSize: '16px', height: '25px', color: 'white' }} />
              )}
            </div>
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default Main;
