import { CloseCircleTwoTone } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, InputNumber, message, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { FormInstance } from 'rc-field-form';
import React, { useEffect, useState } from 'react';
import { create_sell_mutation, create_sell_payment } from '../../../services/sell/mutations';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';

const MultiplePay = ({
  sellData,
  onCancel,
  form2,
  setTotalLots,
  printPDF
}: {
  sellData: any;
  onCancel: () => void;
  form2: FormInstance<any>;
  setTotalLots: React.Dispatch<any>;
  printPDF: (values: any) => Promise<void>;
}) => {
  console.log('sell Data', sellData);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const createSellMutation = useMutation(create_sell_mutation);

  const createSellPayment = useMutation(create_sell_payment);

  const onFinish = async (values: any) => {
    // const copyValues = { ...values };
    console.log('Sell data', sellData);
    const { lines } = values;
    let totalAmount = 0;
    for (let ind = 0; ind < lines.length; ind++) {
      totalAmount += lines[ind].amount;
    }
    if (totalAmount < sellData.totalAmount - sellData.discount) {
      message.error(
        `Please add full Payment! Only added ${totalAmount} out of ${
          sellData.totalAmount - sellData.discount
        }`
      );
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    createSellMutation.mutateAsync(sellData, {
      onSuccess: async ({ data }: { data: any }) => {
        if (data) {
          // console.log('sell added sucessfully');
          message.success('Sell added successfully');
          setTotalLots([]);
          onFinishTwo({
            ...values,
            id: data.id,
            reference: data.referenceNumber,
            customerId: data.customerId
          });
          printPDF({
            ...sellData,
            userId: data.customerId,
            referenceNumber: data.referenceNumber,
            totalAmount: data.totalAmount
          });
        }
      },
      onError: (data: any) => {
        message.error(data.message);
        setIsLoading(false);
      }
    });
  };

  const onFinishTwo = async (values: any) => {
    setIsLoading(true);
    // console.log('Values', values);
    // console.log('selldata', sellData);
    const { lines } = values;
    // console.log('to', totalAmount);
    for (let ind = 0; ind < lines.length; ind++) {
      const cvalues = { ...lines[ind] };
      cvalues.id = values.id;
      cvalues.madeBy = values.customerId;
      cvalues.date = moment().utc().format('YYYY-MM-DD');
      cvalues.reference = values.reference;
      cvalues.walletPayment = 0;
      cvalues.adjustment = ind == lines.length - 1 ? true : false;
      await createSellPayment.mutateAsync(cvalues, {
        onSuccess: async ({ data }: { data: any }) => {
          message.success('Payment Done successfully!');
          if (ind == lines.length - 1) {
            setIsLoading(false);
            form2.setFieldValue(['lines'], []);
          }
        },
        onError: (e: any) => {
          message.error(`${e.response.data.message}`, 5);
          setIsLoading(false);
        }
      });
    }
    form.setFieldValue(['lines'], [{ amount: 0, note: '', paymentMethod: 'cash' }]);
    onCancel();
  };

  useEffect(() => {
    form.setFieldValue(['lines'], [{ amount: 0, note: '', paymentMethod: 'cash' }]);
  }, []);

  // adjustment: false;
  // amount: 10000;
  // date: '2023-03-30';
  // madeBy: 3758;
  // note: 'dfsf';
  // paymentMethod: 'cash';
  // reference: 'S-001-20230309-0000004';

  return (
    <Spin spinning={isLoading}>
      <div>Customer:{sellData.name}</div>
      <div>Amount to be paid:{nepaliNumberFormatter(sellData.totalAmount - sellData.discount)}</div>
      <div>Reference No:{sellData.referenceNumber}</div>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        // validateTrigger={'onChange'}
        // disabled={isLoading}
        autoComplete="off">
        <Form.List name={['lines']}>
          {(fields2, { add: add, remove: remove2 }, { errors: errors2 }) => (
            <>
              <div>
                {fields2.map(({ key: key2, name: name, ...restField2 }) => (
                  <div
                    key={key2}
                    style={{
                      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                      marginTop: '0.7rem',
                      padding: '0.7rem'
                    }}>
                    <div className="grid grid-cols-2 gap-2">
                      <Form.Item
                        {...restField2}
                        label="Amount"
                        name={[name, 'amount']}
                        rules={[
                          { required: true, message: 'Please add Amount!' },
                          () => ({
                            validator(_: any, value: any) {
                              if (!value) {
                                return Promise.reject(`Please input valid Amount!`);
                              }
                              if (value == 0) return Promise.reject(`Please Input valid Amount!`);
                              const allvalues = form.getFieldValue(['lines']);
                              let currTotalAmount = 0;
                              for (let ind = 0; ind < allvalues.length; ind++) {
                                if (ind == name) continue;
                                currTotalAmount += allvalues[ind].amount;
                              }
                              const remAmount =
                                sellData.totalAmount - currTotalAmount - sellData.discount;
                              if (value > remAmount) {
                                return Promise.reject(`Remaining amount only ${remAmount}`);
                              }
                              return Promise.resolve();
                            }
                          })
                        ]}>
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Payment Method"
                        name={[name, 'paymentMethod']}
                        rules={[
                          {
                            required: true,
                            message: 'Please select payment method!'
                          }
                        ]}>
                        <Select>
                          <Select.Option value="cash">Cash</Select.Option>
                          <Select.Option value="card">Card</Select.Option>
                          <Select.Option value="cheque">Cheque</Select.Option>
                          <Select.Option value="bank">Bank Tranfer</Select.Option>
                          <Select.Option value="other">Other</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="grid grid-cols-7 gap-3">
                      <Form.Item
                        className="col-span-6"
                        label="Payment Note"
                        name={[name, 'note']}
                        rules={[
                          {
                            required: true,
                            message: 'Please add a note!'
                          }
                        ]}>
                        <TextArea />
                      </Form.Item>
                      <div className="flex justify-center items-end">
                        {/* <Button
                          className="col-span-2"
                          type="primary"
                          onClick={() => {
                            remove2(name);
                          }}>
                          Remove
                        </Button> */}
                        <CloseCircleTwoTone
                          onClick={() => {
                            remove2(name);
                          }}
                          style={{ transform: 'scale(2)', marginBottom: '20px' }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-5">
                <Button
                  type="primary"
                  onClick={() => {
                    add({
                      amount: 0,
                      paymentMethod: 'cash'
                    });
                  }}>
                  Add Payment Row
                </Button>
              </div>
            </>
          )}
        </Form.List>
        <div className="flex justify-end">
          <Button type="primary" onClick={form.submit}>
            Finalize Payment
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default MultiplePay;
