import { Form, Image, Input, Menu, Spin, TableProps, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IDailyRateTableData } from '../../../services/daily-rate/types';
import { get_daily_rate_list_filter } from '../../../services/daily-rate/queries';
import { ConvertObjectToURL } from '../../../utils/converturl';
import CustomizeTable from '../../../components/Common/CustomizeTable/CustomizeTable';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import { checkAccess } from '../../../routes/acl';
import TableFilter from '../../../components/FliterTable';
import AppContent from '../../../components/Common/Content/Content';
import TableCell from '../../../components/Common/CustomizeTable/CustomCell';
import moment from 'moment';
import { optionalDateSorter } from '../../../utils/sorter.utils';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import { API_URL } from '../../../constants/config';
import ActionDropdown from '../../../components/Common/Dropdownactions';
import { EditOutlined } from '@ant-design/icons';
import { DEFAULT_DATE_FORMAT } from '@/constants';

const DailyRateList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [rateList, setRateList] = useState<IDailyRateTableData[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const breadcrumbItems = [
    {
      label: 'Daily Rate',
      link: '/daily-rate'
    }
  ];

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(100);
  };

  const getInfo = async (filter = '') => {
    setIsLoading(true);
    const response = await get_daily_rate_list_filter(filter);
    // console.log('Response --->', response);
    if (response) {
      const tableData = await Promise.all(
        response.results.map(async (value) => {
          const imgData = await get_image(`${API_URL}storage/media/${value.mediaId}`);
          return { ...value, imageUrl: imgData };
        })
      );

      setRateList(tableData);
    }
    setIsLoading(false);
  };

  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    getInfo(url);
  };

  const columns: ColumnsType<IDailyRateTableData> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 5,
      render: (txt, record, index) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Preview',
      key: 'image',
      width: 20,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              <Image width={200} src={record.imageUrl} />
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Date',
      key: 'date',
      width: 15,
      sorter: (a, b) => optionalDateSorter(a.date, b.date),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>{convertUTCStringtoLocalString(record.date, 'YYYY-MM-DD')}</TableCell>
          </>
        );
      }
    },
    {
      title: 'Created Date',
      key: 'createdAt',
      width: 15,
      sorter: (a, b) => optionalDateSorter(a.createdAt, b.createdAt),
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      render: (a, record) => {
        return (
          <>
            <TableCell>
              {convertUTCStringtoLocalString(record.createdAt, DEFAULT_DATE_FORMAT)}
            </TableCell>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (a, record) => {
        const menuItems: {
          key: string;
          label: JSX.Element;
          onClick?: () => void;
        }[] = [];

        if (checkAccess('ADMIN')) {
          menuItems.push({
            key: '1',
            label: (
              <Tooltip title="Update" color="blue">
                <Link to={`/daily-rate/${record.id}`}>
                  <EditOutlined style={{ transform: 'scale(1.4)', width: '100%' }} />
                </Link>
              </Tooltip>
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  return (
    <Spin spinning={isLoading}>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={false}
        button={
          <>
            <div>
              <TableFilter
                defaultValues={{
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  skip: 0,
                  count: 100
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                form={form}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('ADMIN') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate('/daily-rate/new')}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/daily-rate/new"
                        />
                      </div>
                    )}
                  </>
                }>
                <Form.Item name="value" label="Search">
                  <Input placeholder="Search" />
                </Form.Item>
              </TableFilter>
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={rateList}
          notshowPagination={true}
          customScroll={{ x: 600, y: '75vh' }}
          paginationDatas={{
            page: page,
            total: rateList.length,
            size: size,
            onPagination
          }}
          toSort={handleChange}
          tableName={'daily-rate-list'}
        />
      </AppContent>
    </Spin>
  );
};

export default DailyRateList;
