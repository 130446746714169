import { Form, InputNumber } from 'antd';
import React from 'react';

interface IProps {
  ref?: any;
  restField: any;
  onChangeData: () => void;
  name: number;
  autofocus?: boolean;
  onPressEnterData?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  minValue?: number;
  rules?: any[];
  disabled?: boolean;
  autoFocusRef?: any;
}
const ReusableQuantity = ({
  restField,
  onChangeData,
  autofocus = true,
  name,
  onPressEnterData,
  minValue = 0.1,
  rules = [{ required: true, message: 'Please add a Valid Quantity!' }],
  disabled = false,
  autoFocusRef
}: IProps) => {
  return (
    <Form.Item
      {...restField}
      label="Quantity"
      name={[name, 'quantity']}
      rules={rules}
      // {[{ required: true, message: 'Please add a valid Quantity!' }]}
    >
      <InputNumber
        ref={autoFocusRef}
        min={minValue}
        onChange={() => {
          if (onChangeData) {
            onChangeData();
          }
        }}
        onPressEnter={(e) => {
          if (onPressEnterData) onPressEnterData(e);
        }}
        autoFocus={autofocus}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default ReusableQuantity;
