import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Card, Divider, Form, Input, InputNumber, message, PageHeader, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../../../components/Common/Content/Content';
import { get_routes_list } from '../../../../../services/routes/queries';
import { IRoutes } from '../../../../../services/routes/types';
import {
  delete_users_address_mutation,
  update_users_address_mutation
} from '../../../../../services/users/mutations';
import { get_user_details } from '../../../../../services/users/queries';
import { RootState } from '../../../../../store/store';
import { IUserAddressDataFromServer, IDataFromServer } from '../../../../../services/users/types';
import ReuseChannel from '../../../../channel/Reuse';
import { LeftOutlined } from '@ant-design/icons';
import CustomButton from '../../../../../components/Common/CustomButton/CustomButton';
import DeleteConfirmModal from '../../../../../components/Common/Deleteconfirm/DeleteConfirmModal';
import GoBackButton from '../../../../../components/Common/GoBackButton/GoBackButton';
import CustomSubmitButton from '../../../../../components/Common/CustomButton/CustomSubmitButton';
import { RouteSearch } from '../../../../../components/Common/RouteSearch/RouteSearch';
import { valueType } from 'antd/lib/statistic/utils';
const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: '20px'
};
const { Option } = Select;

const AddressDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id, aid } = useParams();
  const [addressData, setAddressData] = useState();
  const [form] = Form.useForm();
  const center = {
    lat: 27.7172,
    lng: 85.324
  };
  const [marker, setMarkerPosition] = useState(center);
  // const { skip, count, value } = useSelector((state: RootState) => state.lists);
  // const [routesList, setRoutesList] = useState<IRoutes[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [buttonloading, setButtonloading] = useState<boolean>(false);

  // useQuery(['routes'], async () => {
  //   const response = await get_routes_list(skip, count, value);
  //   setRoutesList(response.data.results);
  //   return response;
  // });
  useQuery(['users', id], async () => get_user_details(parseInt(id as string)), {
    retry: false,
    onSuccess: (data: IDataFromServer) => {
      const index = data.addresses.findIndex(
        (val: IUserAddressDataFromServer) => val.id == Number(aid)
      );
      // setAddressData(data.addresses[index]);
      setMarkerPosition({
        lat: data.addresses[index].latitude,
        lng: data.addresses[index].longitude
      });
      form.setFieldsValue(data.addresses[index]);
      // setUserDetails(data.user);
      setIsloading(false);
    }
  });

  const updateAddressMutation = useMutation(update_users_address_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(true);
      setButtonloading(true);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Address added successfully');
        setIsloading(true);
        setButtonloading(true);
        navigate(`/users/${id}/address`);
      }
    }
  });

  const deleteAddressMutation = useMutation(delete_users_address_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(false);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Address deleted successfully');
        setIsloading(true);
        navigate(breadcrumbItems[1].link);
      }
    }
  });
  const onFinish = (values: IUserAddressDataFromServer) => {
    setIsloading(true);
    setButtonloading(true);
    values.id = Number(aid);
    values.userId = Number(id);
    values.latitude = marker.lat;
    values.longitude = marker.lng;
    updateAddressMutation.mutate(values);
  };
  const breadcrumbItems = [
    {
      label: 'Users',
      link: '/users'
    },
    {
      label: 'Address',
      link: `/users/${id}/address`
    },
    {
      label: 'Edit',
      link: `/users/${id}/address/${aid}`
    }
  ];
  const onClick = (e: google.maps.MapMouseEvent) => {
    if (e?.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      form.setFieldValue(['latitude'], lat);
      form.setFieldValue(['longitude'], lng);
      setMarkerPosition({ lat, lng });
    }
  };

  const customInput = () => {
    return <Input style={{ padding: '10px', borderRadius: '9px' }} />;
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <div className="flex justify-between">
            <GoBackButton onClick={() => navigate(breadcrumbItems[1].link)} />
            {/* <CustomButton text="Add Address" onClick={() => navigate(`/users/${id}/address/new`)} /> */}
          </div>
        }
        backgroundWhite={true}>
        <Card style={{ margin: '15px 0', borderRadius: '9px' }}>
          <Form
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            layout="vertical"
            validateTrigger={'onChange'}
            disabled={isLoading}
            autoComplete="off">
            <PageHeader
              title="Address Information"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className={'grid grid-cols-1 gap-3 sm:grid-cols-2 gap-5'}>
              <Form.Item
                name={'addressLine1'}
                label="Address 1"
                rules={[{ required: true, message: 'Please add address!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'addressLine2'}
                label="Address 2"
                rules={[{ required: true, message: 'Please add address!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'street'}
                label="Street"
                rules={[{ required: true, message: 'Please add Street!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'city'}
                label="City"
                rules={[{ required: true, message: 'Please add City!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'state'}
                label="State"
                rules={[{ required: true, message: 'Please add State!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'country'}
                label="Country"
                rules={[{ required: true, message: 'Please add Country!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'postalCode'}
                label="Postal Code"
                rules={[{ required: true, message: 'Please add Postal Code!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'district'}
                label="District"
                rules={[{ required: true, message: 'Please add district!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'ward'}
                label="Ward"
                rules={[{ required: true, message: 'Please add ward!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'municipality'}
                label="Municipality"
                rules={[{ required: true, message: 'Please add municipality!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'landmark'}
                label="Landmark"
                rules={[{ required: true, message: 'Please add landmark!' }]}>
                {/* <Input style={{ padding: '10px', borderRadius: '9px' }} /> */}
                {customInput()}
              </Form.Item>
              <Form.Item
                name={'latitude'}
                label="Latitude"
                initialValue={center.lat}
                rules={[{ required: true, message: 'Please add latitude!' }]}>
                {/* <Input /> */}
                <InputNumber
                  style={{ padding: '6px', borderRadius: '9px' }}
                  onChange={(val: any) => {
                    const long = form.getFieldValue(['longitude']);
                    if (typeof val === 'number') setMarkerPosition({ lat: val, lng: long });
                  }}
                />
              </Form.Item>
              <Form.Item
                name={'longitude'}
                label="Longitude"
                initialValue={center.lng}
                rules={[{ required: true, message: 'Please add longitude!' }]}>
                {/* <Input /> */}
                <InputNumber
                  style={{ padding: '6px', borderRadius: '9px' }}
                  onChange={(val: any) => {
                    const lat = form.getFieldValue(['latitude']);
                    if (typeof val === 'number') setMarkerPosition({ lat, lng: val });
                  }}
                />
              </Form.Item>
              <RouteSearch showAll={false} isAddress={true} />
            </div>
            <div className="flex flex-row-reverse">
              <DeleteConfirmModal
                FunctionAfterOk={() => deleteAddressMutation.mutate(parseInt(aid as string))}
                fromUserdetails={true}
              />
              <Form.Item>
                {/* <Button
                  type="primary"
                  htmlType="submit"
                  loading={buttonloading}
                  style={{ borderRadius: '5px', color: 'white', backgroundColor: '#1890ff' }}>
                  Edit Address
                </Button> */}
                <CustomSubmitButton text={'Edit Address'} />
              </Form.Item>
            </div>
            <div className="my-5">
              <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
                <GoogleMap
                  center={marker}
                  zoom={15}
                  options={{
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false
                  }}
                  onClick={onClick}
                  mapContainerStyle={containerStyle}>
                  <MarkerF position={marker} />
                </GoogleMap>
              </LoadScript>
            </div>
            {/* <div className="flex justify-end mt-5">
              <Form.Item>
                <Button
                  type="default"
                  className="flex mr-5"
                  onClick={() => {
                    navigate(`/users/${id}/address`);
                  }}>
                  Cancel
                </Button>
              </Form.Item>
            </div> */}
          </Form>
        </Card>

        <ReuseChannel slug={`address_${id}_${aid}`} />
      </AppContent>
    </div>
  );
};

export default AddressDetails;
