import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Spin,
  Table,
  TableProps,
  message
} from 'antd';
import { useEffect, useState } from 'react';
import { get_money_info } from '../../../services/pos-session/queries';
import {
  IPosMoneyDisplay,
  IPosSessionResponse,
  ISessionData,
  ISessionDataForServer
} from '../../../services/pos-session/types';
import { convertUTCStringtoLocalString } from '../../../utils/convertToUTC';
import { nepaliNumberFormatter } from '../../../utils/numberFormatter';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { IPurchaseSellSummation } from '../../../pages/sqlsource/report/purchase-sell-summation-report';
import { update_session_mutation } from '../../../services/pos-session/mutations';
import { CustomModal } from '.';
import CashSettlementPrint from '../InvoicePrint/CashSettlementPrint/CashSettlementPrint';
import { getCashSettlementPrintData } from '../InvoicePrint/CashSettlementPrint/services';
import { ICashSettlementPrint } from '../../../services/invoice/types';
import { DEFAULT_DATE_FORMAT } from '@/constants';

interface IPosClosing {
  sessionInfo?: IPosSessionResponse;
  data: IPurchaseSellSummation[];
  locationId?: number;
  handleModalClose: () => void;
}

const PosClosingModal: React.FC<IPosClosing> = ({ sessionInfo, data, handleModalClose }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isPosCashSettlementModalOpen, setIsPosCashSettlementModalOpen] = useState<boolean>(false);
  const [cashSettlementPrintData, setCashSettlementPrintData] =
    useState<ICashSettlementPrint>(Object);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleSort: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  useEffect(() => {
    refetch();
    form.setFieldValue(['grandTotal'], 0);
  }, [sessionInfo?.id]);

  const { refetch } = useQuery(['moneyInfo'], async () => await fetchMoney());

  const fetchMoney = async () => {
    setIsLoading(true);
    const response = await get_money_info();
    if (response) {
      const moneyDisplay = response.results.map((money) => {
        return {
          moneyId: money.id,
          name: `${money.name} (${money.value})`,
          value: money.value,
          quantity: 0,
          totalAmount: 0
        };
      });

      moneyDisplay.sort((a, b) => b.value - a.value);

      form.setFieldValue(['lines'], moneyDisplay);
    }
    setIsLoading(false);
  };

  const calcGrandTotal = () => {
    const lines = form.getFieldValue(['lines']);
    let grandTotal = 0;
    if (lines) {
      if (lines.length > 0) {
        lines.forEach((line: IPosMoneyDisplay) => {
          grandTotal += line.totalAmount;
        });
      }
    }

    form.setFieldValue(['grandTotal'], grandTotal);
  };

  const columns: ColumnsType<IPurchaseSellSummation> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 25,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Method',
      key: 'method',
      sorter: (a, b) => a.method.localeCompare(b.method),
      sortOrder: sortedInfo.columnKey === 'method' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{record.method}</div>;
      },
      width: 80
    },
    {
      title: 'Amount',
      key: 'amount',
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(parseFloat(record.amount))}</div>;
      },
      width: 80
    },
    {
      title: 'Wallet',
      key: 'wallet',
      sorter: (a, b) => parseFloat(a.wallet) - parseFloat(b.wallet),
      sortOrder: sortedInfo.columnKey === 'wallet' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(parseFloat(record.wallet))}</div>;
      },
      width: 80
    },
    {
      title: 'Adjusted',
      key: 'adjusted',
      sorter: (a, b) => parseFloat(a.adjusted) - parseFloat(b.adjusted),
      sortOrder: sortedInfo.columnKey === 'adjusted' ? sortedInfo.order : null,
      render: (record) => {
        return <div color="black">{nepaliNumberFormatter(parseFloat(record.adjusted))}</div>;
      },
      width: 80
    }
  ];

  const onFinish = async (values: ISessionData = form.getFieldsValue()) => {
    try {
      if (sessionInfo) {
        const linesForServer = values.lines.filter((value) => value.totalAmount !== 0);
        const data: ISessionDataForServer = {
          id: sessionInfo.id,
          lines: linesForServer
        };
        await endSessionMutation.mutateAsync(data);
      }
    } catch (errors: any) {
      message.error(errors.message);
    }
  };

  const endSessionMutation = useMutation(update_session_mutation, {
    onSuccess: async () => {
      message.success('Session ended successfully!');
      handleModalClose();
      await handlePosCashSettlementPrint();
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`);
    }
  });

  const handlePosCashSettlementPrint = async () => {
    if (sessionInfo) {
      const printData = await getCashSettlementPrintData(sessionInfo?.id, data);
      setCashSettlementPrintData(printData);
      setIsPosCashSettlementModalOpen(true);
    } else {
      message.error('Session Error. Please reload and try again!');
    }
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        title={'Cash Settlement'}
        isModalOpen={isPosCashSettlementModalOpen}
        setIsModalOpen={setIsPosCashSettlementModalOpen}
        footer={false}>
        <CashSettlementPrint
          sessionInfo={cashSettlementPrintData?.sessionInfo}
          paymentInfo={cashSettlementPrintData?.paymentInfo}
          moneyInfo={cashSettlementPrintData?.moneyInfo}
          locationDetails={cashSettlementPrintData.locationDetails}
          totalPayment={cashSettlementPrintData.totalPayment}
          sellTotal={cashSettlementPrintData.sellTotal}
          sellReturnTotal={cashSettlementPrintData.sellReturnTotal}
        />
      </CustomModal>
      <div className="bg-gray-100 grid grid-cols-2 rounded-md p-2 mb-2">
        <span>
          Start Time :{' '}
          <span className="font-bold">
            {sessionInfo?.startTime
              ? convertUTCStringtoLocalString(sessionInfo.startTime, DEFAULT_DATE_FORMAT)
              : ''}
          </span>
        </span>
        <span>
          User : <span className="font-bold">{sessionInfo?.userName}</span>
        </span>
      </div>
      <Collapse>
        <CollapsePanel header="POS Closing Report" key="1">
          <Table
            size="small"
            scroll={{ x: 1000, y: 500 }}
            pagination={false}
            columns={columns}
            dataSource={data}
            onChange={handleSort}
          />
        </CollapsePanel>
      </Collapse>
      <Divider />
      <Form form={form} onFinish={onFinish} layout="horizontal" initialValues={{ grandTotal: 0 }}>
        <div className="flex flex-col max-w-2xl mx-auto">
          <Form.List name="lines">
            {(fields) => (
              <>
                {fields.map((field) => (
                  <div key={field.key} className="flex items-center gap-3">
                    <Form.Item name={[field.name, 'moneyId']} hidden>
                      <InputNumber />
                    </Form.Item>
                    <Form.Item className="flex-1" name={[field.name, 'name']}>
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item name={[field.name, 'value']} hidden>
                      <InputNumber />
                    </Form.Item>
                    {'X'}
                    <Form.Item name={[field.name, 'quantity']} className="flex-1">
                      <InputNumber
                        min={0}
                        precision={0}
                        onChange={(val) => {
                          if (val) {
                            const total = form.getFieldValue(['lines', field.name, 'value']) * val;
                            form.setFieldValue(['lines', field.name, 'totalAmount'], total);
                          } else {
                            form.setFieldValue(['lines', field.name, 'quantity'], 0);
                            form.setFieldValue(['lines', field.name, 'totalAmount'], 0);
                          }
                          calcGrandTotal();
                        }}
                      />
                    </Form.Item>
                    {'='}
                    <Form.Item name={[field.name, 'totalAmount']} className="flex-1">
                      <InputNumber readOnly={true} min={0} />
                    </Form.Item>
                  </div>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item label="Total" name={['grandTotal']} className="self-end">
            <InputNumber />
          </Form.Item>
        </div>
        <div className="flex justify-end mt-5">
          <Form.Item>
            <Button type="primary" onClick={form.submit}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default PosClosingModal;
