// import { MinusCircleOutlined, PlusCircleFilled, PlusOutlined } from '@ant-design/icons';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, Input, InputNumber, message, PageHeader, Select } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AppContent from '../../../../../components/Common/Content/Content';
import CustomSubmitButton from '../../../../../components/Common/CustomButton/CustomSubmitButton';
import GoBackButton from '../../../../../components/Common/GoBackButton/GoBackButton';
import { RouteSearch } from '../../../../../components/Common/RouteSearch/RouteSearch';
// import { get_offer_list } from '../../../../../services/offfers/queries';
import { get_routes_list } from '../../../../../services/routes/queries';
import { IRoutes } from '../../../../../services/routes/types';
import { create_users_address_mutation } from '../../../../../services/users/mutations';
import { IUserCreateAddressRequest } from '../../../../../services/users/types';
import { RootState } from '../../../../../store/store';

const containerStyle = {
  width: '100%',
  height: '400px',
  marginBottom: '20px'
};
const { Option } = Select;

const AddressCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const center = {
    lat: 27.7172,
    lng: 85.324
  };
  const [marker, setMarkerPosition] = useState(center);
  const { skip, count, value } = useSelector((state: RootState) => state.lists);
  // const [routesList, setRoutesList] = useState<IRoutes[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);

  // useQuery(['routes'], async () => {
  //   const response = await get_routes_list(skip, count, value);
  //   setRoutesList(response.data.results);
  //   return response;
  // });
  const onClick = (e: google.maps.MapMouseEvent) => {
    if (e?.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      form.setFieldValue(['latitude'], lat);
      form.setFieldValue(['longitude'], lng);
      setMarkerPosition({ lat, lng });
    }
  };

  const createAddressMutation = useMutation(create_users_address_mutation, {
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
      setIsloading(false);
    },
    onSuccess: (data: any) => {
      if (data) {
        message.success('Address added successfully');
        setIsloading(false);
        navigate(`/users/${id}/address`);
      }
    }
  });
  const onFinish = (values: IUserCreateAddressRequest) => {
    setIsloading(true);
    values.latitude = marker.lat;
    values.longitude = marker.lng;
    values.userId = parseInt(id as string);
    createAddressMutation.mutate(values);
  };
  const breadcrumbItems = [
    {
      label: 'Users',
      link: '/users'
    },
    {
      label: 'Address',
      link: `/users/${id}/address`
    },
    {
      label: 'Create',
      link: `/users/${id}/address/new`
    }
  ];

  const customInput = (number = false) => {
    return <Input style={{ padding: '10px', borderRadius: '9px' }} />;
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        button={
          <GoBackButton
            onClick={() => {
              navigate(`/users/${id}/address`);
            }}
          />
        }>
        <Form
          form={form}
          initialValues={{}}
          onFinish={onFinish}
          disabled={isLoading}
          layout="vertical"
          validateTrigger={'onChange'}
          autoComplete="off">
          <PageHeader
            title="Address Information"
            style={{
              padding: '8px 0px'
            }}
          />
          <div className={'grid grid-cols-1 gap-3 sm:grid-cols-2 gap-5'}>
            <Form.Item
              name={'addressLine1'}
              label="Address 1"
              rules={[{ required: true, message: 'Please add address!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'addressLine2'}
              label="Address 2"
              rules={[{ required: true, message: 'Please add address!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'street'}
              label="Street"
              rules={[{ required: true, message: 'Please add Street!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'city'}
              label="City"
              rules={[{ required: true, message: 'Please add City!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'state'}
              label="State"
              rules={[{ required: true, message: 'Please add State!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'country'}
              label="Country"
              rules={[{ required: true, message: 'Please add Country!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'postalCode'}
              label="Postal Code"
              rules={[{ required: true, message: 'Please add Postal Code!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>

            <Form.Item
              name={'district'}
              label="District"
              rules={[{ required: true, message: 'Please add district!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'ward'}
              label="Ward"
              rules={[{ required: true, message: 'Please add ward!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'municipality'}
              label="Municipality"
              rules={[{ required: true, message: 'Please add municipality!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'landmark'}
              label="Landmark"
              rules={[{ required: true, message: 'Please add landmark!' }]}>
              {/* <Input /> */}
              {customInput()}
            </Form.Item>
            <Form.Item
              name={'latitude'}
              label="Latitude"
              initialValue={center.lat}
              rules={[{ required: true, message: 'Please add latitude!' }]}>
              {/* <Input /> */}
              <InputNumber
                style={{ padding: '6px', borderRadius: '9px' }}
                onChange={(val: any) => {
                  const long = form.getFieldValue(['longitude']);
                  if (typeof val === 'number') setMarkerPosition({ lat: val, lng: long });
                }}
              />
            </Form.Item>
            <Form.Item
              name={'longitude'}
              label="Longitude"
              initialValue={center.lng}
              rules={[{ required: true, message: 'Please add longitude!' }]}>
              {/* <Input /> */}
              <InputNumber
                style={{ padding: '6px', borderRadius: '9px' }}
                onChange={(val: any) => {
                  const lat = form.getFieldValue(['latitude']);
                  if (typeof val === 'number') setMarkerPosition({ lat, lng: val });
                }}
              />
            </Form.Item>
            <RouteSearch showAll={false} isAddress={true} />
            {/* <Form.Item label="Set Routes" name={'routeId'}>
              <Select defaultValue={'none'}>
                <Option key={'none'} value={undefined}>
                  None
                </Option>
                {routesList?.map((route: IRoutes) => (
                  <Option key={route.id} value={route.id}>
                    {route.name}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              name={'routeId'}
              label="Route"
              rules={[{ required: true, message: 'Please add Latitude!' }]}>
              <InputNumber  min={0} />
            </Form.Item> */}
          </div>
          <div className="my-5">
            <LoadScript googleMapsApiKey={'AIzaSyATJbgY-H04JRS-GO4GaKRHPPDHS0p2ENA'}>
              <GoogleMap
                center={marker}
                zoom={15}
                options={{
                  fullscreenControl: false,
                  streetViewControl: false,
                  mapTypeControl: false
                }}
                onClick={onClick}
                mapContainerStyle={containerStyle}>
                <MarkerF position={marker} />
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="flex justify-end mt-5">
            {/* <Form.Item>
              <Button
                type="default"
                className="flex mr-5"
                onClick={() => {
                  navigate(`/users/${id}/address`);
                }}>
                Cancel
              </Button>
            </Form.Item> */}
            {/* <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item> */}
            <CustomSubmitButton text="Submit" />
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default AddressCreate;
