import { Button, Divider, Form, Input, InputNumber, PageHeader, Select, Spin, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import AppContent from '../../../../../components/Common/Content/Content';
import { LocationSearch } from '../../../../../components/Common/LocationSearch/LocationSearch';
import { ProductsSearch } from '../../../../../components/Common/ProductsSearch';
import {
  ILotDetails,
  ILotsConversionData,
  ILotsConversionFormData,
  IProductDetails,
  IProductListResponse,
  IProductType,
  IProductUnits,
  IProductUnitsType,
  IUnits
} from '../../../../../services/products/types';
import { find_locationId_preference } from '../../../../../store/localstorage/preferences';
import {
  get_unexpired_lots_details_bylocationId_productId,
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productIds
} from '../../../../../services/products/queries';
import { ILines, Line } from '../../../../../services/sell/types';
import { numberDecimalFormatter } from '../../../../../utils/numberFormatter';
import ProductsDB from '../../../../../store/localstorage/ProductsDB';
import FormItem from 'antd/es/form/FormItem';
import UnitsDB from '../../../../../store/localstorage/UnitsDB';
import { convert_product_lot } from '../../../../../services/products/mutations';
import { useMutation } from '@tanstack/react-query';
import useDebounce from '../../../../../hooks/useDebounce';
import { CustomModal } from '../../../../../components/Common/CustomModal';
import ReusableConvertLotAdjustment from '../../../../../components/Common/CustomModal/ReusableConvertLotAdjustment';
import { WebSocketContext } from '../../../../../contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '../../../../../constants/websocketConfig';
import getErrorMessage from '@/utils/getError';

const { Option } = Select;

const ConvertLots = () => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { socket } = useContext(WebSocketContext);
  const [productInfromation, setproductInformation] = useState<IProductDetails>();
  const [convertProductInformation, setConvertProductInformation] = useState<IProductDetails>();
  //const [totalLots, settotalLots] = useState<ILotDetails[]>([]);
  const [displayLots, setDisplayLots] = useState<ILotDetails[]>([]);
  const [displayConvertLots, setDisplayConvertLots] = useState<ILotDetails[]>([]);
  const [units, setUnits] = useState<IUnits>();
  const [convertUnits, setConvertUnits] = useState<IUnits>();
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [lotSelected, setLotSelected] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueConvert, setSearchValueConvert] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const debouncedSearchValueConvert = useDebounce(searchValueConvert, 500);
  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  useEffect(() => {
    searchProductIndexDBConvert(debouncedSearchValueConvert);
  }, [debouncedSearchValueConvert]);
  const [productSearch, setProductSearch] = useState<IProductDetails[]>([]);
  const [productSearchConvert, setProductSearchConvert] = useState<IProductDetails[]>([]);
  const [modalOpenForAdjustment, setModalOpenForAdjustment] = useState<boolean>(false);
  const [adjustmentModalValues, setAdjustmentModalValues] = useState<ILotsConversionFormData>();
  const [createValues, setCreateValues] = useState<ILotsConversionData>();

  const breadcrumbItems = [
    {
      label: 'Convert Products',
      link: '/products/convertlots'
    }
  ];

  useEffect(() => {
    initializeUnits();
    setDefaultLocation();
  }, []);

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['locationId']);
      const originProductId = form.getFieldValue(['originProductId']);
      const convertProductId = form.getFieldValue(['productId']);
      if (originProductId !== null) {
        await fetchLotsOnLocationandProductChange([originProductId], locationId, 'lotsupdate');
      }
      if (convertProductId !== null) {
        await fetchConvertLotsOnLocationandProductChange(
          [convertProductId],
          locationId,
          'lotsupdate'
        );
      }
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        let updatedProducts = data.data as { productId: number; locationId: number }[];

        const originProductId = form.getFieldValue(['originProductId']);
        const convertProductId = form.getFieldValue(['productId']);
        const locationId = form.getFieldValue(['locationId']);

        if (locationId) {
          updatedProducts = updatedProducts.filter((value) => value.locationId === locationId);
        }

        const updatedProductIds = updatedProducts.map((value) => value.productId);
        if (updatedProductIds.includes(originProductId)) {
          await fetchLotsOnLocationandProductChange([originProductId], locationId, 'lotsupdate');
        }

        if (updatedProductIds.includes(convertProductId)) {
          await fetchConvertLotsOnLocationandProductChange(
            [convertProductId],
            locationId,
            'lotsupdate'
          );
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const searchProductIndexDB = async (value: string) => {
    try {
      const response = await ProductsDB.searchProduct(value);
      if (!response || response.length == 0) {
        message.error('Cannot find any product with that name in cache!');
        searchUser(value);
      } else {
        setProductSearch(response);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchProductIndexDBConvert = async (value: string) => {
    try {
      const response = await ProductsDB.searchProduct(value);
      if (!response || response.length == 0) {
        message.error('Cannot find any product with that name in cache!');
        searchUserConvert(value);
      } else {
        setProductSearchConvert(response);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchUser = async (value: string) => {
    let response;
    try {
      response = await get_product_list(0, 5000, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
    } else {
      setProductSearch(response.data.results);
      ProductsDB.addProducts(response.data.results);
    }
  };

  const searchUserConvert = async (value: string) => {
    let response;
    try {
      response = await get_product_list(0, 5000, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearchConvert([]);
    } else {
      setProductSearchConvert(response.data.results);
      ProductsDB.addProducts(response.data.results);
    }
  };

  const options = productSearch.map((value) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  const optionsConvert = productSearchConvert.map((value) => (
    <Option key={value.id} value={value.id}>
      {value.name}
    </Option>
  ));

  const setDefaultLocation = () => {
    const preferenceLocationId = find_locationId_preference();
    if (preferenceLocationId) {
      form.setFieldValue('locationId', preferenceLocationId);
    }
  };

  const initializeUnits = async () => {
    let units = (await UnitsDB.getAllUnits()) as IUnits[];
    if (!units) {
      const allUnits = await get_units_list();
      await UnitsDB.addUnits(allUnits);
      units = (await UnitsDB.getAllUnits()) as IUnits[];
    }
    setAllLocalUnits(units);
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    // setIsloading(true);
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (!productsIdArray || productsIdArray.length === 0) {
        return;
      }

      const currenttotalLots = [];
      if (from === 'productchange') {
        const response = await get_unexpired_lots_details_bylocationId_productId(
          locationId,
          productsIdArray[0]
        );
        //console.log('response', response);
        setDisplayLots([...response]);

        let productDetails: IProductType | string = await ProductsDB.getProduct(productsIdArray[0]);
        if (!productDetails) {
          get_product_list();
          productDetails = await ProductsDB.getProduct(productsIdArray[0]);
        }

        let baseUnit: IProductUnitsType[];
        if (typeof productDetails === 'object') {
          baseUnit = productDetails.productUnits.filter((unit) => unit.isDefault === true);
          form.setFieldValue(['unitId'], baseUnit[0].unitId);
        }
        const unitInfo = allLocalUnits.find((val) => baseUnit[0].unitId == val.id);
        //console.log('UnitInfo', unitInfo);
        setUnits(unitInfo);
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        setDisplayLots((prev: ILotDetails[]) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);
        currenttotalLots.push(...result);
        setDisplayLots([...currenttotalLots]);
      }
      // setIsloading(false);
    } catch (errors: any) {
      // setIsloading(false);
      message.error(errors.message);
    }
  };

  const fetchConvertLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    // setIsloading(true);
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }
      const currenttotalLots = [];
      if (from === 'productchange') {
        // const filterLots = totalLots.find((value) => value.productId == productsIdArray[0]);
        // const filtered = totalLots.filter((value) => value.productId == productsIdArray[0]);
        // setDisplayConvertLots([...filtered]);
        // if (!filterLots) {
        const response = await get_unexpired_lots_details_bylocationId_productId(
          locationId,
          productsIdArray[0]
        );
        //console.log('response', response);
        // settotalLots([...totalLots, ...response]);
        setDisplayConvertLots([...response]);
        // }

        let productDetails: IProductType | string = await ProductsDB.getProduct(productsIdArray[0]);
        if (!productDetails) {
          get_product_list();
          productDetails = await ProductsDB.getProduct(productsIdArray[0]);
        }
        let baseUnit: IProductUnitsType[];
        if (typeof productDetails === 'object') {
          baseUnit = productDetails.productUnits.filter((unit) => unit.isDefault === true);
          form.setFieldValue(['convertUnitId'], baseUnit[0].unitId);
        }
        const unitInfo = allLocalUnits.find((val) => baseUnit[0].unitId == val.id);
        //console.log('UnitInfo', unitInfo);
        setConvertUnits(unitInfo);
      } else if (from === 'lotsupdate') {
        for (let i = 0; i < productsIdArray.length; i++) {
          const result = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[i]
          );
          setDisplayConvertLots((prev) => {
            const filterLots = prev.filter((value) => value.productId !== productsIdArray[i]);

            return [...filterLots, ...result];
          });
        }
      } else {
        for (let i = 0; i < productsIdArray.length; i++) {
          const result = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[i]
          );
          currenttotalLots.push(...result);
        }
        setDisplayConvertLots([...currenttotalLots]);
      }
      // setIsloading(false);
    } catch (errors: any) {
      // setIsloading(false);
      message.error(errors.message);
    }
  };

  const onLocationChange = async (value: number) => {
    const currentProduct = form.getFieldValue(['originProductId']);
    if (currentProduct) {
      await fetchLotsOnLocationandProductChange([currentProduct], value, 'location');
      form.setFieldValue('originLotId', null);
    }
    const currentConvertProduct = form.getFieldValue(['productId']);
    if (currentConvertProduct) {
      await fetchConvertLotsOnLocationandProductChange([currentConvertProduct], value, 'location');
      form.setFieldValue('lotId', null);
      setLotSelected(false);
      form.setFieldValue('grade', null);
    }
  };

  const onProductChange = async (value: number) => {
    try {
      const selectedProduct = productSearch.find((curr) => curr.id == value);

      if (convertProductInformation?.id === selectedProduct?.id) {
        form.setFieldValue(['originProductId'], null);
        form.setFieldValue('unitId', null);
        form.setFieldValue('originLotId', null);
        throw {
          name: 'ProductError',
          message: `The product ${selectedProduct?.name} has already been selected for conversion.`
        };
      }
      setproductInformation(selectedProduct);
      form.setFieldValue(['originProductId'], value);
      const currentLocation = form.getFieldValue(['locationId']);
      await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
      form.setFieldValue('originLotId', null);
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) message.error(errors.message);
      else message.error('Empty fields found!');
      return false;
    }
  };

  const onProductClear = () => {
    setproductInformation(undefined);
    form.setFieldValue(['originProductId'], null);
    form.setFieldValue('unitId', null);
    form.setFieldValue('originLotId', null);
  };

  const onConvertProductChange = async (value: number) => {
    try {
      const selectedProduct = productSearchConvert.find((curr) => curr.id == value);

      if (selectedProduct?.id === productInfromation?.id) {
        form.setFieldValue(['productId'], null);
        form.setFieldValue('convertUnitId', null);
        form.setFieldValue('lotId', null);
        setLotSelected(false);
        form.setFieldValue('grade', null);
        throw {
          name: 'ProductError',
          message: `The product ${selectedProduct?.name} cannot be converted to the same product.`
        };
      }
      setConvertProductInformation(selectedProduct);
      form.setFieldValue(['productId'], value);
      const currentLocation = form.getFieldValue(['locationId']);
      await fetchConvertLotsOnLocationandProductChange([value], currentLocation, 'productchange');
      form.setFieldValue('lotId', null);
      setLotSelected(false);
      form.setFieldValue('grade', null);
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) message.error(errors.message);
      else message.error('Empty fields found!');
      return false;
    }
  };

  const onConvertProductClear = () => {
    setConvertProductInformation(undefined);
    form.setFieldValue(['productId'], null);
    form.setFieldValue('convertUnitId', null);
    form.setFieldValue('lotId', null);
    setLotSelected(false);
    form.setFieldValue('grade', null);
  };

  const onUnitChange = (value: number) => {
    const unitInfo = allLocalUnits.find((val) => val.id == value);
    //console.log('UnitInfo', unitInfo);
    setUnits(unitInfo);
  };

  const onConvertUnitChange = (value: number) => {
    const unitInfo = allLocalUnits.find((val) => val.id == value);
    //console.log('UnitInfo', unitInfo);
    setConvertUnits(unitInfo);
  };

  const FilterUnits = () => {
    //console.log('allLocalUnits', allLocalUnits);
    if (productInfromation) {
      const filteredUnits = productInfromation.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const FilterConvertUnits = () => {
    if (convertProductInformation) {
      const filteredUnits = convertProductInformation.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const createConvertMutation = useMutation(convert_product_lot);

  const onFinish = async (values: ILotsConversionFormData = form.getFieldsValue()) => {
    setIsloading(true);
    try {
      // const defaultUnit = productInfromation?.productUnits.find((unit) => unit.isDefault === true);
      // const defaultConvertUnit = convertProductInformation?.productUnits.find(
      //   (unit) => unit.isDefault === true
      // );
      setAdjustmentModalValues({ ...values });
      if (values.unitId) {
        // if (defaultUnit.unitId !== values.unitId) {
        let currUnit = await UnitsDB.getUnit(values.unitId);
        if (!currUnit) {
          const getAllUnit = await get_units_list();
          await UnitsDB.addUnits(getAllUnit);
          currUnit = await UnitsDB.getUnit(values.unitId);
        }
        if (typeof currUnit === 'object') {
          const BaseUnitQuantity = values.originQuantity * currUnit.baseUnitMultiplier;
          values.originQuantity = BaseUnitQuantity;
        }
        // }
      }

      const filteredLot = displayLots.find((value) => value.id == values.originLotId);
      if (filteredLot && units) {
        if (filteredLot.qtyAvailable < values.originQuantity) {
          form.setFields([
            {
              name: ['originQuantity'],
              errors: [
                `available quantity: ${
                  filteredLot.qtyAvailable / (units?.baseUnitMultiplier || 1)
                } ${units.shortName}`
              ]
            }
          ]);
          throw {
            name: 'QtyError',
            message: `The selected product ${productInfromation?.name}'s lot has quantity: ${
              filteredLot.qtyAvailable / (units?.baseUnitMultiplier || 1)
            } ${units.shortName}. Cannot send ${values.originQuantity}.`
          };
        }
      }

      if (!values.lotId && !values.grade) {
        throw {
          name: 'ConvertError',
          message: 'Either lot or grade is required for conversion.'
        };
      }

      if (values.convertUnitId && values.unitId) {
        let currUnit = await UnitsDB.getUnit(values.convertUnitId);
        if (!currUnit) {
          const getAllUnit = await get_units_list();
          await UnitsDB.addUnits(getAllUnit);
          currUnit = await UnitsDB.getUnit(values.convertUnitId);
        }
        if (typeof currUnit === 'object') {
          const BaseUnitQuantity = values.quantity * currUnit.baseUnitMultiplier;
          values.quantity = BaseUnitQuantity;
        }
      }
      if (values.lotId) {
        delete values.grade;
      }
      delete values.unitId;
      delete values.convertUnitId;
      delete values.locationId;
      delete values.originProductId;

      setCreateValues(values);

      if (values.quantity > values.originQuantity) {
        throw {
          name: 'ConvertError',
          message: 'Converted quantity must be lower than original quantity.'
        };
      } else if (values.quantity <= values.originQuantity) {
        handleAdjustmentModal();
      } else {
        createConvertMutation.mutate(values, {
          onSuccess: async (data) => {
            setIsloading(false);
            if (data) {
              //console.log('data-->', data);
              form.resetFields();
              setDefaultLocation();
              message.success('Product converted successfully!');
              setConvertProductInformation(undefined);
              setproductInformation(undefined);
            }
          },
          onError: (data: any) => {
            setIsloading(false);
            message.error(data.message);
          }
        });
      }
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) message.error(errors.message);
      else message.error(getErrorMessage(errors));
      return false;
    }
  };

  const handleAdjustmentModal = () => {
    setIsloading(false);
    setModalOpenForAdjustment(true);
  };

  const handleAdjustmentModalSubmit = () => {
    if (createValues) {
      createConvertMutation.mutate(createValues, {
        onSuccess: async (data) => {
          setIsloading(false);
          if (data) {
            //console.log('data-->', data);
            form.resetFields();
            setDefaultLocation();
            message.success('Product converted successfully!');
            setModalOpenForAdjustment(false);
            setConvertProductInformation(undefined);
            setproductInformation(undefined);
          }
        },
        onError: (data: any) => {
          setIsloading(false);
          message.error(data.message);
        }
      });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <CustomModal
        isModalOpen={modalOpenForAdjustment}
        setIsModalOpen={setModalOpenForAdjustment}
        title={'Adjustment'}
        footer={false}>
        <ReusableConvertLotAdjustment
          data={adjustmentModalValues}
          handleSubmitClick={handleAdjustmentModalSubmit}
        />
      </CustomModal>
      <AppContent breadcrumbItems={breadcrumbItems} withfilter={true}>
        <div>
          <Form form={form} layout={'vertical'} onFinish={onFinish}>
            <PageHeader
              subTitle="Initial Product"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 xl:grid-cols-5 gap-2">
              <LocationSearch onSelect={(val) => onLocationChange(val)} />
              <Form.Item
                label={'Select Product'}
                name={['originProductId']}
                rules={[
                  {
                    required: true,
                    message: 'Please choose product!'
                  }
                ]}>
                {/* <ProductsSearch
                  onSelect={(val) => {
                    onProductChange(val);
                  }}
                  noall={true}
                  setProductInformation={setproductInformation}
                /> */}
                <Select
                  showSearch
                  allowClear
                  placeholder={'search product'}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  dropdownMatchSelectWidth={false}
                  onSearch={(val) => {
                    setSearchValue(val);
                  }}
                  onClear={() => onProductClear()}
                  onSelect={(val: number) => {
                    onProductChange(val);
                  }}
                  notFoundContent={null}>
                  {options}
                </Select>
              </Form.Item>
              <Form.Item
                name={['unitId']}
                label="Unit"
                rules={[
                  {
                    required: true,
                    message: 'Please choose a Unit!'
                  }
                ]}>
                <Select
                  placeholder="Select a Unit!"
                  dropdownMatchSelectWidth={false}
                  allowClear
                  onChange={(val) => onUnitChange(val)}>
                  {FilterUnits()}
                </Select>
              </Form.Item>
              <Form.Item
                name={['originLotId']}
                label="Lot"
                rules={[
                  {
                    required: true,
                    message: 'Please choose a Lot!'
                  }
                ]}>
                <Select
                  placeholder="Select a Lot!"
                  dropdownMatchSelectWidth={false}
                  // onChange={onLocationChange}
                  allowClear>
                  {displayLots?.map((value) => (
                    <Option
                      value={value.id}
                      key={value.id}
                      style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
                      {`(${numberDecimalFormatter(
                        value.qtyAvailable / (units?.baseUnitMultiplier || 1)
                      )} ${units?.shortName || ''}) ${value.lotNumber}`}{' '}
                      {`Grade-${value.grade} Expiry-${
                        value?.expirationDate
                          ? new Date(value.expirationDate).toLocaleDateString()
                          : 'N/A'
                      }`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <FormItem
                name={['originQuantity']}
                label="Quantity"
                rules={[
                  {
                    required: true,
                    message: 'Please add quantity!'
                  }
                ]}>
                <InputNumber />
              </FormItem>
            </div>
            <Divider />
            <PageHeader
              subTitle="Convert To"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 xl:grid-cols-5 gap-2">
              <Form.Item
                label={'Select Product'}
                name={['productId']}
                rules={[
                  {
                    required: true,
                    message: 'Please choose product!'
                  }
                ]}>
                {/* <ProductsSearch
                  onSelect={(val) => {
                    onConvertProductChange(val);
                  }}
                  noall={true}
                  setProductInformation={setConvertProductInformation}
                /> */}
                <Select
                  showSearch
                  allowClear
                  placeholder={'search product'}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  dropdownMatchSelectWidth={false}
                  onSearch={(val) => {
                    setSearchValueConvert(val);
                  }}
                  onClear={() => onConvertProductClear()}
                  onSelect={(val: number) => {
                    onConvertProductChange(val);
                  }}
                  notFoundContent={null}>
                  {optionsConvert}
                </Select>
              </Form.Item>
              <Form.Item
                name={['convertUnitId']}
                label="Unit"
                rules={[
                  {
                    required: true,
                    message: 'Please choose a Unit!'
                  }
                ]}>
                <Select
                  placeholder="Select a Unit!"
                  dropdownMatchSelectWidth={false}
                  allowClear
                  onChange={(val) => onConvertUnitChange(val)}>
                  {FilterConvertUnits()}
                </Select>
              </Form.Item>
              <Form.Item name={['lotId']} label="Lot">
                <Select
                  placeholder="Select a Lot!"
                  dropdownMatchSelectWidth={false}
                  allowClear
                  onChange={(val) => {
                    val ? setLotSelected(true) : setLotSelected(false);
                  }}>
                  {displayConvertLots?.map((value) => (
                    <Option
                      value={value.id}
                      key={value.id}
                      style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
                      {`(${numberDecimalFormatter(
                        value.qtyAvailable / (convertUnits?.baseUnitMultiplier || 1)
                      )} ${convertUnits?.shortName || ''}) ${value.lotNumber}`}{' '}
                      {`Grade-${value.grade} Expiry-${
                        value?.expirationDate
                          ? new Date(value.expirationDate).toLocaleDateString()
                          : 'N/A'
                      }`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['grade']}
                label="Grade"
                hidden={lotSelected}
                rules={[
                  {
                    required: false,
                    pattern: new RegExp('^[A-Z]$'),
                    message: 'Please Input Grade(Capital Alphabet (A-Z))!'
                  }
                ]}>
                <Input maxLength={1} />
              </Form.Item>
              <FormItem
                name={['quantity']}
                label="Quantity"
                rules={[
                  {
                    required: true,
                    message: 'Please add quantity!'
                  }
                ]}>
                <InputNumber />
              </FormItem>
            </div>
            <div className="flex justify-end mt-5">
              <Form.Item>
                <Button type="primary" onClick={form.submit}>
                  Convert
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </AppContent>
    </Spin>
  );
};

export default ConvertLots;
