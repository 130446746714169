import { IJournalLinesViewResponseTableData } from '@/services/accounts/types';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import Table, { ColumnType } from 'antd/lib/table';

interface BalanceProps {
  debit: number;
  credit: number;
  currentRunningBalance: number;
  data: IJournalLinesViewResponseTableData[];
}

function generateTableSummary(columns: ColumnType<any>[], options: BalanceProps) {
  const hasRunningBalance = columns.some((column) => column.key === 'runningBalance');

  return (
    <Table.Summary fixed>
      {hasRunningBalance && (
        <Table.Summary.Row>
          {columns.map((column) => {
            const columnKey = column.key as string;
            const hasValues = ['runningBalance', 'accountName'].includes(columnKey);

            const colIndex = columns.findIndex((col) => col.key === columnKey);

            if (!hasValues) {
              return <Table.Summary.Cell key={columnKey} index={colIndex}></Table.Summary.Cell>;
            }

            return (
              <Table.Summary.Cell index={colIndex} key={columnKey} className="!px-0">
                {columnKey === 'accountName'
                  ? 'Current Closing Balance'
                  : nepaliNumberFormatter(options.currentRunningBalance)}
              </Table.Summary.Cell>
            );
          })}
        </Table.Summary.Row>
      )}

      <Table.Summary.Row>
        {columns.map((column) => {
          const columnKey = column.key as string;
          const hasValues = ['debit', 'credit', 'accountName'].includes(columnKey);

          const colIndex = columns.findIndex((col) => col.key === columnKey);

          if (!hasValues) {
            return <Table.Summary.Cell key={columnKey} index={colIndex}></Table.Summary.Cell>;
          }

          return (
            <Table.Summary.Cell key={columnKey} index={colIndex} className="!px-0">
              {columnKey === 'accountName'
                ? 'Total'
                : nepaliNumberFormatter(columnKey === 'debit' ? options.debit : options.credit)}
            </Table.Summary.Cell>
          );
        })}
      </Table.Summary.Row>
    </Table.Summary>
  );
}

export default generateTableSummary;
