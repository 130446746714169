import { useMutation } from '@tanstack/react-query';
import {
  Form,
  Button,
  Select,
  InputNumber,
  message,
  PageHeader,
  Divider,
  Checkbox,
  Input,
  Card
} from 'antd';
// import { useWatch } from 'antd/lib/form/Form';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import AppContent from '@/components/Common/Content/Content';
import { get_location_details, get_location_list } from '@/services/locations/queries';
import {
  get_product_list,
  get_units_list,
  get_unexpired_lots_details_bylocationId_productId,
  prices_by_groupId,
  get_price_groups_by_location,
  get_unexpired_lots_details_bylocationId_productIds
} from '@/services/products/queries';
import { IUnits, IProductType, ILotDetails } from '@/services/products/types';
import { create_sell_mutation } from '@/services/sell/mutations';
import {
  ICreateSell,
  ILines,
  IProductUnits,
  ISellConfirmationData,
  Line
} from '@/services/sell/types';
import {
  get_customer_details,
  get_customer_list,
  get_customer_list_route,
  get_user_details
} from '@/services/users/queries';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { IUSerlocation } from '@/services/routes/types';
import { create_tasks_mutation } from '@/services/procurement/mutations';
import useDebounce from '@/hooks/useDebounce';
import GoBackButton from '@/components/Common/GoBackButton/GoBackButton';
import ScoreBoard from '../../score-board';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { LocationSearch } from '@/components/Common/LocationSearch/LocationSearch';
import { RouteSearch } from '@/components/Common/RouteSearch/RouteSearch';
import ProductSearchForLines from '@/components/Common/ProductSearch2';
import { getUserData } from '@/utils/auth.utils';
import {
  find_default_product_category,
  find_invoice_due_limit,
  find_locationId_preference,
  find_sell_confirmation,
  find_shipping_cost_from_location,
  find_zero_total_shipping
} from '@/store/localstorage/preferences';
import ReusableQuantity from '@/components/Common/ReusableQuantity';
import CustomersDB from '@/store/localstorage/CustomerDB';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { CustomModal } from '@/components/Common/CustomModal';
import LocationsDB from '@/store/localstorage/LocationsDB';
import ReusableSellConfirm from '@/components/Common/CustomModal/ReusableSellConfirm';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import ProductCategorySearch from '@/components/Common/ProductCategorySearch/ProductCategorySearch';
import useBeforeUnloadListener from '@/hooks/useBeforeUnloadListener';

import fetchProductData from '@/utils/fetchProductData';
import { notifyMessage } from '@/utils/notifyPurchaseMessage';
import NotifySellPriceModal from '@/components/Common/NotifySellPriceModal';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent, AccountType } from '@/services/accounts/enums';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import { get_sell_discount } from '@/services/sell/queries';
import roundNumber from '@/utils/round.utils';
import DebounceButton from '@/components/Common/DebounceButton';
import useDebounceFunction from '@/hooks/useDebounceFunction';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';

interface ITaxtype {
  taxNumber: string;
  taxType: string;
  id?: string;
  userId?: number;
}

const { Option } = Select;

const CreateSell = () => {
  const navigate = useNavigate();
  const [isTask, setIsTask] = useState<boolean>(true);
  const [hasRule, setHasRule] = useState<boolean>(true);
  const [form] = Form.useForm();
  const { socket } = useContext(WebSocketContext);
  const [tax, setTax] = useState<ITaxtype[]>([]);
  const [address, setAddress] = useState<IUSerlocation[]>([]);
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);
  // const [allLots, setAllLots] = useState<any[]>([]);
  //product search
  const [searchValue] = useState<string>('');
  const [productSearch, setProductSearch] = useState<any[]>([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [confirmModalValues, setConfirmModalValues] = useState<ISellConfirmationData>();
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProduct(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const [productDetails, setProductDetails] = useState<any>();
  const [scoreBoardData, setScoreBoardData] = useState<any>();
  const [totalLots, settotalLots] = useState<any>([]);
  // const { data: routeList } = useQuery(['routeslist'], async () => get_routes_list());
  const [pricegroupDropdown, setpricegroupDropdown] = useState<any>([]);
  const [pricegroupsgroupId, setpricegroupsId] = useState<any>({});
  const [pricegrouplocationId, setpricegrouplocationId] = useState<any>({});
  const [defaultPriceGroup, setdefaultPriceGroup] = useState<any>([]);
  const loggedInUser = getUserData();
  const autofocusRef: React.Ref<any> = useRef(null);
  const preferenceLocationId = find_locationId_preference();
  const scrollElement = useRef<HTMLDivElement>(null);
  const [modalOpenForInvoiceDueLimit, setModalOpenForInvoiceDueLimit] = useState<boolean>(false);
  const [modalOpenForConfirmation, setModalOpenForConfirmation] = useState<boolean>(false);
  const [errormessage, setErrorMessage] = useState<string>('');
  const [isRouteSelected, setIsRouteSelected] = useState<boolean>(false);
  const [manualShipping, setManualShipping] = useState<boolean>(false);
  const [productCategory, setProductCategory] = useState<number | string | null>(
    find_default_product_category()
  );
  const [confirm, setConfirm] = useState<boolean>(false);

  const [purchasedPrices, setPurchasedPrices] = useState<any[]>([]);
  const [createValues, setCreateValues] = useState<ICreateSell>();
  const breadcrumbItems = [
    { label: 'Sell', link: '/sell' },
    { label: 'Create', link: '/sell/new' }
  ];
  const [searchValueUser, setSearchValueUser] = useState<string>('');
  const debouncedSearchValueUser = useDebounce(searchValueUser, 500);
  const [selectValue, setselectValue] = useState<any>();

  // searchValue for locationSearch starts
  const [searchLocationValue, setSearchLocationValue] = useState<string>('');
  const debouncedSearchLocationValue = useDebounce(searchLocationValue, 500);
  const [isCacheResponse, setIsCacheResponse] = useState<boolean>(false);
  const [notifyModalData, setNotifyModalData] = useState<any>([]);

  useEffect(() => {
    // searchLocation(debouncedSearchValue);
    searchLocationIndexDB(debouncedSearchLocationValue);
  }, [debouncedSearchLocationValue]);
  const [locationSearch, setLocationSearch] = useState<any>([]);
  //for locationSearch Ends

  //to set RouteId according to preference locationId at first
  useEffect(() => {
    if (preferenceLocationId) {
      fetchPreferenceRouteId(preferenceLocationId);
    }
  }, [preferenceLocationId]);

  const fetchPreferenceRouteId = async (preferenceLocationId: number) => {
    let locationDetails: any = await LocationsDB.getLocation(preferenceLocationId);
    if (!locationDetails) {
      locationDetails = await get_location_details(preferenceLocationId);
      await LocationsDB.addLocations([locationDetails]);
    }
    if (locationDetails.routeId)
      form.setFieldValue(['routeId'], locationDetails.routeId), searchUser('');
    else form.setFieldValue(['routeId'], '');
  };

  useEffect(() => {
    searchUser(debouncedSearchValueUser);
    // searchUser(debouncedSearchValue);
  }, [debouncedSearchValueUser]);
  const [userSearch, setUserSearch] = useState<any>([]);
  const [skip, setSkip] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const locationId = form.getFieldValue(['locationId']);
      const lines = form.getFieldValue(['lines']);
      const productIds = new Set<number>(
        lines.map((value: ILines) => {
          return value.productId;
        })
      );
      await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const lines = form.getFieldValue(['lines']);
        const productIds = new Set<number>(lines.map((value: ILines) => value.productId));

        const locationId = form.getFieldValue(['locationId']);
        // Get Products and filter by Location
        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          const updatedProductIds = updatedProducts.map((value) => value.productId);
          await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected]);

  const searchUser = async (value: any) => {
    let response;
    try {
      const routeId = form.getFieldValue('routeId');
      if (routeId != '' && routeId != undefined) {
        setIsRouteSelected(true);
        response = await get_customer_list_route(0, 10, value, routeId);
      } else {
        setIsRouteSelected(false);
        form.setFieldValue('userId', null);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any customer with that value in server!');
      setUserSearch([]);
      setIsMore(false);
      setSkip(0);
    } else {
      setUserSearch(response.data.results);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) setIsMore(false);
      else setIsMore(true);
      setSkip(10);
    }
  };

  const searchMoreUser = async (value: any) => {
    let response;
    try {
      const routeId = form.getFieldValue('routeId');
      if (routeId != '' && routeId != undefined) {
        response = await get_customer_list_route(skip, 10, value, routeId);
      } else {
        response = await get_customer_list(skip, 10, value);
      }
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.info('Cannot find more customer with that value in server!');
      setIsMore(false);
    } else {
      setSkip(skip + 10);
      setUserSearch([...userSearch, ...response.data.results]);
      CustomersDB.addCustomers(response.data.results);
      if (response.data.results.length < 10) {
        setIsMore(false);
      }
    }
  };

  const optionsUser = userSearch
    .filter((curr: any) => !curr.user.disabled)
    .map((value: any) => (
      <Option key={value.id} value={value.id}>
        <div className="flex justify-between">
          <div>
            {' '}
            {value.name ? value.name : value?.user?.name},{' '}
            {value.phone ? value.phone : value?.user?.phone}{' '}
          </div>
          <div style={{ marginRight: '15%', color: 'green' }}>
            {selectValue && selectValue == value.id ? (
              <>
                <span className="text-xl font-bold">{value.grade}</span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Option>
    ));
  const createSellMutation = useMutation(create_sell_mutation);

  const onUserChange = async (id: number) => {
    let selectedUser: any = await CustomersDB.getCustomer(id);
    let userDetails: any; //for address
    if (!selectedUser) {
      const response = await get_customer_details(id);
      // console.log(response);
      if (response?.user && response?.customer) {
        selectedUser = response.customer;
        CustomersDB.addCustomers([{ ...response.customer, user: response?.user?.user }]);
        userDetails = response.user;
      }
    }

    if (selectedUser) {
      const userAccount = await get_account_details_by_userid_type(
        selectedUser.userId,
        AccountType.USER
      );

      if (userAccount?.isArchived) {
        message.error({
          content: (
            <span>
              Unable to create a sale of this customer. <strong>Reason:</strong>{' '}
              <span className="text-red-500">Account Archived</span>
            </span>
          ),
          duration: 5
        });
        form.setFieldValue('userId', null);
        return;
      }
    }

    // Find Discount
    await getAllDiscounts();

    const scoreDetails = JSON.parse(selectedUser.scoreCard);
    if (scoreDetails) {
      const diff = scoreDetails?.numberOfSales - scoreDetails?.numberOfPayments;
      const limitdiff = find_invoice_due_limit();
      if (diff > limitdiff) {
        // message.error({
        //   content: `This customer has pending payments for ${diff} sales greater than limit.`,
        //   duration: 3
        // });

        setErrorMessage(
          `This customer has pending payments for ${diff} sales greater than limit ${limitdiff}.`
        );
        setModalOpenForInvoiceDueLimit(true);
        // form.setFieldValue('userId', null);
        // return;
      }
    }
    // let userDetails: any = await UsersDB.getUser(selectedUser.userId);
    if (!userDetails) {
      userDetails = await get_user_details(selectedUser.userId);
    }
    if (userDetails.addresses.length > 0) {
      form.setFieldValue('addressId', userDetails.addresses[0].id);
      // const findOne = userDetails.addresses.find((curr: any) => curr.routeId !== null);
      // if (findOne) form.setFieldValue(['routeId'], findOne.routeId);
    } else {
      form.setFieldValue('addressId', null);
    }

    setAddress(userDetails.addresses);
    setTax(userDetails.tax_informations);
    setScoreBoardData({ userId: id, allData: JSON.parse(selectedUser.scoreCard) });
  };

  const getAllDiscounts = async () => {
    const lines = form.getFieldValue(['lines']);

    const customerId = form.getFieldValue(['userId']);
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }
    if (currentLocationId) {
      const linesWithQuantity = lines.map((line: any) => {
        return { ...line, quantity: line.quantity || 0 };
      });

      if (linesWithQuantity.length > 0) {
        const topOffer = await get_sell_discount(currentLocationId, customerId, linesWithQuantity);

        if (topOffer) {
          // console.log('topOffer', topOffer);
          for (let i = 0; i < lines.length; i++) {
            if (lines[i].productId) {
              const discPer = topOffer.find(
                (val) => val.productId === lines[i].productId
              )?.discountPercent;
              form.setFieldValue(['lines', i, 'discountPer'], discPer);
              onUnitandQuantityChange(i);
            }
          }
        } else {
          message.error('Offer not found.');
        }
      }
    } else {
      message.error('Location not found.');
    }
  };

  const createTaskMutation = useMutation(create_tasks_mutation, {
    onSuccess: (data: any) => {
      message.success('Task added successfully');
    },
    onError: (data: any) => {
      message.error(data.message);
    }
  });

  const onFinishInitial = async (values: ICreateSell = form.getFieldsValue()) => {
    setIsloading(true);
    const userId = values.userId;
    const routeId = values.routeId;
    let grandTotal = 0;
    if (values.grandtotal) {
      grandTotal = values.grandtotal;
    }
    // console.log('Values', values);
    delete values.userId;
    delete values.routeId;
    delete values.grandtotal;
    values.cratesSent = 0;
    // values.sellOrderId = 0;
    try {
      //validation
      if (values.lines.length == 0)
        throw {
          name: 'LinesError',
          message: 'Please select atleast one Product.'
        };

      if (productCategory !== null) {
        if (typeof productCategory === 'number') {
          values.categoryId = productCategory;
        } else {
          const defaultCategory = find_default_product_category();
          if (defaultCategory === null) {
            throw {
              name: 'CategoryError',
              message: 'Please select a default Product Category from preferences.'
            };
          } else {
            values.categoryId = defaultCategory;
          }
        }
      } else {
        throw {
          name: 'CategoryError',
          message: 'Please select a Product Category.'
        };
      }

      const modalValues = { ...values, userId: userId, routeId: routeId, grandTotal: grandTotal };
      for (let i = 0; i < values.lines.length; i++) {
        delete values.lines[i].productName;
        delete values.lines[i].total;
        delete values.lines[i].sellingPrice;
        const filteredLot = totalLots.find((value: any) => value.id == values.lines[i].lotId);
        const unitId = values.lines[i].unitId;
        const findOne: any = allLocalUnits.find((curr: any) => curr.id === unitId);
        if (filteredLot.qtyAvailable < values.lines[i].quantity * findOne.baseUnitMultiplier) {
          form.setFields([
            {
              name: ['lines', i, 'quantity'],
              errors: [
                `available quantity: ${filteredLot.qtyAvailable / findOne.baseUnitMultiplier} ${
                  findOne.shortName
                }`
              ]
            }
          ]);
          throw {
            name: 'QtyError',
            message: `The selected product number ${i + 1}. ${
              values.lines[i].productName
            }'s lot has quantity: ${
              filteredLot.qtyAvailable / findOne.baseUnitMultiplier
            }. Cannot send ${values.lines[i].quantity}.`
          };
        }
      }

      values.date = JSON.stringify(new Date()).slice(1, -1);
      values.createdBy = loggedInUser.id;
      setCreateValues(values);

      if (find_sell_confirmation()) {
        setIsloading(false);
        confirmationPopup(modalValues);
      } else {
        const { lines, locationId } = values;
        const response = await get_sell_discount(locationId, Number(selectValue), lines);

        lines.forEach((line) => delete line.hsCode);

        const changedLines = [];

        for (const data of response) {
          const lineIndex = lines.findIndex((line: any) => line.productId === data.productId);

          if (lineIndex !== -1) {
            const line = lines[lineIndex];
            const isDiscountSame = line.discount === data.discount;
            if (!isDiscountSame) {
              // Replace the discount with the new one in the form values
              form.setFieldValue(['lines', lineIndex, 'discountPer'], data.discountPercent);
              onUnitandQuantityChange(lineIndex);
              changedLines.push(line.productName);
            }
          }
        }

        if (changedLines.length > 0) {
          CustomErrorModal({
            message: `Discount for ${changedLines.join(', ')} was updated. Please submit again!`
          });

          setIsloading(false);
          setModalOpenForConfirmation(false);
          return;
        }

        values.discount = 0;

        await createSellMutation.mutateAsync(values, {
          onSuccess: async ({ data }: { data: any }) => {
            setIsloading(false);
            if (data) {
              // console.log('data-->', data);
              message.success('Sell added successfully');
              if (isTask) {
                createTaskMutation.mutate({
                  name: ' ',
                  description: ' ',
                  assignee: null,
                  reference: 'sell',
                  referenceId: data.id,
                  status: 'assigned',
                  dueDate: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
                });
              }
              navigate('/sell');
            }
          },
          onError: async (data: any) => {
            setIsloading(false);
            if (data.response.data.message === 'Error Discount is not valid') {
              await getAllDiscounts();
              CustomErrorModal({ message: 'Discount was updated. Please click submit again!' });
              return false;
            } else {
              message.error(data.response.data.message);
            }
          }
        });
      }
    } catch (errors: any) {
      setIsloading(false);
      if ('name' in errors) {
        CustomErrorModal({
          title: 'Error! Cannot create sell',
          message: errors.message
        });
      } else {
        CustomErrorModal({
          title: 'Error! Cannot create sell',
          message: errors.message
        });
      }
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    try {
      if (!locationId) {
        throw {
          name: 'Location Error',
          message: 'Please select Location!'
        };
      }

      if (productsIdArray.length === 0) {
        return;
      }

      const currenttotalLots = [];
      if (from === 'productchange') {
        const filterLots = totalLots.find((value: Line) => value.productId == productsIdArray[0]);
        if (!filterLots) {
          const response = await get_unexpired_lots_details_bylocationId_productId(
            locationId,
            productsIdArray[0]
          );
          settotalLots([...totalLots, ...response]);
        }
      } else if (from === 'lotsupdate') {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        settotalLots((prev: ILotDetails[]) => {
          const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
          return [...filterLots, ...result];
        });
      } else {
        const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
          ...new Set(productsIdArray)
        ]);

        currenttotalLots.push(...result);
        settotalLots([...currenttotalLots]);
      }
    } catch (errors: any) {
      message.error(errors.message);
    }
  };

  const checkAccountRule = async (locationId: number) => {
    if (
      (await checkHasAccountRule(locationId, AccountRulesEvent.SELL_CREATE)) &&
      (await checkHasAccountRule(locationId, AccountRulesEvent.VAT_CREATE))
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.SELL_CREATE}" or "${AccountRulesEvent.VAT_CREATE}" rule has not been created!`
      });
    }
  };

  const onLocationChange = async (value: number) => {
    if (value) {
      // Check Rule
      await checkAccountRule(value);

      // Find Discount
      await getAllDiscounts();

      const lines = form.getFieldValue(['lines']);
      let grandTotal = 0;
      for (let i = 0; i < lines.length; i++) {
        grandTotal += lines[i].total + lines[i].vat;
      }
      if (!manualShipping) {
        await calculateShipping(grandTotal);
      }
    }
    const mySet = new Set<number>();
    const data = form.getFieldValue(['lines']);
    if (data) {
      data.map((curr: any, ind: number) => {
        mySet.add(curr.productId);
        form.setFieldValue(['lines', ind, 'lotId'], null);
      });
    }
    form.setFieldValue('location', value);
    fetchLotsOnLocationandProductChange(Array.from(mySet), value, 'location');
    // let defaultPrice: any = [];
    let currPriceGroups: any = [];
    if (pricegrouplocationId[value]) {
      setpricegroupDropdown(pricegrouplocationId[value]);
      currPriceGroups = [...pricegrouplocationId[value]];
    } else {
      const response = await get_price_groups_by_location(value);
      let defaultpricegroup = [...defaultPriceGroup];
      if (defaultpricegroup.length == 0) {
        const response2 = await get_price_groups_by_location('');
        setdefaultPriceGroup(response2.data);
        defaultpricegroup = [...response2.data];
      }
      if (response?.data) {
        currPriceGroups = [...response.data, ...defaultpricegroup];
        setpricegroupDropdown([...response.data, ...defaultpricegroup]);
        setpricegrouplocationId((prev: any) => ({
          ...prev,
          [value]: [...response.data, ...defaultpricegroup]
        }));
      }
    }
    if (data.length == 0) {
      return;
    }
    if (currPriceGroups.length == 0) {
      for (let lineindex = 0; lineindex < data.length; lineindex++) {
        form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
        form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
      }
    }
    const foundArray = new Array(data.length).fill(false);
    for (let ind = 0; ind < currPriceGroups.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[currPriceGroups[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[currPriceGroups[ind].id];
      } else {
        const response2 = await prices_by_groupId(currPriceGroups[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [currPriceGroups[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      for (let lineindex = 0; lineindex < data.length; lineindex++) {
        if (!foundArray[lineindex]) {
          const selectedProductId = data[lineindex].productId;
          const selectedUnitId = data[lineindex].unitId;
          const findOne = currDefaultPrice.find(
            (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
          );
          if (findOne) {
            form.setFieldValue(['lines', lineindex, 'unitPrice'], findOne.sellingPrice);
            form.setFieldValue(['lines', lineindex, 'priceGroupId'], findOne.priceGroupId);
            foundArray[lineindex] = true;
          } else {
            form.setFieldValue(['lines', lineindex, 'unitPrice'], 0);
            form.setFieldValue(['lines', lineindex, 'priceGroupId'], null);
          }
        }
      }

      const checkallisfound = foundArray.find((curr: any) => !curr);
      if (checkallisfound == undefined) {
        break;
      }
    }
  };
  const FilterUnits = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);
    if (checkCurrentProduct && productList) {
      const selectedProduct = productList.find(
        (val: IProductType) => val.id == checkCurrentProduct
      );
      const filteredUnits = selectedProduct.productUnits.map((value: IProductUnits) => {
        const data = allLocalUnits.find((val: IUnits) => value.unitId == val.id);
        return data;
      });
      return (
        <>
          {filteredUnits.map((value: any) => {
            if (!value) return null;
            return (
              <Option value={value.id} key={value.id}>
                {`${value.name}`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  function onLotChange(name: number, value: number) {
    const productId = form.getFieldValue(['lines', name, 'productId']);
    if (!productId) {
      return message.error('Failed to get product id');
    }

    let filteredLots: any = [];
    if (totalLots.length !== 0) {
      filteredLots = totalLots.filter((currLot: any) => currLot.productId === productId);
    }

    const currentSelectedLot = filteredLots.find((currLot: any) => currLot.id === value);
    if (!currentSelectedLot) {
      return message.error('Failed to assign hs code based on selected lot');
    }

    form.setFieldValue(['lines', name, 'hsCode'], currentSelectedLot?.hsCode);
  }

  const FilterLot = (name: number) => {
    const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);

    const currentLocation = form.getFieldValue(['locationId']);

    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => (
            <Option
              value={value.id}
              key={value.id}
              style={{ color: value.qtyAvailable > 0 ? 'green' : 'red' }}>
              {`(${numberDecimalFormatter(
                value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)
              )} ${unitInfo?.shortName || ''}) ${value.lotNumber}`}{' '}
              {`Grade-${value.grade} Expiry-${
                value?.expirationDate ? new Date(value.expirationDate).toLocaleDateString() : 'N/A'
              }`}
            </Option>
          ))}
        </>
      );
    }
  };

  const onProductChange = async (value: number, name?: number) => {
    // form.setFieldValue([name, 'productId'], value);
    const data = form.getFieldValue(['lines']);
    const currentLocation = form.getFieldValue(['locationId']);
    await fetchLotsOnLocationandProductChange([value], currentLocation, 'productchange');
    if (productSearch) {
      const selectedProduct = productSearch.find((val) => val.id == value);
      // const selectedProduct = productList.data.results.find((val: IProductType) => val.id == value);
      const unitIdList: IProductUnits[] = selectedProduct.productUnits;
      const existingProduct = data.find((valueUnitTest: any) => valueUnitTest.productId == value);
      // console.log(existingProduct, data, selectedProduct);
      if (existingProduct && existingProduct.unitId) {
        form.setFieldValue(['lines', data.length - 1, 'unitId'], existingProduct.unitId);
      } else {
        const defaultUnit = unitIdList.find((currUnit: any) => currUnit.isDefault === true);
        form.setFieldValue(['lines', data.length - 1, 'unitId'], defaultUnit?.unitId);
      }

      let fetch = false;
      for (const value of unitIdList) {
        let unitData;
        try {
          unitData = await UnitsDB.getUnit(value.unitId);
        } catch (e) {
          console.log(e);
        }
        if (!unitData) {
          const response = await get_units_list();
          await UnitsDB.addUnits(response);
          fetch = true;
        }
      }
      if (fetch) setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
      // if (!form.getFieldValue('offerId')) {
      //   const wholeDiscountOffer = offerList.find((curr: any) => curr.wholeDiscount != null);
      //   if (wholeDiscountOffer) {
      //     if (form.getFieldValue(['applyOffer']))
      //       form.setFieldValue('offerId', wholeDiscountOffer.id);
      //     else setLatestOfferId(wholeDiscountOffer.id);
      //     // form.setFieldValue('offerId', wholeDiscountOffer.id);
      //   } else {
      //     for (let ind = 0; ind < offerList.length; ind++) {
      //       if (offerList[ind].productDiscount) {
      //         const productDiscountArray = JSON.parse(offerList[ind].productDiscount);
      //         const findProduct = productDiscountArray.find(
      //           (currPro: any) => currPro.productId == selectedProduct.id
      //         );
      //         if (findProduct) {
      //           if (form.getFieldValue(['applyOffer']))
      //             form.setFieldValue('offerId', offerList[ind].id);
      //           else setLatestOfferId(offerList[ind].id);
      //           break;
      //         } else {
      //           continue;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    const selectedUnitId = form.getFieldValue(['lines', data.length - 1, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', data.length - 1, 'productId']);
    const purchasedPrice = await fetchProductData(
      data.length - 1,
      selectedProductId,
      selectedUnitId,
      currentLocation
    );
    const productInfo = {
      productId: selectedProductId,
      unitId: selectedUnitId,
      purchasedPrice
    };

    setPurchasedPrices([...purchasedPrices, productInfo]);

    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    // Find Discount
    const customerId = form.getFieldValue(['userId']);
    if (currentLocationId && selectedProductId && customerId) {
      const lines = form.getFieldValue(['lines']);
      const linesWithQuantity = lines.map((line: any) => {
        return { ...line, quantity: line.quantity || 0 };
      });

      const topOffer = await get_sell_discount(currentLocationId, customerId, linesWithQuantity);

      if (topOffer) {
        const discount = topOffer[0].discountPercent;
        form.setFieldValue(['lines', data.length - 1, 'discountPer'], discount);
      } else {
        message.error('Offer not found.');
      }
    } else {
      message.error('Location not found.');
    }

    for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
      } else {
        const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [pricegroupDropdown[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
      );
      // console.log('findone', findOne);
      if (findOne) {
        form.setFieldValue(['lines', data.length - 1, 'priceGroupId'], findOne.priceGroupId);
        onPriceGroupChange(data.length - 1);
        break;
      } else {
        form.setFieldValue(['lines', data.legth - 1, 'priceGroupId'], null);
      }
    }
  };

  const searchProduct = async (value: any) => {
    if (value != '') {
      let response;
      try {
        response = await get_product_list(0, 5, value);
      } catch (e) {
        console.log(e);
      }
      if (!response || response.data.results.length == 0) {
        message.error('Cannot find any product with that name!');
        setProductSearch([]);
      } else {
        setProductSearch(response.data.results);
        checkProductAndAdd(response.data.results);
      }
    }
    // setSearchLoading(false);
  };

  const checkProductAndAdd = (products: any[]) => {
    if (productList.length > 0) {
      products = products.filter((value) => {
        const searchProduct = productSearch.find((val) => val.id == value.id);
        if (searchProduct) return false;
        return true;
      });
      if (products.length > 0)
        setProductList((prevValue) => {
          return [...prevValue, ...products];
        });
    } else {
      setProductList(products);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      locationId: preferenceLocationId ? preferenceLocationId : null,
      addressId: null,
      taxId: null,
      lines: [],
      discount: 0,
      misc: 0,
      shipping: 0,
      adjustment: 0,
      offerId: null,
      note: ''
      // status: 'pending'
    });
    initializeUnits();
    if (preferenceLocationId) {
      onLocationChange(preferenceLocationId);
    }
  }, []);

  useBeforeUnloadListener();

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  const calculateTotal = async () => {
    const lines = form.getFieldValue(['lines']);
    let grandTotal = 0;
    let discountTotal = 0;
    if (lines !== undefined) {
      for (let i = 0; i < lines.length; i++) {
        grandTotal += lines[i].total + lines[i].vat;
        lines[i].discount = parseFloat(lines[i].discount);
        discountTotal += lines[i].discount;
      }
    }
    form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
    form.setFieldValue(['discount'], numberDecimalFormatter(discountTotal));

    if (!manualShipping) {
      await calculateShipping(grandTotal);
    }
  };

  const calculateShipping = async (grandTotal: number) => {
    if (grandTotal > find_zero_total_shipping()) {
      form.setFieldValue(['shipping'], 0);
      calculateShippingWithTax(0);
    } else {
      const locationId = form.getFieldValue(['locationId']);
      if (!locationId) {
        message.error('Please select location!');
      } else {
        const shippingCost = await find_shipping_cost_from_location(locationId);
        form.setFieldValue(['shipping'], shippingCost);
        calculateShippingWithTax(shippingCost);
      }
    }
  };

  const calculateShippingWithTax = async (val: number | null) => {
    if (val !== null) {
      const shippingTax = val * (13 / 100);
      const shippingWithTax = (val + shippingTax).toFixed(2);
      form.setFieldValue(['shippingWithTax'], shippingWithTax);
    }
  };

  const onUnitandQuantityChange = async (name: number) => {
    // console.log('name', name);
    // console.log('number', value);
    const rate = form.getFieldValue(['lines', name, 'unitPrice']);
    const qty = form.getFieldValue(['lines', name, 'quantity']);
    const mis = form.getFieldValue(['lines', name, 'misc']);

    let total = rate * qty + mis;
    const productId = form.getFieldValue(['lines', name, 'productId']);

    // For Discount
    const discountPer = form.getFieldValue(['lines', name, 'discountPer']);
    const discount = roundNumber((total * discountPer) / 100);
    total = parseFloat((total - discount).toFixed(2));

    form.setFieldValue(['lines', name, 'discount'], discount);

    let vat = 0;
    let productDetail = await ProductsDB.getProduct(productId);
    if (!productDetail) {
      const allProducts = await get_product_list();
      await ProductsDB.addProducts(allProducts.data.results);
      productDetail = await ProductsDB.getProduct(productId);
    }
    if (typeof productDetail === 'object' && productDetail.vat) {
      vat = total * (productDetail.vat / 100);
    }
    form.setFieldValue(['lines', name, 'vat'], vat);
    form.setFieldValue(['lines', name, 'total'], total);

    const lines = form.getFieldValue(['lines']);
    // console.log('lines', lines);
    let grandTotal = 0;
    let discountTotal = 0;
    for (let i = 0; i < lines.length; i++) {
      grandTotal += lines[i].total + lines[i].vat;
      lines[i].discount = parseFloat(lines[i].discount);
      discountTotal += lines[i].discount;
    }
    // console.log('---', form.getFieldsValue());
    // console.log('grandtotal', grandTotal);

    form.setFieldValue(['grandtotal'], numberDecimalFormatter(grandTotal));
    form.setFieldValue(['discount'], numberDecimalFormatter(discountTotal));
    // const shippingCost = find_shipping_cost(grandTotal);
    if (!manualShipping) {
      await calculateShipping(grandTotal);
    }

    // form.setFieldValue(['shipping'], numberDecimalFormatter(shippingCost));

    // const grandTotall = form.getFieldValue(['lines','grandtotal']);
    // console.log('grandTotall field value', grandTotall);
  };

  const onPriceGroupChange = async (val: number) => {
    const productInfo = form.getFieldValue(['lines', val]);
    // console.log('product Info', productInfo);
    let defaultPrice: any = [];
    const locationId = form.getFieldValue(['locationId']);
    // console.log('pricegroupsgroupId', pricegroupsgroupId);
    if (locationId && productInfo.priceGroupId) {
      if (!pricegroupsgroupId[productInfo.priceGroupId]) {
        const response2 = await prices_by_groupId(productInfo.priceGroupId);
        defaultPrice = [...response2.data];
        // pricegroupsgroupId[productInfo.priceGroupId] = [...response2.data];
        setpricegroupsId((prev: any) => ({
          ...prev,
          [productInfo.priceGroupId]: response2.data
        }));
      } else {
        defaultPrice = pricegroupsgroupId[productInfo.priceGroupId];
      }
    }

    const alreadySelectedUnitId = form.getFieldValue(['lines', val, 'unitId']);
    const findone = defaultPrice.find(
      (res2elem: any) =>
        res2elem.productId == productInfo.productId && res2elem.unitId == alreadySelectedUnitId
    );
    if (findone) {
      form.setFieldValue(['lines', val, 'unitPrice'], findone.sellingPrice);
    } else {
      form.setFieldValue(['lines', val, 'unitPrice'], 0);
    }
  };

  const onUnitChange = async (name: number, unitId?: any) => {
    const selectedUnitId = form.getFieldValue(['lines', name, 'unitId']);
    const selectedProductId = form.getFieldValue(['lines', name, 'productId']);
    if (unitId) {
      const allLines = form.getFieldValue(['lines']);
      // console.log(allLines, unitId);
      allLines.map((val: any, index: number) => {
        // console.log(selectedProductId, val.productId);
        if (val.productId == selectedProductId) {
          form.setFieldValue(['lines', index, 'unitId'], unitId);
        }
      });
    }
    // console.log('pid', selectedProductId);
    // console.log('uid', selectedUnitId);
    const currentLocationId = form.getFieldValue(['locationId']);
    if (!currentLocationId) {
      message.error('Please select locationId.');
      return;
    }

    for (let ind = 0; ind < pricegroupDropdown.length; ind++) {
      let currDefaultPrice: any = [];
      if (pricegroupsgroupId[pricegroupDropdown[ind].id]) {
        currDefaultPrice = pricegroupsgroupId[pricegroupDropdown[ind].id];
      } else {
        const response2 = await prices_by_groupId(pricegroupDropdown[ind].id);
        setpricegroupsId((prev: any) => ({
          ...prev,
          [pricegroupDropdown[ind].id]: response2.data
        }));
        currDefaultPrice = response2.data;
      }
      const findOne = currDefaultPrice.find(
        (curr: any) => curr.productId == selectedProductId && curr.unitId == selectedUnitId
      );
      // console.log('findone', findOne);
      if (findOne) {
        form.setFieldValue(['lines', name, 'unitPrice'], findOne.sellingPrice);
        form.setFieldValue(['lines', name, 'priceGroupId'], findOne.priceGroupId);
        break;
      } else {
        form.setFieldValue(['lines', name, 'unitPrice'], 0);
        form.setFieldValue(['lines', name, 'priceGroupId'], null);
      }
    }
    onUnitandQuantityChange(name);
    const purchasedPrice = await fetchProductData(
      name,
      selectedProductId,
      selectedUnitId,
      currentLocationId
    );

    const productInfo = {
      productId: selectedProductId,
      unitId: selectedUnitId,
      purchasedPrice
    };

    setPurchasedPrices([...purchasedPrices, productInfo]);
  };

  const searchLocationIndexDB = async (value: string) => {
    try {
      const response = await LocationsDB.searchLocationByName(value, 100);
      if (!response || response.length == 0) {
        message.info('Cannot find any location with that name in cache, searching in server...');
        searchLocation(value);
      } else {
        setLocationSearch(response);
        setIsCacheResponse(true);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchLocation = async (value: any) => {
    let response;
    try {
      response = await get_location_list(0, 100, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any location with that name in server!');
      setLocationSearch([]);
    } else {
      setLocationSearch(response.data.results);
      LocationsDB.addLocations(response.data.results);
    }
    setIsCacheResponse(false);
  };

  const optionsLocations = () => {
    const routeId = form.getFieldValue(['routeId']);
    console.log('RouteID ', routeId);

    if (!routeId || routeId == '') {
      return locationSearch.map((value: any) => (
        <Option key={value.id} value={value.id}>
          {value.name}
        </Option>
      ));
    } else {
      return locationSearch
        .filter((value: any) => value.routeId == form.getFieldValue(['routeId']))
        .map((value: any) => (
          <Option key={value.id} value={value.id}>
            {value.name}
          </Option>
        ));
    }
  };

  const isProductAlreadyAdded = (index: number, checkproductId: number) => {
    const lines = form.getFieldValue(['lines']);
    const findOne = lines?.find((curr: any) => curr.productId == checkproductId);
    if (findOne) {
      return false;
    }
    return true;
  };

  const confirmationPopup = (values: ISellConfirmationData) => {
    //console.log(values);
    setConfirmModalValues(values);
    setModalOpenForConfirmation(true);
  };

  const handleConfirmationModalSubmit = async () => {
    setIsloading(true);
    try {
      if (createValues) {
        const { lines, locationId } = createValues;
        const response = await get_sell_discount(locationId, Number(selectValue), lines);

        lines.forEach((line) => delete line.hsCode);

        const changedLines = [];

        for (const data of response) {
          const lineIndex = lines.findIndex((line) => line.productId === data.productId);
          const requestedDiscount = data.discount === 0 ? 0 : parseFloat(data.discount.toFixed(2));

          if (lineIndex !== -1) {
            const line = lines[lineIndex];
            const isDiscountSame = line.discount === requestedDiscount;
            if (!isDiscountSame) {
              // Replace the discount with the new one in the form values
              form.setFieldValue(['lines', lineIndex, 'discountPer'], data.discountPercent);
              onUnitandQuantityChange(lineIndex);
              changedLines.push(line.productName);
            }
          }
        }

        if (changedLines.length > 0) {
          CustomErrorModal({
            message: `Discount for ${changedLines.join(', ')} was updated. Please submit again!`
          });

          setIsloading(false);
          setModalOpenForConfirmation(false);
          return;
        }

        createValues.discount = 0;
        await createSellMutation.mutateAsync(createValues, {
          onSuccess: async ({ data }: { data: any }) => {
            setIsloading(false);
            if (data) {
              // console.log('data-->', data);
              message.success('Sell added successfully');
              if (isTask) {
                createTaskMutation.mutate({
                  name: ' ',
                  description: ' ',
                  assignee: null,
                  reference: 'sell',
                  referenceId: data.id,
                  status: 'assigned',
                  dueDate: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000)
                });
              }
              navigate('/sell');
            }
          },
          onError: async (data: any) => {
            setIsloading(false);
            setModalOpenForConfirmation(false);
            if (data.response.data.message === 'Error Discount is not valid') {
              await getAllDiscounts();
              CustomErrorModal({ message: 'Discount was updated. Please click submit again!' });
              return false;
            } else {
              message.error(data.response.data.message);
            }
          }
        });
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  // const handleConfirmationModalSubmit = useDebounceFunction(onConfirmationModalSubmit);
  const onFinish = useDebounceFunction(onFinishInitial);

  return (
    <div>
      <CustomModal
        isModalOpen={modalOpenForInvoiceDueLimit}
        setIsModalOpen={setModalOpenForInvoiceDueLimit}
        title={<div style={{ color: 'orangered' }}>Warning!</div>}
        footer={false}
        width="50%"
        handleCancel={() => setModalOpenForInvoiceDueLimit(true)}>
        <div>{errormessage}</div>
        <div className="flex justify-end gap-2 mt-3">
          <Button
            // type="primary"
            style={{
              backgroundColor: '#0AA245',
              color: 'white'
            }}
            onClick={() => {
              setModalOpenForInvoiceDueLimit(false);
            }}>
            Approve
          </Button>
          <Button
            style={{ backgroundColor: 'red', color: 'white' }}
            onClick={() => {
              // navigate('/sell');
              form.setFieldValue('userId', null);
              setModalOpenForInvoiceDueLimit(false);
            }}>
            Deny
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        isModalOpen={modalOpenForConfirmation}
        setIsModalOpen={setModalOpenForConfirmation}
        title={'Confirmation'}
        footer={false}>
        <ReusableSellConfirm
          data={confirmModalValues}
          isFinishLoading={isLoading}
          handleSubmitClick={handleConfirmationModalSubmit}
        />
      </CustomModal>
      <NotifySellPriceModal
        title={'Confirmation'}
        visible={confirm}
        onOk={async () => {
          setConfirm(false);
          await form.submit();
        }}
        onCancel={() => {
          setConfirm(false);
        }}
        data={notifyModalData}
      />

      <AppContent
        breadcrumbItems={breadcrumbItems}
        backgroundWhite={true}
        button={<GoBackButton onClick={() => navigate('/sell')} />}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          validateTrigger={'onChange'}
          disabled={isLoading}
          onValuesChange={(_, allFields) => {
            setProductDetails(allFields);
          }}
          autoComplete="off">
          <Card style={{ borderRadius: '10px' }}>
            <PageHeader
              title="Sell Information"
              style={{
                padding: '8px 0px'
              }}
            />
            <div className={'grid cols-1 md:grid-cols-3 gap-5 mb-5'}>
              {<LocationSearch onSelect={onLocationChange} notAll={true} required={true} />}

              <RouteSearch form={form} onSelect={() => searchUser('')} isClear={true} />
              {/* <CustomerSearch onSelect={onUserChange} /> */}
              <Form.Item
                name={['userId']}
                label="Customer"
                rules={[
                  {
                    required: true,
                    message: 'Please choose Customer!'
                  }
                ]}>
                <Select
                  showSearch
                  placeholder={'search user'}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  disabled={!isRouteSelected}
                  onChange={(value) => {
                    setselectValue(value);
                    onUserChange(value);
                  }}
                  dropdownMatchSelectWidth={false}
                  onSearch={(val) => {
                    setSearchValueUser(val);
                  }}
                  notFoundContent={null}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />

                      <div className="flex flex-col" style={{ padding: '0 8px 4px' }}>
                        {isMore ? (
                          <Button
                            type="text"
                            style={{
                              color: 'blue',
                              width: '100%'
                            }}
                            onClick={() => {
                              searchMoreUser(searchValueUser);
                            }}>
                            {/* {'Sync & Search on Server'} */}
                            {'Get More...'}
                          </Button>
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              textAlign: 'center'
                            }}>
                            No more data...
                          </div>
                        )}
                      </div>
                    </>
                  )}>
                  {optionsUser}
                </Select>
              </Form.Item>
            </div>
          </Card>
          {scoreBoardData && (
            <div className="card grid mb-5 mt-5 bg-white" style={{ borderRadius: '10px' }}>
              <ScoreBoard data={scoreBoardData} />
            </div>
          )}
          <Form.List name={['lines']}>
            {(fields2, { add: add2, remove: remove2 }, { errors: errors2 }) => (
              <>
                <div>
                  <div className={'card grid mb-5 mt-5 bg-white'} style={{ borderRadius: '10px' }}>
                    <PageHeader
                      subTitle="Add Product"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <ProductCategorySearch
                        setValue={productCategory}
                        onProductCategoryChange={(val) => {
                          setProductCategory(val);
                          if (val !== 'default') {
                            form.setFieldValue(['lines'], []);
                          }
                        }}
                        isAll={false}
                      />
                      <ProductSearchForLines
                        add2={add2}
                        onProductChange={onProductChange}
                        productList={productList}
                        setProductList={setProductList}
                        productSearch={productSearch}
                        setProductSearch={setProductSearch}
                        autofocusRef={autofocusRef}
                        isProductAlreadyAdded={isProductAlreadyAdded}
                        index={0}
                        locationId={form.getFieldValue(['locationId'])}
                        validation={productCategory !== null ? true : false}
                        productCategory={productCategory}
                      />
                    </div>
                  </div>
                  {fields2.length > 0 && (
                    <PageHeader
                      title="All Products"
                      style={{
                        padding: '8px 0px 8px 10px'
                      }}
                    />
                  )}
                </div>
                <Card
                  ref={scrollElement}
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'scroll',
                    // backgroundColor: 'gray',
                    borderRadius: '9px'
                  }}>
                  {fields2.map(({ key: key2, name: name2, ...restField2 }) => {
                    return (
                      <>
                        <div className="flex gap-2 items-center">
                          <span className="font-bold text-sm mb-5">{name2 + 1}.</span>
                          <div
                            key={key2}
                            className="card"
                            style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                            <div
                              className={
                                'grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-10'
                              }
                              key={key2}>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'productId']}
                                hidden></Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'autoFocus']}
                                hidden></Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'sellingPrice']}
                                hidden></Form.Item>
                              <Form.Item {...restField2} name={[name2, 'productName']} label="Name">
                                <Input
                                  type={'text'}
                                  disabled
                                  style={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    // border: '0px',
                                    fontWeight: 'bold'
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'unitId']}
                                label="Unit"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please choose a Unit!'
                                  }
                                ]}>
                                {
                                  <Select
                                    placeholder="Select a Unit!"
                                    onChange={(val: number) => onUnitChange(name2, val)}
                                    dropdownMatchSelectWidth={false}
                                    allowClear>
                                    {FilterUnits(name2)}
                                  </Select>
                                }
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'lotId']}
                                label="Lot"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please choose a Lot!'
                                  }
                                ]}>
                                {
                                  <Select
                                    placeholder="Select a Lot!"
                                    dropdownMatchSelectWidth={false}
                                    onChange={(value) => onLotChange(name2, value)}
                                    allowClear>
                                    {FilterLot(name2)}
                                  </Select>
                                }
                              </Form.Item>

                              <Form.Item {...restField2} label="HS Code" name={[name2, 'hsCode']}>
                                <Input disabled />
                              </Form.Item>

                              <ReusableQuantity
                                name={name2}
                                restField={restField2}
                                onChangeData={() => onUnitandQuantityChange(name2)}
                                onPressEnterData={(e) => {
                                  if (autofocusRef.current) {
                                    autofocusRef.current.focus();
                                  }
                                }}
                              />
                              <Form.Item
                                {...restField2}
                                name={[name2, 'priceGroupId']}
                                label="Price Group"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Select Price Group'
                                  }
                                ]}>
                                {
                                  <Select
                                    placeholder="Select price group"
                                    dropdownMatchSelectWidth={false}
                                    onChange={() => onPriceGroupChange(name2)}
                                    allowClear>
                                    {pricegroupDropdown?.map((value: any) => (
                                      <Option value={value.id} key={value.id}>
                                        {value.name}
                                      </Option>
                                    ))}
                                  </Select>
                                }
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                label="Rate"
                                name={[name2, 'unitPrice']}
                                rules={[
                                  { required: true, message: 'Please add Rate!' },
                                  () => ({
                                    validator(_: any, value: any) {
                                      if (!value) {
                                        return Promise.reject(`Please input valid Rate!`);
                                      }
                                      if (value === 0) {
                                        return Promise.reject(`Please Input valid Rate!`);
                                      }

                                      return Promise.resolve();
                                    }
                                  })
                                ]}>
                                <InputNumber
                                  min={0}
                                  onChange={() => onUnitandQuantityChange(name2)}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                name={[name2, 'discountPer']}
                                initialValue="0"
                                hidden>
                                <InputNumber disabled={true} />
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                label="Discount"
                                name={[name2, 'discount']}
                                rules={[{ required: true, message: 'Please add Discount!' }]}>
                                <InputNumber
                                  min={0}
                                  disabled={true}
                                  style={{ color: 'black' }}
                                  onChange={() => onUnitandQuantityChange(name2)}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField2}
                                label="Misc"
                                name={[name2, 'misc']}
                                rules={[{ required: true, message: 'Please add Misc!' }]}>
                                <InputNumber
                                  min={0}
                                  onChange={() => onUnitandQuantityChange(name2)}
                                />
                              </Form.Item>
                              <Form.Item {...restField2} label="VAT" name={[name2, 'vat']}>
                                <InputNumber
                                  precision={2}
                                  min={0}
                                  disabled
                                  style={{ color: 'black' }}
                                />
                              </Form.Item>

                              {/* <div className="flex items-center justify-between gap-2"> */}
                              <Form.Item {...restField2} name={[name2, 'total']} label="Total">
                                <InputNumber min={0} disabled style={{ color: 'black' }} />
                              </Form.Item>

                              {fields2.length > 0 ? (
                                <div className="flex items-center justify-start mt-5">
                                  <CustomButton
                                    backgroundColor="white"
                                    text="Remove"
                                    textColor="green"
                                    onClick={async () => {
                                      // console.log('scrool Element--> ', scrollElement);
                                      const x = scrollElement.current?.scrollTop;
                                      remove2(name2);
                                      const y = scrollElement.current?.scrollTop;
                                      if (scrollElement.current && typeof y == 'number') {
                                        scrollElement.current.scrollTop = y;
                                      }
                                      await calculateTotal();
                                      // console.log('x y', x, y);
                                    }}
                                  />
                                </div>
                              ) : null}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Card>
              </>
            )}
          </Form.List>
          {form.getFieldValue(['lines'])?.length > 0 ? (
            <Card
              style={{ borderRadius: '10px', marginBottom: '10px', marginTop: '10px' }}
              className="grid grid-cols-2">
              <Form.Item name={['grandtotal']} label="Grand Total (Without Shipping)">
                <InputNumber min={0} disabled style={{ color: 'black' }} />
              </Form.Item>
            </Card>
          ) : null}
          <div
            className={'card grid grid-cols-2 gap-3 mb-3 md:grid-cols-3 lg:grid-cols-4 bg-white'}
            style={{ borderRadius: '10px' }}>
            <Form.Item
              name={['addressId']}
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Please choose address!'
                }
              ]}>
              <Select placeholder="Select a address!" allowClear dropdownMatchSelectWidth={false}>
                {address?.map((value: IUSerlocation) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {`${value.addressLine1}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name={['taxId']}
              label="Tax"
              rules={[
                {
                  required: true,
                  message: 'Please choose tax number!'
                }
              ]}>
              <Select
                placeholder="Select a tax number!"
                allowClear
                dropdownMatchSelectWidth={false}>
                {tax?.map((value: ITaxtype) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {`${value.taxNumber}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* <Form.Item
              name={['offerId']}
              label="Offer"
              rules={[
                {
                  required: false,
                  message: 'Please choose offer!'
                }
              ]}>
              <Select placeholder="Select a offer!" allowClear dropdownMatchSelectWidth={false}>
                {offerList?.map((value: IValuesforsell) => {
                  return (
                    <Option value={value.id} key={value.id}>
                      {`${value.name}(${value.code})`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              name={['applyOffer']}
              label="Apply Offer"
              valuePropName="checked"
              initialValue={true}>
              <Checkbox
                onChange={(e) => {
                  if (!e.target.checked) {
                    setLatestOfferId(form.getFieldValue(['offerId']));
                    form.setFieldValue(['offerId'], null);
                  } else {
                    form.setFieldValue(['offerId'], latestofferId);
                  }
                }}
              />
            </Form.Item> */}
            <Form.Item
              label="Discount"
              name={['discount']}
              rules={[{ required: true, message: 'Please add Discount!' }]}>
              <InputNumber disabled={true} style={{ color: 'black' }} min={0} />
            </Form.Item>
            <Form.Item
              label="Misc"
              name={['misc']}
              rules={[{ required: true, message: 'Please add misc!' }]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="Shipping"
              name={['shipping']}
              rules={[{ required: true, message: 'Please add shipping!' }]}>
              <InputNumber
                min={0}
                disabled={!manualShipping}
                onChange={(val) => calculateShippingWithTax(val)}
                addonAfter={
                  <Form.Item name={['isCustomShipping']} noStyle initialValue={false}>
                    <Select
                      onChange={async (val) => {
                        setManualShipping(val);
                        const grandtotal = form.getFieldValue(['grandtotal']);
                        if (grandtotal !== undefined || grandtotal !== null) {
                          await calculateShipping(grandtotal);
                        }
                      }}
                      dropdownMatchSelectWidth={false}>
                      <Option value={true}>Enabled</Option>
                      <Option value={false}>Disabled</Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
            <Form.Item label="Shipping (With Tax)" name={['shippingWithTax']}>
              <InputNumber min={0} disabled={true} />
            </Form.Item>
            <Form.Item
              label="Adjustment"
              name={['adjustment']}
              rules={[{ required: true, message: 'Please add adjustment!' }]}>
              <InputNumber min={0} />
            </Form.Item>
          </div>
          <Form.Item
            label="Note"
            name={['note']}
            rules={[{ required: false, message: 'Please add adjustment!' }]}>
            <Input />
          </Form.Item>
          <Checkbox
            // defaultChecked
            onChange={(value) => {
              setIsTask(value.target.checked);
            }}>
            Create Task?
          </Checkbox>
          <div className="flex justify-end mt-5">
            <Form.Item>
              <DebounceButton
                type="primary"
                disabled={!hasRule}
                onClick={async () => {
                  const errorMessages = await notifyMessage(
                    form.getFieldValue(['lines']),
                    purchasedPrices
                  );
                  if (errorMessages.length > 0) {
                    setCreateValues(form.getFieldsValue());
                    setConfirm(true);
                    setNotifyModalData(errorMessages);
                  } else {
                    form.submit();
                  }
                }}>
                Submit
              </DebounceButton>
              {/* <CustomSubmitButton text="Submit" /> */}
            </Form.Item>
          </div>
        </Form>
      </AppContent>
    </div>
  );
};

export default CreateSell;
